import {
  GlobalStyles,
  ThemeProvider,
  Typography,
} from '@bbnpm/bb-ui-framework';
import styled from 'styled-components';

const StyledContainer = styled.div`
  width: 40%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10rem;
  text-align: center;
`;

const StyledText = styled(Typography.Hero)`
  font-size: 6rem;
`;

const Error = () => (
  <ThemeProvider>
    <GlobalStyles />
    <StyledContainer>
      <StyledText>404</StyledText>
      <Typography.PageTitle>Resource Not Found</Typography.PageTitle>
      <Typography.Text>
        Sorry, the page you&apos;re looking for was not found
      </Typography.Text>
    </StyledContainer>
  </ThemeProvider>
);

export default Error;
