import { arrayOf, number, objectOf, shape, string } from 'prop-types';

import { ProfessorPackProps } from '../../types';
import { PackResourceProps } from '../pack_resources/types';

export const PackSectionProps = {
  id: number,
  title: string,
  listOrder: number,
  sectionType: string,
  createdAt: string,
  professorPack: shape(ProfessorPackProps),
  href: string,
  editAdminPackSectionPath: string,
  packResources: arrayOf(shape(PackResourceProps)),
  newAdminPackSectionPackResourcePath: string,
  errors: objectOf(arrayOf(string)),
};

export const ShowProps = PackSectionProps;
export const PackSectionFormProps = {
  ...PackSectionProps,
  adminProfessorPackPackSectionsPath: string,
  sectionTypes: arrayOf(arrayOf(string)),
};
