import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import memoize from 'lodash.memoize';
import startcase from 'lodash.startcase';
import {
  arrayOf,
  func,
  number,
  object,
  objectOf,
  shape,
  string,
} from 'prop-types';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Col, Form, Modal, Row, Table } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { useAsyncFn, useToggle } from 'react-use';

import { get } from '../../api';
import { isNullish, toCurrency } from '../../utils';
import AutosuggestInput from '../AutosuggestInput';
import Container from '../Container';
import Input from '../Input';
import PostForm from '../PostForm';
import Spinner from '../Spinner';
import { ProductProps } from '../types';

const CountryHelp = () => {
  const [show, toggleShow] = useToggle(false);

  return (
    <>
      <Button onClick={toggleShow} variant="link" className="btn-text">
        I can&apos;t find my country
      </Button>
      <Modal show={show} onHide={toggleShow} centered>
        <Modal.Body>
          Bloomberg for Education provides the list of countries it currently
          accepts transactions from within.
        </Modal.Body>
      </Modal>
    </>
  );
};

const entityKeyFn = country => country.gec;
const entityValueFn = country => country.name;

const stripeElementOptions = {
  classes: {
    base: 'form-control',
    invalid: 'is-invalid',
  },
  style: { base: { fontSize: '16px', lineHeight: 1.5 } },
};

const stripeComponentFor = ({ Component, onBlur, disabled, label }) => {
  const StripeComponent = ({ name, value, onChange }) => (
    <Input
      required
      name={name}
      label={label}
      inputRender={() => (
        <Component
          onChange={onChange}
          onBlur={onBlur}
          options={{ ...stripeElementOptions, disabled }}
        />
      )}
      errors={value?.error ? [value.error.message] : []}
    />
  );

  StripeComponent.propTypes = {
    name: string.isRequired,
    value: object,
    onChange: func,
  };

  return StripeComponent;
};

const Payment = ({
  promoCodeId,
  classCode,
  product,
  activeProductPrice,
  quantity,
  productVariationId,
  batClassId,
  user,
  tosUrl,
  countries: initialCountries,
  ordersPath,
  calculateTaxOrdersPath,
  countriesRequiredStateCity,
}) => {
  const countries = useMemo(
    () =>
      Object.keys(initialCountries).map(k => ({
        name: startcase(k),
        gec: initialCountries[k],
      })),
    [initialCountries],
  );

  const { register, getValues, watch, control } = useForm();
  const tosAccepted = watch('tos', false);

  const price = parseFloat(product.price);
  const discountedPrice = Math.min(price, parseFloat(activeProductPrice));

  const originalTotal = price * quantity;
  const discountedTotal = discountedPrice * quantity;
  const discount =
    discountedTotal < originalTotal ? originalTotal - discountedTotal : 0;

  const [{ loading: taxesLoading, value: taxes }, getTaxes] = useAsyncFn(
    memoize(
      async (zipCode, country) => {
        if (zipCode && country) {
          const { taxes } = await get(calculateTaxOrdersPath, {
            zipCode,
            country,
            quantity,
            unitPrice: discountedPrice,
          });
          return parseFloat(taxes ?? 0);
        } else {
          return Promise.resolve(null);
        }
      },
      (...args) => args.join(),
    ),
    [calculateTaxOrdersPath, discountedPrice, quantity],
  );

  const actualTotal = discountedTotal + (taxes ?? 0);
  const isFormDisabled = actualTotal == 0;

  const handleGetTaxes = useCallback(() => {
    const { billZipCode, billCountry } = getValues([
      'billZipCode',
      'billCountry',
    ]);
    getTaxes(billZipCode, billCountry);
  }, [getValues, getTaxes]);

  const elements = useElements();
  const stripe = useStripe();

  const [billCountry, setBillCountry] = useState();
  const [stripeToken, setStripeToken] = useState();
  const isStateCityRequired = countriesRequiredStateCity.includes(billCountry);

  const onEntityKeyChange = useCallback(
    billCountry => {
      setBillCountry(billCountry);
      handleGetTaxes();
    },
    [handleGetTaxes],
  );

  const values = watch([
    'cardNumber',
    'expiration',
    'cvc',
    'firstName',
    'lastName',
    'billCountry',
  ]);

  const canCreateToken =
    ['cardNumber', 'expiration', 'cvc'].every(k => values[k]?.complete) &&
    ['firstName', 'lastName', 'billCountry'].every(k => values[k]);

  const isFormComplete =
    tosAccepted &&
    canCreateToken &&
    (isFormDisabled || (!taxesLoading && !isNullish(taxes)));

  const [isSubmitting, toggleIsSubmitting] = useToggle(false);

  const formRef = useRef();

  const handleSubmit = async e => {
    e.preventDefault();

    toggleIsSubmitting();

    const { firstName, lastName, billCountry } = getValues();
    const cardNumberElement = elements.getElement(CardNumberElement);

    const { token } = await stripe.createToken(cardNumberElement, {
      name: `${firstName} ${lastName}`,
      address_country: billCountry,
    });

    setStripeToken(token?.id);

    formRef.current.submit();
  };

  return (
    <Container>
      <h1 className="border-bottom my-5 pb-3 text-center">
        Complete Your Purchase
      </h1>
      <PostForm ref={formRef} action={ordersPath} onSubmit={handleSubmit}>
        <Row>
          <Col sm={7}>
            <Form.Row>
              <Col sm={6}>
                <Controller
                  control={control}
                  name="cardNumber"
                  defaultValue=""
                  render={stripeComponentFor({
                    Component: CardNumberElement,
                    disabled: isFormDisabled,
                  })}
                />
              </Col>
              <Col sm={3}>
                <Controller
                  control={control}
                  name="expiration"
                  defaultValue=""
                  render={stripeComponentFor({
                    Component: CardExpiryElement,
                    disabled: isFormDisabled,
                  })}
                />
              </Col>
              <Col sm={3}>
                <Controller
                  control={control}
                  name="cvc"
                  defaultValue=""
                  render={stripeComponentFor({
                    Component: CardCvcElement,
                    disabled: isFormDisabled,
                    label: 'CVC/CVV',
                  })}
                />
              </Col>
            </Form.Row>
            <Form.Row>
              <Col>
                <Input
                  required
                  name="firstName"
                  label="Cardholder First Name"
                  defaultValue={user.firstName}
                  ref={register}
                  disabled={isFormDisabled}
                />
              </Col>
              <Col>
                <Input
                  required
                  name="lastName"
                  label="Cardholder Last Name"
                  defaultValue={user.lastName}
                  ref={register}
                  disabled={isFormDisabled}
                />
              </Col>
            </Form.Row>
            <Form.Row>
              <Col>
                <AutosuggestInput
                  helpText={<CountryHelp />}
                  required
                  isFetchAsync={false}
                  initialSuggestions={countries}
                  entityKeyFn={entityKeyFn}
                  entityValueFn={entityValueFn}
                  shouldRenderSuggestions={true}
                  name="billCountry"
                  label="Country"
                  placeholder="type to enter country"
                  ref={register}
                  onEntityKeyChange={onEntityKeyChange}
                  disabled={isFormDisabled}
                />
              </Col>
              <Col>
                <Input
                  required
                  ref={register}
                  name="billZipCode"
                  label="Zip Code"
                  onBlur={handleGetTaxes}
                  disabled={isFormDisabled}
                />
              </Col>
            </Form.Row>
            {isStateCityRequired && (
              <Form.Row>
                <Col>
                  <Input
                    required
                    name="billCity"
                    label="City"
                    defaultValue={''}
                    ref={register}
                    disabled={isFormDisabled}
                  />
                </Col>
                <Col>
                  <Input
                    required
                    name="billState"
                    label="State"
                    defaultValue={''}
                    ref={register}
                    disabled={isFormDisabled}
                  />
                </Col>
              </Form.Row>
            )}
            <p className="text-right font-size-sm">
              <Button
                variant="link"
                className="btn-text"
                onClick={() => {
                  window.history.back();
                }}>
                Go back
              </Button>
            </p>
          </Col>
          <Col sm={5}>
            <Table borderless size="sm" className="table-white-space-normal">
              <tbody>
                <tr>
                  <td>
                    {product.name} {classCode?.freeBmc ? '(Waived)' : ''}{' '}
                    {quantity > 1 ? `(qty: ${quantity})` : ''}
                  </td>
                  <td className="text-right">{toCurrency(originalTotal)}</td>
                </tr>
                {discount > 0 && (
                  <tr>
                    <td>Discount</td>
                    <td className="text-right">-{toCurrency(discount)}</td>
                  </tr>
                )}
                <tr>
                  <td>Subtotal</td>
                  <td className="text-right">{toCurrency(discountedTotal)}</td>
                </tr>
                <tr>
                  <td>Tax</td>
                  <td className="text-right">
                    {taxesLoading ? (
                      <Spinner />
                    ) : isNullish(taxes) ? (
                      '--'
                    ) : (
                      toCurrency(taxes)
                    )}
                  </td>
                </tr>
              </tbody>
            </Table>
            <div className="d-flex justify-content-between font-weight-bold font-size-lg border-top my-4 pt-4">
              <div>Total</div>
              <div>{toCurrency(actualTotal)}</div>
            </div>

            <input
              type="hidden"
              name="stripeToken"
              defaultValue={stripeToken}
            />

            <input type="hidden" name="quantity" defaultValue={quantity} />
            <input type="hidden" name="productSku" defaultValue={product.sku} />

            {classCode && (
              <input
                type="hidden"
                name="classCode"
                defaultValue={classCode.code}
              />
            )}

            {promoCodeId && (
              <input
                type="hidden"
                name="promoCodeId"
                defaultValue={promoCodeId}
              />
            )}

            {productVariationId && (
              <input
                type="hidden"
                name="productVariationId"
                defaultValue={productVariationId}
              />
            )}

            {batClassId && (
              <input
                type="hidden"
                name="batClassId"
                defaultValue={batClassId}
              />
            )}

            <Input
              ref={register}
              name="tos"
              type="checkbox"
              label={
                <div className="text-justify">
                  I signify that I have read and agree to the{' '}
                  <a href={tosUrl} target="_blank" rel="noreferrer">
                    Terms of Service
                  </a>
                  , and that I accept immediate access to the Bloomberg Market
                  Concepts course and agree to waive any withdrawal rights
                  available to me under applicable law.
                </div>
              }
            />

            <Button
              type="submit"
              disabled={!isFormComplete || isSubmitting}
              className="btn-block mb-3">
              Purchase
            </Button>
          </Col>
        </Row>
      </PostForm>
    </Container>
  );
};

Payment.propTypes = {
  classCode: string,
  product: shape(ProductProps).isRequired,
  activeProductPrice: string.isRequired,
  quantity: number.isRequired,
  productVariationId: string,
  batClassId: number,
  user: shape({
    firstName: string.isRequired,
    lastName: string.isRequired,
  }).isRequired,
  tosUrl: string.isRequired,
  countries: objectOf(string).isRequired,
  ordersPath: string.isRequired,
  calculateTaxOrdersPath: string.isRequired,
  countriesRequiredStateCity: arrayOf(string).isRequired,
};

const StripeWrapper = ({ stripeKey, ...props }) => {
  const [stripePromise, setStripePromise] = useState();
  useEffect(
    () => setStripePromise(loadStripe(stripeKey), [stripeKey]),
    [stripeKey],
  );

  return stripePromise ? (
    <Elements stripe={stripePromise}>
      <Payment {...props} />
    </Elements>
  ) : null;
};

StripeWrapper.propTypes = { stripeKey: string.isRequired };

export default StripeWrapper;
