import axios from 'axios';

import { csrfFromDom } from './utils';

const withAuth = async cb => {
  try {
    const { data } = await cb();
    return data;
  } catch (error) {
    if (error.response.status === 401) {
      if (error.response.data?.message)
        window.sessionStorage.setItem(
          'flash',
          JSON.stringify({ alert: error.response.data.message }),
        );
      window.Turbolinks.visit('/');
    } else {
      throw error;
    }
  }
};

export const post = (path, values) => {
  const { param, token } = csrfFromDom();
  return withAuth(() => axios.post(path, { ...values, [param]: token }));
};

export const create = post;

export const put = (path, values) => {
  const { param, token } = csrfFromDom();
  return withAuth(() => axios.put(path, { ...values, [param]: token }));
};

export const sendAsForm = (url, formData, method) => {
  const { param, token } = csrfFromDom();
  formData.append(param, token);

  return withAuth(() =>
    axios({
      method,
      url,
      data: formData,
      headers: {
        'Content-Type': `multipart/form-data`,
      },
    }),
  );
};

export const update = put;

export const destroy = path => {
  const { param, token } = csrfFromDom();
  return withAuth(() => axios.delete(path, { params: { [param]: token } }));
};

export const get = (path, params, options) =>
  withAuth(() => axios.get(path, { ...options, params }));
