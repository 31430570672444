import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import cn from 'classnames';
import startcase from 'lodash.startcase';
import { node, string } from 'prop-types';
import { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { createBreakpoint, useEffectOnce, useToggle } from 'react-use';

import { get, post } from '../api';
import { csrfFromDom } from '../utils';

export const useFetchData = (path, cb) =>
  useCallback(params => get(path, params).then(cb), [path, cb]);

export const usePostData = (path, cb) =>
  useCallback(body => post(path, body).then(cb), [path, cb]);

export const UPLOAD_FILE_STATUSES = {
  INIT: 'INIT',
  UPLOAD_STARTED: 'UPLOAD_STARTED',
  IMPORT_STARTED: 'IMPORT_STARTED',
  DONE: 'DONE',
  FAILED: 'FAILED',
};

export const useUploadFile = ({ rusticiToken, rusticiBaseUrl }) => {
  const uploadUrl = `${rusticiBaseUrl}/courses/importJobs/upload`;
  const checkJobUrl = `${rusticiBaseUrl}/courses/importJobs`;

  const initialState = {
    status: UPLOAD_FILE_STATUSES.INIT,
    uploadFileProgress: { loaded: 0, total: 0 },
  };

  const { reducer, actions } = createSlice({
    name: 'uploadFile',
    initialState,
    reducers: {
      uploadFileStart: state => {
        state.status = UPLOAD_FILE_STATUSES.UPLOAD_STARTED;
      },
      uploadFileProgress: (state, action) => {
        state.uploadFileProgress = action.payload;
      },
      fileImportStarted: state => {
        state.status = UPLOAD_FILE_STATUSES.IMPORT_STARTED;
      },
      fileImportComplete: state => {
        state.status = UPLOAD_FILE_STATUSES.DONE;
      },
      err: (state, action) => {
        state.status = UPLOAD_FILE_STATUSES.FAILED;
        state.error = action.payload;
      },
      reset: () => initialState,
    },
  });

  const [state, dispatch] = useReducer(reducer, initialState);

  const uploadFile = async ({
    file,
    id,
    markUploadedPath,
    onComplete,
    mayCreateNewVersion = false,
  }) => {
    dispatch(actions.uploadFileStart());

    const formData = new FormData();
    formData.append('file', file);

    try {
      const headers = {
        Authorization: `Basic ${rusticiToken}`,
        engineTenantName: 'default',
      };

      const response = await axios.post(
        `${uploadUrl}?courseId=${id}&mayCreateNewVersion=${mayCreateNewVersion}`,
        formData,
        {
          headers,
          onUploadProgress: ({ loaded, total }) =>
            dispatch(actions.uploadFileProgress({ loaded, total })),
        },
      );

      dispatch(actions.fileImportStarted());

      const jobId = response.data.result;

      const intervalId = setInterval(async () => {
        const importJobResponse = await axios.get(`${checkJobUrl}/${jobId}`, {
          headers,
        });

        if (importJobResponse.data.status === 'COMPLETE') {
          clearInterval(intervalId);
          if (!mayCreateNewVersion) await post(markUploadedPath);
          dispatch(actions.fileImportComplete());
          onComplete && onComplete();
        }
      }, 2000);
    } catch (error) {
      const {
        status,
        data: { message },
      } = error.response;
      dispatch(actions.err({ status, message }));
    }
  };

  const reset = () => {
    dispatch(actions.reset());
  };

  return { ...state, uploadFile, reset };
};

export const useCsrf = () => {
  const [csrf, setCsrf] = useState();
  useEffect(() => {
    setCsrf(csrfFromDom());
  }, []);
  return csrf;
};

export const useIsMounted = () => {
  const [isMounted, toggleIsMounted] = useToggle(false);
  useEffectOnce(toggleIsMounted);
  return isMounted;
};

const filterActive = ({ active }) => active;
export const useActiveFilterMemo = collection =>
  useMemo(() => collection.filter(filterActive), [collection]);

export const useWizard = (steps, initialActiveStep = 1) => {
  const [activeStep, setActiveStep] = useState(initialActiveStep);

  const hasNextStep = activeStep !== steps.length;
  const hasPrevStep = activeStep !== 1;

  const nextStep = e => {
    // For some reason, the form gets submitted once it reaches the final step.
    // This prevents that.
    e.preventDefault();
    setActiveStep(hasNextStep ? activeStep + 1 : 1);
  };
  const prevStep = () =>
    setActiveStep(hasPrevStep ? activeStep - 1 : steps.length);

  const Nav = ({ as: Component = 'div', className, ...rest }) => (
    <Component className={cn('d-flex wizard-steps', className)} {...rest}>
      {steps.map((step, i) => (
        <div
          className={cn('py-2 flex-grow-1 text-center', {
            active: i + 1 === activeStep,
          })}
          key={step}>
          {startcase(step)}
        </div>
      ))}
    </Component>
  );

  Nav.propTypes = {
    as: node,
    className: string,
  };

  return {
    Nav,
    nextStep,
    hasNextStep,
    prevStep,
    hasPrevStep,
    value: steps[activeStep - 1],
  };
};

// Must correspond to BS $grid-breakpoints
export const useBreakpoint = createBreakpoint({
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
});
