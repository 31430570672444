import { arrayOf, oneOfType, shape, string } from 'prop-types';

import { UserProps } from '../../types';

export const PurchaseEntitlementFormProps = {
  productSku: string,
  href: string,
  reason: string,
  products: arrayOf(arrayOf(oneOfType([string, string]))),
  user: shape(UserProps),
};
