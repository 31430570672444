import {
  GlobalStyles,
  ThemeProvider,
  Tooltip,
  Typography,
  VirtualList,
  VirtualListGroup,
  VirtualListItem,
} from '@bbnpm/bb-ui-framework';
import { arrayOf, number, shape, string } from 'prop-types';
import { Col, Row } from 'react-bootstrap';

import Container from '../Container';
import Hero from '../Hero';
import {
  BatClassProps,
  CourseVersionProps,
  ProfessorPackProps,
  VideoProps,
} from '../types';
import {
  StyledCompleted,
  StyledGroupLabel,
  StyledInProgress,
  StyledItemLabel,
  StyledLink,
  StyledNumberText,
  StyledScore,
} from './batClasses.styles';

const statusElement = status => {
  switch (status) {
    case 'COMPLETED':
      return <StyledCompleted>Complete</StyledCompleted>;
    case 'IN_PROGRESS':
      return <StyledInProgress>In Progress</StyledInProgress>;
    case 'NOT_STARTED':
      return <Typography.Caption>Not Started</Typography.Caption>;
  }
};

const GroupLabel = ({ name, items }) => (
  <StyledGroupLabel>
    <Typography.SectionTitle>{name}</Typography.SectionTitle>
    &nbsp; &nbsp;
    <StyledNumberText>{items} Items</StyledNumberText>
  </StyledGroupLabel>
);

const ListItemLabel = ({ name, description, status, href, score }) => (
  <StyledLink href={href}>
    <Tooltip content={description} placement="top-start">
      <StyledItemLabel>
        <div>
          <Typography.SectionTitle>{name}</Typography.SectionTitle>
          {status && statusElement(status)}
        </div>
        {score && (
          <StyledScore>
            <Typography.Caption>Score</Typography.Caption>
            <Typography.Text>{score}</Typography.Text>
          </StyledScore>
        )}
      </StyledItemLabel>
    </Tooltip>
  </StyledLink>
);

const Show = ({
  nameAlias,
  videos,
  courseVersions,
  caseStudies,
  classCode,
  expiredAt,
  professor,
}) => {
  return (
    <ThemeProvider>
      <GlobalStyles />
      <Hero
        title={nameAlias}
        link={{
          path: '/classes',
          name: 'Back to Class Enrollments',
          icon: 'arrow-left',
        }}
        description={
          <Row>
            <Col>
              <Typography.Text>
                <strong>ID:</strong> &nbsp; {classCode}
              </Typography.Text>
            </Col>
            <Col>
              <Typography.Text>
                <strong>Professor:</strong> &nbsp; {professor.fullName}
              </Typography.Text>
            </Col>
            <Col>
              <Typography.Text>
                <strong>Deadline:</strong> &nbsp; {expiredAt}
              </Typography.Text>
            </Col>
          </Row>
        }
      />
      <Container>
        <VirtualList height={2000}>
          <VirtualListGroup
            label={<GroupLabel name="Courses" items={courseVersions.length} />}
            groupId={1}>
            {courseVersions.map(cv => (
              <VirtualListItem
                key={cv.id}
                label={
                  <ListItemLabel
                    name={cv.name}
                    description={cv.description}
                    status={cv.userInfo.status}
                    score={cv.userInfo.score ? cv.userInfo.score + '%' : '-'}
                    href={cv.href}
                  />
                }
                href={cv.href}
              />
            ))}
          </VirtualListGroup>
          <VirtualListGroup
            label={<GroupLabel name="Videos" items={videos.length} />}
            groupId={2}>
            {videos.map(video => (
              <VirtualListItem
                key={video.id}
                label={
                  <ListItemLabel
                    name={video.name}
                    description={video.description}
                    status={video.userInfo.status}
                    href={video.href}
                  />
                }
              />
            ))}
          </VirtualListGroup>
          <VirtualListGroup
            label={
              <GroupLabel name="Case Studies" items={caseStudies.length} />
            }
            groupId={3}>
            {caseStudies.map(cs => (
              <VirtualListItem
                key={cs.id}
                label={
                  <ListItemLabel
                    name={cs.title}
                    description={cs.description}
                    href={cs.href}
                  />
                }
              />
            ))}
          </VirtualListGroup>
        </VirtualList>
      </Container>
    </ThemeProvider>
  );
};

Show.propTypes = {
  ...BatClassProps,
  createWithClassCodeClassEnrollmentsPath: string,
  batClassesPath: string,
  batClasses: arrayOf(shape(BatClassProps)),
  caseStudies: arrayOf(shape(ProfessorPackProps)),
  videos: arrayOf(shape(VideoProps)),
  courseVersions: arrayOf(shape(CourseVersionProps)),
};

GroupLabel.propTypes = { name: string.isRequired, items: number.isRequired };

ListItemLabel.propTypes = {
  name: string.isRequired,
  description: string.isRequired,
  href: string.isRequired,
  status: string,
  score: string,
};

export default Show;
