import { bool, func, string } from 'prop-types';

export const BssoOptInModalProps = {
  show: bool.isRequired,
  onHide: func.isRequired,
  bssoUrl: string.isRequired,
};

export const BssoOptOutModalProps = {
  show: bool.isRequired,
  onHide: func.isRequired,
};
