import { Button, Input, Typography } from '@bbnpm/bb-ui-framework';
import styled from 'styled-components';

export const ButtonGroup = styled.div`
  display: flex;
  margin-top: 40px;
  justify-content: center;
`;

export const StyledInput = styled(Input)`
  width: 65%;
  position: absolute;
  right: 10%;
`;

export const StyledButton = styled(Button)`
  position: absolute;
  right: 0;
`;

export const StyledSectionTitle = styled(Typography.SectionTitle)`
  border-bottom: 1px solid black;
  margin-bottom: 20px;
`;

export const SwitchContainer = styled.div`
  width: 65%;
  position: absolute;
  right: 10%;
`;
