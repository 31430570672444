import {
  Button,
  GlobalStyles,
  ThemeProvider,
  Typography,
} from '@bbnpm/bb-ui-framework';
import { Col, Row } from '@bloomberg/styled-flexboxgrid';

import { BfeGrid } from '../../../styles/grid';
import { ClientDateTime } from '../../ClientDate';
import { StatusIcon } from '../../CommonComponents';
import { TradingChallengeProps } from '../../types';
import LeaderboardsTable from '../leaderboards/Table';
import RegistrationsTable from '../trading_challenge_registrations/Table';

const KeyDatesInfo = ({ dateInfos }) =>
  dateInfos.map(({ label, value }, idx) => (
    <Col md={4} key={idx}>
      <Typography.Label>
        <strong>{label}:</strong>
      </Typography.Label>
      {value}
    </Col>
  ));

const Show = ({
  name,
  overview,
  description,
  active,
  registrationStart,
  registrationEnd,
  challengeStart,
  challengeEnd,
  startingPositionsDeadline,
  newAdminTradingChallengeLeaderboardPath,
  leaderboards,
  leaderboardsPath,
  registrationsPath,
  editAdminTradingChallengePath,
  adminIndexHref,
  leaderboardTableProps,
  registrationTableProps,
  registrations,
  adminInstitutionsPath,
  institutionTeamCountCsvPath,
  participantsInfosCsvPath,
  newRegistrationPath,
}) => {
  return (
    <ThemeProvider>
      <GlobalStyles />
      <BfeGrid>
        <Row>
          <Button
            as="a"
            kind="tertiary"
            icon="arrow-left"
            href={adminIndexHref}>
            Back to Trading Challenges
          </Button>
        </Row>

        <Row className="d-flex justify-content-between align-items-center">
          <Col>
            <Typography.PageTitle>Name: {name}</Typography.PageTitle>
          </Col>

          <Col className="d-flex">
            <Button
              className="mr-1"
              kind="secondary"
              as="a"
              href={editAdminTradingChallengePath}>
              Edit
            </Button>
          </Col>
        </Row>

        <Row>
          <Col>
            <Typography.Text>
              <strong>Active:</strong>
              <br />
              <StatusIcon value={active} />
            </Typography.Text>
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <Typography.Text>
              <strong>Overview:</strong> <br /> {overview}
            </Typography.Text>
            <Typography.Text>
              <strong>Description:</strong>
              <br /> {description}
            </Typography.Text>
          </Col>
        </Row>

        <Row>
          <KeyDatesInfo
            dateInfos={[
              {
                label: 'Registration Window',
                value: (
                  <Typography.Text>
                    <ClientDateTime date={registrationStart} /> -{' '}
                    <ClientDateTime date={registrationEnd} />
                  </Typography.Text>
                ),
              },
              {
                label: 'Challenge Duration',
                value: (
                  <Typography.Text>
                    <ClientDateTime date={challengeStart} /> -{' '}
                    <ClientDateTime date={challengeEnd} />
                  </Typography.Text>
                ),
              },
              {
                label: 'Deadline for starting positions',
                value: (
                  <Typography.Text>
                    <ClientDateTime date={startingPositionsDeadline} />
                  </Typography.Text>
                ),
              },
            ]}
          />
        </Row>

        <Row>
          <Button as="a" href={institutionTeamCountCsvPath} className="mr-3">
            Institution Team Count CSV
          </Button>
          <Button as="a" href={participantsInfosCsvPath}>
            Complete Registrations CSV
          </Button>
        </Row>

        <LeaderboardsTable
          leaderboards={leaderboards}
          path={leaderboardsPath}
          leaderboardTableProps={leaderboardTableProps}
          newAdminTradingChallengeLeaderboardPath={
            newAdminTradingChallengeLeaderboardPath
          }
        />

        <RegistrationsTable
          registrations={registrations}
          path={registrationsPath}
          registrationTableProps={registrationTableProps}
          adminInstitutionsPath={adminInstitutionsPath}
          newRegistrationPath={newRegistrationPath}
        />
      </BfeGrid>
    </ThemeProvider>
  );
};

export default Show;

Show.propTypes = TradingChallengeProps;
