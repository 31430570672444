import { Banner, Typography } from '@bbnpm/bb-ui-framework';

import { NotEnabledNoticeProps } from './types';

const NotEnabledNotice = ({ batContactLink }) => (
  <>
    <Banner
      kind="warning"
      message="You are not enabled for Bloomberg Businessweek Case Studies"
    />
    <br />
    <Typography.Text>
      <strong>
        Access to Bloomberg Businessweek Case Studies requires your campus to
        have at least 3 terminals. To learn more about this offering please see
        below or contact us directly at{' '}
        <Typography.Link href={`mailto:${batContactLink}`}>
          {batContactLink}
        </Typography.Link>
      </strong>
    </Typography.Text>
  </>
);

NotEnabledNotice.propTypes = NotEnabledNoticeProps;

export default NotEnabledNotice;
