import cn from 'classnames';
import { bool, node, string } from 'prop-types';

const TabGrid = ({ children, className, ...rest }) => (
  <div className={cn('tab-grid', className)} {...rest}>
    {children}
  </div>
);

TabGrid.propTypes = {
  children: node.isRequired,
  className: string,
};

const Tab = ({ isCurrent = false, children, className, ...rest }) => (
  <a
    className={cn('tab-grid-tab', className, {
      'tab-grid-tab--current': isCurrent,
    })}
    {...rest}>
    {children}
  </a>
);

Tab.propTypes = {
  isCurrent: bool,
  children: node.isRequired,
  className: string,
};

TabGrid.Tab = Tab;

export default TabGrid;
