import startcase from 'lodash.startcase';
import { ProgressBar } from 'react-bootstrap';

import { STATUS } from '../UserStatus';
import { CourseSummaryProps } from './types';

const CourseSummary = ({ courseVersions }) => (
  <>
    <h4>Course Summary</h4>
    {courseVersions.map(({ id, name, userInfo }) => (
      <div key={id} className="mb-3">
        <div className="d-flex font-size-sm mb-1">
          <div>{name}</div>
          <div className="ml-auto text-muted">
            {startcase((userInfo.status || STATUS.NOT_STARTED).toLowerCase())}
          </div>
        </div>
        <ProgressBar
          variant={userInfo.progress === 1 ? 'success' : 'warning'}
          now={userInfo.progress * 100}
        />
      </div>
    ))}
  </>
);

CourseSummary.propTypes = CourseSummaryProps;

export default CourseSummary;
