import { Button } from 'react-bootstrap';
import TextareaAutosize from 'react-textarea-autosize';

import AttachmentInputs from '../../AttachmentInputs';
import Container from '../../Container';
import Input from '../../Input';
import PatchForm from '../../PatchForm';
import PostForm from '../../PostForm';
import { ProfessorPackFormProps } from './types';

const ProfessorPackForm = ({
  id,
  listOrder,
  title,
  description,
  shortDescription,
  overview,
  learningObjective,
  adminHref,
  errors = {},
  adminProfessorPacksPath,
  professorPacksBucketName,
  fileDirectory,
  packPreviewAttachment,
  professorPackLogo,
}) => {
  const [Form, action, submitText] = id
    ? [PatchForm, adminHref, 'Update']
    : [PostForm, adminProfessorPacksPath, 'Submit'];

  return (
    <Container>
      <div className="d-flex align-items-center">
        <h1>{id ? 'Edit' : 'Add'} Case Study</h1>
        <Button className="ml-4" variant="light" href={action} size="sm">
          Cancel
        </Button>
      </div>

      <Form
        encType="multipart/form-data"
        action={action}
        submitText={submitText}>
        <Input
          type="number"
          required
          name="listOrder"
          defaultValue={listOrder || 0}
          errors={errors.listOrder}
        />
        <Input
          required
          name="title"
          defaultValue={title || ''}
          errors={errors.title}
        />
        <Input
          required
          name="description"
          as={TextareaAutosize}
          defaultValue={description || ''}
        />
        <Input
          name="shortDescription"
          as={TextareaAutosize}
          defaultValue={shortDescription || ''}
        />
        <Input
          required
          name="overview"
          as={TextareaAutosize}
          defaultValue={overview || ''}
        />
        <Input
          required
          name="learningObjective"
          as={TextareaAutosize}
          defaultValue={learningObjective || ''}
        />
        <AttachmentInputs
          attachment={packPreviewAttachment}
          professorPacksBucketName={professorPacksBucketName}
          fileDirectory={fileDirectory}
          attributesKey="packPreviewAttachmentAttributes"
          label="Case Study Preview"
          referenceType="ProfessorPack"
        />
        <AttachmentInputs
          attachment={professorPackLogo}
          professorPacksBucketName={professorPacksBucketName}
          fileDirectory={fileDirectory}
          attributesKey="professorPackLogoAttributes"
          label="Case Study Logo"
          referenceType="ProfessorPackLogo"
        />
      </Form>
    </Container>
  );
};

ProfessorPackForm.propTypes = ProfessorPackFormProps;

export default ProfessorPackForm;
