import {
  arrayOf,
  bool,
  func,
  number,
  objectOf,
  shape,
  string,
} from 'prop-types';

import {
  LeaderboardProps,
  TableProps,
  TradingChallengeProps,
  UserProps,
  WebinarSeriesProps,
} from '../types';

export const TeamMemberType = {
  firstName: string,
  lastName: string,
  emailName: string,
  phone: string,
  department: string,
  title: string,
  memberType: string,
};
export const TeamMembersType = arrayOf(shape(TeamMemberType));

export const FormErrorsType = objectOf({
  teamName: string,
  teamMembers: shape(TeamMemberType),
});

export const TeamMemberFormSectionPropTypes = {
  disabled: bool,
  formErrors: FormErrorsType,
  teamMembers: TeamMembersType,
  setTeamMembers: func,
};

export const CaptainInfoSectionPropTypes = {
  disabled: bool,
  formErrors: FormErrorsType,
  captainInfo: TeamMemberType,
  setCaptainInfo: func,
  user: shape(UserProps),
};

export const FacultyInfoSectionPropTypes = {
  disabled: bool,
  formErrors: FormErrorsType,
  facultyInfo: TeamMemberType,
  setFacultyInfo: func,
  user: shape(UserProps),
};

export const RegistrationFormProps = {
  tradingChallenge: shape(TradingChallengeProps),
  user: shape(UserProps),
  isHighSchool: bool,
  createRegistrationPath: string,
  institution: string,
  registrationError: string,
  emailDomains: arrayOf(string),
};

export const ShowProps = {
  ...TradingChallengeProps,
  bannerImg: string,
  webinars: WebinarSeriesProps,
  registrationLink: string,
  peopleIconImg: string,
  teamsIconImg: string,
  tradesIconImg: string,
  registrationsCount: number,
  teamMembersCount: number,
};

export const TermFuncProps = {
  label: string,
};

export const LeaderboardCompProps = {
  ...LeaderboardProps,
  ...TableProps,
};
