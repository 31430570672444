import { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import { create, update } from '../../../api';
import Input from '../../Input';
import { PromoCodeFormModalProps } from './types';

const PromoCodeFormModal = ({
  show,
  onHide,
  adminPromoCodesPath,
  onSuccess,
  promoCode: initialPromoCode,
  products,
}) => {
  const [promoCode, setPromoCode] = useState(initialPromoCode);

  const { register, handleSubmit } = useForm();
  return (
    <Modal show={show} onHide={onHide} centered>
      <Form
        onSubmit={handleSubmit(async (values, e) => {
          e.preventDefault();
          try {
            const [action, path] = promoCode?.id
              ? [update, promoCode.href]
              : [create, adminPromoCodesPath];
            await action(path, values);
            onSuccess();
          } catch (error) {
            const { data } = error.response;
            setPromoCode(data);
          }
        })}>
        <Modal.Header closeButton>
          <Modal.Title>{promoCode?.id ? 'Edit' : 'New'} Promo Code</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Input
            required
            ref={register}
            defaultValue={promoCode?.code ?? ''}
            name="code"
            errors={promoCode?.errors?.code}
          />
          <Input
            required
            as="select"
            options={products}
            includeBlank
            ref={register}
            defaultValue={promoCode?.product?.id ?? ''}
            name="productId"
            errors={promoCode?.errors?.productId}
            helpText="*Promo code will be valid for this product only."
          />
          <Input
            required
            type="number"
            ref={register}
            defaultValue={promoCode?.discountedPrice ?? ''}
            name="discountedPrice"
            errors={promoCode?.errors?.discountedPrice}
            label="Price"
            min={0}
          />
          <Input
            type="date"
            required
            ref={register}
            defaultValue={promoCode?.startDate ?? ''}
            name="startDate"
            errors={promoCode?.errors?.startDate}
          />
          <Input
            type="date"
            required
            ref={register}
            defaultValue={promoCode?.endDate ?? ''}
            name="endDate"
            errors={promoCode?.errors?.endDate}
          />
          <Input
            required
            type="number"
            ref={register}
            defaultValue={promoCode?.remainingUses ?? ''}
            name="remainingUses"
            min={0}
            errors={promoCode?.errors?.remainingUses}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Cancel
          </Button>
          <Button variant="primary" type="submit">
            {promoCode?.id ? 'Update' : 'Create'}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

PromoCodeFormModal.propTypes = PromoCodeFormModalProps;

export default PromoCodeFormModal;
