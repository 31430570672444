export const TOOLKIT_TAG_TYPES = {
  ToolkitTopic: 'Topic',
  ToolkitType: 'Document Type',
};

export const TOOLKIT_FILTER_MAPPINGS = {
  toolkitTopic: {
    label: 'Topics',
    key: 'topics',
  },
  toolkitType: {
    label: 'Document Type',
    key: 'type',
  },
};
