import {
  GlobalStyles,
  ThemeProvider,
  Typography,
} from '@bbnpm/bb-ui-framework';
import { array, bool, string } from 'prop-types';

import Container from '../Container';
import CaseStudiesList from './CaseStudiesList';

const Index = ({
  hasNoEnrolledClassIds,
  batClassesPath,
  hasNoUniqueAssignedCaseStudies,
  caseStudies,
  defaultLogoPath,
}) => {
  return (
    <ThemeProvider>
      <GlobalStyles />
      <Container>
        <Typography.PageTitle>
          <strong>Case Studies</strong>
        </Typography.PageTitle>
        <Typography.Text>
          Bloomberg Businessweek Case Studies are the result of a collaboration
          between the Bloomberg for Education and Bloomberg Businessweek teams.
          Case studies in finance are normally reserved for graduate level
          coursework. We designed these case studies to align with the most
          commonly used undergrad Intro to Finance textbooks and the Bloomberg
          Terminal. As employers and students are clamoring for more practical
          experience, these case studies focus on experiential learning. At the
          end of the course, we want students to be able to DO as well as KNOW.
        </Typography.Text>
        {hasNoEnrolledClassIds ? (
          <Typography.Text style={{ color: 'red' }}>
            You have not linked your profile to a class code. To access
            Bloomberg Businessweek Case Studies, your instructor must create a
            class code and assign case studies to that class code. To link your
            profile to a class code, navigate to the{' '}
            <Typography.Link href={batClassesPath}>Classes</Typography.Link>{' '}
            tab.
          </Typography.Text>
        ) : hasNoUniqueAssignedCaseStudies ? (
          <Typography.Text style={{ color: 'red' }}>
            Your class code(s) does not require any Bloomberg Businessweek Case
            Studies to be completed as part of the course. Please contact your
            professor directly with any questions.
          </Typography.Text>
        ) : (
          <CaseStudiesList
            caseStudies={caseStudies}
            defaultLogoPath={defaultLogoPath}
          />
        )}
      </Container>
    </ThemeProvider>
  );
};

Index.propTypes = {
  hasNoEnrolledClassIds: bool.isRequired,
  batClassesPath: string.isRequired,
  hasNoUniqueAssignedCaseStudies: bool.isRequired,
  caseStudies: array.isRequired,
  defaultLogoPath: string.isRequired,
};

export default Index;
