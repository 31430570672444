import { node } from 'prop-types';
import { forwardRef } from 'react';

import PostForm from './PostForm';

const PatchForm = forwardRef(({ children, ...rest }, ref) => (
  <PostForm {...rest} ref={ref}>
    <input name="_method" type="hidden" value="PATCH" />
    {children}
  </PostForm>
));

PatchForm.displayName = 'PatchForm';

PatchForm.propTypes = { children: node };

export default PatchForm;
