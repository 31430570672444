import { arrayOf, shape, string } from 'prop-types';

import { CountryCodeProps, InstitutionProps, TableProps } from '../../types';

export const ShowProps = {
  ...InstitutionProps,
  adminUsersPath: string.isRequired,
};

export const CellValueStringProps = {
  cell: shape({ value: string.isRequired }).isRequired,
};

export const IndexProps = {
  ...TableProps,
  adminInstitutionsPath: string.isRequired,
  institutions: arrayOf(shape(InstitutionProps)).isRequired,
};

export const InstitutionFormProps = {
  ...InstitutionProps,
  countryCodes: arrayOf(shape(CountryCodeProps)).isRequired,
};
