import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';

import VideoPlayer from '../../VideoPlayer';
import { RusticiContext } from './Index';
import { PreviewModalProps } from './types';

const PreviewModal = ({ show, onHide, rusticiId }) => {
  const [launchLink, setLaunchLink] = useState(null);
  const { rusticiBaseUrl, rusticiToken, rusticiCdnHost } =
    useContext(RusticiContext);

  useEffect(() => {
    if (!show) return;
    const fetchLaunchLink = async () => {
      const url = `${rusticiBaseUrl}/courses/${rusticiId}/preview`;
      const headers = {
        Authorization: `Basic ${rusticiToken}`,
        engineTenantName: 'default',
      };
      const { data } = await axios.post(url, {}, { headers });
      setLaunchLink(rusticiCdnHost + data.launchLink);
    };
    fetchLaunchLink();
  }, [
    setLaunchLink,
    rusticiBaseUrl,
    rusticiToken,
    rusticiCdnHost,
    rusticiId,
    show,
  ]);

  return (
    <Modal show={show} onHide={onHide} centered size="xl">
      <Modal.Body>{launchLink && <VideoPlayer src={launchLink} />}</Modal.Body>
    </Modal>
  );
};

PreviewModal.propTypes = PreviewModalProps;

export default PreviewModal;
