import {
  Button,
  FormField,
  GlobalStyles,
  Switch,
  ThemeProvider,
  Typography,
} from '@bbnpm/bb-ui-framework';
import { useToggle } from 'react-use';

import Container from '../Container';
import PatchForm from '../PatchForm';
import BssoOptInModal from '../bsso/BssoOptInModal';
import BssoOptOutModal from '../bsso/BssoOptOutModal';
import { UserProps } from '../types';
import ReadOnlyModal from './ReadOnlyModal';
import {
  ButtonGroup,
  StyledButton,
  StyledInput,
  StyledSectionTitle,
  SwitchContainer,
} from './profiles.styles.js';

const Edit = ({
  userid,
  email,
  firstName,
  lastName,
  readOnlyFields,
  newUpdatePasswordPath,
  homePhone,
  rootPath,
  profilePath,
  bssoEnabled,
  bssoUrl,
  canUpdateBssoStatus,
  hasBConnectId,
}) => {
  const [isOpen, toggleIsOpen] = useToggle(false);
  const [bssoModalIsOpen, toggleBssoModalIsOpen] = useToggle(false);
  const [isBssoEnabled, toggleIsBssoEnabled] = useToggle(bssoEnabled);

  const onHide = () => {
    if (isBssoEnabled) toggleIsBssoEnabled();
    toggleBssoModalIsOpen();
  };

  return (
    <ThemeProvider>
      <GlobalStyles />
      <Container>
        <PatchForm
          action={profilePath}
          submitRender={() => (
            <ButtonGroup>
              <Button kind="primary" type="submit">
                Update My Profile
              </Button>
              &nbsp; &nbsp;
              <a href={rootPath}>
                <Button kind="secondary">Cancel</Button>
              </a>
            </ButtonGroup>
          )}>
          <Typography.PageTitle>Edit Profile</Typography.PageTitle>
          <br />
          <StyledSectionTitle>General</StyledSectionTitle>

          <FormField label="Bloomberg for Education ID" labelPosition="left">
            <StyledInput placeholder={userid} disabled={true} />
          </FormField>

          <FormField label="Email" labelPosition="left">
            <StyledInput placeholder={email} disabled={true} />
            <StyledButton kind="secondary" onClick={toggleIsOpen}>
              Change
            </StyledButton>
          </FormField>

          {hasBConnectId && (
            <FormField label="Password" labelPosition="left">
              <StyledInput placeholder="**********" disabled={true} />
              <a href={newUpdatePasswordPath}>
                <StyledButton kind="secondary">Change</StyledButton>{' '}
              </a>
            </FormField>
          )}

          <FormField label="Firstname" labelPosition="left">
            <StyledInput placeholder={firstName} disabled={true} />
            <StyledButton kind="secondary" onClick={toggleIsOpen}>
              Change
            </StyledButton>
          </FormField>

          <FormField label="Lastname" labelPosition="left">
            <StyledInput placeholder={lastName} disabled={true} />
            <StyledButton kind="secondary" onClick={toggleIsOpen}>
              Change
            </StyledButton>
          </FormField>

          <FormField label="Phone" labelPosition="left">
            <StyledInput name="homePhone" defaultValue={homePhone || ''} />
          </FormField>
          {canUpdateBssoStatus && (
            <>
              <FormField label="BSSO Enabled" labelPosition="left">
                <SwitchContainer>
                  <Switch
                    checked={isBssoEnabled}
                    onChange={() => {
                      toggleIsBssoEnabled();
                      toggleBssoModalIsOpen();
                    }}
                  />
                  {!isBssoEnabled && (
                    <input type="hidden" name="bssoEnabled" value={false} />
                  )}
                </SwitchContainer>
              </FormField>
            </>
          )}
        </PatchForm>
        <ReadOnlyModal
          isOpen={isOpen}
          toggleIsOpen={toggleIsOpen}
          readOnlyFields={readOnlyFields}
        />
        {isBssoEnabled ? (
          <BssoOptInModal
            show={bssoModalIsOpen}
            onHide={onHide}
            bssoUrl={bssoUrl}
          />
        ) : (
          <BssoOptOutModal
            show={bssoModalIsOpen}
            onHide={toggleBssoModalIsOpen}
          />
        )}
      </Container>
    </ThemeProvider>
  );
};

Edit.propTypes = UserProps;

export default Edit;
