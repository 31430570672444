import { Typography } from '@bbnpm/bb-ui-framework';

import { FeedbackProps } from './types';

const Feedback = ({ caseStudiesSurvey }) => (
  <>
    <Typography.PageTitle>
      <strong>Feedback</strong>
    </Typography.PageTitle>
    <Typography.Text>
      Provide{' '}
      <Typography.Link href={caseStudiesSurvey} target="_blank">
        feedback
      </Typography.Link>{' '}
      on our case studies so we can collaborate on future design and content
      changes.
    </Typography.Text>
  </>
);

Feedback.propTypes = FeedbackProps;

export default Feedback;
