import { Row } from 'react-bootstrap';
import styled from 'styled-components';

export const StyledRow = styled(Row)`
  margin-left: 0px;
  margin-right: 0px;
`;

export const CourseCategoryInfo = styled.div`
  padding: 1.5rem 0;

  & > h1 {
    margin-top: 0;
  }
`;

export const StyledCourseCard = styled.a`
  background-color: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};

  width: 100%;

  &:hover {
    color: ${({ theme }) => theme.colors.white};
  }
`;
