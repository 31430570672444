import {
  Button,
  FormField,
  GlobalStyles,
  Input,
  ThemeProvider,
  Typography,
  useNotification,
} from '@bbnpm/bb-ui-framework';
import { useState } from 'react';

import { create } from '../../../api';
import { BfeGrid } from '../../../styles/grid';
import { HorizontalLineBreak, RequiredLabel } from '../../CommonComponents';
import TeamMemberForm, {
  newTeamMember,
} from '../../trading_challenges/formSections/TeamMemberFormSection';
import CaptainSection from './formSections/CaptainSection';
import FacultySection from './formSections/FacultySection';
import { ButtonContainer, FlexGrowCol, StyledRow } from './styles';

const RegistrationForm = ({
  tradingChallenge,
  adminUsersPath,
  adminCaptainDetailsPath,
  createRegistrationPath,
  errors = {},
}) => {
  const notif = useNotification();
  const [teamName, setTeamName] = useState('');
  const [teamMembers, setTeamMembers] = useState([
    newTeamMember(),
    newTeamMember(),
  ]);
  const [captainInfo, setCaptainInfo] = useState(newTeamMember());
  const [facultyInfo, setFacultyInfo] = useState(
    newTeamMember({ memberType: 'professor' }),
  );

  const handleSubmit = async () => {
    const formData = {
      teamName,
      teamMembers: [captainInfo, facultyInfo, ...teamMembers],
    };
    try {
      const { adminHref } = await create(createRegistrationPath, formData);
      notif.addSuccess({ message: 'Registration successfully created!' });

      window.Turbolinks.visit(adminHref);
    } catch (e) {
      notif.addError({
        message:
          e?.response?.data?.error || 'Something went wrong with submission.',
      });
    }
  };

  return (
    <ThemeProvider>
      <GlobalStyles />
      <BfeGrid>
        <form>
          <Input type="hidden" name="teamName" value={teamName} />
          <Input
            type="hidden"
            name="teamMembers"
            value={[captainInfo, facultyInfo, ...teamMembers]}
          />
          <StyledRow>
            <Typography.LargeTitle>
              New {tradingChallenge.name} Registration
            </Typography.LargeTitle>
          </StyledRow>

          <HorizontalLineBreak />

          <Typography.SectionTitle>Team Information</Typography.SectionTitle>
          <StyledRow>
            <FlexGrowCol>
              <FormField
                label={<RequiredLabel>Team Name</RequiredLabel>}
                validationType={errors?.teamName && 'error'}
                validationContent={errors?.teamName}>
                <Input
                  type="text"
                  value={teamName}
                  onChange={evt => setTeamName(evt.target.value)}
                  required
                />
              </FormField>
            </FlexGrowCol>
          </StyledRow>

          <HorizontalLineBreak />

          <CaptainSection
            captainInfo={captainInfo}
            setCaptainInfo={setCaptainInfo}
            adminUsersPath={adminUsersPath}
            adminCaptainDetailsPath={adminCaptainDetailsPath}
          />

          <HorizontalLineBreak />

          <FacultySection
            facultyInfo={facultyInfo}
            setFacultyInfo={setFacultyInfo}
          />

          <HorizontalLineBreak />

          <TeamMemberForm
            setTeamMembers={setTeamMembers}
            teamMembers={teamMembers}
            formErrors={{}}
          />

          <HorizontalLineBreak />

          <ButtonContainer>
            <Button kind="primary" onClick={handleSubmit}>
              Submit
            </Button>

            <Button
              as="a"
              href={`/admin/trading_challenges/${tradingChallenge.id}`}>
              Cancel
            </Button>
          </ButtonContainer>
        </form>
      </BfeGrid>
    </ThemeProvider>
  );
};

export default RegistrationForm;
