import {
  Button,
  GlobalStyles,
  Icon,
  ThemeProvider,
  Typography,
} from '@bbnpm/bb-ui-framework';
import { Fragment } from 'react';
import styled from 'styled-components';

import AttachmentLink from '../../AttachmentLink';
import Container from '../../Container';
import DaperImg from '../../DaperImg';
import DeleteButton from '../../DeleteButton';
import { ShowProps } from './types';

const StyledImg = styled(DaperImg)`
  margin: 1.5rem 0;
  padding: 0.25rem;
  background-color: ${({ theme }) => theme.colors.white};
  width: 30%;
`;

const Show = ({
  title,
  description,
  createdAt,
  active,
  adminHref,
  adminIndexHref,
  editAdminToolkitDocumentPath,
  cloudStorageAttachment,
  thumbnail,
  toolkitTags,
  toolkitType,
}) => (
  <ThemeProvider>
    <GlobalStyles />
    <Container>
      <div>
        <Button as="a" kind="tertiary" icon="arrow-left" href={adminIndexHref}>
          Back to Toolkit Documents
        </Button>
      </div>
      <div className="d-flex align-items-center">
        <Typography.PageTitle>{title}</Typography.PageTitle>
        <Button
          as="a"
          kind="secondary"
          className="ml-4"
          href={editAdminToolkitDocumentPath}>
          Edit Toolkit Document
        </Button>

        <DeleteButton
          size="sm"
          className="ml-2"
          variant="danger"
          action={adminHref}
          onClick={e => {
            if (
              window.confirm(
                'Are you sure you want to permanently delete this document?',
              )
            ) {
              return true;
            } else {
              e.preventDefault();
              e.stopPropagation();
            }
          }}>
          <Icon name="trash" className="mr-2" />
          Delete
        </DeleteButton>
      </div>

      {toolkitTags && toolkitTags.length > 0 && (
        <Typography.Text>
          Created at {createdAt}. Part of{' '}
          {toolkitTags.map((topic, index) => {
            return (
              <Fragment key={topic.id}>
                <a href={topic.adminHref}>{topic.title}</a>
                {index < toolkitTags.length - 1 && ', '}
              </Fragment>
            );
          })}
        </Typography.Text>
      )}

      <Typography.SectionTitle>Description:</Typography.SectionTitle>
      <Typography.Text className="text-pre-wrap">{description}</Typography.Text>

      {toolkitType && (
        <>
          <Typography.SectionTitle>Document Type:</Typography.SectionTitle>
          <a className="text-pre-wrap mb-3" href={toolkitType?.adminHref}>
            {toolkitType?.title}
          </a>
        </>
      )}

      <Typography.SectionTitle>Status:</Typography.SectionTitle>
      <Typography.Text className="text-pre-wrap">
        {active ? 'Active' : 'Inactive'}
      </Typography.Text>

      {thumbnail ? (
        <>
          <Typography.SectionTitle>
            <b>Thumbnail:</b>
          </Typography.SectionTitle>
          <StyledImg metadata={thumbnail} fluid className="bg-white" />
        </>
      ) : (
        toolkitType?.thumbnail && (
          <div className="mb-3">
            <Typography.SectionTitle>Thumbnail:</Typography.SectionTitle>
            <StyledImg
              metadata={toolkitType?.thumbnail}
              fluid
              className="bg-white"
            />
          </div>
        )
      )}

      {cloudStorageAttachment && (
        <>
          <Typography.SectionTitle>Toolkit Document:</Typography.SectionTitle>
          <AttachmentLink attachment={cloudStorageAttachment} />
        </>
      )}
    </Container>
  </ThemeProvider>
);

Show.propTypes = ShowProps;

export default Show;
