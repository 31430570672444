import { GlobalStyles, ThemeProvider } from '@bbnpm/bb-ui-framework';

import Container from '../Container';
import Hero from '../Hero';
import CourseCard from './CourseCard';
import { StyledRow } from './courseCategories.styles';
import { IndexProps } from './types';

const Index = ({ courseCategories }) => (
  <ThemeProvider activeTheme="dark">
    <GlobalStyles />
    <Hero
      title={'Certificates'}
      description={
        'Bloomberg for Education offers a series of credentials that will boost your knowledge and strengthen your job applications. These e-learning courses provide a self-paced, interactive way to explore different areas of finance deeply. Upon finishing each program, you will receive a certificate of completion that you can promote on your LinkedIn, resume, and CV.'
      }
    />
    <Container>
      <StyledRow>
        {courseCategories.map(courseCategory =>
          courseCategory.active ? (
            <CourseCard
              courseCategory={courseCategory}
              key={courseCategory.id}
            />
          ) : null,
        )}
      </StyledRow>
    </Container>
  </ThemeProvider>
);

Index.propTypes = IndexProps;
export default Index;
