import { arrayOf, bool, shape, string } from 'prop-types';

import { WebinarProps, WebinarSeriesProps } from '../types';

export const WebinarCardProps = {
  isCurrent: bool,
  webinar: shape(WebinarProps).isRequired,
  className: string,
};

export const IndexProps = {
  webinars: arrayOf(shape(WebinarProps)).isRequired,
};

export const ShowProps = {
  ...WebinarProps,
  latestWebinars: arrayOf(shape(WebinarProps)).isRequired,
  allWebinarSeries: arrayOf(shape(WebinarSeriesProps)).isRequired,
};
