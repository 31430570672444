import {
  Button,
  Dropdown,
  FormField,
  Input,
  Modal,
} from '@bbnpm/bb-ui-framework';
import { useEffect, useState } from 'react';

import Container from '../../Container';
import ImageFileInput from '../../ImageFileInput';
import { TOOLKIT_TAG_TYPES } from './constants';
import { ToolkitTagFormProps } from './types';

const tagTypes = Object.entries(TOOLKIT_TAG_TYPES);

const ToolkitTagFormModal = ({
  isOpen,
  onClose,
  onSubmit,
  toolkitTag = {},
}) => {
  const { id } = toolkitTag;
  const [type, setType] = useState(toolkitTag.type || 'ToolkitTopic');
  const [title, setTitle] = useState(toolkitTag.title || '');
  const [thumbnail, setThumbnail] = useState(toolkitTag.thumbnail);

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const isToolkitType = type === 'ToolkitType';
  const submitText = id ? 'Update' : 'Add';

  const resetForm = () => {
    setType(toolkitTag.type || 'ToolkitTopic');
    setTitle(toolkitTag.title || '');
    setThumbnail(toolkitTag.thumbnail);
    setIsSubmitting(false);
    setErrors({});
  };

  const submitForm = async () => {
    setIsSubmitting(true);
    const bodyFormData = new FormData();
    bodyFormData.append('type', type);
    bodyFormData.append('title', title);

    if (
      isToolkitType &&
      thumbnail &&
      Object.getPrototypeOf(thumbnail)?.constructor?.name === 'File'
    ) {
      bodyFormData.append('thumbnail', thumbnail);
    }
    await onSubmit(bodyFormData);
  };

  const handleError = e => {
    setIsSubmitting(false);
    setErrors(e?.response?.data?.errors);
  };

  useEffect(() => {
    // reset the form when closed
    resetForm();
  }, [isOpen]);

  return (
    <Modal
      portalContainerId="bfe-root"
      isOpen={isOpen}
      onClose={onClose}
      title={`${id ? 'Edit' : 'Add'} Toolkit Tag`}
      primaryActions={
        <>
          {!id && (
            <Button
              kind="primary"
              disabled={isSubmitting}
              onClick={async () => {
                try {
                  await submitForm();
                  resetForm();
                } catch (e) {
                  handleError(e);
                }
              }}>
              Add Another
            </Button>
          )}
          <Button
            kind="primary"
            disabled={isSubmitting}
            onClick={async () => {
              try {
                await submitForm();
                onClose();
              } catch (e) {
                handleError(e);
              }
            }}>
            {submitText}
          </Button>
          <Button
            kind="secondary"
            onClick={onClose}
            size="sm"
            disabled={isSubmitting}>
            Cancel
          </Button>
        </>
      }>
      <Container>
        <FormField label="Tag Type">
          <Dropdown
            options={tagTypes.map(([value, label]) => ({ label, value }))}
            onItemSelect={selected => setType(selected?.value)}
            value={type}
            className="mb-3"
            disabled={id != null}
          />
        </FormField>
        <FormField
          label="Title"
          validationType={errors?.title && 'error'}
          validationContent={errors?.title && `Title ${errors?.title}`}>
          <Input
            type="text"
            name="title"
            value={title}
            onChange={evt => setTitle(evt.target.value)}
          />
        </FormField>
        {isToolkitType && (
          <FormField
            label="Thumbnail"
            validationType={errors?.title && 'error'}
            validationContent={
              errors?.thumbnail && `Thumbnail ${errors?.thumbnail}`
            }>
            <ImageFileInput
              name="thumbnail"
              label={false}
              accept=".jpg, .png"
              helpText="upload the largest available version"
              onChange={newThumbnail => setThumbnail(newThumbnail)}
              value={toolkitTag?.thumbnail}
            />
          </FormField>
        )}
      </Container>
    </Modal>
  );
};

ToolkitTagFormModal.propTypes = ToolkitTagFormProps;

export default ToolkitTagFormModal;
