import { Button as BbuiButton, Typography } from '@bbnpm/bb-ui-framework';
import cn from 'classnames';
import { bool, object, string } from 'prop-types';
import { useEffect } from 'react';
import { ButtonGroup, Image } from 'react-bootstrap';
import { useCopyToClipboard, useTimeoutFn, useToggle } from 'react-use';

import Tooltip from '../Tooltip';
import { StyledCertAvailable, StyledCertRight } from './courseVersions.styles';

export const CertificateCardProps = {
  certificateAvailable: bool.isRequired,
  downloadCertificatePath: string.isRequired,
  linkedinImagePath: string.isRequired,
  certificateUrl: string,
  courseCategory: object.isRequired,
};

export const formatLinkedInUrl = ({ name, certId, certUrl, certifiedDate }) => {
  const params = {
    organizationId: 2494,
    name,
    certId,
    certUrl,
    issueYear: certifiedDate.getFullYear(),
    issueMonth: certifiedDate.getMonth() + 1, // this returns 0 index int
  };

  const searchParams = new URLSearchParams(params);

  return searchParams.toString();
};

const CertificateCard = ({
  certificateAvailable,
  certificateId,
  downloadCertificatePath,
  linkedinImagePath,
  certificateUrl,
  courseCategory,
  certifiedAt,
}) => {
  const [isCopied, toggleIsCopied] = useToggle(false);
  const [, cancel, reset] = useTimeoutFn(toggleIsCopied, 2000);
  const [, copyToClipboard] = useCopyToClipboard();
  const certifiedDate = new Date(certifiedAt);

  useEffect(cancel, [cancel]);

  const handleCopyToClipboard = () => {
    copyToClipboard(certificateUrl);
    toggleIsCopied(true);
    reset();
  };
  return (
    <StyledCertRight>
      <Typography.SectionTitle style={{ fontWeight: 'bold' }}>
        Certificate of Completion
      </Typography.SectionTitle>

      {certificateAvailable ? (
        <StyledCertAvailable>
          <div>
            <BbuiButton as="a" href={downloadCertificatePath} target="_blank">
              Download
            </BbuiButton>

            <ButtonGroup className="ml-3">
              <BbuiButton as="a" href={certificateUrl} target="_blank">
                Verification Link
              </BbuiButton>
              <Tooltip
                key={`is-copied-${isCopied}`}
                tooltip={isCopied ? 'Copied!' : 'Copy to clipboard'}
                tooltipProps={{
                  className: cn({ 'tooltip-success': isCopied }),
                }}
                show={isCopied ? true : undefined}>
                <BbuiButton
                  disabled={isCopied}
                  onClick={handleCopyToClipboard}
                  variant={isCopied ? 'outline-success' : 'outline-dark'}>
                  <i
                    className={cn('fas fa-fw fa-sm', {
                      'fa-clipboard': !isCopied,
                      'fa-check': isCopied,
                    })}></i>
                </BbuiButton>
              </Tooltip>
            </ButtonGroup>
          </div>

          <a
            href={
              'https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&' +
              formatLinkedInUrl({
                name: courseCategory.title,
                certUrl: certificateUrl,
                certId: certificateId,
                certifiedDate,
              })
            }
            target="_blank"
            rel="nofollow noopener noreferrer">
            <Image
              fluid
              src={linkedinImagePath}
              alt="LinkedIn Add to Profile button"
            />
          </a>
        </StyledCertAvailable>
      ) : (
        <div>
          <Typography.Text>
            After completing the {courseCategory.title} core modules indicated
            by the blue ribbon, the certificate of completion is available for
            download.
          </Typography.Text>
        </div>
      )}
    </StyledCertRight>
  );
};

CertificateCard.propTypes = CertificateCardProps;

export default CertificateCard;
