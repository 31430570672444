import { bool, oneOfType, string } from 'prop-types';

import PostButton from './PostButton';

const DeleteButton = ({
  variant = 'danger',
  kind = 'destruction',
  ...rest
}) => <PostButton variant={variant} kind={kind} method="DELETE" {...rest} />;

DeleteButton.propTypes = { variant: oneOfType([string, bool]), kind: string };

export default DeleteButton;
