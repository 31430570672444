import {
  Button,
  GlobalStyles,
  ThemeProvider,
  Typography,
} from '@bbnpm/bb-ui-framework';
import { bool, number } from 'prop-types';
import { Col, Row } from 'react-bootstrap';

import Container from '../../Container';
import Hero from '../../Hero';
import { BatClassProps } from '../../types';
import { ButtonGroup } from './ClassForm.styles';
import DeleteClassButton from './DeleteClassButton';
import ReportTable from './ReportTable';

const Show = ({
  nameAlias,
  createdAt,
  expiredAt,
  classCode,
  remainingUses,
  professorHref,
  editProfessorsClassPath,
  sponsorProfessorsClassPath,
  learnersCount,
  usedLicenses,
  hasPurchasedLicenses,
  assignmentsStatusClassPath,
  assignmentsStatusClassCsvPath,
}) => {
  return (
    <ThemeProvider>
      <GlobalStyles />
      <Hero
        title={nameAlias}
        link={{
          path: '/professors/classes',
          name: 'Back to Classes',
          icon: 'arrow-left',
        }}
        description={
          <Row>
            <Col>
              <Typography.Text>
                <strong>Creation Date:</strong> &nbsp; {createdAt}
              </Typography.Text>
              <Typography.Text>
                <strong>Deadline:</strong> &nbsp; {expiredAt}
              </Typography.Text>
            </Col>
            <Col>
              <Typography.Text>
                <strong>Learners:</strong> &nbsp; {learnersCount}
              </Typography.Text>
              <Typography.Text>
                <strong>Class Code:</strong> &nbsp; {classCode}
              </Typography.Text>
            </Col>
            <Col>
              <Typography.Text>
                <strong>Unused Web Licenses:</strong> &nbsp; {remainingUses}
              </Typography.Text>
              <Typography.Text>
                <strong>Used Web Licenses:</strong> &nbsp; {usedLicenses}
              </Typography.Text>
            </Col>
          </Row>
        }
        primaryAction={
          <ButtonGroup>
            <Button as="a" href={assignmentsStatusClassCsvPath} kind="primary">
              Export to CSV
            </Button>
            <Button as="a" kind="secondary" href={editProfessorsClassPath}>
              Edit Class
            </Button>
            <Button as="a" kind="secondary" href={sponsorProfessorsClassPath}>
              {hasPurchasedLicenses ? 'Add Licenses' : 'Sponsor Class'}
            </Button>
            <DeleteClassButton
              disabled={learnersCount !== 0 || remainingUses !== 0}
              nameAlias={nameAlias}
              classCode={classCode}
              action={professorHref}
            />
          </ButtonGroup>
        }
      />

      <Container>
        {learnersCount === 0 ? (
          <>
            <Typography.PageTitle>You have no Learners.</Typography.PageTitle>
            <Typography.Text>
              Please distribute Class Code{' '}
              <span className="font-weight-bold">{classCode}</span> to your
              class to begin monitoring Learner progress and scores.
            </Typography.Text>
          </>
        ) : (
          <Typography.PageTitle>Learners</Typography.PageTitle>
        )}
        <ReportTable
          columns={[
            { Header: 'First Name', accessor: 'firstName' },
            { Header: 'Last Name', accessor: 'lastName' },
            { Header: 'Signed Up', accessor: 'createdAt', disableSortBy: true },
            { Header: 'Status', accessor: 'completed', disableSortBy: true },
          ]}
          fetchPath={assignmentsStatusClassPath}
        />
        <Typography.Hint>
          <span className="font-weight-bold">Note:</span>{' '}
          <span className="font-italic">
            Any Bloomberg Certificate is an unproctored assessment with
            non-randomized questions and options, which is only updated during a
            release of a new Certificate edition. The Certificate of Completion
            is granted to any learner who completes the required courses
            irrespective of their score. Retakes are not allowed.
          </span>
        </Typography.Hint>
      </Container>
    </ThemeProvider>
  );
};

Show.propTypes = {
  ...BatClassProps,
  learnersCount: number,
  usedLicenses: number,
  hasPurchasedLicenses: bool,
};

export default Show;
