import { FileInput } from '@bbnpm/bb-ui-framework';
import { shape, string } from 'prop-types';
import { useEffect } from 'react';
import { useRef } from 'react';
import { useForm } from 'react-hook-form';

import Button from './Button';
import DaperImg, { daperUrl } from './DaperImg';
import Input from './Input';
import { ImageFileProps } from './types';

const ImageFileInput = ({ name, value, helpText, onChange, ...rest }) => {
  const inputRef = useRef(null);
  const { register, watch } = useForm();
  const files = watch(name);

  const fileChosen = files && files[0];

  useEffect(() => {
    if (onChange && fileChosen) {
      onChange(fileChosen);
    }
  }, [fileChosen]);

  return (
    <Input
      ref={ref => {
        inputRef.current = ref;
        register(ref);
      }}
      type="file"
      name={name}
      helpText={!fileChosen && !value && helpText}
      inputRender={inputProps => (
        <>
          <FileInput
            {...inputProps}
            className={fileChosen || value ? 'd-none' : 'd-block'}
          />
          {(fileChosen || value) && (
            <div className="d-flex align-items-center">
              {fileChosen ? (
                <img
                  src={window.URL.createObjectURL(files[0])}
                  className="mr-2"
                  height={75}
                />
              ) : (
                <a href={daperUrl(value)} target="_blank" rel="noreferrer">
                  <DaperImg
                    fluid
                    metadata={value}
                    height={75}
                    className="mr-2"
                  />
                </a>
              )}
              <div>
                <p>
                  {fileChosen ? (
                    files[0].name
                  ) : (
                    <a href={daperUrl(value)} target="_blank" rel="noreferrer">
                      {value.filename}
                    </a>
                  )}
                </p>
                <Button
                  onClick={() => inputRef.current.click()}
                  size="sm"
                  className="mr-2"
                  variant="outline-dark">
                  Change
                </Button>
                <span className="font-size-sm text-muted">{helpText}</span>
              </div>
            </div>
          )}
        </>
      )}
      {...rest}
    />
  );
};
ImageFileInput.propTypes = {
  name: string.isRequired,
  value: shape(ImageFileProps),
  helpText: string,
};

export default ImageFileInput;
