import { arrayOf, shape, string } from 'prop-types';

import { CourseCategoryProps, TableProps } from '../../types';

export const CourseCategoryFormProps = {
  ...CourseCategoryProps,
  adminCourseCategoriesPath: string.isRequired,
};

export const IndexProps = {
  ...TableProps,
  adminCourseCategoriesPath: string,
  CourseCategories: arrayOf(shape(CourseCategoryProps)).isRequired,
};

export const ActionsProps = {
  row: shape({
    original: shape(CourseCategoryProps).isRequired,
  }).isRequired,
};

export const StatusProps = {
  cell: shape({ value: CourseCategoryProps.active }),
};
