import { useCallback } from 'react';
import { actions } from 'react-table';

actions.setFilters = 'setFilters';

const reducer = (state, action) => {
  if (action.type === actions.setFilters) {
    return { ...state, filters: action.payload };
  }

  return state;
};

const useInstance = instance => {
  const { dispatch } = instance;
  instance.setFilters = useCallback(
    filters => {
      dispatch({ type: actions.setFilters, payload: filters });
    },
    [dispatch],
  );
};

const useFilterPlugin = hooks => {
  hooks.stateReducers.push(reducer);
  hooks.useInstance.push(useInstance);
};

useFilterPlugin.pluginName = 'useFilterPlugin';

export default useFilterPlugin;
