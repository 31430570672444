import {
  GlobalStyles,
  Icon,
  ThemeProvider,
  Typography,
} from '@bbnpm/bb-ui-framework';

import Container from '../../Container';
import { StyledLink, StyledListItem } from './styles';
import { ShowProps } from './types';

const Show = ({ title, overview, packSections }) => (
  <ThemeProvider>
    <GlobalStyles />
    <Container>
      <Typography.PageTitle>
        <strong>{title}</strong>
      </Typography.PageTitle>
      <Typography.SectionTitle>
        <strong>Overview</strong>
      </Typography.SectionTitle>
      <Typography.Text>{overview}</Typography.Text>
      <br />
      <Typography.SectionTitle>
        <strong>Learning Objectives</strong>
      </Typography.SectionTitle>
      <Typography.Text>{overview}</Typography.Text>
      {packSections.map(section => (
        <div key={section.id}>
          <Typography.SectionTitle>
            <strong>{section.title}</strong>{' '}
          </Typography.SectionTitle>
          {section.packResources
            .filter(resource => resource.cloudStorageAttachment)
            .map((resource, i) => (
              <StyledListItem key={resource.id}>
                <Typography.Text>
                  {i + 1 + '. '} {resource.title} &nbsp; | &nbsp;
                  <StyledLink
                    href={
                      resource.cloudStorageAttachment.professorsPackResourcePath
                    }>
                    Download <Icon name="download" />
                  </StyledLink>
                </Typography.Text>
                <Typography.Text>{resource.description}</Typography.Text>
              </StyledListItem>
            ))}
          <div></div>
        </div>
      ))}
    </Container>
  </ThemeProvider>
);

Show.propTypes = ShowProps;

export default Show;
