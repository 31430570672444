import { arrayOf, shape, string } from 'prop-types';

import { OrderProps, TableProps } from '../types';

export const IndexProps = {
  ...TableProps,
  ordersPath: string.isRequired,
  orders: arrayOf(shape(OrderProps)).isRequired,
};

export const ReceiptLinkProps = {
  row: shape({ original: shape(OrderProps) }).isRequired,
};

export const ShowProps = OrderProps;
