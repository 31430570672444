import { useCsrf } from './utils';

const Csrf = () => {
  const csrf = useCsrf();
  return csrf ? (
    <input type="hidden" name={csrf.param} defaultValue={csrf.token} />
  ) : null;
};

export default Csrf;
