import { arrayOf, bool, number, shape, string } from 'prop-types';

export const TableProps = {
  totalCount: number.isRequired,
  pageSize: number.isRequired,
  pageIndex: number.isRequired,
  sortBy: string.isRequired,
};

export const AttachmentProps = shape({
  id: number,
  fileName: string,
  downloadAdminCloudStorageAttachmentPath: string,
});

export const ProductProps = {
  id: number.isRequired,
  name: string.isRequired,
  price: string.isRequired,
  sku: string.isRequired,
};

const ClassEnrollmentProps = {
  batClassId: number,
  batClass: shape(BatClassProps),
};

export const BatClassProps = {
  id: number,
  nameAlias: string,
  status: string,
  createdAt: string,
  classEnrollmentsCount: number,
  professor: shape(UserProps),
  href: string,
  adminHref: string,
  classCode: string,
  remainingUses: number,
  editAdminBatClassPath: string,
  impersonateAdminClassPath: string,
  classEnrollments: arrayOf(shape(ClassEnrollmentProps)),
  enrolledLearners: arrayOf(shape(UserProps)),
  courseVersions: arrayOf(shape(CourseVersionProps)),
  expiredAtWithTime: string,
  adminBatClassesPath: string,
  expiredAt: string,
};

export const SeriesProps = {
  id: number,
  name: string,
  description: string,
  active: bool,
  videosCount: number,
  featuredVideoId: number,
  createdAt: string,
  adminSeriesPath: string,
  path: string,
  href: string,
  adminHref: string,
};

export const ImageFileProps = {
  filename: string.isRequired,
  height: number.isRequired,
  width: number.isRequired,
  daperKey: string.isRequired,
};

export const FileProps = {
  filename: string,
  url: string,
};

export const VideoProps = {
  id: number,
  href: string,
  adminHref: string,
  rusticiId: string,
  name: string,
  description: string,
  type: string,
  durationMins: number,
  numberOfQuestions: number,
  uploadedAt: string,
  active: bool,
  featured: bool,
  editAdminVideoPath: string,
  markUploadedAdminVideoPath: string,
  videoRegistrationsCount: number,
  thumbnail: shape(ImageFileProps),
  series: shape(SeriesProps),
  userInfo: shape({
    status: string.isRequired,
    completedAt: string,
  }),
};

export const WebinarSeriesProps = {
  id: number,
  href: string,
  adminHref: string,
  name: string,
  description: string,
  created_at: string,
  active: bool,
  featuredWebinarId: number,
  webinarsCount: number,
  webinars: arrayOf(shape(WebinarProps)),
};

export const WebinarProps = {
  id: number,
  href: string,
  adminHref: string,
  name: string,
  description: string,
  created_at: string,
  featured: bool,
  active: bool,
  durationMins: number,
  vimeo_video_id: string,
  occurred_at: string,
  editAdminWebinarPath: string,
  thumbnail: shape(ImageFileProps),
  slidesDocument: shape(FileProps),
  webinarSeries: shape(WebinarSeriesProps),
};

export const CourseModuleProps = {
  adminCourseModulePath: string,
  markUploadedAdminCourseModulePath: string,
  id: number,
  name: string,
  category: string,
  internalName: string,
  numberOfQuestions: number,
  createdAt: string,
  uploadedAt: string,
  courseModuleRegistrationsCount: number,
  registerCourseModuleCourseVersionPath: string,
  status: string,
};

export const CourseProps = {
  id: number,
  name: string,
  category: string,
  description: string,
  shortDescription: string,
  position: number,
  courseVersionsCount: number,
  createdAt: string,
  adminCourseVersionPath: string,
  adminCourseCourseVersionsPath: string,
  newAdminCourseCourseVersionPath: string,
  activeVersionName: string,
  courseVersions: arrayOf(shape(CourseVersionProps)),
};

export const UserStatusProps = {
  status: string,
  score: number,
};

export const CourseVersionProps = {
  id: number,
  name: string,
  internalName: string,
  icon: string,
  description: string,
  active: bool,
  upgradable: bool,
  requiredForCertification: bool,
  courseRegistrationsCount: number,
  courseModuleCourseVersionsCount: number,
  createdAt: string,
  adminCourseVersionPath: string,
  adminCourseCourseVersionsPath: string,
  editAdminCourseVersionPath: string,
  course: shape(CourseProps),
  courseModules: arrayOf(shape(CourseModuleProps)),
  requiredForClass: bool,
  eligibleForUpgrade: bool,
  href: string,
  userInfo: shape(UserStatusProps),
};

export const CustomerProps = {
  id: number,
  name: string,
  location: string,
  terminals: number,
  firmId: number,
  bbgCustomerId: number,
  terminalAccess: bool,
  billables: number,
  href: string,
  adminFirmPath: string,
  editAdminCustomerPath: string,
};

export const LayoutThemeProps = {
  id: number,
  name: string,
};

export const InstitutionProps = {
  id: number,
  name: string,
  notes: string,
  studentRateEligible: bool,
  caseStudiesAccess: bool,
  professorToolkitAccess: bool,
  unlimitedBmcAccess: bool,
  href: string,
  editAdminInstitutionPath: string,
  customers: arrayOf(shape(CustomerProps)),
  sponsoredLogo: shape(ImageFileProps),
  layoutThemes: arrayOf(shape(LayoutThemeProps)),
  layoutTheme: shape(LayoutThemeProps),
  nonMatchEmailDomainLimit: number,
};

export const EmailDomainProps = {
  id: number,
  domain: string,
};

export const FirmProps = {
  id: number,
  name: string,
  bbgFirmId: number,
  href: string,
  editAdminFirmPath: string,
  newAdminFirmCustomerPath: string,
  institutions: arrayOf(shape(InstitutionProps)),
  customers: arrayOf(shape(CustomerProps)),
};

export const CourseRegistrationProps = {
  id: number,
  createdAt: string,
  status: string,
  score: number,
  courseVersion: shape(CourseVersionProps),
};

export const VideoRegistrationProps = {
  id: number,
  createdAt: string,
  status: string,
  score: number,
  video: shape(VideoProps),
};

export const CourseModuleRegistrationsProps = {
  id: number,
  score: number,
  withoutRustici: bool,
  courseModuleId: number,
  internalName: string,
  completedAt: string,
  createdAt: string,
  refreshRusticiDataPath: string,
  resetRusticiRegScoresPath: string,
  mark_completed_path: string,
};

export const RusticiRegistrationsProps = {
  ...CourseModuleRegistrationsProps,
  ...VideoRegistrationProps,
};

export const BmcSubscriptionProps = { productSku: string };

export const ProfessorInfoProps = {
  id: number,
  accountVerified: bool,
  notifyLearnersDrEnablement: bool,
};

export const OrderProps = {
  id: number,
  href: string,
  createdAt: string,
  name: string,
  promo: string,
  total: string,
};

export const UserProps = {
  userid: number,
  email: string,
  firstName: string,
  lastName: string,
  type: string,
  isActive: bool,
  isConfirmed: bool,
  adminUsersPath: string,
  adminInstitutionsPath: string,
  href: string,
  bmcSubscription: shape(BmcSubscriptionProps),
  certifiedAt: string,
  confirmedAt: string,
  createdAt: string,
  impersonateAdminUserPath: string,
  editAdminUserPath: string,
  types: arrayOf(arrayOf(string)),
  professorInfo: shape(ProfessorInfoProps),
  resetTerminalDrSessionAdminUserPath: string,
  enrolledClasses: arrayOf(shape(BatClassProps)),
  videoRegistrations: arrayOf(shape(VideoRegistrationProps)),
  courseRegistrations: arrayOf(shape(CourseRegistrationProps)),
  courseModuleRegistrations: arrayOf(shape(CourseModuleRegistrationsProps)),
  classesAdminUserPath: string,
  orders: arrayOf(shape(OrderProps)),
  adminUserPath: string,
  certificateUrl: string,
  isLearner: bool,
  isManager: bool,
};

export const ProfessorPackProps = {
  id: number,
  listOrder: number,
  title: string,
  description: string,
  shortDescription: string,
  overview: string,
  learningObjective: string,
  createdAt: string,
  href: string,
  adminHref: string,
  edit_admin_professor_pack_path: string,
  packPreviewAttachment: AttachmentProps,
  professorPackLogo: AttachmentProps,
};

export const ToolkitTagProps = {
  id: number,
  title: string,
  type: string,
  createdAt: string,
  adminHref: string,
  adminToolkitTagPath: string,
  newAdminToolkitDocumentPath: string,
};

export const ToolkitDocumentTagProps = {
  id: number,
  toolkitTagId: number,
  toolkitDocumentId: number,
  createdAt: string,
  updatedAt: string,
};

export const ToolkitDocumentProps = {
  id: number,
  title: string,
  description: string,
  createdAt: string,
  active: bool,
  adminHref: string,
  adminToolkitDocumentPath: string,
  editAdminToolkitDocumentsPath: string,
  cloudStorageAttachment: AttachmentProps,
  thumbnail: shape(ImageFileProps),
  toolkitTags: arrayOf(shape(ToolkitTagProps)),
  toolkitDocumentTags: arrayOf(shape(ToolkitDocumentTagProps)),
  toolkitType: shape(ToolkitTagProps),
};

export const CountryCodeProps = {
  id: number,
  countryName: string,
  region: string,
};

export const CourseCategoryProps = {
  id: number,
  name: string,
  title: string,
  active: bool,
  description: string,
  position: number,
  createdAt: string,
  adminHref: string,
  editAdminCourseCategoryPath: string,
  thumbnail: shape(ImageFileProps),
  certificationStatus: string,
};

export const TradingChallengeProps = {
  id: number,
  name: string,
  overview: string,
  description: string,
  active: bool,
  status: string,
  registrationStart: string,
  registrationEnd: string,
  challengeStart: string,
  challengeEnd: string,
  startingPositionsDeadline: string,
  registrationPeriodOpen: bool,
};

export const StatusIconProps = {
  value: bool,
};

export const PromoItemProps = {
  id: number,
  title: string,
  active: bool,
  description: string,
  actionText: string,
  url: string,
  position: number,
  createdAt: string,
  adminHref: string,
  adminPromoItemPath: string,
  editAdminPromoItemPath: string,
  thumbnail: shape(ImageFileProps),
};

export const LinkProps = {
  path: string,
  icon: string,
  name: string,
};

export const PurchaseEntitlementProps = {
  id: number,
  editAdminPurchaseEntitlementPath: string,
  createdAt: string,
  updatedAt: string,
  productSku: string,
  user: shape(UserProps),
};

export const LeaderboardProps = {
  id: number,
  tradingChallengeId: number,
  position: number,
  name: string,
  active: bool,
  created_at: string,
  editAdminTradingChallengeLeaderboardPath: string,
  adminHref: string,
  adminTradingChallengePath: string,
};

export const TradingChallengeRegistrationProps = {
  id: number,
  teamName: string,
  userId: number,
  adminHref: string,
  adminTradingChallengePath: string,
  insitutionName: string,
  captainInfo: shape({
    uuid: number,
    name: string,
  }),
};

export const TradingChallengeRegionProps = {
  id: number,
  countryCodeId: number,
  region: string,
  countryName: string,
  adminHref: string,
};
