import { Button, Modal, Typography } from '@bbnpm/bb-ui-framework';
import { bool, func, string } from 'prop-types';

const ReadOnlyModal = ({ isOpen, toggleIsOpen, readOnlyFields }) => (
  <Modal
    portalContainerId="bfe-root"
    isOpen={isOpen}
    onClose={toggleIsOpen}
    title="Update profile information"
    primaryActions={
      <>
        <Button kind="secondary" onClick={toggleIsOpen}>
          Cancel
        </Button>
      </>
    }>
    <Typography.Text>
      You are not authorized to update the following information:{' '}
      {readOnlyFields}
    </Typography.Text>
    <Typography.Text>
      If you would like to update them, please send an email to{' '}
      <Typography.Link href={`mailto:bbg.edu@bloomberg.net`}>
        bbg.edu@bloomberg.net
      </Typography.Link>
    </Typography.Text>
  </Modal>
);

ReadOnlyModal.propTypes = {
  isOpen: bool.isRequired,
  toggleIsOpen: func.isRequired,
  readOnlyFields: string.isRequired,
};

export default ReadOnlyModal;
