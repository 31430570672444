import { Button } from 'react-bootstrap';

import Container from '../../Container';
import PrimaryAdminKey from '../../PrimaryAdminKey';
import Table from '../../Table';
import { IndexProps } from './types';

const columns = [
  { Header: 'ID', accessor: 'id', Cell: PrimaryAdminKey },
  { Header: 'Order', accessor: 'listOrder' },
  { Header: 'Title', accessor: 'title' },
  { Header: 'Created At', accessor: 'createdAt' },
];

const Index = ({ professorPacks, newAdminProfessorPackPath }) => (
  <Container>
    <div className="d-flex align-items-center mb-3">
      <h1>Case Studies</h1>
      <Button
        href={newAdminProfessorPackPath}
        className="ml-5"
        variant="primary">
        Add Case Study
      </Button>
    </div>

    <Table
      initialData={professorPacks}
      columns={columns}
      initialSortBy="listOrder ASC"
    />
  </Container>
);

Index.propTypes = IndexProps;

export default Index;
