import { useState } from 'react';
import { useToggle } from 'react-use';

import { destroy } from '../../../api';
import { noop } from '../../../utils';
import Button from '../../Button';
import CheckOrMinusCell from '../../CheckOrMinusCell';
import Container from '../../Container';
import DateCell from '../../DateCell';
import Table from '../../Table';
import { useFetchData } from '../../utils';
import PromoCodeFormModal from './PromoCodeFormModal';
import { ActionsProps, IndexProps } from './types';

const Actions = ({ row, refreshData, products, isManager }) => {
  const { href, ordersCount } = row.original;

  const [showEditForm, toggleShowEditForm] = useToggle(false);

  const handleDelete = async () => {
    await destroy(href);
    refreshData();
  };

  const onEditSuccess = () => {
    toggleShowEditForm();
    refreshData();
  };

  return (
    <span className="text-nowrap">
      {isManager && (
        <>
          <Button
            title="Edit"
            icon="edit"
            variant="light"
            size="sm"
            onClick={toggleShowEditForm}
          />
          <Button
            title="Delete"
            icon="eraser"
            variant="danger"
            size="sm"
            className="ml-2"
            onClick={handleDelete}
            disabled={ordersCount > 0}
          />
          {showEditForm && (
            <PromoCodeFormModal
              show={true}
              onHide={toggleShowEditForm}
              products={products}
              onSuccess={onEditSuccess}
              promoCode={row.original}
            />
          )}
        </>
      )}
    </span>
  );
};

Actions.propTypes = ActionsProps;

const columns = [
  { Header: 'ID', accessor: 'id', filteredAs: 'number' },
  { Header: 'Code', accessor: 'code', filteredAs: 'string' },
  { Header: 'Product', accessor: 'product.name', disableSortBy: true },
  { Header: 'Price', accessor: 'discountedPrice' },
  { Header: 'Orders', accessor: 'ordersCount' },
  { Header: 'Remaining Uses', accessor: 'remainingUses', filteredAs: 'number' },
  { Header: 'Starts', accessor: 'startDate', Cell: DateCell },
  { Header: 'Expires', accessor: 'endDate', Cell: DateCell },
  { Header: 'Created', accessor: 'createdAt' },
  {
    Header: 'Active',
    accessor: 'isActive',
    Cell: CheckOrMinusCell,
    disableSortBy: true,
  },
  {
    accessor: 'userid',
    id: 'actions',
    disableSortBy: true,
    Cell: Actions,
  },
];

const scopes = [
  { id: 'isActive', title: 'Active' },
  { id: 'isExpired', title: 'Expired' },
];

const cb = ({ totalCount, pageSize, promoCodes }) => ({
  data: promoCodes,
  pageSize,
  totalCount,
});

const Index = ({
  promoCodes,
  sortBy,
  totalCount,
  adminPromoCodesPath,
  products: initialProducts,
  isManager,
}) => {
  const fetchData = useFetchData(adminPromoCodesPath, cb);
  const [showAddForm, toggleShowAddForm] = useToggle(false);
  const [{ refreshData }, setRefreshDataFn] = useState({ refreshData: noop });

  const handleAddSuccess = () => window.Turbolinks.reload();

  const products = initialProducts.map(({ id, name }) => [id, name]);

  return (
    <Container>
      <div className="d-flex align-items-center mb-3">
        <h1>Promo Codes</h1>
        {isManager && (
          <Button
            className="ml-5"
            variant="primary"
            onClick={toggleShowAddForm}>
            Add Promo Code
          </Button>
        )}
      </div>

      <Table
        columns={columns}
        initialData={promoCodes}
        initialSortBy={sortBy}
        initialTotalCount={totalCount}
        initialScopes={{ isActive: false, isExpired: false }}
        availableScopes={scopes}
        fetchData={fetchData}
        onRefreshDataFn={setRefreshDataFn}
        refreshData={refreshData}
        products={products}
        isManager={isManager}
      />

      <PromoCodeFormModal
        show={showAddForm}
        onHide={toggleShowAddForm}
        products={products}
        adminPromoCodesPath={adminPromoCodesPath}
        onSuccess={handleAddSuccess}
      />
    </Container>
  );
};

Index.propTypes = IndexProps;

export default Index;
