import { arrayOf, bool, func, number, shape, string } from 'prop-types';

import { SeriesProps, TableProps, VideoProps } from '../../types';

export const VideoFormProps = {
  ...VideoProps,
  adminVideosPath: string.isRequired,
  rusticiHost: string.isRequired,
  rusticiBasePath: string.isRequired,
  rusticiToken: string.isRequired,
  rusticiCdnHost: string.isRequired,
  types: arrayOf(string).isRequired,
};

export const IndexProps = {
  ...TableProps,
  newAdminVideoPath: string.isRequired,
  adminVideosPath: string.isRequired,
  videos: arrayOf(shape(VideoProps)).isRequired,
};

export const ActionsProps = {
  row: shape({
    original: shape(VideoProps).isRequired,
  }).isRequired,
};

export const PreviewModalProps = {
  show: bool.isRequired,
  onHide: func.isRequired,
  rusticiId: string.isRequired,
  rusticiBaseUrl: string.isRequired,
  rusticiToken: string.isRequired,
  rusticiCdnHost: string.isRequired,
};

export const UploadVideoProps = {
  rusticiToken: string.isRequired,
  rusticiBaseUrl: string.isRequired,
  adminVideosPath: string.isRequired,
};

export const AcceptedFileProps = {
  status: string.isRequired,
  file: shape({
    name: string.isRequired,
    size: number.isRequired,
  }).isRequired,
  now: number.isRequired,
  handleRequestUpload: func.isRequired,
};

export const SeriesCellProps = {
  cell: shape({
    value: shape(SeriesProps),
  }).isRequired,
};
