import { Button, FormField, Input, Modal } from '@bbnpm/bb-ui-framework';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useToggle } from 'react-use';

import { update } from '../../../api';
import { ButtonContainer, RequiredLabel } from '../../CommonComponents';

export const UpdateMemberCell = ({ row, refreshData }) => {
  const isDisabled = row.original.memberType === 'captain';
  const [showMemberModal, toggleShowMemberModal] = useToggle(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setErrors({});
  }, [showMemberModal]);

  const handleEdit = async values => {
    try {
      await update(
        `/admin/trading_challenge_team_members/${row.original.id}`,
        values,
      );
      refreshData();
      toggleShowMemberModal();
    } catch (error) {
      setErrors(error.response?.data?.errors);
    }
  };
  return (
    <>
      <Button
        icon="pencil"
        disabled={isDisabled}
        onClick={toggleShowMemberModal}
      />
      <MemberModal
        isOpen={showMemberModal}
        toggleIsOpen={toggleShowMemberModal}
        refreshData={refreshData}
        onSubmit={handleEdit}
        errors={errors}
        {...row.original}
      />
    </>
  );
};

const MemberModal = ({
  isOpen,
  toggleIsOpen,
  registrationId,
  onSubmit,
  errors,
  id,
  firstName,
  lastName,
  email,
  memberType,
}) => {
  const { register, handleSubmit } = useForm();
  return (
    <Modal
      isOpen={isOpen}
      title="Add Team Member"
      portalContainerId="bfe-root"
      onClose={toggleIsOpen}>
      <form
        onSubmit={handleSubmit((values, e) => {
          e.preventDefault();
          onSubmit(values, id);
        })}>
        <FormField label={<RequiredLabel>First Name</RequiredLabel>}>
          <Input
            ref={register}
            name="firstName"
            defaultValue={firstName}
            required
          />
        </FormField>
        <FormField label={<RequiredLabel>Last Name</RequiredLabel>}>
          <Input
            ref={register}
            name="lastName"
            defaultValue={lastName}
            required
          />
        </FormField>
        <FormField
          label={<RequiredLabel>Email</RequiredLabel>}
          validationType={errors?.email?.length && 'error'}
          validationContent={errors?.email?.join(', ')}>
          <Input ref={register} name="email" defaultValue={email} required />
        </FormField>
        <FormField
          validationType={errors?.base?.length && 'error'}
          validationContent={errors?.base?.join(', ')}>
          <input
            type="hidden"
            ref={register}
            name="memberType"
            value={memberType ?? 'member'}
          />
          {registrationId && (
            <input
              type="hidden"
              ref={register}
              name="trading_challengeRegistrationId"
              value={registrationId}
            />
          )}
        </FormField>

        <ButtonContainer>
          <Button kind="primary" type="submit">
            {id ? 'Update' : 'Add'}
          </Button>
          <Button kind="secondary" onClick={toggleIsOpen} className="mx-1">
            Cancel
          </Button>
        </ButtonContainer>
      </form>
    </Modal>
  );
};

export default MemberModal;
