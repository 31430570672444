import {
  Button,
  GlobalStyles,
  ThemeProvider,
  Typography,
} from '@bbnpm/bb-ui-framework';
import { Badge } from 'react-bootstrap';

import Container from '../../Container';
import DeleteButton from '../../DeleteButton';
import { ButtonLinkStyle } from './styles';
import { ShowProps } from './types';

const Show = ({
  course,
  courseModules,
  active,
  upgradable,
  internalName,
  name,
  icon,
  requiredForCertification,
  editAdminCourseVersionPath,
  adminCourseVersionPath,
}) => {
  return (
    <ThemeProvider>
      <GlobalStyles />
      <Container>
        {active && (
          <div>
            <Badge variant="success">Active</Badge>
          </div>
        )}
        <div className="d-flex align-items-center mb-3">
          <Typography.PageTitle>{internalName}</Typography.PageTitle>
          <Button
            as="a"
            kind="secondary"
            href={course.href}
            style={ButtonLinkStyle}>
            Course
          </Button>
        </div>

        <Typography.Text>
          Visible Name: <strong>{name || course.name}</strong>{' '}
          {!name && <span className="text-muted">(Inherited from course)</span>}
        </Typography.Text>

        {icon && (
          <Typography.Text>
            Icon: <i className={`fas fa-lg fa-${icon}`} />
          </Typography.Text>
        )}

        <Typography.Text>
          Required for certification:{' '}
          <strong>{requiredForCertification ? 'Yes' : 'No'}</strong>
        </Typography.Text>

        <Typography.Text>
          Upgradable: <strong>{upgradable ? 'Yes' : 'No'}</strong>
        </Typography.Text>

        <Typography.SectionTitle>Modules</Typography.SectionTitle>

        {courseModules.length ? (
          <ol>
            {courseModules.map(({ id, internalName }) => (
              <li key={id}>{internalName}</li>
            ))}
          </ol>
        ) : (
          <Typography.Text>No modules found.</Typography.Text>
        )}

        <div className="mb-2">
          <Button
            as="a"
            href={editAdminCourseVersionPath}
            style={ButtonLinkStyle}>
            Edit
          </Button>
          <DeleteButton action={adminCourseVersionPath}>Delete</DeleteButton>
        </div>
      </Container>
    </ThemeProvider>
  );
};

Show.propTypes = ShowProps;

export default Show;
