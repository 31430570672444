import { arrayOf, number, shape, string } from 'prop-types';

import { BatClassProps, TableProps } from '../../types';

export const IndexProps = {
  ...TableProps,
  adminClassesPath: string.isRequired,
  newAdminClassPath: string.isRequired,
  classes: arrayOf(shape(BatClassProps)).isRequired,
};

export const ShowProps = BatClassProps;

export const ClassDropdownProps = {
  row: shape({
    original: shape(BatClassProps),
  }),
};

export const ClassFormProps = {
  ...BatClassProps,
  adminBatClassesPath: string,
  suggestedClassCode: string,
  defaultExpiredAt: string,
  professorEmail: string,
  nonMatchEmailDomainLimit: number,
};
