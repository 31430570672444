import { arrayOf, bool, number, shape, string } from 'prop-types';

import Button from '../../Button';
import Container from '../../Container';
import Input from '../../Input';
import PatchForm from '../../PatchForm';
import PostForm from '../../PostForm';

const CustomerForm = ({
  id,
  href,
  name = '',
  location = '',
  billables = 0,
  terminals = 0,
  terminalAccess = false,
  bbgCustomerId,
  errors = {},
  adminFirmPath,
  adminFirmCustomersPath,
}) => {
  const [Form, action, submitText] = id
    ? [PatchForm, href, 'Update']
    : [PostForm, adminFirmCustomersPath, 'Submit'];

  return (
    <Container>
      <div className="d-flex align-items-center">
        <h1>{id ? 'Edit' : 'Add'} Customer</h1>
        {adminFirmPath && (
          <Button
            className="ml-4"
            variant="light"
            href={adminFirmPath}
            size="sm">
            Cancel
          </Button>
        )}
      </div>
      <Form action={action} submitText={submitText}>
        <Input required name="name" defaultValue={name} errors={errors.name} />
        <Input
          required
          name="bbgCustomerId"
          defaultValue={bbgCustomerId}
          errors={errors.bbgCustomerId}
        />
        <Input
          name="location"
          defaultValue={location}
          errors={errors.location}
        />
        <Input
          name="billables"
          type="number"
          defaultValue={billables}
          errors={errors.billables}
        />
        <Input
          name="terminals"
          type="number"
          defaultValue={terminals}
          errors={errors.terminals}
        />
        <Input
          name="terminalAccess"
          defaultChecked={terminalAccess}
          type="switch"
          helpText="Professors with an e-mail domain tied to this customer's institution will get access to the Terminal Login Authorization page"
        />
      </Form>
    </Container>
  );
};

CustomerForm.propTypes = {
  id: number,
  href: string,
  name: string,
  location: string,
  billables: number,
  terminals: number,
  bbgCustomerId: number,
  terminalAccess: bool,
  errors: shape({
    name: arrayOf(string),
    location: arrayOf(string),
    billables: arrayOf(string),
    terminals: arrayOf(string),
    bbgCustomerId: arrayOf(string),
  }),
  adminFirmPath: string,
  adminFirmCustomersPath: string,
};

export default CustomerForm;
