import { Typography } from '@bbnpm/bb-ui-framework';
import styled from 'styled-components';

export const StyledListItem = styled.div`
  padding: 0 15px 0 15px;
`;

export const StyledLink = styled(Typography.Link)`
  color: ${({ theme }) => theme.colors.blue[400]} !important;
  text-decoration: none !important;
`;
