import {
  GlobalStyles,
  ThemeProvider,
  Typography,
} from '@bbnpm/bb-ui-framework';

import { BfeGrid, Row } from '../../styles/grid';
import {
  StyledPageTitle,
  TitleHero,
  TitleWithImageContainer,
} from '../home_page/styles';

const VideoPlayer = ({ vimeoId }) => {
  return (
    <div style={{ textAlign: 'center', width: '100%' }}>
      <iframe
        src={`https://player.vimeo.com/video/${vimeoId}&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`}
        width="672"
        height="378"
        frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
        title="Building Your Personal Brand"></iframe>
    </div>
  );
};
const CareerPage = ({ bannerImg }) => {
  return (
    <ThemeProvider>
      <GlobalStyles />
      <TitleHero>
        <TitleWithImageContainer img={bannerImg}>
          <StyledPageTitle>Career Center</StyledPageTitle>
          <Typography.Text style={{ whiteSpace: 'pre-line' }}>
            Welcome to our Career Center, a hub of resources created by our
            Education specialists and Bloomberg recruiters to help you parlay
            your newly gained knowledge and skills into valuable career
            opportunities. Watch our professional development videos to gain a
            competitive advantage and visit our jobs page to learn more about
            early engagement programs and entry-level roles.
          </Typography.Text>
        </TitleWithImageContainer>
      </TitleHero>
      <BfeGrid>
        <Row>
          <Typography.Text>
            You&apos;ve already taken the first step in your professional
            development by completing Bloomberg for Education&apos;s certificate
            courses, participating in our annual trading challenges and watching
            interactive videos led by Bloomberg market experts.
          </Typography.Text>
          <Typography.Text>
            You might be wondering how to parlay your gained knowledge and
            skills into a valuable career opportunity.
          </Typography.Text>
          <Typography.Text>
            Your success in both landing a role and standing out on the job
            market is based on a combination of factors. Our business recruiters
            have created a variety of resources aimed at helping you gain a
            competitive advantage and learn more about available job
            opportunities at Bloomberg.
          </Typography.Text>
        </Row>

        <Row>
          <Typography.SectionTitle>
            Professional Development Resource
          </Typography.SectionTitle>
        </Row>
        <Row>
          <Typography.Text>
            Whether you&apos;re a student or early career professional, managing
            your personal brand is an integral part of growing a successful
            career. In the video below, learn the value of personal branding and
            how you can leverage specific tools to create a positive perception
            for professional success - both offline and online.
          </Typography.Text>
        </Row>
        <Row>
          <VideoPlayer vimeoId={'980706569?h=c445e965db'} />
        </Row>

        <br />
        <br />
        <Row>
          <Typography.SectionTitle>
            Inside Bloomberg Podcast
          </Typography.SectionTitle>
        </Row>
        <Row>
          <Typography.Text>
            Interested in learning more about working at Bloomberg but
            haven&apos;t had the chance to meet recruiters in person? Watch the
            6-part “Inside Bloomberg” podcast to explore life at Bloomberg and
            the core values that make the company stand out.
          </Typography.Text>

          <Typography.SubSectionTitle>
            Episode 1: Collaboration: More Than Just a Buzzword
          </Typography.SubSectionTitle>
          <Typography.Text>
            Sales Account Manager, Gabi Santos, and Global Early Careers Program
            Manager, Anri Kimura Bushby, explain why a positive attitude is
            priceless at Bloomberg and how skills can be developed with support,
            but attitude can&apos;t be taught.
          </Typography.Text>
        </Row>
        <Row>
          <VideoPlayer vimeoId={'980702924?h=f5dec0e49d'} />
        </Row>

        <br />
        <br />
        <Row>
          <Typography.SectionTitle>
            Career Opportunities at Bloomberg
          </Typography.SectionTitle>
        </Row>
        <Row>
          <Typography.Text>
            If you&apos;re passionate about the financial markets, solving
            complex problems and challenging the status quo, we want to hear
            from you. Whether you’re student looking for an internship, a
            graduate seeking a full-time position, or just want to learn more
            about what we do, take your first step toward a career at Bloomberg
            below. Who knows where it may take you next.
          </Typography.Text>
          <Typography.Text>
            Visit our{' '}
            <Typography.Link
              href={'https://www.bloomberg.com/company/careers/early-career/'}>
              early career website
            </Typography.Link>{' '}
            to learn more.
          </Typography.Text>
        </Row>
      </BfeGrid>
    </ThemeProvider>
  );
};

export default CareerPage;
