import { Button, FormField, Input, Modal } from '@bbnpm/bb-ui-framework';
import { useForm } from 'react-hook-form';

import { ButtonContainer, RequiredLabel } from '../../CommonComponents';

const AddFirmInstitutionModal = ({
  isOpen,
  toggleIsOpen,
  institutionId,
  onSubmit,
  errors,
}) => {
  const { register, handleSubmit } = useForm();
  return (
    <Modal
      isOpen={isOpen}
      title="Link to Existing Firm"
      portalContainerId="bfe-root"
      onClose={toggleIsOpen}>
      <form
        onSubmit={handleSubmit((values, e) => {
          e.preventDefault();
          onSubmit(values);
        })}>
        <FormField
          label={<RequiredLabel>BBg Firm Id</RequiredLabel>}
          validationType={errors.firm?.length && 'error'}
          validationContent={errors.firm?.join(', ')}>
          <Input
            placeholder="Enter existing Bloomberg firm id"
            ref={register}
            name="bbgFirmId"
            required
          />
        </FormField>
        <FormField>
          <input
            type="hidden"
            ref={register}
            name="institutionId"
            value={institutionId}
          />
        </FormField>

        <ButtonContainer>
          <Button kind="primary" type="submit">
            Add
          </Button>
          <Button kind="secondary" onClick={toggleIsOpen} className="mx-1">
            Cancel
          </Button>
        </ButtonContainer>
      </form>
    </Modal>
  );
};

export default AddFirmInstitutionModal;
