import { Typography } from '@bbnpm/bb-ui-framework';
import { array, string } from 'prop-types';

import CaseStudiesListItem from './CaseStudyListItem';

const CaseStudiesList = ({ caseStudies, defaultLogoPath }) => (
  <>
    <Typography.SectionTitle>
      Required Case Studies by Class Code
      {caseStudies.map(caseStudy => (
        <CaseStudiesListItem
          key={caseStudy.id}
          caseStudy={caseStudy}
          defaultLogoPath={defaultLogoPath}
        />
      ))}
    </Typography.SectionTitle>
  </>
);

CaseStudiesList.propTypes = {
  caseStudies: array.isRequired,
  defaultLogoPath: string.isRequired,
};

export default CaseStudiesList;
