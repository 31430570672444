import cn from 'classnames';
import { node } from 'prop-types';
import { useRef, useState } from 'react';
import { useIntersection, useIsomorphicLayoutEffect } from 'react-use';

const StickyFooter = ({ children }) => {
  const intersectionRef = useRef(null);
  const intersection = useIntersection(intersectionRef, {});
  const [{ height }, setFooterRect] = useState({});

  useIsomorphicLayoutEffect(() => {
    setFooterRect(intersectionRef.current.getBoundingClientRect());
  }, [intersectionRef, setFooterRect]);

  const isSticky = intersection && !intersection.isIntersecting;

  return (
    <div ref={intersectionRef} style={{ height }}>
      <div
        className={cn({
          'fixed-bottom py-3 bg-white border-top': isSticky,
        })}>
        {children}
      </div>
    </div>
  );
};

StickyFooter.propTypes = { children: node.isRequired };

export default StickyFooter;
