import { FormField, Input, Typography } from '@bbnpm/bb-ui-framework';

import { RequiredLabel } from '../../CommonComponents';
import { CaptainInfoSectionPropTypes } from '../types';

export const CaptainInfo = ({
  disabled,
  user,
  captainInfo,
  setCaptainInfo,
  formErrors,
}) => {
  return (
    <div id="captain-info-section">
      <Typography.SectionTitle style={{ margin: '1rem 0' }}>
        Captain Information
      </Typography.SectionTitle>
      <FormField
        label={<RequiredLabel>Captain First Name</RequiredLabel>}
        validationType={formErrors.teamMembers?.['0']?.firstName && 'error'}
        validationContent={
          formErrors.teamMembers?.['0']?.firstName &&
          'First Name' + formErrors.teamMembers?.['0']?.firstName
        }>
        <Input
          name="captain"
          type="text"
          value={user ? user.firstName : captainInfo.firstName}
          onChange={evt =>
            setCaptainInfo({ ...captainInfo, firstName: evt.target.value })
          }
          disabled={user || disabled}
        />
      </FormField>
      <FormField
        label={<RequiredLabel>Captain Last Name</RequiredLabel>}
        validationType={formErrors.teamMembers?.['0']?.lastName && 'error'}
        validationContent={
          formErrors.teamMembers?.['0']?.lastName &&
          'Last Name' + formErrors.teamMembers?.['0']?.lastName
        }>
        <Input
          name="captain"
          type="text"
          value={user ? user.lastName : captainInfo.lastName}
          onChange={evt =>
            setCaptainInfo({ ...captainInfo, lastName: evt.target.value })
          }
          disabled={user || disabled}
        />
      </FormField>
      <FormField
        label={<RequiredLabel>Captain Email</RequiredLabel>}
        validationType={formErrors.teamMembers?.['0']?.email && 'error'}
        validationContent={
          formErrors.teamMembers?.['0']?.email &&
          'Email' + formErrors.teamMembers?.['0']?.email
        }>
        <Input
          name="captain_email"
          type="text"
          value={user ? user.email || user.uuid : captainInfo.email}
          onChange={evt =>
            setCaptainInfo({ ...captainInfo, email: evt.target.value })
          }
          disabled={user || disabled}
        />
      </FormField>
    </div>
  );
};

CaptainInfo.propTypes = CaptainInfoSectionPropTypes;
