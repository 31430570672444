import {
  Button,
  GlobalStyles,
  ThemeProvider,
  Typography,
} from '@bbnpm/bb-ui-framework';
import { useState } from 'react';
import { useToggle } from 'react-use';

import { destroy, sendAsForm } from '../../../api';
import { noop } from '../../../utils/index';
import Container from '../../Container';
import DaperImgCell from '../../DaperImgCell';
import PrimaryAdminKey from '../../PrimaryAdminKey';
import Table from '../../Table';
import { useFetchData } from '../../utils';
import ToolkitTagFormModal from './ToolkitTagFormModal';
import { TOOLKIT_TAG_TYPES } from './constants';
import { IndexProps, TopicsActionsProps } from './types';

const TopicsActions = ({ row, refreshData }) => {
  const [showEditForm, toggleShowEditForm] = useToggle(false);
  const handleEditSubmit = async values => {
    await sendAsForm(row.original.adminHref, values, 'put');
    refreshData();
  };

  const handleDelete = async () => {
    if (
      window.confirm(
        'Are you sure you want to permanently delete this topic and remove it from all documents?',
      )
    ) {
      try {
        await destroy(row.original.adminToolkitTagPath);
        refreshData();
      } catch (error) {
        alert('Something went wrong.');
      }
    }
  };

  return (
    <span className="text-nowrap">
      <Button
        title="Edit"
        icon="pencil"
        kind="secondary"
        onClick={toggleShowEditForm}
      />
      <Button
        title="Delete"
        icon="trash"
        kind="destruction"
        className="ml-2"
        onClick={handleDelete}
      />
      <ToolkitTagFormModal
        isOpen={showEditForm}
        onClose={toggleShowEditForm}
        onSubmit={handleEditSubmit}
        toolkitTag={row.original}
      />
    </span>
  );
};

const ToolkitType = ({ row }) => TOOLKIT_TAG_TYPES[row.original.type];

const scopes = [
  { id: 'is_toolkit_topic', title: 'Topics' },
  { id: 'is_toolkit_type', title: 'Document Types' },
];

const initialScopes = { is_toolkit_topic: false, is_toolkit_type: false };

TopicsActions.propTypes = TopicsActionsProps;

const columns = [
  { Header: 'ID', accessor: 'id', Cell: PrimaryAdminKey },
  { Header: 'Title', accessor: 'title' },
  { Header: 'Created At', accessor: 'createdAt' },
  {
    Header: 'Tag Type',
    accessor: 'n/a',
    id: 'type',
    Cell: ToolkitType,
  },
  {
    Header: 'Thumbnail',
    accessor: 'thumbnail',
    disableSortBy: true,
    cellProps: { className: 'text-center' },
    Cell: DaperImgCell,
  },
  {
    accessor: 'n/a',
    id: 'actions',
    disableSortBy: true,
    Cell: TopicsActions,
  },
];

const cb = ({ totalCount, pageSize, toolkitTags }) => ({
  data: toolkitTags,
  pageSize,
  totalCount,
});

const Index = ({
  totalCount,
  pageSize,
  pageIndex,
  sortBy,
  toolkitTags,
  adminToolkitTagsPath,
  adminToolkitDocumentsPath,
}) => {
  const [showAddForm, toggleShowAddForm] = useToggle(false);

  const [{ refreshData }, setRefreshDataFn] = useState({ refreshData: noop });
  const fetchData = useFetchData(adminToolkitTagsPath, cb);

  const handleAddSubmit = async values => {
    await sendAsForm(adminToolkitTagsPath, values, 'post');
    refreshData();
  };

  return (
    <ThemeProvider>
      <GlobalStyles />
      <Container>
        <ToolkitTagFormModal
          isOpen={showAddForm}
          onClose={toggleShowAddForm}
          onSubmit={handleAddSubmit}
        />
        <div className="d-flex align-items-center mb-3">
          <Typography.PageTitle>Toolkit Tags</Typography.PageTitle>
          <Button onClick={toggleShowAddForm} className="ml-4" kind="primary">
            Add Toolkit Tag
          </Button>
          <Button
            as="a"
            href={adminToolkitDocumentsPath}
            className="ml-4"
            kind="secondary">
            Manage Toolkit Documents
          </Button>
        </div>

        <Table
          initialData={toolkitTags}
          columns={columns}
          initialSortBy={sortBy}
          initialPageSize={pageSize}
          initialPageIndex={pageIndex}
          initialTotalCount={totalCount}
          fetchData={fetchData}
          onRefreshDataFn={setRefreshDataFn}
          refreshData={refreshData}
          availableScopes={scopes}
          initialScopes={initialScopes}
        />
      </Container>
    </ThemeProvider>
  );
};

Index.propTypes = IndexProps;

export default Index;
