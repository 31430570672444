import { Button, Icon, Modal } from '@bbnpm/bb-ui-framework';
import { useToggle } from 'react-use';
import styled from 'styled-components';

import { destroy, update } from '../../../api';
import CourseModuleFormModal from './CourseModuleFormModal';
import PreviewModal from './PreviewModal';
import UploadModal from './UploadModal';
import { CourseModuleActionsProps } from './types';

const StyledButton = styled(Button)`
  border: none;
`;

const CourseModuleActions = ({ row, refreshData, courseModuleCategories }) => {
  const { adminCourseModulePath, uploadedAt, rusticiId } = row.original;
  const [showEditForm, toggleShowEditForm] = useToggle(false);
  const [showUpload, toggleShowUpload] = useToggle(false);
  const [showPreview, toggleShowPreview] = useToggle(false);
  const [showDeleteModal, toggleShowDeleteModal] = useToggle(false);

  const handleEditSubmit = async values => {
    await update(adminCourseModulePath, values);
    refreshData();
    toggleShowEditForm();
  };

  const handleDelete = async () => {
    try {
      await destroy(row.original.adminCourseModulePath);
      refreshData();
    } catch (error) {
      alert('Something went wrong.');
    }
    toggleShowDeleteModal();
  };

  return (
    <span className="text-nowrap">
      <StyledButton title="Edit" kind="secondary" onClick={toggleShowEditForm}>
        <Icon name="autofill" />
      </StyledButton>
      {uploadedAt && (
        <StyledButton
          title="Preview"
          kind="secondary"
          onClick={toggleShowPreview}>
          <Icon name="eye" />
        </StyledButton>
      )}
      {!uploadedAt && (
        <StyledButton
          title="Upload"
          kind="secondary"
          onClick={toggleShowUpload}>
          <Icon name="upload" />
        </StyledButton>
      )}
      <StyledButton
        title="Delete"
        kind="destruction"
        disabled={!!row.original.courseVersionsCount}
        onClick={toggleShowDeleteModal}>
        <Icon name="trash" />
      </StyledButton>
      <CourseModuleFormModal
        show={showEditForm}
        onHide={toggleShowEditForm}
        onSubmit={handleEditSubmit}
        courseModule={row.original}
        refreshData={refreshData}
        courseModuleCategories={courseModuleCategories}
      />
      <UploadModal
        show={showUpload}
        onHide={toggleShowUpload}
        courseModule={row.original}
        refreshData={refreshData}
      />
      <PreviewModal
        show={showPreview}
        onHide={toggleShowPreview}
        rusticiId={rusticiId}
      />
      <Modal
        isOpen={showDeleteModal}
        title={`Delete Module: ${row.original.name}`}
        contentLabel="Delete Module"
        portalContainerId="bfe-root"
        primaryActions={
          <>
            <Button kind="destruction" onClick={handleDelete}>
              Delete Module Permanently
            </Button>
            <Button kind="secondary" onClick={toggleShowDeleteModal}>
              Cancel
            </Button>
          </>
        }>
        PLEASE CONFIRM YOU WANT TO DELETE {`${row.original.name}`} MODULE. THIS
        ACTION IS PERMANENT!
      </Modal>
    </span>
  );
};

CourseModuleActions.propTypes = CourseModuleActionsProps;

export default CourseModuleActions;
