import { number, shape, string } from 'prop-types';
import { Image } from 'react-bootstrap';

export const daperUrl = ({ daperKey, width, height }) =>
  `https://assets.bwbx.io/images/users/${daperKey}/${width}x${height}.jpg`;

const DaperImg = ({ metadata, width, height, ...imgProps }) => {
  const { daperKey, width: origWidth, height: origHeight } = metadata;

  const intrinsicRatio = origHeight / origWidth;

  const [resolvedWidth, resolvedHeight] = width
    ? [width, height ? height : Math.round(width * intrinsicRatio)]
    : height
    ? [Math.round(height / intrinsicRatio), height]
    : [-1, -1];

  const srcSet =
    width || height
      ? [1, 2, 3]
          .map(
            pixelDensity =>
              `${daperUrl({
                daperKey,
                width: resolvedWidth * pixelDensity,
                height: resolvedHeight * pixelDensity,
              })} ${pixelDensity}x`,
          )
          .join(',')
      : false;

  return (
    <Image
      src={daperUrl({ daperKey, width: resolvedWidth, height: resolvedHeight })}
      srcSet={srcSet}
      {...imgProps}
    />
  );
};

DaperImg.propTypes = {
  metadata: shape({
    daperKey: string.isRequired,
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
  width: number,
  height: number,
};

export default DaperImg;
