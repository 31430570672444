import { ProgressBar } from 'react-bootstrap';

import { humanFileSize } from '../../../utils';
import Button from '../../Button';
import Spinner from '../../Spinner';
import { UPLOAD_FILE_STATUSES } from '../../utils';
import { AcceptedFileProps } from './types';

const progressBarStyles = {
  height: 2,
  borderRadius: 1,
};

const AcceptedFile = ({ status, file, now, handleRequestUpload }) => (
  <>
    <div className="d-flex align-items-center mb-3">
      <div className="mr-3">
        <i className="mb-1 fas fa-file fa-4x text-gray-300" />
        {status === UPLOAD_FILE_STATUSES.UPLOAD_STARTED && (
          <ProgressBar now={now} style={progressBarStyles} />
        )}
      </div>
      <div className="text-muted font-size-sm">
        <strong>{file.name}</strong>
        <br />({humanFileSize(file.size)})
      </div>
    </div>
    <Button
      variant="primary"
      className="mr-2"
      onClick={handleRequestUpload}
      disabled={status !== UPLOAD_FILE_STATUSES.INIT}>
      {status === UPLOAD_FILE_STATUSES.INIT ? (
        <>
          <i className="fas fa-cloud-upload-alt mr-2" /> Upload
        </>
      ) : (
        <>
          <Spinner /> Uploading ({Math.floor(now)}%)
        </>
      )}
    </Button>
    <Button
      size="sm"
      className="text-muted"
      variant="link"
      disabled={status !== UPLOAD_FILE_STATUSES.INIT}>
      or choose a file
    </Button>
  </>
);
AcceptedFile.propTypes = AcceptedFileProps;

export default AcceptedFile;
