import { Button, Card, Icon, Typography } from '@bbnpm/bb-ui-framework';
import { Row } from '@bloomberg/styled-flexboxgrid';
import Carousel from 'react-multi-carousel';
import styled from 'styled-components';

export const TitleHero = styled.div`
  background-color: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};
`;

export const TitleContainer = styled.div`
  padding: 1rem 0.5rem 0.5rem;
  margin: 0 auto;
  max-width: 1440px;
`;

export const StyledPageTitle = styled(Typography.LargeTitle)`
  color: ${({ theme }) => theme.colors.amber[500]};
  margin-bottom: 0;
`;

export const TitleFooterHero = styled.div`
  background-color: ${({ theme }) => theme.colors.grey[850]};
`;

export const TitleFooterContainer = styled.div`
  margin: 0 auto;
  max-width: 1440px;
  display: flex;
  flex-wrap: wrap;
`;

export const TitleFooterItem = styled.div`
  padding: 1rem 0.5rem 1.5rem;
  color: ${({ theme }) => theme.colors.white};
  &:hover {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const StyledTableItem = styled.td`
  font-size: 14px !important;
  padding: 4px !important;
  white-space: nowrap;
`;

export const AddClassButton = styled(Button)`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.white};
  &:hover {
    background-color: transparent !important;
    border: none;
    color: ${({ theme }) => theme.colors.white};
  }
  &:focus {
    background-color: transparent !important;
    border: none;
    color: ${({ theme }) => theme.colors.white};
  }
`;
export const StyledLink = styled.a`
  color: ${({ theme }) => theme.colors.white};
  text-decoration: underline;
  &:hover {
    color: inherit;
  }
`;

export const StyledArrow = styled(Icon)`
  color: ${({ theme }) => theme.colors.black};
  border: 1px solid ${({ theme }) => theme.colors.black};
  border-radius: 50%;
  padding: 10px;
  font-size: 12px;
`;

export const StyledStatus = styled(Typography.Label)`
  color: ${({ theme }) => theme.colors.blue[500]};
`;

export const StyledCarousel = styled(Carousel)`
  .react-multiple-carousel__arrow {
    background: transparent;
    border: 2px solid white;
  }

  .react-multi-carousel-dot > button {
    background: black;
    border: 2px solid white;
    width: 1rem;
    height: 1rem;
  }

  .react-multi-carousel-dot-list {
    padding: 0.5rem;
  }

  .react-multi-carousel-dot--active > button {
    background: ${({ theme }) => theme.colors.blue[500]};
  }
`;

export const CarouselItemContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};
  margin: 0;
  padding: 2rem 1rem 3rem 1rem;
  height: 100%;
  max-height: 400px;
`;

export const CarouselItemRow = styled(Row)`
  :first-child {
    padding: 0 2rem;
  }
`;

export const CarouselItemImage = styled.div`
  & > img {
    width: 100%;
    max-height: 300px;
    object-fit: cover;
  }
`;

export const StyledCard = styled(Card)`
  width: fit-content;
  min-width: 0px;

  * > h2 {
    margin-top: 0.5rem;
  }
`;

export const LargeIcon = styled(Icon)`
  font-size: 100px;
`;

export const NoMarginTitle = styled(Typography.LargeTitle)`
  margin: 0;
`;

export const StyledButtonGroup = styled.div`
  top: -10px;
  right: 1%;
  margin: 1.15rem 0;
`;

export const StyledRow = styled(Row)`
  margin-bottom: 3rem !important;

  * > h1 {
    margin-top: 0;
  }
`;

export const StyledHr = styled.hr`
  border-bottom: 1px solid black;
  margin-bottom: 2rem;
`;

export const TitleWithImageContainer = styled.div`
  padding: 1rem 3rem 3rem 0.5rem;
  @media (width > 60em) {
    background-image: ${({ img }) => `url("${img}")`};
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right;
    padding: 1rem 25rem 3rem 0.5rem;
  }
  margin: 0 auto;
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : `1440px`)};
`;
