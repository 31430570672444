import { Button, Typography } from '@bbnpm/bb-ui-framework';
import sortBy from 'lodash.sortby';
import { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import styled from 'styled-components';

import { post } from '../../../api';
import { PromoItemPositionSelectorProps } from './types';

const PromoItemDraggable = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  width: 100%;
  margin: 12px 0;
  padding: 12px;
`;

const DraggableContainer = styled.div`
  height: 100%;
`;

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.grey[100]};
  margin-top: 16px;
  padding: 16px;
  text-align: center;
`;

const PromoItemPositionSelector = ({
  promoItems,
  refreshData,
  updateOrderAdminPromoItemsPath,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [sortedItems, setSortedItems] = useState(
    sortBy(promoItems, ['position']),
  );
  useEffect(
    () => setSortedItems(sortBy(promoItems, ['position'])),
    [...promoItems.map(item => item.id)],
  );

  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }

    const newList = [...sortedItems];
    const fromIdx = result.source.index;
    const toIdx = result.destination.index;

    const [removed] = newList.splice(fromIdx, 1);
    newList.splice(toIdx, 0, removed);

    setSortedItems(newList);
  };

  const handleSave = async () => {
    setIsSubmitting(true);
    try {
      await post(updateOrderAdminPromoItemsPath, {
        data: sortedItems.map(item => item.id),
      });
      await refreshData();
    } catch (e) {
      alert('Something went wrong. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Container>
      <div className="d-flex align-items-baseline justify-content-center mb-3">
        <Typography.SectionTitle>
          Set Item Position Order
        </Typography.SectionTitle>
        <Button
          className="ml-3"
          kind="primary"
          onClick={handleSave}
          disabled={isSubmitting}>
          Update Item Positions
        </Button>
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="promoItemPositionDroppable">
          {droppableProvided => (
            <DraggableContainer ref={droppableProvided.innerRef}>
              {sortedItems.map((item, idx) => {
                return (
                  <Draggable
                    key={item.id}
                    draggableId={item.id.toString()}
                    index={idx}>
                    {provided => {
                      return (
                        <>
                          <PromoItemDraggable
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}>
                            {idx + 1}. {item.title} (id:{item.id})
                          </PromoItemDraggable>
                        </>
                      );
                    }}
                  </Draggable>
                );
              })}
              {droppableProvided.placeholder}
            </DraggableContainer>
          )}
        </Droppable>
      </DragDropContext>
    </Container>
  );
};

PromoItemPositionSelector.propTypes = PromoItemPositionSelectorProps;
export default PromoItemPositionSelector;
