import cn from 'classnames';
import startcase from 'lodash.startcase';
import {
  arrayOf,
  bool,
  elementType,
  func,
  node,
  number,
  object,
  oneOfType,
  string,
} from 'prop-types';
import { forwardRef } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { useToggle } from 'react-use';

import { trimISODate } from '../utils';

const Input = forwardRef(
  (
    {
      label,
      name,
      helpText,
      required,
      controlId,
      as = 'input',
      type = 'text',
      options = [],
      errors = [],
      includeBlank = false,
      formGroupProps = {},
      inputAppendProps,
      inputRender,
      ...rest
    },
    ref,
  ) => {
    const showLabel = label !== false;
    const actualLabel = label || startcase(name);
    const isInvalid = !!errors.length;
    const [showPassword, toggleShowPassword] = useToggle(false);

    const inputProps = {
      ref,
      required,
      name,
      type,
      as,
      isInvalid,
      ...rest,
    };

    const isInvalidClassName = { 'is-invalid': isInvalid };

    return (
      <Form.Group {...formGroupProps} controlId={controlId || name}>
        {type === 'switch' ? (
          <>
            <input type="hidden" name={name} defaultValue="false" />
            <Form.Switch
              ref={ref}
              label={actualLabel}
              name={name}
              id={name}
              {...rest}
            />
          </>
        ) : type === 'checkbox' ? (
          <>
            <input type="hidden" name={name} defaultValue="false" />
            <Form.Check
              ref={ref}
              label={actualLabel}
              name={name}
              id={name}
              {...rest}
            />
          </>
        ) : (
          <>
            {showLabel && (
              <Form.Label>
                {actualLabel}
                {required && <span className="text-danger"> *</span>}
              </Form.Label>
            )}
            {inputRender ? (
              inputRender(inputProps)
            ) : type === 'date' ? (
              <DayPickerInput
                placeholder=""
                value={rest.defaultValue ? trimISODate(rest.defaultValue) : ''}
                component={Form.Control}
                classNames={{
                  container: cn('DayPickerInput d-block', isInvalidClassName),
                  overlayWrapper: 'DayPickerInput-OverlayWrapper',
                  overlay: 'DayPickerInput-Overlay',
                }}
                inputProps={{
                  autoComplete: 'off',
                  ...inputProps,
                  type: 'text',
                  defaultValue: undefined,
                }}
              />
            ) : type === 'password' ? (
              <InputGroup
                className={cn('password-input-group', isInvalidClassName)}>
                <Form.Control
                  {...inputProps}
                  type={showPassword ? 'text' : 'password'}
                />
                <InputGroup.Append>
                  <Button onClick={toggleShowPassword} variant={false}>
                    {showPassword ? 'Hide' : 'Show'}
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            ) : inputAppendProps ? (
              <InputGroup className={cn(isInvalidClassName)}>
                <Form.Control {...inputProps} />
                <InputGroup.Append>
                  <Button {...inputAppendProps} />
                </InputGroup.Append>
              </InputGroup>
            ) : (
              <Form.Control {...inputProps}>
                {as === 'select' ? (
                  <>
                    {includeBlank && <option value=""></option>}
                    {options.map(([k, v]) => (
                      <option key={k} value={k}>
                        {v}
                      </option>
                    ))}
                  </>
                ) : null}
              </Form.Control>
            )}
          </>
        )}
        {isInvalid && (
          <Form.Control.Feedback type="invalid">
            {errors.join(', ')}
          </Form.Control.Feedback>
        )}
        {helpText && <Form.Text className="text-muted">{helpText}</Form.Text>}
      </Form.Group>
    );
  },
);

Input.propTypes = {
  label: oneOfType([node, bool]),
  name: string.isRequired,
  helpText: node,
  required: bool,
  controlId: string,
  as: elementType,
  type: string,
  errors: arrayOf(string),
  options: arrayOf(arrayOf(oneOfType([string, number]))),
  includeBlank: bool,
  formGroupProps: object,
  inputAppendProps: object,
  inputRender: func,
};

Input.displayName = 'Input';

export default Input;
