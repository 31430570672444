import { Switch, ThemeProvider, Typography } from '@bbnpm/bb-ui-framework';
import { useState } from 'react';
import { Button } from 'react-bootstrap';

import Container from '../../Container';
import Input from '../../Input';
import PatchForm from '../../PatchForm';
import PostForm from '../../PostForm';
import { ClassFormProps } from './types';

const ClassForm = ({
  id,
  nameAlias,
  expiredAtWithTime,
  errors = {},
  adminHref,
  adminBatClassesPath,
  classCode,
  remainingUses,
  suggestedClassCode,
  defaultExpiredAt,
  professorEmail,
  locked,
  isManager,
  nonMatchEmailDomainLimit,
}) => {
  const [Form, action, submitText] = id
    ? [PatchForm, adminHref, 'Update']
    : [PostForm, adminBatClassesPath, 'Submit'];

  const [checked, setChecked] = useState(locked);

  return (
    <ThemeProvider>
      <Container>
        <div className="d-flex align-items-center">
          <h1>{id ? 'Edit' : 'Add'} Class</h1>
          {adminHref && (
            <Button className="ml-4" variant="light" href={adminHref} size="sm">
              Cancel
            </Button>
          )}
        </div>

        <Form action={action} submitText={submitText}>
          <Input
            label="Email"
            errors={errors.professorEmail}
            required
            name="professorEmail"
            defaultValue={professorEmail || ''}
          />
          <Input
            type="date"
            label="Expiration Date"
            errors={errors.expiredAt}
            name="expiredAt"
            defaultValue={expiredAtWithTime || defaultExpiredAt || ''}
          />
          <Input
            label="Name"
            errors={errors.nameAlias}
            required
            name="nameAlias"
            defaultValue={nameAlias || ''}
          />
          <Input
            disabled={id}
            required
            label="Class Code"
            name="classCode"
            defaultValue={classCode || suggestedClassCode}
          />
          <Input
            required
            label="Remaining Free BMC Credits"
            name="remainingUses"
            type="number"
            defaultValue={remainingUses || 0}
          />
          <Input
            label="Non-Matching email domains limit"
            name="nonMatchEmailDomainLimit"
            defaultValue={nonMatchEmailDomainLimit || 0}
            type="number"
            min={0}
            errors={errors.nonMatchEmailDomainLimit}
          />
          <input name="locked" type="hidden" defaultValue={checked || false} />
          <>
            <Typography.FormLabel>Class Code Revoked</Typography.FormLabel>
            <Typography.Text>
              False &nbsp;
              <Switch
                checked={checked}
                disabled={!isManager}
                onChange={event => {
                  setChecked(event.target.checked);
                }}
              />
              &nbsp; True
            </Typography.Text>
          </>
        </Form>
      </Container>
    </ThemeProvider>
  );
};

ClassForm.propTypes = ClassFormProps;

export default ClassForm;
