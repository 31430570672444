import { useMemo } from 'react';
import { Button, Col, ResponsiveEmbed, Row } from 'react-bootstrap';
import { useToggle } from 'react-use';

import { dateSorter, minutesFormatted } from '../../utils';
import ClientDate from '../ClientDate';
import Container from '../Container';
import { daperUrl } from '../DaperImg';
import TabGrid from '../TabGrid';
import { useActiveFilterMemo } from '../utils';
import WebinarCard from '../webinars/WebinarCard';
import { LeadWebinarPanelProps, ShowProps } from './types';

const LeadWebinarPanel = ({ webinar }) => (
  <a href={webinar.href} className="link-unstyled mb-4">
    <Row noGutters className="border bg-white">
      <Col xs={4} className="border-right">
        <ResponsiveEmbed
          aspectRatio="16by9"
          style={{
            backgroundImage: webinar.thumbnail?.daperKey
              ? `url(${daperUrl({
                  daperKey: webinar.thumbnail.daperKey,
                  width: 315 * 2,
                  height: -1,
                })})`
              : '',
            backgroundSize: 'cover',
          }}>
          <div className="cover-with-duration">
            {minutesFormatted(webinar.durationMins)}
          </div>
        </ResponsiveEmbed>
      </Col>
      <Col className="p-3">
        <h4>{webinar.name}</h4>
        <p className="text-pre-wrap">{webinar.description}.</p>
        <p className="text-muted">
          <ClientDate
            date={webinar.occurredAt}
            dateFormat={{ month: 'long', year: 'numeric' }}
          />
        </p>
      </Col>
    </Row>
  </a>
);

LeadWebinarPanel.propTypes = LeadWebinarPanelProps;

const leadWebinar = (featuredWebinarId, webinars) => {
  let featuredWebinar = null;
  if (featuredWebinarId) {
    featuredWebinar = webinars.find(v => v.id === featuredWebinarId);
  }
  // Return featured webinar if set or the first webinar on the list
  return featuredWebinar || webinars[0];
};

const Show = ({
  id,
  name,
  description,
  webinars,
  featuredWebinarId,
  allWebinarSeries,
}) => {
  const [isSortDirectionDesc, toggleSortDirection] = useToggle(true);
  const activeWebinars = useActiveFilterMemo(webinars);
  const activeSeries = useActiveFilterMemo(allWebinarSeries);
  const sortedWebinars = useMemo(
    () =>
      [...activeWebinars].sort(
        dateSorter({ prop: 'occurredAt', isDesc: isSortDirectionDesc }),
      ),
    [isSortDirectionDesc, activeWebinars],
  );
  const introWebinar = leadWebinar(featuredWebinarId, activeWebinars);

  return (
    <Container>
      {name && <h2>{name}</h2>}
      {description && <p className="text-pre-wrap">{description}</p>}

      {activeWebinars.length > 0 && (
        <>
          <LeadWebinarPanel webinar={introWebinar} />
          <span className="mb-4 text-nowrap">
            <Button
              onClick={toggleSortDirection}
              title="Occurred At"
              variant="outline-secondary"
              size="sm">
              Occurred At
              <i
                className={`ml-4 fas fa-angle-${
                  isSortDirectionDesc ? 'down' : 'up'
                }`}
              />
            </Button>
          </span>
        </>
      )}

      <Row>
        {sortedWebinars.map(webinar => (
          <Col lg={4} sm={6} key={webinar.id}>
            <WebinarCard webinar={webinar} className="mb-3" />
          </Col>
        ))}
      </Row>

      <h2 className="font-weight-normal with-angle-right">Available Series</h2>
      <TabGrid className="mb-3">
        {activeSeries.map(({ name, id: seriesId, href }) => (
          <TabGrid.Tab key={seriesId} href={href} isCurrent={id === seriesId}>
            {name}
          </TabGrid.Tab>
        ))}
      </TabGrid>
    </Container>
  );
};

Show.propTypes = ShowProps;

export default Show;
