import { func, number, string } from 'prop-types';
import { useEffect, useState } from 'react';
import { Button, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { useToggle } from 'react-use';

import { post } from '../../api';
import Spinner from '../Spinner';
import ErrorAlert from './ErrorAlert';

const IdentityVerificationForm = ({
  action,
  actionTokenRequest,
  nextStep,
  prevStep,
  email,
  cellPhone,
  countryCallingCode,
  verificationMethod,
  token,
  setToken,
  tokenSentCounter,
  incTokenSentCounter,
}) => {
  const [isSubmitting, toggleIsSubmitting] = useToggle(false);
  const [isErrorPresent, setIsErrorPresent] = useState(false);
  const [isResendTokenDisabled, SetIsResendTokenDisabled] = useState(true);
  const secondsToWait = 120;
  const tokenMaxDeliveryLimit = 4;
  const [counter, setCounter] = useState(secondsToWait);

  useEffect(() => {
    let timer = null;
    if (counter > 0) {
      timer = setTimeout(() => setCounter(counter - 1), 1000);
    }
    if (counter === 0) {
      SetIsResendTokenDisabled(false);
    }
    return () => clearTimeout(timer);
  }, [counter]);

  const formatTimer = seconds => {
    const date = new Date(null);
    date.setSeconds(seconds);
    return date.toISOString().substr(14, 5);
  };

  const handleResendToken = async () => {
    try {
      toggleIsSubmitting();
      SetIsResendTokenDisabled(true);
      setCounter(secondsToWait);

      const response = await post(actionTokenRequest, {
        tokenDeliveryMethod: verificationMethod,
        cellPhone: countryCallingCode + cellPhone,
      });

      if (response.errors.failure) {
        setIsErrorPresent(true);
        toggleIsSubmitting();
      } else {
        incTokenSentCounter();
        toggleIsSubmitting();
      }
    } catch (error) {
      setIsErrorPresent(true);
      toggleIsSubmitting();
    }
  };

  const handleTokenValidation = async () => {
    try {
      toggleIsSubmitting();

      const response = await post(action, { token });

      if (!response.isTokenValid || response.errors.failure) {
        setIsErrorPresent(true);
        toggleIsSubmitting();
      } else {
        toggleIsSubmitting();
        nextStep();
      }
    } catch (error) {
      toggleIsSubmitting();
      setIsErrorPresent(true);
    }
  };

  const tokenCodeRecipient =
    verificationMethod === 'METHOD_EMAIL'
      ? email
      : `+${countryCallingCode}-${cellPhone}`;

  const isTokenMaxDeliveryReached = tokenSentCounter > tokenMaxDeliveryLimit;

  return (
    <Form>
      <Row>
        <Col md={{ span: 7, offset: 3 }}>
          {isErrorPresent && <ErrorAlert />}

          <Form.Group>
            <Form.Label>Enter verification code sent to:</Form.Label>
            <Form.Label>{tokenCodeRecipient}</Form.Label>
            <InputGroup>
              <Form.Control
                value={token}
                onChange={e => setToken(e.target.value)}
              />
              <InputGroup.Append>
                <Button
                  variant={
                    isResendTokenDisabled || isTokenMaxDeliveryReached
                      ? 'secondary'
                      : 'info'
                  }
                  disabled={isResendTokenDisabled || isTokenMaxDeliveryReached}
                  onClick={handleResendToken}>
                  {`${formatTimer(counter)} - Resend Code`}
                </Button>
              </InputGroup.Append>
            </InputGroup>
            <Form.Text muted>
              Verification codes expire after 5 minutes. You can resend a new
              code after 2 minutes.
            </Form.Text>
          </Form.Group>

          <div className="d-flex justify-content-between">
            <Button
              className="px-3"
              disabled={isSubmitting || isTokenMaxDeliveryReached}
              onClick={prevStep}>
              Previous
            </Button>

            <Button
              onClick={handleTokenValidation}
              className="px-4"
              disabled={!token || isSubmitting}>
              {isSubmitting && <Spinner />}
              {isSubmitting ? ' Processing...' : 'Next'}
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

IdentityVerificationForm.propTypes = {
  action: string.isRequired,
  actionTokenRequest: string.isRequired,
  nextStep: func.isRequired,
  prevStep: func.isRequired,
  email: string.isRequired,
  cellPhone: string.isRequired,
  countryCallingCode: string.isRequired,
  verificationMethod: string.isRequired,
  token: string.isRequired,
  setToken: func.isRequired,
  tokenSentCounter: number.isRequired,
  incTokenSentCounter: func.isRequired,
};

export default IdentityVerificationForm;
