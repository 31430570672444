import {
  Button,
  GlobalStyles,
  Icon,
  Table,
  ThemeProvider,
  Tooltip,
  Typography,
  useNotification,
} from '@bbnpm/bb-ui-framework';
import { string } from 'prop-types';
import { Container } from 'react-bootstrap';

import Hero from '../../Hero';

const Confirmation = ({
  classCode,
  nameAlias,
  expiredAt,
  status,
  professorHref,
  createdAt,
}) => {
  const notifAccessor = useNotification();

  return (
    <ThemeProvider>
      <GlobalStyles />
      <Hero
        title="Class Confirmation and Code"
        description={
          <>
            Your class has been created. Please distribute this Class Code:{' '}
            <strong>{classCode}</strong> to your learners. You can always find
            this code on the <strong>Class Details</strong> page.
          </>
        }
        primaryAction={
          <Button as="a" kind="primary" href={professorHref}>
            View Class
          </Button>
        }
      />
      <Container fluid="xl" className="py-3">
        <Typography.PageTitle>Class Details</Typography.PageTitle>
        <Table>
          <tbody>
            <tr>
              <td>Class Name</td>
              <td>{nameAlias}</td>
            </tr>
            <tr>
              <td>Class Code</td>
              <td>
                <Typography.Link
                  onClick={() => {
                    navigator.clipboard.writeText(classCode);
                    notifAccessor.addInfo({
                      message: `Class code ${classCode} copied to clipboard.`,
                      timeout: 10000,
                    });
                  }}>
                  {classCode}{' '}
                  <Tooltip content="Copy to Clipboard">
                    <Icon name="link" />
                  </Tooltip>
                </Typography.Link>
              </td>
            </tr>
            <tr>
              <td>Expires At</td>
              <td>{expiredAt}</td>
            </tr>
            <tr>
              <td>Status</td>
              <td>{status}</td>
            </tr>
            <tr>
              <td>Created At</td>
              <td>{createdAt}</td>
            </tr>
          </tbody>
        </Table>

        <Typography.Text>
          If you have any questions, please contact us at{' '}
          <Typography.Link href={`mailto:bbg.edu@bloomberg.net`}>
            bbg.edu@bloomberg.net
          </Typography.Link>
          .
        </Typography.Text>
      </Container>
    </ThemeProvider>
  );
};

Confirmation.propTypes = {
  classCode: string.isRequired,
  nameAlias: string.isRequired,
  expiredAt: string.isRequired,
  status: string.isRequired,
  professorHref: string.isRequired,
  createdAt: string.isRequired,
};

export default Confirmation;
