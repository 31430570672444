import { Button } from 'react-bootstrap';

import CheckOrMinusCell from '../../CheckOrMinusCell';
import Container from '../../Container';
import PrimaryKey from '../../PrimaryKey';
import Table from '../../Table';
import { useFetchData } from '../../utils';
import { IndexProps } from './types';

const columns = [
  { Header: 'ID', accessor: 'id', filteredAs: 'number', Cell: PrimaryKey },
  { Header: 'Name', accessor: 'name', filteredAs: 'string' },
  { Header: 'Category', accessor: 'category', filteredAs: 'string' },
  {
    Header: 'Student Rate',
    accessor: 'studentRateEligible',
    Cell: CheckOrMinusCell,
  },
  {
    Header: 'Case Studies',
    accessor: 'caseStudiesAccess',
    Cell: CheckOrMinusCell,
  },
  {
    Header: 'Resource Center',
    accessor: 'professorToolkitAccess',
    Cell: CheckOrMinusCell,
  },
  {
    Header: 'Unlimited BMC',
    accessor: 'unlimitedBmcAccess',
    Cell: CheckOrMinusCell,
  },
  {
    Header: 'BMC Limit',
    accessor: 'bmcAccessLimit',
  },
];

const scopes = [
  { id: 'university', title: 'University' },
  { id: 'highSchool', title: 'High school' },
  { id: 'nonProfit', title: 'Non profit' },
  { id: 'internal', title: 'Internal' },
];

const initialScopes = {
  university: false,
  highSchool: false,
  nonProfit: false,
  internal: false,
};

const cb = ({ totalCount, pageSize, institutions }) => ({
  data: institutions,
  pageSize,
  totalCount,
});

const Index = ({
  totalCount,
  pageSize,
  pageIndex,
  sortBy,
  adminInstitutionsPath,
  institutions,
  newAdminInstitutionPath,
}) => {
  const fetchData = useFetchData(adminInstitutionsPath, cb);
  return (
    <Container>
      <div className="d-flex align-items-center mb-3">
        <h1>Institutions</h1>
        <Button
          href={newAdminInstitutionPath}
          className="ml-5"
          variant="primary">
          Add Institution
        </Button>
      </div>
      <Table
        initialData={institutions}
        initialPageSize={pageSize}
        initialPageIndex={pageIndex}
        initialSortBy={sortBy}
        initialTotalCount={totalCount}
        columns={columns}
        fetchData={fetchData}
        initialScopes={initialScopes}
        availableScopes={scopes}
      />
    </Container>
  );
};

Index.propTypes = IndexProps;

export default Index;
