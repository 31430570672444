import { Button, Modal, Typography } from '@bbnpm/bb-ui-framework';
import { useToggle } from 'react-use';

import { destroy } from '../../../api';

const DeleteMemberModal = ({
  row,
  showDeleteModal,
  toggleShowDeleteModal,
  refreshData,
}) => {
  const handleDelete = async () => {
    try {
      await destroy(row.original.adminHref);
      refreshData();
    } catch (error) {
      alert('Minimum of 2 team members required');
    }
    toggleShowDeleteModal();
  };
  return (
    <Modal
      portalContainerId="bfe-root"
      title="Remove from team"
      isOpen={showDeleteModal}
      primaryActions={
        <>
          <Button kind="secondary" onClick={toggleShowDeleteModal}>
            Cancel
          </Button>
          <Button kind="destruction" onClick={handleDelete}>
            Delete
          </Button>
        </>
      }>
      <Typography.Text>
        Removing <strong>{row.original.email}</strong> from this team. Please
        click delete to confirm.
      </Typography.Text>
    </Modal>
  );
};

const DeleteMemberCell = ({ row, refreshData }) => {
  const isDisabled =
    row.original.memberType === 'captain' ||
    row.original.memberType === 'professor';
  const [showDeleteModal, toggleShowDeleteModal] = useToggle(false);

  return (
    <>
      <Button
        kind="destruction"
        icon="trash"
        disabled={isDisabled}
        onClick={toggleShowDeleteModal}
      />
      <DeleteMemberModal
        row={row}
        showDeleteModal={showDeleteModal}
        toggleShowDeleteModal={toggleShowDeleteModal}
        refreshData={refreshData}
      />
    </>
  );
};

export default DeleteMemberCell;
