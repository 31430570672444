import { arrayOf, bool, shape, string } from 'prop-types';

import { SeriesProps, VideoProps } from '../types';

export const VideoCardProps = {
  isCurrent: bool,
  video: shape(VideoProps).isRequired,
  className: string,
};

export const IndexProps = {
  videos: arrayOf(shape(VideoProps)).isRequired,
};

export const ShowProps = {
  ...VideoProps,
  generateLaunchLinkVideoPath: string.isRequired,
  latestVideos: arrayOf(shape(VideoProps)).isRequired,
  allSeries: arrayOf(shape(SeriesProps)).isRequired,
};
