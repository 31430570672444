import {
  Checkbox,
  FormField,
  GlobalStyles,
  Icon,
  Input,
  InputGroup,
  ThemeProvider,
  Typography,
} from '@bbnpm/bb-ui-framework';
import startcase from 'lodash.startcase';
import { arrayOf, objectOf, string } from 'prop-types';
import { useEffect, useState } from 'react';
import {
  Col,
  Form,
  Row,
  ToggleButton,
  ToggleButtonGroup,
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Recaptcha from 'react-recaptcha';
import { useAsyncFn } from 'react-use';
import { useToggle } from 'react-use';

import { get } from '../../api';
import { noop } from '../../utils';
import AlertWithIcon from '../AlertWithIcon';
import Container from '../Container';
import PostForm from '../PostForm';
import {
  DisabledText,
  HighSchoolCheckboxContainer,
  KeepMeUpdatedCheckboxContainer,
  PageTitle,
  PasswordShowHideText,
  ProfessorInfoContainer,
  StyledButton,
  StyledLink,
} from './styles';

const TYPES = {
  learner: 'user-graduate',
  professor: 'chalkboard-teacher',
};

const TYPE_KEYS = Object.keys(TYPES);

const New = ({
  firstName,
  lastName,
  email,
  userRegistrationPath,
  forEmailInstitutionsPath,
  tosUrl,
  privacyPolicyUrl,
  recaptchaSiteKey,
  errors,
  userType = TYPE_KEYS[0],
  recruitingCampaignEnabled,
}) => {
  const { register, watch } = useForm();
  const type = watch('type', userType);
  const isLearner = type === 'learner';

  const [isHighSchoolStudent, toggleIsHighSchoolStudent] = useToggle(false);
  const [showPassword, toggleShowPassword] = useToggle(false);
  const [inputEmail, setInputEmail] = useState('');

  const [{ loading: institutionLoading, value: institution }, fetch] =
    useAsyncFn(
      async email => {
        const { institution } = await get(forEmailInstitutionsPath, { email });
        return institution;
      },
      [forEmailInstitutionsPath],
    );

  useEffect(() => {
    if (recaptchaSiteKey) {
      const script = document.createElement('script');
      script.src = 'https://www.google.com/recaptcha/api.js';
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
    }
  }, [recaptchaSiteKey]);

  return (
    <ThemeProvider>
      <GlobalStyles />
      <Container>
        {errors?.base?.length && (
          <AlertWithIcon variant="danger">{errors.base[0]}</AlertWithIcon>
        )}
        <Row>
          <Col md={{ span: 8, offset: 2 }}>
            <PageTitle>
              <Typography.PageTitle>
                Bloomberg for Education Sign Up
              </Typography.PageTitle>
            </PageTitle>

            <ToggleButtonGroup
              className="d-flex mb-3"
              name="type"
              defaultValue={userType}>
              {TYPE_KEYS.map(key => (
                <ToggleButton
                  inputRef={register}
                  variant="outline-dark"
                  key={key}
                  value={key}>
                  <i className={`mr-2 fas fa-${TYPES[key]}`} /> {startcase(key)}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
            <Typography.FormLabel>
              {isLearner
                ? 'LEARNER: I am interested in taking Bloomberg Market Concepts and learning from other Bloomberg for Education materials. Courses are also accessible via {BCER <GO>} on the Bloomberg Terminal.'
                : 'PROFESSOR: I am an administrative member interested in integrating Bloomberg for Education content into my curriculum.'}
            </Typography.FormLabel>
            <PostForm
              action={userRegistrationPath}
              submitRender={({ disabled }) => (
                <div className="d-flex mb-3 align-items-center">
                  <div className="mr-auto">
                    <StyledButton
                      disabled={disabled}
                      type="submit"
                      kind="primary"
                      style={{ padding: '0 3em 0 3em' }}>
                      Sign Up
                    </StyledButton>
                  </div>
                  {recaptchaSiteKey && (
                    <Recaptcha
                      render="explicit"
                      onloadCallback={noop}
                      sitekey={recaptchaSiteKey}
                    />
                  )}
                </div>
              )}>
              <FormField
                validationType={errors?.email?.length && 'error'}
                validationContent={errors?.email
                  ?.map(error => 'Email ' + error)
                  .join(', ')}>
                <Input
                  placeholder={
                    isLearner
                      ? 'Enter your institution email address if you have one, otherwise enter your personal email'
                      : 'Enter your institution email address'
                  }
                  name="user[email]"
                  defaultValue={email ?? ''}
                  onBlur={e => {
                    const trimmedInput = e.target.value.trim();
                    if (trimmedInput) {
                      setInputEmail(trimmedInput);
                      fetch(trimmedInput);
                    }
                  }}
                  type="email"
                  required
                />
              </FormField>

              <FormField
                validationType={errors?.institution?.length && 'error'}
                validationContent={errors?.institution
                  ?.map(error => 'Institution ' + error)
                  .join(', ')}>
                <InputGroup disabled>
                  <InputGroup.Addon>
                    <Icon name="courthouse" />
                  </InputGroup.Addon>
                  <Input
                    placeholder={
                      institutionLoading
                        ? 'Loading...'
                        : institution
                          ? institution.name
                          : 'No institution found'
                    }
                  />
                </InputGroup>
              </FormField>

              {/*
                only show check box if:
                - is a learner sign up
                - non empty input to email field
                - not in the process of loading institutions
                - if no institution found or category is not university
               */}
              {isLearner &&
                inputEmail &&
                !institutionLoading &&
                (!institution || institution?.category !== 'university') && (
                  <HighSchoolCheckboxContainer>
                    <Checkbox
                      name="isHighSchoolStudent"
                      defaultChecked={false}
                      label={
                        <DisabledText>
                          I&apos;m a high school student or was referred by a
                          non-profit organization
                        </DisabledText>
                      }
                      onClick={toggleIsHighSchoolStudent}
                    />
                  </HighSchoolCheckboxContainer>
                )}
              <FormField
                validationType={errors?.password?.length && 'error'}
                validationContent={errors?.password
                  ?.map(error => 'Password ' + error)
                  .join(', ')}>
                <InputGroup>
                  <Input
                    placeholder="Password"
                    name="user[password]"
                    type={showPassword ? 'text' : 'password'}
                    required
                  />
                  <InputGroup.Addon>
                    <PasswordShowHideText onClick={toggleShowPassword}>
                      {showPassword ? 'Hide' : 'Show'}
                    </PasswordShowHideText>
                  </InputGroup.Addon>
                </InputGroup>
              </FormField>
              <br />
              <Form.Row>
                <Col>
                  <FormField
                    validationType={errors?.firstName?.length && 'error'}
                    validationContent={errors?.firstName
                      ?.map(error => 'First name ' + error)
                      .join(', ')}>
                    <Input
                      placeholder="First Name"
                      name="user[firstName]"
                      defaultValue={firstName ?? ''}
                      required
                    />
                  </FormField>
                </Col>
                <Col>
                  <FormField
                    validationType={errors?.lastName?.length && 'error'}
                    validationContent={errors?.lastName
                      ?.map(error => 'Last name ' + error)
                      .join(', ')}>
                    <Input
                      placeholder="Last Name"
                      name="user[lastName]"
                      defaultValue={lastName ?? ''}
                      required
                    />
                  </FormField>
                </Col>
              </Form.Row>
              <DisabledText>
                Enter first and last name as you want them to appear on your BMC
                certificate.
              </DisabledText>

              {!isLearner && (
                <ProfessorInfoContainer>
                  <input type="hidden" name="professor" defaultValue="true" />
                  <Input
                    name="user[professorInfoAttributes][identityVerificationInfo]"
                    placeholder={
                      'Enter website link to verify identity (e.g., bio page on school website, LinkedIn profile)'
                    }
                    required
                  />
                </ProfessorInfoContainer>
              )}
              <KeepMeUpdatedCheckboxContainer>
                <Checkbox
                  name="user[agreedToMarketCampaign]"
                  defaultChecked={false}
                  label={
                    <DisabledText>
                      {isHighSchoolStudent
                        ? 'I would like to receive the Bloomberg newsletter with updates on their global education program and details on upcoming career opportunities at the company. You can unsubscribe at any time.'
                        : 'Keep me updated about new BMC courses, webinars, student offers and promotions.'}
                    </DisabledText>
                  }
                />
                {recruitingCampaignEnabled && !isHighSchoolStudent && (
                  <Checkbox
                    name="user[agreedToRecruitmentCampaign]"
                    defaultChecked={false}
                    label={
                      <DisabledText>
                        Keep me updated about recruitment opportunities at
                        Bloomberg.
                      </DisabledText>
                    }
                  />
                )}
              </KeepMeUpdatedCheckboxContainer>
            </PostForm>

            <Typography.Hint>
              By clicking &quot;Sign Up&quot; I signify that I have read and
              agreed to the{' '}
              <StyledLink href={tosUrl}>Terms of Service</StyledLink> and{' '}
              <StyledLink href={privacyPolicyUrl}>Privacy Policy</StyledLink>.
            </Typography.Hint>
          </Col>
        </Row>
      </Container>
    </ThemeProvider>
  );
};

New.propTypes = {
  firstName: string,
  lastName: string,
  email: string,
  userRegistrationPath: string.isRequired,
  forEmailInstitutionsPath: string.isRequired,
  tosUrl: string.isRequired,
  privacyPolicyUrl: string.isRequired,
  recaptchaSiteKey: string,
  errors: objectOf(arrayOf(string)),
  userType: string,
};

export default New;
