import { AnchorLinkBar, Icon, Table } from '@bbnpm/bb-ui-framework';
import { Row } from '@bloomberg/styled-flexboxgrid';
import styled from 'styled-components';

export const StyledTableHeader = styled.thead`
  background-color: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};
`;

export const ColoredText = styled.p`
  color: ${({ value, theme }) =>
    value < 0 ? theme.colors.red[400] : theme.colors.green[400]};
`;

export const MarginBottomRow = styled(Row)`
  margin-bottom: 3rem;
`;

export const StyledIcon = styled(Icon)`
  color: ${({ theme, value }) =>
    value < 0
      ? theme.colors.red[400]
      : value > 0
        ? theme.colors.green[400]
        : theme.colors.grey[400]};
`;

export const StyledTable = styled(Table)`
  margin-top: 1.5rem;
`;

export const StyledDataCell = styled.p`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 75%;
`;

export const StickyAnchorLinkBar = styled(AnchorLinkBar)`
  position: sticky;
  top: 0;
  background-color: ${({ theme }) => theme.colors.white};
`;
