import { arrayOf, bool, func, number, shape, string } from 'prop-types';

import { LeaderboardProps, TableProps } from '../../types';

export const DeleteLeaderboardModalProps = {
  row: shape({
    orginal: shape(LeaderboardProps).isRequired,
  }).isRequired,
  showDeleteModal: bool.isRequired,
  toggleShowDeleteModal: func.isRequired,
  refreshData: func.isRequired,
};

export const LeaderboardActionsCellProps = {
  row: shape({
    orginal: shape(LeaderboardProps).isRequired,
  }).isRequired,
  refreshData: func.isRequired,
};

export const LeaderboardFormProps = {
  ...LeaderboardProps,
};

export const ShowProps = {
  ...TableProps,
  ...LeaderboardProps,
};

export const CurrencyProps = {
  value: number.isRequired,
};

export const LeaderboardsTableProps = {
  leaderboards: arrayOf(shape(LeaderboardProps)),
  path: string.isRequired,
  leaderboardTableProps: shape(TableProps),
  newAdminTradingChallengeLeaderboardPath: string,
};
