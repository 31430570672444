import { Button, GlobalStyles, ThemeProvider } from '@bbnpm/bb-ui-framework';
import { node, object, string } from 'prop-types';

import Csrf from './Csrf';

const PostButton = ({
  children,
  action,
  method,
  kind = 'primary',
  formProps = {},
  ...rest
}) => {
  return (
    <ThemeProvider>
      <GlobalStyles />
      <form action={action} method="POST" className="d-inline" {...formProps}>
        <Csrf />
        {method && <input name="_method" type="hidden" defaultValue={method} />}
        <Button type="submit" kind={kind} {...rest}>
          {children}
        </Button>
      </form>
    </ThemeProvider>
  );
};

PostButton.propTypes = {
  children: node.isRequired,
  action: string.isRequired,
  method: string,
  formProps: object,
  kind: string,
};

export default PostButton;
