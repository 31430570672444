import {
  GlobalStyles,
  Icon,
  ThemeProvider,
  Typography,
} from '@bbnpm/bb-ui-framework';

import AlertTerminalPlanNeedsUpgrade from '../AlertTerminalPlanNeedsUpgrade';
import Container from '../Container';
import DaperImg from '../DaperImg';
import Hero from '../Hero';
import CertificateCard from './CertificateCard';
import NewCourseVersion from './NewCourseVersion';
import {
  CertificateDiv,
  StyledList,
  StyledListItem,
  StyledSectionTitle,
} from './courseVersions.styles';
import { IndexProps } from './types';

const Index = ({
  courseVersions,
  certificateAvailable,
  downloadCertificatePath,
  linkedinImagePath,
  terminalPlanNeedsUpgrade,
  newBmcSubscriptionPath,
  courseCategory,
  cpdImagePath,
  certificateUrl,
  certificateId,
  certifiedAt,
}) => {
  return (
    <ThemeProvider>
      <GlobalStyles />
      <Hero
        link={{
          path: '/certifications',
          name: 'Back to Certificates',
          icon: 'arrow-left',
        }}
        title={courseCategory.title + ' Certificate'}
        description={courseCategory.longDescription}
        logo={cpdImagePath}
      />
      <div style={{ backgroundColor: '#f7f7f7' }}>
        <Container>
          {terminalPlanNeedsUpgrade && (
            <AlertTerminalPlanNeedsUpgrade url={newBmcSubscriptionPath} />
          )}
          <div style={{ display: 'flex' }}>
            <div style={{ width: '60%' }}>
              <StyledSectionTitle>Overview</StyledSectionTitle>
              <Typography.Text>
                By taking {courseCategory.title}, you will:
              </Typography.Text>
              <ul>
                {courseCategory.overviewItems.map((item, i) => (
                  <li key={i}>{item}</li>
                ))}
              </ul>
            </div>
            <StyledList>
              <StyledListItem>
                <Icon name="calendar" /> &nbsp; Flexible Schedule
              </StyledListItem>
              <StyledListItem>
                <Icon name="bloomberg-terminal" /> &nbsp; 100% Online
              </StyledListItem>
            </StyledList>
          </div>
          <CertificateDiv>
            <DaperImg
              metadata={courseCategory.certificateThumbnail}
              style={{ width: '20%' }}
            />
            <CertificateCard
              certificateAvailable={certificateAvailable}
              downloadCertificatePath={downloadCertificatePath}
              linkedinImagePath={linkedinImagePath}
              certificateUrl={certificateUrl}
              courseCategory={courseCategory}
              certificateId={certificateId}
              certifiedAt={certifiedAt}
            />
          </CertificateDiv>
          <StyledSectionTitle>Courses</StyledSectionTitle>
          {courseVersions.map(courseVersion => (
            <NewCourseVersion key={courseVersion.id} {...courseVersion} />
          ))}
        </Container>
      </div>
    </ThemeProvider>
  );
};

Index.propTypes = IndexProps;

export default Index;
