import { FormField, Input, Typography } from '@bbnpm/bb-ui-framework';

import { RequiredLabel } from '../../../CommonComponents';
import { FlexGrowCol, StyledRow } from '../styles';

const FacultySection = ({ facultyInfo, setFacultyInfo }) => {
  return (
    <>
      <Typography.SectionTitle>Professor Information</Typography.SectionTitle>
      <StyledRow>
        <FlexGrowCol>
          <FormField
            label={<RequiredLabel>Faculty Advisor First Name</RequiredLabel>}>
            <Input
              type="text"
              onChange={evt =>
                setFacultyInfo({ ...facultyInfo, firstName: evt.target.value })
              }
            />
          </FormField>
        </FlexGrowCol>

        <FlexGrowCol>
          <FormField
            label={<RequiredLabel>Faculty Advisor Last Name</RequiredLabel>}>
            <Input
              type="text"
              onChange={evt =>
                setFacultyInfo({ ...facultyInfo, lastName: evt.target.value })
              }
            />
          </FormField>
        </FlexGrowCol>

        <FlexGrowCol>
          <FormField
            label={<RequiredLabel>Faculty Advisor Email</RequiredLabel>}>
            <Input
              required
              type="text"
              onChange={evt =>
                setFacultyInfo({ ...facultyInfo, email: evt.target.value })
              }
            />
          </FormField>
        </FlexGrowCol>
      </StyledRow>

      <StyledRow>
        <FlexGrowCol>
          <FormField label="Faculty Advisor Phone">
            <Input
              type="text"
              onChange={evt =>
                setFacultyInfo({ ...facultyInfo, phone: evt.target.value })
              }
            />
          </FormField>
        </FlexGrowCol>

        <FlexGrowCol>
          <FormField label="Faculty Advisor Department">
            <Input
              type="text"
              onChange={evt =>
                setFacultyInfo({ ...facultyInfo, department: evt.target.value })
              }
            />
          </FormField>
        </FlexGrowCol>

        <FlexGrowCol>
          <FormField label="Faculty Advisor Title">
            <Input
              type="text"
              onChange={evt =>
                setFacultyInfo({ ...facultyInfo, title: evt.target.value })
              }
            />
          </FormField>
        </FlexGrowCol>
      </StyledRow>
    </>
  );
};

export default FacultySection;
