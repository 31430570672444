import { useRef } from 'react';
import { Col, ResponsiveEmbed, Row } from 'react-bootstrap';
import { useAsyncFn, useFullscreen, useToggle } from 'react-use';

import { post } from '../../api';
import { pluralize } from '../../utils';
import Button from '../Button';
import CardCarousel from '../CardCarousel';
import ClientDate from '../ClientDate';
import Container from '../Container';
import { daperUrl } from '../DaperImg';
import Spinner from '../Spinner';
import TabGrid from '../TabGrid';
import { STATUS } from '../UserStatus';
import VideoPlayer from '../VideoPlayer';
import { useActiveFilterMemo } from '../utils';
import VideoCard from './VideoCard';
import { ShowProps } from './types';

const Show = ({
  id,
  name,
  description,
  createdAt,
  videoRegistrationsCount,
  thumbnail,
  generateLaunchLinkVideoPath,
  series,
  latestVideos,
  allSeries,
  userInfo,
}) => {
  const iframeRef = useRef(null);
  const [show, toggle] = useToggle(false);
  const isFullscreen = useFullscreen(iframeRef, show, {
    onClose: () => toggle(false),
  });

  const activeSeries = useActiveFilterMemo(allSeries);

  const [{ loading, value: launchLink }, handleRequestLaunchLink] =
    useAsyncFn(async () => {
      const { launchLink } = await post(generateLaunchLinkVideoPath);
      return launchLink;
    }, [generateLaunchLinkVideoPath]);

  return (
    <Container>
      {launchLink ? (
        <VideoPlayer ref={iframeRef} src={launchLink} />
      ) : (
        <ResponsiveEmbed aspectRatio="16by9">
          <div
            className="d-flex align-items-center justify-content-center pointer-on-hover bg-dark"
            onClick={() => !loading && handleRequestLaunchLink()}>
            <div
              style={{
                backgroundColor: '#000',
                backgroundImage: thumbnail?.daperKey
                  ? `url(${daperUrl({
                      daperKey: thumbnail.daperKey,
                      width: 1110 * 2,
                      height: -1,
                    })})`
                  : '',
                backgroundSize: 'cover',
                opacity: 0.3,
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                position: 'absolute',
                zIndex: 1,
              }}></div>
            {loading ? (
              <Spinner variant="light" size={false} />
            ) : (
              <i
                className="fas fa-play-circle fa-7x fa-inverse"
                style={{ zIndex: 2 }}
              />
            )}
          </div>
        </ResponsiveEmbed>
      )}

      <div className="d-flex align-items-center">
        <div className="mb-3">
          <h1 className="mb-0">{name}</h1>
          {series?.active && (
            <>
              in{' '}
              <strong>
                <a className="link-unstyled" href={series.href}>
                  {series.name}
                </a>
              </strong>
            </>
          )}
        </div>
        {!isFullscreen && launchLink && (
          <Button
            onClick={() => toggle(true)}
            className="ml-auto flex-shrink-0"
            variant="outline-dark"
            icon="expand">
            Fullscreen
          </Button>
        )}
      </div>

      <div className="d-flex mb-3">
        {userInfo?.status === STATUS.COMPLETED && (
          <div className="text-success font-weight-bold">
            Completed On <ClientDate date={userInfo.completedAt} /> |{' '}
            {userInfo.score}%
          </div>
        )}
        <div className="ml-auto">
          <ClientDate date={createdAt} /> |{' '}
          {pluralize(videoRegistrationsCount, 'view')}
        </div>
      </div>

      {description && <p className="text-pre-wrap">{description}</p>}

      {series?.active && (
        <>
          <h2 className="font-weight-normal with-angle-right">
            {series.name} Series
          </h2>
          <CardCarousel spans={{ lg: 4, sm: 6 }}>
            {series.videos.map(video => (
              <VideoCard
                key={video.id}
                video={video}
                isCurrent={video.id === id}
              />
            ))}
          </CardCarousel>
        </>
      )}

      <h2 className="font-weight-normal with-angle-right">Available Series</h2>
      <TabGrid className="mb-3">
        {activeSeries.map(({ name, id: seriesId, href }) => (
          <TabGrid.Tab
            key={seriesId}
            href={href}
            isCurrent={series?.id === seriesId}>
            {name}
          </TabGrid.Tab>
        ))}
      </TabGrid>

      <h2 className="font-weight-normal with-angle-right">Latest Releases</h2>
      <Row>
        {latestVideos.map(video => (
          <Col lg={4} sm={6} key={video.id}>
            <VideoCard video={video} className="mb-4" />
          </Col>
        ))}
      </Row>
    </Container>
  );
};

Show.propTypes = ShowProps;

export default Show;
