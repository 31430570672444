import {
  Button,
  GlobalStyles,
  Icon,
  ThemeProvider,
  Typography,
} from '@bbnpm/bb-ui-framework';
import { useState } from 'react';
import { useToggle } from 'react-use';
import styled from 'styled-components';

import { sendAsForm } from '../../../api';
import { noop } from '../../../utils/index';
import Container from '../../Container';
import DaperImg from '../../DaperImg';
import DeleteButton from '../../DeleteButton';
import Table from '../../Table';
import { useFetchData } from '../../utils';
import { columns } from '../toolkit_documents/Index';
import ToolkitTagFormModal from './ToolkitTagFormModal';
import { TOOLKIT_TAG_TYPES } from './constants';
import { ShowProps } from './types';

const StyledImg = styled(DaperImg)`
  margin: 1.5rem 0;
  padding: 0.25rem;
  background-color: ${({ theme }) => theme.colors.white};
  width: 40%;
`;

const cb = ({ totalCount, pageSize, toolkitTag }) => ({
  data: toolkitTag.toolkitDocuments,
  pageSize,
  totalCount,
});

const Show = ({
  totalCount,
  pageSize,
  pageIndex,
  sortBy,
  toolkitTag,
  newAdminToolkitDocumentPath,
}) => {
  const [showEditForm, toggleShowEditForm] = useToggle(false);
  const [values, setValues] = useState(toolkitTag);
  const {
    title,
    createdAt,
    toolkitDocuments,
    adminIndexHref,
    adminHref,
    adminToolkitTagPath,
  } = values;

  const [{ refreshData }, setRefreshDataFn] = useState({ refreshData: noop });
  const fetchData = useFetchData(adminToolkitTagPath, cb);

  const handleEditSubmit = async newVals => {
    const res = await sendAsForm(adminHref, newVals, 'put');
    setValues({
      ...values,
      ...res?.data,
    });
    refreshData();
  };

  return (
    <ThemeProvider>
      <GlobalStyles />
      <Container>
        <ToolkitTagFormModal
          isOpen={showEditForm}
          onClose={toggleShowEditForm}
          onSubmit={handleEditSubmit}
          toolkitTag={values}
        />
        <div>
          <Button
            as="a"
            kind="tertiary"
            icon="arrow-left"
            href={adminIndexHref}>
            Back to Toolkit Tags
          </Button>
        </div>
        <div className="d-flex align-items-center">
          <Typography.PageTitle>{title}</Typography.PageTitle>
          <Button
            className="ml-4"
            kind="secondary"
            onClick={toggleShowEditForm}>
            Edit Tag
          </Button>

          <DeleteButton
            size="sm"
            className="ml-2"
            variant="danger"
            action={adminHref}
            onClick={e => {
              if (
                window.confirm(
                  'Are you sure you want to permanently delete this topic and remove it from all documents?',
                )
              ) {
                return true;
              } else {
                e.preventDefault();
                e.stopPropagation();
              }
            }}>
            <Icon name="trash" className="mr-2" />
            Delete
          </DeleteButton>
        </div>

        <p>Created at {createdAt}.</p>

        <div className="d-flex align-items-center mb-3">
          <Button as="a" kind="secondary" href={newAdminToolkitDocumentPath}>
            New Document
          </Button>
        </div>
        <Typography.SectionTitle>Tag Type:</Typography.SectionTitle>
        <Typography.Text className="text-pre-wrap">
          {TOOLKIT_TAG_TYPES[values?.type ?? 'ToolkitTopic']}
        </Typography.Text>
        {values?.thumbnail && (
          <div className="mb-3">
            <Typography.SectionTitle>Thumbnail:</Typography.SectionTitle>
            <StyledImg
              metadata={values?.thumbnail}
              fluid
              className="bg-white"
            />
          </div>
        )}

        <Table
          initialData={toolkitDocuments}
          columns={columns}
          initialSortBy={sortBy}
          initialPageSize={pageSize}
          initialPageIndex={pageIndex}
          initialTotalCount={totalCount}
          fetchData={fetchData}
          onRefreshDataFn={setRefreshDataFn}
          refreshData={refreshData}
        />
      </Container>
    </ThemeProvider>
  );
};

Show.propTypes = ShowProps;

export default Show;
