import { useMemo } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useToggle } from 'react-use';

import { dateSorter } from '../../utils';
import ClientDate from '../ClientDate';
import Container from '../Container';
import CoverWithDuration from '../CoverWithDuration';
import TabGrid from '../TabGrid';
import UserStatus from '../UserStatus';
import { useActiveFilterMemo } from '../utils';
import VideoCard from '../videos/VideoCard';
import { LeadVideoPanelProps, ShowProps } from './types';

const LeadVideoPanel = ({ video }) => (
  <a href={video.href} className="link-unstyled mb-4">
    <Row noGutters className="border bg-white">
      <Col xs={4} className="border-right">
        <CoverWithDuration
          daperKey={video.thumbnail?.daperKey}
          width={630}
          duration={video.durationMins}
        />
      </Col>
      <Col className="p-3">
        <h4>{video.name}</h4>
        <p className="text-pre-wrap">{video.description}.</p>
        <p className="text-muted">
          <ClientDate
            date={video.createdAt}
            dateFormat={{ month: 'long', year: 'numeric' }}
          />
        </p>
        <p>
          <UserStatus {...(video.userInfo ?? {})} />
        </p>
      </Col>
    </Row>
  </a>
);

LeadVideoPanel.propTypes = LeadVideoPanelProps;

const leadVideo = (featuredVideoId, videos) => {
  let featuredVideo = null;
  if (featuredVideoId) {
    featuredVideo = videos.find(v => v.id === featuredVideoId);
  }
  // Return featured video if set or the first video on the list
  return featuredVideo || videos[0];
};

const Show = ({
  id,
  name,
  description,
  videos,
  featuredVideoId,
  allSeries,
}) => {
  const [isSortDirectionDesc, toggleSortDirection] = useToggle(true);
  const activeVideos = useActiveFilterMemo(videos);
  const activeSeries = useActiveFilterMemo(allSeries);
  const sortedVideos = useMemo(
    () =>
      [...activeVideos].sort(
        dateSorter({ prop: 'createdAt', isDesc: isSortDirectionDesc }),
      ),
    [isSortDirectionDesc, activeVideos],
  );
  const introVideo = leadVideo(featuredVideoId, activeVideos);

  return (
    <Container>
      {name && <h2>{name}</h2>}
      {description && <p className="text-pre-wrap">{description}</p>}

      {activeVideos.length > 0 && (
        <>
          <LeadVideoPanel video={introVideo} />
          <span className="mb-4 text-nowrap">
            <Button
              onClick={toggleSortDirection}
              title="Upload Date"
              variant="outline-secondary"
              size="sm">
              Upload Date
              <i
                className={`ml-4 fas fa-angle-${
                  isSortDirectionDesc ? 'down' : 'up'
                }`}
              />
            </Button>
          </span>
        </>
      )}

      <Row>
        {sortedVideos.map(video => (
          <Col lg={4} sm={6} key={video.id}>
            <VideoCard video={video} className="mb-4" />
          </Col>
        ))}
      </Row>

      <h2 className="font-weight-normal with-angle-right">Available Series</h2>
      <TabGrid className="mb-3">
        {activeSeries.map(({ name, id: seriesId, href }) => (
          <TabGrid.Tab key={seriesId} href={href} isCurrent={id === seriesId}>
            {name}
          </TabGrid.Tab>
        ))}
      </TabGrid>
    </Container>
  );
};

Show.propTypes = ShowProps;

export default Show;
