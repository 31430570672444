import {
  Button,
  FormField,
  Input,
  MultiSelect,
  Switch,
  TextArea,
} from '@bbnpm/bb-ui-framework';
import { Fragment, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { useMap, useToggle } from 'react-use';

import { BfeGrid } from '../../../styles/grid';
import { PageWrapper, RequiredLabel } from '../../CommonComponents';
import ImageFileInput from '../../ImageFileInput';
import PatchForm from '../../PatchForm';
import PostForm from '../../PostForm';
import { CourseCategoryFormProps } from './types';

const HiddenInputFields = ({
  allPermissionTypes,
  selectedMap,
  existingPermissionTypesMap,
}) => (
  <Fragment>
    {allPermissionTypes.map((permissionType, index) => {
      const resourcePermissionId =
        existingPermissionTypesMap[permissionType.id];

      return (
        <Fragment key={index}>
          {resourcePermissionId && (
            <input
              type="hidden"
              name={`resourceRestrictionsAttributes[${index}][id]`}
              defaultValue={resourcePermissionId}
            />
          )}
          <input
            type="hidden"
            name={`resourceRestrictionsAttributes[${index}][permissionTypeId]`}
            defaultValue={permissionType.id || ''}
          />
          <input
            type="hidden"
            name={`resourceRestrictionsAttributes[${index}][_destroy]`}
            defaultValue={selectedMap[permissionType.id] ? '0' : '1'}
          />
        </Fragment>
      );
    })}
  </Fragment>
);

const CourseCategoryForm = ({
  adminCourseCategoriesPath,
  adminHref,
  id,
  title,
  description,
  longDescription,
  overview,
  active,
  position,
  thumbnail,
  certificateThumbnail,
  errors,
  resourceRestrictions,
  allPermissionTypes,
}) => {
  const [Form, action, submitText] = id
    ? [PatchForm, adminHref, 'Update']
    : [PostForm, adminCourseCategoriesPath, 'Submit'];

  const [checked, toggleChecked] = useToggle(active);
  const [requiresPermission, toggleRequiresPermission] = useToggle(
    resourceRestrictions.length > 0,
  );

  const existingPermissionTypesMap = {};
  resourceRestrictions.forEach(
    resourcePermission =>
      (existingPermissionTypesMap[resourcePermission.permissionTypeId] =
        resourcePermission.id),
  );

  const initialSelected = {};
  resourceRestrictions.forEach(
    resourcePermission =>
      (initialSelected[resourcePermission.permissionTypeId] =
        resourcePermission.permissionTypeId),
  );
  const [selectedMap, { set: setSelected }] = useMap(initialSelected);
  const [items, setItems] = useState(
    resourceRestrictions.map(
      resourceRestriction => resourceRestriction.permissionTypeId,
    ),
  );

  return (
    <PageWrapper>
      <BfeGrid>
        <div className="d-flex align-items-center mb-3">
          <h1>Edit Course Category</h1>
          <Button
            className="ml-2"
            kind="secondary"
            as="a"
            href={adminCourseCategoriesPath}>
            All Course Categories
          </Button>
        </div>

        <Form
          action={action}
          submitText={submitText}
          encType="multipart/form-data">
          <FormField label="Active" className="d-flex align-items-center mb-3">
            <Switch
              name="active"
              checked={checked}
              labelPosition="right"
              onChange={toggleChecked}
            />
            <Input type="hidden" name="active" value={checked} />
          </FormField>

          <FormField
            label={<RequiredLabel>Title</RequiredLabel>}
            validationType={errors?.title && 'error'}
            validationContent={errors?.title}>
            <Input
              name="title"
              required
              defaultValue={title ?? ''}
              errors={errors?.title}
            />
          </FormField>

          <FormField
            label="Description"
            validationType={errors?.description && 'error'}
            validationContent={errors?.description}>
            <TextArea
              name="description"
              defaultValue={description ?? ''}
              autosize={{ minRows: 1 }}
            />
          </FormField>

          <FormField
            label="Long Description"
            validationType={errors?.longDescription && 'error'}
            validationContent={errors?.longDescription}>
            <TextArea
              name="longDescription"
              defaultValue={longDescription ?? ''}
              as={TextareaAutosize}
            />
          </FormField>

          <FormField
            label="Overview"
            validationType={errors?.overview && 'error'}
            validationContent={errors?.overview}>
            <TextArea
              name="overview"
              defaultValue={overview ?? ''}
              as={TextareaAutosize}
            />
          </FormField>

          <FormField
            label="Position"
            validationType={errors?.position && 'error'}
            validationContent={errors?.position}>
            <Input
              defaultValue={position}
              required
              type="number"
              name="position"
            />
          </FormField>

          <FormField
            validationType={errors?.thumbnail && 'error'}
            validationContent={errors?.thumbnail}>
            <ImageFileInput
              name="thumbnail"
              accept=".jpg, .png"
              helpText="upload the largest available version"
              value={thumbnail}
            />
          </FormField>

          <FormField
            validationType={errors?.certificateThumbnail && 'error'}
            validationContent={errors?.certificateThumbnail}>
            <ImageFileInput
              name="certificateThumbnail"
              accept=".jpg, .png"
              helpText="upload the largest available version"
              value={certificateThumbnail}
            />
          </FormField>

          <FormField label="Requires Permission">
            <Switch
              checked={requiresPermission}
              onChange={(event, isChecked) => {
                toggleRequiresPermission();
                if (!isChecked) {
                  for (const key in selectedMap) {
                    setSelected(key, false);
                  }
                  setItems([]);
                }
              }}
            />
          </FormField>

          <FormField
            label="Required Permission Types"
            className="mb-3"
            disabled={!requiresPermission}
            validationType={
              requiresPermission && items.length === 0 && 'warning'
            }>
            <MultiSelect
              options={allPermissionTypes.map(({ id, name }) => ({
                label: name.toUpperCase(),
                value: id,
              }))}
              values={items}
              onItemSelect={({ value }) => {
                setSelected(value, true);
                setItems(items => [value, ...items]);
              }}
              onItemDeselect={({ value }) => {
                setSelected(value, false);
                setItems(items => {
                  const newItems = [...items];
                  newItems.splice(newItems.indexOf(value), 1);

                  return newItems;
                });
              }}
            />
            <HiddenInputFields
              allPermissionTypes={allPermissionTypes}
              selectedMap={selectedMap}
              existingPermissionTypesMap={existingPermissionTypesMap}
            />
          </FormField>
        </Form>
      </BfeGrid>
    </PageWrapper>
  );
};

CourseCategoryForm.propTypes = CourseCategoryFormProps;

export default CourseCategoryForm;
