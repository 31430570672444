import {
  array,
  arrayOf,
  bool,
  elementType,
  exact,
  func,
  node,
  number,
  object,
  objectOf,
  oneOfType,
  shape,
  string,
} from 'prop-types';

const columnsPropType = arrayOf(
  exact({
    accessor: oneOfType([string, func]).isRequired,
    id: string,
    Header: string,
    filteredAs: string,
    disableSortBy: bool,
    Cell: elementType,
    cellProps: object,
  }),
).isRequired;

const scopesPropType = arrayOf(
  exact({
    id: string.isRequired,
    title: string.isRequired,
  }),
);

const filtersPropType = arrayOf(
  exact({
    guid: number,
    id: string.isRequired,
    predicate: string.isRequired,
    value: oneOfType([string, number]),
  }),
);

export const TableProps = {
  columns: columnsPropType,
  initialData: array,
  initialPageSize: number,
  initialPageIndex: number,
  initialSortBy: string,
  initialTotalCount: number,
  fetchData: func,
  availableScopes: scopesPropType,
  initialScopes: objectOf(bool),
  additionalFilters: arrayOf(
    shape({
      Header: string.isRequired,
      accessor: string.isRequired,
      filteredAs: string.isRequired,
    }),
  ),
  onRowClick: func,
  size: string,
};

export const TableControlsProps = {
  columns: columnsPropType,
  pageCount: number.isRequired,
  totalCount: number.isRequired,
  page: array.isRequired,
  canPreviousPage: bool.isRequired,
  canNextPage: bool.isRequired,
  gotoPage: func.isRequired,
  nextPage: func.isRequired,
  previousPage: func.isRequired,
  setPageSize: func.isRequired,
  pageSize: number.isRequired,
  pageIndex: number.isRequired,
  isLoading: bool.isRequired,
  filters: filtersPropType,
  onFiltersSet: func.isRequired,
  availableScopes: scopesPropType.isRequired,
  scopes: objectOf(bool).isRequired,
  onScopesSet: func.isRequired,
  additionalFilters: arrayOf(
    shape({
      Header: string.isRequired,
      accessor: string.isRequired,
      filteredAs: string.isRequired,
    }),
  ),
};

export const TableFiltersProps = {
  columns: columnsPropType,
  filters: filtersPropType,
  onFiltersSet: func.isRequired,
  additionalFilters: arrayOf(
    shape({
      Header: string.isRequired,
      accessor: string.isRequired,
      filteredAs: string.isRequired,
    }),
  ),
};

export const TableScopesProps = {
  availableScopes: scopesPropType.isRequired,
  scopes: objectOf(bool).isRequired,
  onScopesSet: func.isRequired,
};

export const TableDropdownProps = { children: node.isRequired };
