import {
  Button,
  Dropdown,
  FormField,
  Modal,
  MultiSelect,
} from '@bbnpm/bb-ui-framework';
import startcase from 'lodash.startcase';
import { useEffect, useState } from 'react';

import { AssignRegionModalProps } from './types';

const AssignRegionModal = ({
  isOpen,
  availableCountries,
  toggleShowForm,
  path,
  refreshData,
  allowedRegions,
  onSubmit,
  setAvailableCountries,
}) => {
  const [state, setState] = useState({
    countryCodeIds: [],
    region: null,
  });
  const [errors, setErrors] = useState({});

  const { countryCodeIds, region } = state;
  //reset
  useEffect(() => {
    setErrors({});
    setState({
      countryCodeIds: [],
      region: null,
    });
  }, [isOpen]);

  const validateForm = () => {
    let isValid = true;
    if (countryCodeIds.length === 0) {
      setErrors(errors => {
        const updatedErrors = Object.assign({}, errors);
        updatedErrors.countryCodeIds = 'Please select countries to assign';
        return updatedErrors;
      });
      isValid = false;
    }
    if (!region) {
      setErrors(errors => {
        const updatedErrors = Object.assign({}, errors);
        updatedErrors.region = 'Please select a region';
        return updatedErrors;
      });
      isValid = false;
    }
    return isValid;
  };

  const submitForm = async () => {
    if (!validateForm()) return;
    try {
      const formData = new FormData();
      // send ids in array
      for (const id of countryCodeIds) {
        formData.append('countryCodeIds[]', id);
      }
      if (region) {
        formData.append('region', region);
      }
      await onSubmit(formData);
      setAvailableCountries(available => {
        const updated = available.filter(
          country => !countryCodeIds.includes(country.id),
        );
        return updated;
      });
      toggleShowForm();
      refreshData();
    } catch (e) {
      alert('Error, try again.');
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      title="Assign Trading Challenge Regions"
      portalContainerId="bfe-root">
      <div
        action={path}
        className="d-flex flex-column justify-content-between"
        style={{ height: '500px' }}>
        <div>
          <FormField
            label="Countries"
            validationType={errors?.countryCodeIds && 'error'}
            validationContent={
              errors?.countryCodeIds && `${errors?.countryCodeIds}`
            }>
            <MultiSelect
              placeholder="Select countries"
              searchable
              options={availableCountries.map(country => ({
                label: country.countryName,
                value: country.id,
              }))}
              onItemsChange={countries => {
                setState(prevState => {
                  const newState = Object.assign({}, prevState);
                  if (countries) {
                    newState.countryCodeIds = countries.map(c => c.value);
                  } else {
                    newState.countryCodeIds = [];
                  }
                  return newState;
                });
              }}
            />
          </FormField>

          <FormField
            label="Region"
            validationType={errors?.region && 'error'}
            validationContent={errors?.region && `${errors?.region}`}>
            <Dropdown
              placeholder="Select a region"
              options={allowedRegions.map(region => ({
                label: startcase(region),
                value: region,
              }))}
              onItemSelect={region => {
                setState(prevState => {
                  const newState = Object.assign({}, prevState);
                  newState.region = region.value;
                  return newState;
                });
              }}
            />
          </FormField>
        </div>
        <div className="d-flex justify-content-end">
          <Button kind="primary" type="submit" onClick={submitForm}>
            Assign
          </Button>
          <Button kind="secondary" className="ml-2" onClick={toggleShowForm}>
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};

AssignRegionModal.propTypes = AssignRegionModalProps;

export default AssignRegionModal;
