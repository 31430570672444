import {
  Experimental__ProgressBar,
  GlobalStyles,
  Pagination,
  ThemeProvider,
  Typography,
} from '@bbnpm/bb-ui-framework';
import { Container, Row } from 'react-bootstrap';
import styled from 'styled-components';

import TableFilters from './TableFilters';
import TableScopes from './TableScopes';
import { TableControlsProps } from './types';

const FlexRow = styled(Row)`
  display: flex;
  justify-content: space-between;
`;

const TableControls = ({
  columns,
  pageCount,
  totalCount,
  gotoPage,
  setPageSize,
  pageSize,
  pageIndex,
  isLoading,
  filters,
  onFiltersSet,
  availableScopes,
  scopes,
  onScopesSet,
  additionalFilters,
  variant,
}) => {
  const hasMultiplePages = pageCount > 1;

  return (
    <ThemeProvider>
      <GlobalStyles />
      <Container>
        <FlexRow>
          <TableFilters
            columns={columns}
            filters={filters}
            onFiltersSet={onFiltersSet}
            additionalFilters={additionalFilters}
            variant={variant}
          />
          <TableScopes
            availableScopes={availableScopes}
            scopes={scopes}
            onScopesSet={onScopesSet}
          />
        </FlexRow>

        <Row>
          {isLoading ? (
            <Experimental__ProgressBar />
          ) : variant === 'revamp' && totalCount === 0 ? (
            <Typography.Text>
              No classes found. Let&apos;s get started.
            </Typography.Text>
          ) : totalCount === 0 ? (
            <span>No results found</span>
          ) : (
            (hasMultiplePages || totalCount > 10) && (
              <Pagination
                pageSizeOptions={[10, 20, 30, 40, 50]}
                pageSize={pageSize}
                totalItems={totalCount}
                currentPage={pageIndex + 1}
                onPageSizeChange={selectedPageSize =>
                  setPageSize(selectedPageSize)
                }
                onPageChange={page => gotoPage(page - 1)}
                style={{ marginTop: '10px', width: '100%' }}
              />
            )
          )}
        </Row>
      </Container>
    </ThemeProvider>
  );
};

TableControls.propTypes = TableControlsProps;

export default TableControls;
