import {
  Button,
  GlobalStyles,
  ThemeProvider,
  Typography,
} from '@bbnpm/bb-ui-framework';
import { array, arrayOf, number, shape, string } from 'prop-types';
import { useCallback, useState } from 'react';
import { Container } from 'react-bootstrap';

import { create } from '../../api';
import { noop } from '../../utils';
import AsyncModal, { useAsyncModal } from '../AsyncModal';
import Input from '../Input';
import PrimaryKey from '../PrimaryKey';
import Table from '../Table';
import { BatClassProps, TableProps } from '../types';
import { useFetchData } from '../utils';
import { AddContainer } from './batClasses.styles';

const defaultColumns = [
  {
    Header: 'ID',
    accessor: 'classCode',
    Cell: PrimaryKey,
  },
  {
    Header: 'Name',
    accessor: 'nameAlias',
  },
  {
    Header: 'Professor',
    accessor: 'professor.fullName',
    disableSortBy: true,
  },
  {
    Header: 'Deadline',
    accessor: 'expiredAt',
  },
];

const cb = ({ totalCount, pageSize, batClasses: data }) => ({
  data,
  pageSize,
  totalCount,
});

const onRowClick = ({ href }) => window.Turbolinks.visit(href);

const Index = ({
  batClasses,
  batClassesPath,
  pageSize,
  pageIndex,
  totalCount,
  sortBy,

  createWithClassCodeClassEnrollmentsPath,
  columns = defaultColumns,
  currentId,
  ...rest
}) => {
  const [{ refreshData }, setRefreshDataFn] = useState({ refreshData: noop });
  const { register, errors, open, modalProps } = useAsyncModal();
  const fetchData = useFetchData(batClassesPath, cb);

  const getRowProps = useCallback(
    ({ id }) => (id === currentId ? { className: 'table-active' } : {}),
    [currentId],
  );

  const handleSubmit = async () => {
    await open(async ({ resolve, reject, values: { classCode } }) => {
      try {
        resolve(
          await create(createWithClassCodeClassEnrollmentsPath, { classCode }),
        );
      } catch (error) {
        reject({
          classCode:
            error?.response?.data?.errors?.batClass?.[0] ??
            'Something went wrong. Try again or use different code',
        });
      }
    });
    refreshData();
  };
  return (
    <ThemeProvider>
      <GlobalStyles />
      <Container fluid="xl" className="py-3" {...rest}>
        <Typography.PageTitle>Class Enrollments</Typography.PageTitle>
        <Typography.Text>
          Find you class in the list below or add a new one to start learning.
        </Typography.Text>
        <AddContainer>
          <Typography.SectionTitle>Add new class</Typography.SectionTitle>
          <Button kind="primary" onClick={handleSubmit}>
            Add Class
          </Button>
        </AddContainer>

        <Table
          initialData={batClasses}
          initialPageSize={pageSize}
          initialPageIndex={pageIndex}
          initialTotalCount={totalCount}
          initialSortBy={sortBy}
          columns={columns}
          fetchData={fetchData}
          onRefreshDataFn={setRefreshDataFn}
          onRowClick={onRowClick}
          getRowProps={getRowProps}
        />
        <AsyncModal {...modalProps} title="Add Class">
          <Input
            ref={register}
            required
            name="classCode"
            errors={errors?.classCode ? [errors.classCode.message] : []}
          />
        </AsyncModal>
      </Container>
    </ThemeProvider>
  );
};

Index.propTypes = {
  ...TableProps,
  batClasses: arrayOf(shape(BatClassProps)).isRequired,
  createWithClassCodeClassEnrollmentsPath: string.isRequired,
  columns: array,
  currentId: number,
};
export default Index;
