import cn from 'classnames';
import { node, oneOf, string } from 'prop-types';
import { Alert } from 'react-bootstrap';

const AlertWithIcon = ({ variant, className, children, ...alertProps }) => (
  <Alert
    variant={variant}
    className={cn('d-flex align-items-center', className)}
    {...alertProps}>
    <i
      className={cn('fas fa-fw mr-3', {
        'fa-exclamation-circle': variant === 'warning',
        'fa-exclamation-triangle': variant === 'danger',
        'fa-info-circle': variant === 'info',
      })}
    />
    <div>{children}</div>
  </Alert>
);

AlertWithIcon.propTypes = {
  variant: oneOf(['warning', 'danger', 'info']).isRequired,
  children: node.isRequired,
  className: string,
};

export default AlertWithIcon;
