import { useState } from 'react';

import { destroy } from '../../../api';
import Button from '../../Button';
import CheckOrMinusCell from '../../CheckOrMinusCell';
import Container from '../../Container';
import DaperImgCell from '../../DaperImgCell';
import DateCell from '../../DateCell';
import PrimaryAdminKey from '../../PrimaryAdminKey';
import Table from '../../Table';
import { WebinarSeriesProps } from '../../types';
import { useFetchData } from '../../utils';
import { ActionsProps, IndexProps } from './types';

const Actions = ({ row, refreshData }) => {
  const handleDelete = async () => {
    try {
      await destroy(row.original.adminHref);
      refreshData();
    } catch (error) {
      alert('Something went wrong.');
    }
  };
  return (
    <span className="text-nowrap">
      <Button
        title="Edit"
        icon="edit"
        variant="light"
        size="sm"
        href={row.original.editAdminWebinarPath}
      />
      <Button
        title="Delete"
        icon="trash-alt"
        variant="danger"
        size="sm"
        className="ml-2"
        onClick={handleDelete}
      />
    </span>
  );
};
Actions.propTypes = ActionsProps;

const WebinarSeriesCell = ({ cell }) =>
  cell.value && <a href={cell.value.adminHref}>{cell.value.name}</a>;
WebinarSeriesCell.propTypes = WebinarSeriesProps;

const columns = [
  { Header: 'ID', accessor: 'id', filteredAs: 'number', Cell: PrimaryAdminKey },
  {
    Header: 'Thumbnail',
    accessor: 'thumbnail',
    disableSortBy: true,
    cellProps: { className: 'text-center' },
    Cell: DaperImgCell,
  },
  { Header: 'Name', accessor: 'name', filteredAs: 'string' },
  {
    Header: 'Active',
    accessor: 'active',
    disableSortBy: true,
    Cell: CheckOrMinusCell,
  },
  {
    Header: 'Featured',
    accessor: 'featured',
    disableSortBy: true,
    Cell: CheckOrMinusCell,
  },
  { Header: 'Vimeo ID', accessor: 'vimeoVideoId', filteredAs: 'string' },
  {
    Header: 'Created',
    accessor: 'createdAt',
    Cell: DateCell,
  },
  {
    Header: 'Occurred',
    accessor: 'occurredAt',
    Cell: DateCell,
  },
  {
    Header: 'Webinar Series',
    accessor: 'webinarSeries',
    Cell: WebinarSeriesCell,
  },
  {
    accessor: 'n/a',
    id: 'actions',
    disableSortBy: true,
    Cell: Actions,
  },
];

const cb = ({ totalCount, pageSize, webinars }) => ({
  data: webinars,
  pageSize,
  totalCount,
});

const scopes = [
  { id: 'isActive', title: 'Active' },
  { id: 'isInactive', title: 'Inactive' },
];

const Index = ({
  newAdminWebinarPath,
  adminWebinarsPath,
  webinars,
  totalCount,
  pageSize,
  pageIndex,
  sortBy,
}) => {
  const fetchData = useFetchData(adminWebinarsPath, cb);
  const [{ refreshData }, setRefreshDataFn] = useState({
    refreshData: () => {},
  });

  return (
    <Container>
      <div className="d-flex align-items-center mb-3">
        <h1>Webinars</h1>
        <Button className="ml-5" variant="primary" href={newAdminWebinarPath}>
          Add Webinar
        </Button>
      </div>
      <Table
        initialData={webinars}
        columns={columns}
        initialPageSize={pageSize}
        initialPageIndex={pageIndex}
        initialSortBy={sortBy}
        initialTotalCount={totalCount}
        initialScopes={{ isActive: false, isInactive: false }}
        availableScopes={scopes}
        fetchData={fetchData}
        onRefreshDataFn={setRefreshDataFn}
        refreshData={refreshData}
      />
    </Container>
  );
};

Index.propTypes = IndexProps;

export default Index;
