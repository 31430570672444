import {
  arrayOf,
  bool,
  func,
  number,
  oneOfType,
  shape,
  string,
} from 'prop-types';

import { ProductProps, TableProps } from '../../types';

const PromoCodeProps = {
  code: string,
  createdAt: string,
  discountedPrice: number,
  endDate: string,
  href: string,
  id: number,
  isActive: bool,
  ordersCount: number,
  product: shape(ProductProps),
  remainingUses: number,
  startDate: string,
};

const ProductSelectProps = arrayOf(arrayOf(oneOfType([string, number])));

export const PromoCodeFormModalProps = {
  show: bool.isRequired,
  onHide: func.isRequired,
  adminPromoCodesPath: string,
  onSuccess: func.isRequired,
  promoCode: shape(PromoCodeProps),
  products: ProductSelectProps,
};

export const IndexProps = {
  ...TableProps,
  promoCodes: arrayOf(shape(PromoCodeProps)),
  adminPromoCodesPath: string,
  products: arrayOf(shape(ProductProps)),
};

export const ActionsProps = {
  row: shape({
    original: shape(PromoCodeProps),
  }),
  refreshData: func.isRequired,
  products: ProductSelectProps,
};
