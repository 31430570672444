import {
  AnchorLinkBar,
  GlobalStyles,
  ThemeProvider,
  Typography,
} from '@bbnpm/bb-ui-framework';
import { Col } from '@bloomberg/styled-flexboxgrid';
import { useMemo, useState } from 'react';
import { Element, scroller } from 'react-scroll';

import { BfeGrid } from '../../styles/grid';
import Table from '../Table';
import { StyledHr } from '../home_page/styles';
import { useFetchData } from '../utils';
import LeaderboardTable, {
  ProfitLossCell,
  RelativeIcon,
} from './LeaderboardTable';
import { TradingChallengeTitleCard } from './Show';
import { MarginBottomRow, StickyAnchorLinkBar, StyledDataCell } from './styles';
import { LeaderboardCompProps } from './types';

const PrevWeekCell = ({ row, value }) => {
  const hasPrev = value !== '--';
  const rank = row.original.globalRank;

  return (
    <StyledDataCell>
      {value} {hasPrev && <RelativeIcon prev={value} curr={rank} />}
    </StyledDataCell>
  );
};

const columns = [
  { Header: 'Global Rank', accessor: 'globalRank' },
  {
    Header: 'Team Name',
    accessor: 'tradingChallengeRegistration.teamName',
    disableSortBy: true,
  },
  {
    Header: 'Institution',
    accessor: 'tradingChallengeRegistration.user.institution.name',
    disableSortBy: true,
  },
  {
    Header: 'Location',
    accessor:
      'tradingChallengeRegistration.user.institution.countryCode.countryName',
    disableSortBy: true,
  },
  { Header: 'Relative', accessor: 'relativePl', Cell: ProfitLossCell },
  { Header: 'Absolute', accessor: 'absolutePl', Cell: ProfitLossCell },
  {
    Header: 'Prev Rank',
    accessor: 'lastWeekRank',
    disableSortBy: true,
    Cell: PrevWeekCell,
  },
];

const cb = ({ totalCount, pageSize, leaderboardItems }) => ({
  data: leaderboardItems,
  pageSize,
  totalCount,
});

const scopes = [
  { id: 'isNorthAmerica', title: 'North America' },
  { id: 'isLatinAmerica', title: 'Latin America' },
  { id: 'isEurope', title: 'Europe' },
  { id: 'isMiddleEastAndAfrica', title: 'Middle East & Africa' },
  { id: 'isAsiaAndOceania', title: 'Asia & Oceania' },
];

const Leaderboard = ({
  name,
  leaderboardName,
  overview,
  bannerImg,
  globalItems,
  asiaAndOceaniaItems,
  europeItems,
  latinAmericaItems,
  middleEastAndAfricaItems,
  northAmericaItems,
  pageSize,
  pageIndex,
  sortBy,
  totalCount,
  leaderboardItems,
  path,
}) => {
  const fetchData = useFetchData(path, cb);
  const additionalFilters = useMemo(
    () => [
      {
        Header: 'Team Name',
        accessor: 'tradingChallengeRegistrationTeamName',
        filteredAs: 'string',
      },
      {
        Header: 'Institution',
        accessor: 'tradingChallengeRegistrationUserInstitutionName',
        filteredAs: 'string',
      },
      {
        Header: 'Country',
        accessor:
          'tradingChallengeRegistrationUserInstitutionCountryCodeCountryName',
        filteredAs: 'string',
      },
    ],
    [],
  );

  const [step, setStep] = useState(0);

  const navigationListHandler = link => {
    scroller.scrollTo(`step-${link}`, { duration: 500, smooth: true });
    setStep(link);
  };

  return (
    <ThemeProvider>
      <GlobalStyles />
      <TradingChallengeTitleCard
        title={name}
        description={overview}
        img={bannerImg}
        hideActionButton
      />
      <BfeGrid>
        <Typography.LargeTitle>
          <strong>{leaderboardName}</strong>
        </Typography.LargeTitle>
        <StickyAnchorLinkBar
          onLinkChange={navigationListHandler}
          activeIndex={step}
          className="pl-0">
          <AnchorLinkBar.Link>Global</AnchorLinkBar.Link>
          <AnchorLinkBar.Link>North America</AnchorLinkBar.Link>
          <AnchorLinkBar.Link>Latin America</AnchorLinkBar.Link>
          <AnchorLinkBar.Link>Europe</AnchorLinkBar.Link>
          <AnchorLinkBar.Link>Middle East & Africa</AnchorLinkBar.Link>
          <AnchorLinkBar.Link>Asia & Oceania</AnchorLinkBar.Link>
          <AnchorLinkBar.Link>Complete Leaderboard</AnchorLinkBar.Link>
        </StickyAnchorLinkBar>

        <Element name="step-0" />
        <StyledHr />
        <MarginBottomRow>
          <Col xs={12} lg={3}>
            <Typography.LargeTitle>Global Leaderboard</Typography.LargeTitle>
          </Col>
          <Col xs={12} lg={9}>
            <LeaderboardTable items={globalItems} />
          </Col>
        </MarginBottomRow>

        <Element name="step-1" />
        <StyledHr />
        <MarginBottomRow>
          <Col xs={12} lg={3}>
            <Typography.LargeTitle>
              North America Leaderboard
            </Typography.LargeTitle>
          </Col>
          <Col xs={12} lg={9}>
            <LeaderboardTable items={northAmericaItems} />
          </Col>
        </MarginBottomRow>

        <Element name="step-2" />
        <StyledHr />
        <MarginBottomRow>
          <Col xs={12} lg={3}>
            <Typography.LargeTitle>
              Latin America Leaderboard
            </Typography.LargeTitle>
          </Col>
          <Col xs={12} lg={9}>
            <LeaderboardTable items={latinAmericaItems} />
          </Col>
        </MarginBottomRow>

        <Element name="step-3" />
        <StyledHr />
        <MarginBottomRow>
          <Col xs={12} lg={3}>
            <Typography.LargeTitle>Europe Leaderboard</Typography.LargeTitle>
          </Col>
          <Col xs={12} lg={9}>
            <LeaderboardTable items={europeItems} />
          </Col>
        </MarginBottomRow>

        <Element name="step-4" />
        <StyledHr />
        <MarginBottomRow>
          <Col xs={12} lg={3}>
            <Typography.LargeTitle>
              Middle East & Africa Leaderboard
            </Typography.LargeTitle>
          </Col>
          <Col xs={12} lg={9}>
            <LeaderboardTable items={middleEastAndAfricaItems} />
          </Col>
        </MarginBottomRow>

        <Element name="step-5" />
        <StyledHr />
        <MarginBottomRow>
          <Col xs={12} lg={3}>
            <Typography.LargeTitle>
              Asia & Oceania Leaderboard
            </Typography.LargeTitle>
          </Col>
          <Col xs={12} lg={9}>
            <LeaderboardTable items={asiaAndOceaniaItems} />
          </Col>
        </MarginBottomRow>

        <Element name="step-6" />
        <StyledHr />
        <MarginBottomRow>
          <Col xs={12} lg={3}>
            <Typography.LargeTitle>Complete Leaderboard</Typography.LargeTitle>
          </Col>
          <Col xs={12} lg={9}>
            <Table
              columns={columns}
              intitialData={leaderboardItems}
              fetchData={fetchData}
              intialPageSize={pageSize}
              initialPageIndex={pageIndex}
              initialSortBy={sortBy}
              initialTotalCount={totalCount}
              availableScopes={scopes}
              additionalFilters={additionalFilters}
              variant="tradingChallenge"
            />
          </Col>
        </MarginBottomRow>
      </BfeGrid>
    </ThemeProvider>
  );
};

Leaderboard.propTypes = LeaderboardCompProps;

export default Leaderboard;
