import { Badge } from 'react-bootstrap';

import { isNullish } from '../../utils';
import RequiredForCertificationIcon from '../RequiredForCertificationIcon';
import RequiredForClassIcon from '../RequiredForClassIcon';
import UserStatus from '../UserStatus';
import { CourseVersionProps } from '../types';

const CourseVersion = ({
  name,
  description,
  icon,
  requiredForCertification,
  requiredForClass = false,
  eligibleForUpgrade = false,
  href,
  userInfo,
}) => (
  <a
    href={href}
    className="text-reset d-block mb-4 p-4 bg-white border course-version text-decoration-none">
    <div className="d-flex align-items-center">
      {icon && <i className={`fas fa-fw fa-3x fa-${icon} mr-3`} />}
      <h4 className="m-0">
        {name}
        {requiredForCertification && <RequiredForCertificationIcon />}
        {requiredForClass && <RequiredForClassIcon />}
      </h4>
      <div className="ml-auto">
        {!isNullish(userInfo.score) && (
          <span>
            <strong>Score:</strong> {userInfo.score}%
          </span>
        )}
        <strong className="ml-3">
          <UserStatus status={userInfo.status} />
        </strong>
      </div>
    </div>
    {description && <div className="mt-3">{description}</div>}
    {eligibleForUpgrade && (
      <div className="h5 text-center mt-2">
        <Badge variant="primary" className="text-uppercase">
          New Version Available
        </Badge>
      </div>
    )}
  </a>
);

CourseVersion.propTypes = CourseVersionProps;

export default CourseVersion;
