import cn from 'classnames';
import { node, string } from 'prop-types';
import { Container as BsContainer } from 'react-bootstrap';

const Container = ({ children, className, ...rest }) => (
  <BsContainer
    fluid="xl"
    className={cn('py-3 h-100 d-flex flex-column', className)}
    {...rest}>
    {children}
  </BsContainer>
);

Container.propTypes = { children: node.isRequired, className: string };

export default Container;
