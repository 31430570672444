import { arrayOf, bool, func, number, shape, string } from 'prop-types';
import { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useLocalStorage, useToggle } from 'react-use';

import { update } from '../../../api';
import { noop } from '../../../utils';
import AlertWithIcon from '../../AlertWithIcon';
import AsyncModal, { useAsyncModal } from '../../AsyncModal';
import Container from '../../Container';
import DateCell from '../../DateCell';
import Input from '../../Input';
import Table from '../../Table';
import Tooltip from '../../Tooltip';
import VimeoPlayer from '../../VimeoPlayer';
import { ProfessorInfoProps } from '../../admin/users/types';
import { CustomerProps, InstitutionProps, UserProps } from '../../types';
import { useFetchData } from '../../utils';

const TutorialAlert = () => {
  const [hideTutorialAlertLS, updateHideTutorialAlertLS] =
    useLocalStorage('hideDRTutorial');

  const [hideTutorialAlert, updateHideTutorialAlert] = useState(true);

  useEffect(() => {
    updateHideTutorialAlert(hideTutorialAlertLS);
  }, [hideTutorialAlertLS, updateHideTutorialAlert]);

  const [showTutorialModal, toggleShowTutorialModal] = useToggle(false);

  return hideTutorialAlert ? null : (
    <>
      <AlertWithIcon
        variant="info"
        dismissible
        onClose={() => updateHideTutorialAlertLS(true)}>
        Learn more about the new Terminal Access workflow by{' '}
        <Button
          variant="link"
          className="align-baseline d-inline p-0"
          onClick={toggleShowTutorialModal}>
          clicking here
        </Button>
        .
      </AlertWithIcon>
      <Modal
        show={showTutorialModal}
        onHide={toggleShowTutorialModal}
        centered
        size="lg">
        <VimeoPlayer vimeoId="454454320" autoplay />
      </Modal>
    </>
  );
};

const customerColumns = [
  { Header: 'Customer ID', accessor: 'bbgCustomerId', disableSortBy: true },
  { Header: 'Customer Name', accessor: 'name', disableSortBy: true },
  { Header: 'Location', accessor: 'location', disableSortBy: true },
  {
    Header: 'Total Terminals',
    accessor: 'terminals',
    disableSortBy: true,
    cellProps: { className: 'text-center' },
  },
];

const scopes = [
  { id: 'drAccessDisabled', title: 'Not Enabled' },
  { id: 'drAccessEnabled', title: 'Enabled' },
  { id: 'drAccessCompleted', title: 'Created Login' },
];

const initialScopes = {
  drAccessDisabled: false,
  drAccessEnabled: false,
  drAccessCompleted: false,
};

const cb = ({ totalCount, pageSize, users }) => ({
  data: users,
  pageSize,
  totalCount,
});

const Cell = ({ row, customers, refreshData, drEnabled }) => {
  const { userid, enableDrPath, customer, terminalDrInfo } = row.original;
  const isChecked = !!(customer && customer.bbgCustomerId);

  const { open, register, modalProps } = useAsyncModal();

  const updateWith = async customerId => {
    await update(enableDrPath, { customerId });
    refreshData();
  };

  const onSwitchToggled = async () => {
    try {
      if (isChecked) {
        updateWith(null);
      } else if (customers.length === 1) {
        updateWith(customers[0].id);
      } else if (customers.length > 1) {
        const { customerId } = await open();
        if (customerId) updateWith(customerId);
      }
    } catch (error) {
      alert('Something went wrong');
    }
  };

  if (!drEnabled) {
    return terminalDrInfo?.uuid ? (
      <Tooltip placement="right" tooltip="User currently has Terminal login.">
        <i className="fas fa-user-check" />
      </Tooltip>
    ) : (
      <Tooltip placement="right" tooltip="Feature is currently disabled.">
        <i className="fas fa-ban" />
      </Tooltip>
    );
  }

  return terminalDrInfo?.uuid ? (
    <Tooltip
      placement="right"
      tooltip="User currently has Terminal login (cannot be disabled)">
      <i className="fas fa-user-check" />
    </Tooltip>
  ) : (
    <>
      <Tooltip
        placement="right"
        tooltip={`Click to ${
          isChecked ? 'disable' : 'enable'
        } user's ability to create a Terminal login`}>
        <span className="d-inline-block">
          <Form.Switch
            key={userid}
            id={userid}
            label=""
            onChange={onSwitchToggled}
            checked={isChecked}
          />
        </span>
      </Tooltip>
      <AsyncModal {...modalProps} centered>
        <Input
          ref={register}
          as="select"
          name="customerId"
          label="Assign a Bloomberg Customer number to this User"
          options={customers.map(c => [c.id, `${c.bbgCustomerId} - ${c.name}`])}
          required
        />
      </AsyncModal>
    </>
  );
};

Cell.propTypes = {
  row: shape({
    original: shape(UserProps).isRequired,
  }).isRequired,
  customers: arrayOf(shape(CustomerProps)).isRequired,
  refreshData: func.isRequired,
  drEnabled: bool,
};

const userColumns = [
  {
    Header: 'Status',
    accessor: 'actions',
    Cell,
    cellProps: { className: 'text-center' },
    disableSortBy: true,
  },
  { Header: 'First Name', accessor: 'firstName', filteredAs: 'string' },
  { Header: 'Last Name', accessor: 'lastName', filteredAs: 'string' },
  { Header: 'Email', accessor: 'email', filteredAs: 'string' },
  { Header: 'Created At', accessor: 'createdAt' },
  {
    Header: 'Requested',
    accessor: 'terminalDrInfo.terminal_access_requested_at',
    Cell: DateCell,
  },
  {
    Header: 'Customer',
    id: 'customers.bbgCustomerId',
    accessor: ({ customer }) => (customer ? customer.bbgCustomerId : ''),
  },
];

const DrNotificationSettings = ({
  isChecked,
  professorInfoId,
  updateNotificationSettings,
  userPath,
}) => {
  const updateSettings = async () => {
    try {
      await update(userPath, {
        professorInfoAttributes: {
          id: professorInfoId,
          notifyLearnersDrEnablement: !isChecked,
        },
      });
      updateNotificationSettings(!isChecked);
    } catch (error) {
      alert('Something went wrong. Please try again.');
    }
  };

  return (
    <Tooltip
      placement="left"
      tooltip={`Click to ${
        isChecked ? 'disable' : 'enable'
      } automated notification e-mails to newly enabled users`}>
      <span className="d-inline-block">
        <Form.Switch
          key={professorInfoId}
          id={professorInfoId}
          label=""
          onChange={updateSettings}
          checked={isChecked}
        />
      </span>
    </Tooltip>
  );
};

DrNotificationSettings.propTypes = {
  isChecked: bool.isRequired,
  professorInfoId: number.isRequired,
  updateNotificationSettings: func.isRequired,
  userPath: string.isRequired,
};

const Manage = ({
  institution,
  usersPath,
  professorInfo,
  userPath,
  drEnabled,
}) => {
  const { customers = [] } = institution || {};
  const customersWithDr = customers.filter(customer => customer.terminalAccess);
  const fetchUsersData = useFetchData(usersPath, cb);
  const [{ refreshData }, setRefreshDataFn] = useState({ refreshData: noop });
  const [notifyLearnersDrEnablement, setNotifyLearnersDrEnablement] = useState(
    professorInfo.notifyLearnersDrEnablement,
  );
  const drDisabledContent = (
    <p>
      The remote Terminal access provided during the pandemic&apos;s widespread
      campus ended on <strong>June 30th, 2022</strong>. Students can only access
      your terminal at an installed campus location.
    </p>
  );
  let content;

  if (!institution || institution.customers.length === 0) {
    content = drEnabled ? (
      <>
        <p>
          Your account is not associated with one of Bloomberg&apos;s academic
          institution partners. If you are a faculty member at an academic
          institution with Bloomberg Terminals, please ensure your Bloomberg for
          Education account is tied to your institution&apos;s e-mail address.
          If your account is tied to your institution&apos;s e-mail address,
          contact us at{' '}
          <a
            href="mailto:bbg.edu@bloomberg.net"
            target="_blank"
            rel="noreferrer">
            bbg.edu@bloomberg.net
          </a>{' '}
          so we can add your e-mail domain (e.g. @bloomberg.edu) as an official
          domain to our records.
        </p>
        <p>
          Your academic institution must subscribe to Bloomberg&apos;s Disaster
          Recovery service for students and faculty to remotely access
          Terminals.
        </p>
      </>
    ) : (
      drDisabledContent
    );
  } else if (customersWithDr.length === 0) {
    content = drEnabled ? (
      <p>
        Your account is associated with {institution.name}. This institution is
        not currently enabled for Bloomberg&apos;s Disaster Recovery service
        which prevents your institution from accessing Bloomberg Terminals
        remotely. Please reach out to your Bloomberg Account Representative for
        more information about how you can access your Bloomberg Terminals
        remotely.
      </p>
    ) : (
      drDisabledContent
    );
  } else {
    content = (
      <>
        {drDisabledContent}
        <h4 className="pt-3 mt-3">Customers</h4>
        <Table
          size="sm"
          columns={customerColumns}
          initialData={customersWithDr}
        />
        <div className="d-flex">
          <div className="mr-auto">
            <h4>Bloomberg for Education Users</h4>
          </div>
          {drEnabled && (
            <>
              <div className="p-2">E-Mail Notification</div>
              <div className="p-2">
                <DrNotificationSettings
                  isChecked={notifyLearnersDrEnablement}
                  professorInfoId={professorInfo.id}
                  updateNotificationSettings={setNotifyLearnersDrEnablement}
                  userPath={userPath}
                />
              </div>
            </>
          )}
        </div>
        <Table
          size="sm"
          columns={userColumns}
          fetchData={fetchUsersData}
          initialSortBy="lastName ASC"
          onRefreshDataFn={setRefreshDataFn}
          customers={customersWithDr}
          refreshData={refreshData}
          initialScopes={initialScopes}
          availableScopes={scopes}
          drEnabled={drEnabled}
        />
      </>
    );
  }

  return (
    <Container className="py-3">
      {drEnabled && <TutorialAlert />}
      <h1>Manage Terminal Access</h1>
      {content}
    </Container>
  );
};

Manage.propTypes = {
  institution: shape(InstitutionProps),
  usersPath: string.isRequired,
  professorInfo: ProfessorInfoProps,
  userPath: string.isRequired,
  drEnabled: bool,
};

export default Manage;
