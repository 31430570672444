import {
  GlobalStyles,
  Table,
  ThemeProvider,
  Typography,
} from '@bbnpm/bb-ui-framework';

import Container from '../Container';
import { ShowProps } from './types';

const Show = ({
  createdAt,
  merchantTransactionId,
  name,
  quantity,
  subtotal,
  tax,
  total,
  productPrice,
  activeProductPrice,
  promoCodeId,
  isNewRecord,
  isFree,
  isEligiblePurchase,
  userName,
  userEmail,
}) => {
  return (
    <ThemeProvider>
      <GlobalStyles />
      <Container>
        <Typography.PageTitle>Order Summary</Typography.PageTitle>
        <Typography.Text>
          <strong>User: </strong>
          {userName}
        </Typography.Text>
        <Typography.Text>
          <strong>Email: </strong>
          {userEmail}
        </Typography.Text>
        <Typography.Text>
          <strong>Order Placed: </strong>
          {createdAt}
        </Typography.Text>
        <Typography.Text>
          <strong>Order Number: </strong>
          {merchantTransactionId}
        </Typography.Text>
        <Table borders="enclosed">
          <thead>
            <tr>
              <th>Product</th>
              <th className="text-right">Price</th>
              <th className="text-center">Quantity</th>
              <th className="text-right">Subtotal</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{name}</td>
              <td className="text-right">
                {promoCodeId && <strike>{productPrice}</strike>}{' '}
                {activeProductPrice}
              </td>
              <td className="text-center">{quantity}</td>
              <td className="text-right">{subtotal}</td>
            </tr>
          </tbody>

          {isNewRecord ? (
            <tfoot>
              {!isFree && (
                <td className="text-right">
                  <Typography.Text>
                    Plus Applicable Taxes{' '}
                    <strong>
                      *Please fill out your billing address information to
                      calculate tax.
                    </strong>
                  </Typography.Text>
                </td>
              )}
            </tfoot>
          ) : (
            <tfoot>
              <tr>
                <td className="text-right" colSpan={3}>
                  Tax
                </td>
                <td className="text-right">{tax}</td>
              </tr>
              <tr>
                <td className="text-right" colSpan={3}>
                  Total
                </td>
                <td className="text-right">{total}</td>
              </tr>
            </tfoot>
          )}
        </Table>

        {isEligiblePurchase && (
          <div>
            <Typography.Text>Thank you for your purchase</Typography.Text>
          </div>
        )}
      </Container>
    </ThemeProvider>
  );
};

Show.propTypes = ShowProps;

export default Show;
