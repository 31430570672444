import analytics from './analytics';

export const onDomReady = fn =>
  document.addEventListener('turbolinks:load', fn);

export const cookiesSupported = () => {
  let cookieEnabled = navigator.cookieEnabled ? true : false;

  //Test for browswers that don't support the cookieEnabled flag
  if (typeof navigator.cookieEnabled == 'undefined' && !cookieEnabled) {
    document.cookie = 'testcookie';
    cookieEnabled = document.cookie.indexOf('testcookie') != -1 ? true : false;
  }

  return cookieEnabled; //if cookies are enabled on client's browser
};

// http://stackoverflow.com/a/2901298
export const numberWithCommas = n => {
  var parts = n.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
};

export const getGuid = (() => {
  let guidIndex = 0;
  return () => guidIndex++;
})();

export const humanFileSize = (bytes, si = true) => {
  var thresh = si ? 1000 : 1024;
  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }
  var units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  var u = -1;
  do {
    bytes /= thresh;
    ++u;
  } while (Math.abs(bytes) >= thresh && u < units.length - 1);
  return bytes.toFixed(1) + ' ' + units[u];
};

let _csrf;
export const csrfFromDom = () => {
  if (_csrf) return _csrf;
  const param = window.document
    .querySelector('meta[name="csrf-param"]')
    .getAttribute('content');

  const token = window.document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute('content');

  _csrf = { param, token };
  return _csrf;
};

export const noop = () => {};

export const pluralize = (count, single, plural = `${single}s`) =>
  `${count} ${count === 1 ? single : plural}`;

export const isNullish = value => value == null;

export const parseISODate = isoStr => new Date(isoStr);

export const formatISODateLocale = isoStr =>
  parseISODate(isoStr).toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });

export const trimISODate = isoStr => isoStr.substr(0, 10);

export const toFixed = (value, precision = 0) => {
  const power = Math.pow(10, precision);
  return (Math.round(value * power) / power).toFixed(precision);
};

export const toCurrency = value => `$${toFixed(value, 2)}`;

export const minutesToHoursFormatted = mins =>
  [Math.floor(mins / 60), mins % 60]
    .map(n => n.toString().padStart(2, 0))
    .join(':');

export const minutesFormatted = mins => `${mins} min`;

export const dateSorter =
  ({ prop, isDesc = true }) =>
  (a, b) =>
    new Date((isDesc ? b : a)[prop]) - new Date((isDesc ? a : b)[prop]);

export const isDevelopment =
  process.env.NODE_ENV === 'development' ||
  process.env.NODE_ENV === 'integration';

export const initAnalytics = () => {
  const { trackPage } = analytics();
  onDomReady(({ target }) => trackPage(target.location));
};
