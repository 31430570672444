import {
  Button,
  FormField,
  Input,
  Modal,
  Switch,
  TextArea,
} from '@bbnpm/bb-ui-framework';
import { useForm } from 'react-hook-form';

import { ButtonContainer, RequiredLabel } from '../../CommonComponents';
import { WebinarSeriesFormModalProps } from './types';

const WebinarSeriesFormModal = ({
  show,
  onHide,
  onSubmit,
  webinarSeries = {},
}) => {
  const { register, handleSubmit } = useForm();

  return (
    <Modal
      isOpen={show}
      onClose={onHide}
      portalContainerId="bfe-root"
      title={`${webinarSeries.id ? 'Edit' : 'Add'} Webinar Series`}>
      <form
        onSubmit={handleSubmit((values, e) => {
          e.preventDefault();
          onSubmit(values);
        })}>
        <FormField label={<RequiredLabel>Name</RequiredLabel>}>
          <Input
            ref={register}
            name="name"
            defaultValue={webinarSeries.name || ''}
            required
          />
        </FormField>

        <FormField label="Description">
          <TextArea
            ref={register}
            name="description"
            defaultValue={webinarSeries.description || ''}
          />
        </FormField>

        <FormField>
          <Switch
            ref={register}
            name="active"
            defaultChecked={webinarSeries.active}
            label="Active"
            labelPosition="right"
          />
        </FormField>

        <FormField>
          <Switch
            ref={register}
            name="restrictedForProfessors"
            defaultChecked={webinarSeries.restrictedForProfessors}
            label="Restricted for Professors"
            labelPosition="right"
          />
        </FormField>

        <ButtonContainer>
          <Button kind="primary" type="submit">
            {webinarSeries.id ? 'Update' : 'Create'}
          </Button>
          <Button kind="secondary" onClick={onHide} className="mx-1">
            Cancel
          </Button>
        </ButtonContainer>
      </form>
    </Modal>
  );
};

WebinarSeriesFormModal.propTypes = WebinarSeriesFormModalProps;

export default WebinarSeriesFormModal;
