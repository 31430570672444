import {
  Button,
  FormField,
  GlobalStyles,
  Input,
  RadioButtonGroup,
  ThemeProvider,
  Typography,
} from '@bbnpm/bb-ui-framework';
import { object, string } from 'prop-types';

import Container from '../Container';
import PostForm from '../PostForm';

const PostRegistrationForm = ({
  email,
  url,
  privacyPolicyUrl,
  tosUrl,
  errors = {},
}) => {
  const options = [
    { label: 'Learner', value: 'Candidate' },
    { label: 'Professor', value: 'Professor' },
  ];
  return (
    <ThemeProvider>
      <GlobalStyles />
      <Container>
        <Typography.PageTitle>More Information Required</Typography.PageTitle>
        <PostForm action={url}>
          <FormField
            validationType={errors.email?.length && 'error'}
            validationContent={errors.email?.join(', ')}
            label="Email"
            required>
            <Input placeholder={email} disabled />
          </FormField>

          <RadioButtonGroup
            name="user[type]"
            label="Account Type"
            options={options}
            labelDescription="Required"
            validationType={errors.type?.length && 'error'}
            validationContent={errors.type?.join(', ')}
          />

          <FormField
            label="First name"
            labelDescription="Required"
            validationType={errors.firstName?.length && 'error'}
            validationContent={errors.firstName?.join(', ')}>
            <Input name="user[first_name]" required />
          </FormField>

          <FormField
            label="Last name"
            labelDescription="Required"
            validationType={errors.lastName?.length && 'error'}
            validationContent={errors.lastName?.join(', ')}>
            <Input name="user[last_name]" required />
          </FormField>

          <Typography.Caption>
            Bloomberg may send you offers and promotions. By clicking the
            Continue button below, I represent and agree that the information I
            have provided is truthful and accurate, and I signify that I have
            read and agree to the{' '}
            <Typography.Link href={tosUrl} target="_blank">
              Terms of Sevice
            </Typography.Link>{' '}
            and the{' '}
            <Typography.Link href={privacyPolicyUrl} target="_blank">
              Privacy Policy
            </Typography.Link>
          </Typography.Caption>

          <Button kind="primary" type="submit">
            Get Started
          </Button>
        </PostForm>
      </Container>
    </ThemeProvider>
  );
};

PostRegistrationForm.propTypes = {
  email: string.isRequired,
  url: string.isRequired,
  privacyPolicyUrl: string.isRequired,
  tosUrl: string.isRequired,
  errors: object,
};

export default PostRegistrationForm;
