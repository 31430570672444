import { string } from 'prop-types';

import AlertWithIcon from './AlertWithIcon';

const AlertTerminalPlanNeedsUpgrade = ({ url, ...alertProps }) => (
  <AlertWithIcon variant="warning" {...alertProps}>
    Your BMC subscription is only available on the Bloomberg Terminal.{' '}
    <a href={url}>Click here</a> to buy web access to BMC.
  </AlertWithIcon>
);

AlertTerminalPlanNeedsUpgrade.propTypes = {
  url: string.isRequired,
};

export default AlertTerminalPlanNeedsUpgrade;
