import { Dropdown } from 'react-bootstrap';

import { TableDropdownProps } from './types';

const TableDropdown = ({ children }) => (
  <Dropdown drop="left">
    <Dropdown.Toggle variant="outline-dark" className="py-0 border-0" size="sm">
      <i className="fas fa-lg fa-fw fa-ellipsis-h" />
    </Dropdown.Toggle>

    <Dropdown.Menu>{children}</Dropdown.Menu>
  </Dropdown>
);

TableDropdown.propTypes = TableDropdownProps;

TableDropdown.Item = Dropdown.Item;

export default TableDropdown;
