import { Switch, Typography } from '@bbnpm/bb-ui-framework';
import PropTypes from 'prop-types';

import Table from '../../Table';

const CaseStudyCell = ({
  row,
  deletionStatusCaseStudyMap,
  setDeletionCaseStudy,
}) => {
  const { professorPackId } = row.original;

  return (
    <Switch
      key={professorPackId}
      id={professorPackId}
      label=""
      onChange={() =>
        setDeletionCaseStudy(
          professorPackId,
          !deletionStatusCaseStudyMap[professorPackId],
        )
      }
      checked={!deletionStatusCaseStudyMap[professorPackId]}
    />
  );
};

CaseStudyCell.propTypes = {
  row: PropTypes.object.isRequired,
  setDeletionCaseStudy: PropTypes.func.isRequired,
  deletionStatusCaseStudyMap: PropTypes.object.isRequired,
};

const caseStudyColumns = [
  { Header: 'Name', accessor: 'title' },
  { Header: 'Description', accessor: 'description', disableSortBy: true },
  {
    Header: 'Required',
    accessor: 'actions',
    Cell: CaseStudyCell,
    disableSortBy: true,
  },
];

export const AssignedCaseStudies = ({
  canAccessProfessorPacks,
  caseStudyAssignments,
  deletionStatusCaseStudyMap,
  setDeletionCaseStudy,
}) => {
  return canAccessProfessorPacks ? (
    <>
      <Typography.SectionTitle>
        Select Required Case Studies
      </Typography.SectionTitle>
      <Table
        initialPageSize={50}
        size="sm"
        columns={caseStudyColumns}
        initialData={caseStudyAssignments}
        deletionStatusCaseStudyMap={deletionStatusCaseStudyMap}
        setDeletionCaseStudy={setDeletionCaseStudy}
      />
    </>
  ) : (
    <Typography.SubSectionTitle>
      You are not enabled for Bloomberg Businessweek Case Studies. Access to
      Bloomberg Businessweek Case Studies requires your campus to have at least
      3 terminals. To learn more about this offering please contact us directly
      at{' '}
      <a href="mailto:bbg.edu@bloomberg.net" target="_blank" rel="noreferrer">
        bbg.edu@bloomberg.net
      </a>
    </Typography.SubSectionTitle>
  );
};

AssignedCaseStudies.propTypes = {
  canAccessProfessorPacks: PropTypes.bool,
  caseStudyAssignments: PropTypes.array.isRequired,
  deletionStatusCaseStudyMap: PropTypes.object.isRequired,
  setDeletionCaseStudy: PropTypes.func.isRequired,
};

export default AssignedCaseStudies;
