import { Button, Modal, Typography } from '@bbnpm/bb-ui-framework';

import { BssoOptOutModalProps } from './types';

const BssoOptOutModal = ({ show, onHide }) => (
  <Modal
    portalContainerId="bfe-root"
    isOpen={show}
    onClose={onHide}
    title="Bloomberg Single-Sign-On Opt Out"
    primaryActions={<Button onClick={onHide}>Close</Button>}>
    <>
      <Typography.Text>
        Upon opt-out, logging in via email/password will be available for this
        account.
      </Typography.Text>
      <Typography.Text>
        If you wish to opt back in, you simply need to log in via the
        &quot;Continue with Bloomberg&quot; option.
      </Typography.Text>
    </>
  </Modal>
);

BssoOptOutModal.propTypes = BssoOptOutModalProps;

export default BssoOptOutModal;
