import {
  Button,
  FormField,
  GlobalStyles,
  Input,
  ThemeProvider,
  Typography,
} from '@bbnpm/bb-ui-framework';
import { object, string } from 'prop-types';
import styled from 'styled-components';

import Container from '../Container';
import PostForm from '../PostForm';

const StyledPostForm = styled(PostForm)`
  width: 50%;
  margin-left: auto;
  margin-right: auto;
`;

const New = ({ errors = {}, url }) => {
  return (
    <ThemeProvider>
      <GlobalStyles />
      <Container>
        <StyledPostForm action={url}>
          <Typography.PageTitle>Update Password</Typography.PageTitle>
          <Typography.Text>
            <strong>Your new password</strong> <br />
            must be at least 8 characters in length and contain at least one
            letter and one number; and cannot be any of your last 3 passwords.
          </Typography.Text>
          <FormField
            validationType={errors.current_password?.length && 'error'}
            validationContent={errors.current_password?.join(', ')}>
            <Input
              name="user[current_password]"
              placeholder="Current Password"
              type="password"
            />
          </FormField>
          <FormField
            validationType={errors.password?.length && 'error'}
            validationContent={errors.password?.join(', ')}>
            <Input
              name="user[password]"
              placeholder="New Password"
              type="password"
            />
          </FormField>
          <FormField
            validationType={errors.password_confirmation?.length && 'error'}
            validationContent={errors.password_confirmation?.join(', ')}>
            <Input
              name="user[password_confirmation]"
              placeholder="Confirm Password"
              type="password"
            />
          </FormField>
          <br />
          <Button kind="primary" type="submit">
            Update Password
          </Button>
        </StyledPostForm>
      </Container>
    </ThemeProvider>
  );
};

New.propTypes = { url: string.isRequired, errors: object };

export default New;
