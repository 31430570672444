import { number } from 'prop-types';
import { Col, Row } from 'react-bootstrap';

const WizardHeaderNav = ({ step }) => {
  const activeStepCss = 'text-center border-bottom border-info';
  const notActiveStepCss = 'text-center text-muted';
  return (
    <Row className="mb-3">
      <Col>
        <div className={step === 1 ? activeStepCss : notActiveStepCss}>
          <h6>Contact Information</h6>
        </div>
      </Col>
      <Col>
        <div className={step === 2 ? activeStepCss : notActiveStepCss}>
          <h6>Verification Method</h6>
        </div>
      </Col>
      <Col>
        <div className={step === 3 ? activeStepCss : notActiveStepCss}>
          <h6>Verify Identity</h6>
        </div>
      </Col>
      <Col>
        <div className={step === 4 ? activeStepCss : notActiveStepCss}>
          <h6>Create Account</h6>
        </div>
      </Col>
    </Row>
  );
};

WizardHeaderNav.propTypes = {
  step: number.isRequired,
};

export default WizardHeaderNav;
