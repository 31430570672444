import { Stack, Typography } from '@bbnpm/bb-ui-framework';
import styled from 'styled-components';

export const StyledSectionTitle = styled(Typography.SectionTitle)`
  font-weight: bold;
  margin-top: 2rem;
  margin-bottom: 1rem;
`;

export const StyledList = styled.ul`
  width: 40%;
  height: inherit;
  text-align: center;
  list-style: none;
  display: flex;
  flex-direction: column;
  algin-items: center;
  justify-content: center;
`;

export const StyledListItem = styled.li`
  padding: 0.5rem 0;
  width: 50%;
  text-align: left;
`;

export const CertificateDiv = styled.div`
  display: flex;
  padding: 1rem;
  height: 15rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  background-color: white;
`;

export const StyledCertRight = styled.div`
  padding: 1rem;
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

export const StyledCertAvailable = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 7.5rem;
`;

export const StyledShowItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
`;

export const StyledLink = styled.a`
  color: ${({ theme }) => theme.colors.blue[300]};
  &:hover {
    text-decoration: none;
  }
`;

export const StyledCompleted = styled(Typography.Caption)`
  color: ${({ theme }) => theme.colors.green[600]};
  margin-top: 0;
`;

export const StyledInProgress = styled(Typography.Caption)`
  color: ${({ theme }) => theme.colors.blue[600]};
  margin-top: 0;
`;

export const StyledNotStarted = styled(Typography.Caption)`
  margin-top: 0;
`;

export const StyledStack = styled(Stack)`
  padding: 10px 0;
`;

export const StatusDiv = styled.div`
  display: flex;
`;
