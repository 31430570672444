import { Button, Typography } from '@bbnpm/bb-ui-framework';
import { Col } from '@bloomberg/styled-flexboxgrid';
import camelCase from 'lodash.camelcase';
import { arrayOf, shape } from 'prop-types';

import DaperImg from '../DaperImg';
import { PromoItemProps } from '../types';
import {
  CarouselItemContainer,
  CarouselItemImage,
  CarouselItemRow,
  StyledCarousel,
} from './styles';

const responsive = {
  superLargeDesktop: {
    // xl
    breakpoint: { max: 5120, min: 1280 },
    items: 1,
    partialVisibilityGutter: 40, // this is needed to tell the amount of px that should be visible.
  },
  desktop: {
    breakpoint: { max: 1280, min: 1024 },
    items: 1,
    partialVisibilityGutter: 40, // this is needed to tell the amount of px that should be visible.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    partialVisibilityGutter: 30, // this is needed to tell the amount of px that should be visible.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    partialVisibilityGutter: 30, // this is needed to tell the amount of px that should be visible.
  },
};

const PromoCarouselCard = ({
  title,
  description,
  actionText,
  url,
  thumbnail,
}) => {
  return (
    <CarouselItemContainer>
      <CarouselItemRow>
        <Col xs={6}>
          <Typography.SectionTitle>{title}</Typography.SectionTitle>
          <Typography.Text style={{ whiteSpace: 'pre-line' }}>
            {description}
          </Typography.Text>
          {actionText && (
            <Button
              kind="primary"
              as="a"
              target="_blank"
              href={url}
              onClick={() =>
                window.bfeAnalytics.trackEvent({
                  category: 'homepageAction',
                  action: `promoItemClicked`,
                  label: camelCase(title),
                })
              }>
              {actionText}
            </Button>
          )}
        </Col>
        <Col xs={6}>
          {thumbnail && (
            <CarouselItemImage>
              <DaperImg metadata={thumbnail} width={500} />
            </CarouselItemImage>
          )}
        </Col>
      </CarouselItemRow>
    </CarouselItemContainer>
  );
};

PromoCarouselCard.propTypes = shape(PromoItemProps);

const PromotionalCarousel = ({ promotionalItems }) => {
  if (promotionalItems.length === 1) {
    const { title, description, actionText, url, thumbnail } =
      promotionalItems[0];
    return (
      <PromoCarouselCard
        title={title}
        description={description}
        actionText={actionText}
        url={url}
        thumbnail={thumbnail}
      />
    );
  }
  return (
    <StyledCarousel
      arrows={false}
      responsive={responsive}
      infinite={promotionalItems.length > 2}
      showDots={true}
      autoPlay={promotionalItems.length > 2}
      autoPlaySpeed={10000}
      containerClass="carousel-container">
      {promotionalItems.map(
        ({ title, description, actionText, url, thumbnail }, idx) => (
          <PromoCarouselCard
            key={idx}
            title={title}
            description={description}
            actionText={actionText}
            url={url}
            thumbnail={thumbnail}
          />
        ),
      )}
    </StyledCarousel>
  );
};

PromotionalCarousel.propTypes = {
  promotionalItems: arrayOf(shape(PromoItemProps)),
};

export default PromotionalCarousel;
