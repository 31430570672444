import { arrayOf, bool, func, number, object, shape, string } from 'prop-types';

import { ToolkitDocumentProps, ToolkitTagProps } from '../../types';

export const ToolkitTagGroups = {
  toolkitTopic: arrayOf(shape(ToolkitTagProps)).isRequired,
  toolkitType: arrayOf(shape(ToolkitTagProps)).isRequired,
};

export const IndexProps = {
  toolkitDocuments: arrayOf(shape(ToolkitDocumentProps)).isRequired,
  activeTags: shape(ToolkitTagGroups).isRequired,
  docCounts: string.isRequired,
  totalCount: number.isRequired,
  fullName: string.isRequired,
  searchHref: string.isRequired,
  backgroundImg: string.isRequired,
};

export const DocumentCardProps = {
  doc: shape(ToolkitDocumentProps).isRequired,
  idx: number.isRequired,
  pdfLoading: bool,
  cardFooterAction: func,
};

export const LoadMoreProps = {
  initialData: arrayOf(shape(ToolkitDocumentProps)).isRequired,
  initialPageSize: number,
  initialPageIndex: number,
  initialSortBy: string,
  initialTotalCount: number,
  fetchData: func,
  initialScopes: object,
  setDocumentsFn: func,
  documents: arrayOf(shape(ToolkitDocumentProps)).isRequired,
  totalCount: number,
  activeFilterIds: object,
  activeFilterTitles: object,
  query: string,
};
