import {
  Button,
  FormField,
  GlobalStyles,
  Switch,
  ThemeProvider,
  Typography,
} from '@bbnpm/bb-ui-framework';
import { bool, number, string } from 'prop-types';
import { useToggle } from 'react-use';

import Container from '../../Container';
import PatchForm from '../../PatchForm';
import BssoOptInModal from '../../bsso/BssoOptInModal';
import BssoOptOutModal from '../../bsso/BssoOptOutModal';
import ReadOnlyModal from '../../profiles/ReadOnlyModal';
import {
  ButtonGroup,
  StyledButton,
  StyledInput,
  SwitchContainer,
} from '../../profiles/profiles.styles';

const Edit = ({
  url,
  email,
  newUpdatePasswordPath,
  firstName,
  lastName,
  workPhone,
  rootPath,
  readOnlyFields,
  bssoEnabled,
  bssoUrl,
  canUpdateBssoStatus,
  hasBConnectId,
}) => {
  const [isOpen, toggleIsOpen] = useToggle(false);
  const [bssoModalIsOpen, toggleBssoModalIsOpen] = useToggle(false);
  const [isBssoEnabled, toggleIsBssoEnabled] = useToggle(bssoEnabled);

  const onHide = () => {
    if (isBssoEnabled) toggleIsBssoEnabled();
    toggleBssoModalIsOpen();
  };

  return (
    <ThemeProvider>
      <GlobalStyles />
      <Container>
        <PatchForm action={url}>
          <Typography.PageTitle>Edit Profile</Typography.PageTitle>
          <br />

          <FormField label="Email" labelPosition="left">
            <StyledInput placeholder={email} disabled />
            <StyledButton kind="secondary" onClick={toggleIsOpen}>
              Change
            </StyledButton>
          </FormField>

          {hasBConnectId && (
            <FormField label="Password" labelPosition="left">
              <StyledInput placeholder="**********" disabled />
              <a href={newUpdatePasswordPath}>
                <StyledButton kind="secondary">Change</StyledButton>
              </a>
            </FormField>
          )}

          <FormField
            label="First name"
            labelDescription="Required"
            labelPosition="left">
            <StyledInput name="first_name" defaultValue={firstName} required />
          </FormField>

          <FormField
            label="Last name"
            labelDescription="Required"
            labelPosition="left">
            <StyledInput name="last_name" defaultValue={lastName} required />
          </FormField>

          <FormField label="Phone" labelPosition="left">
            <StyledInput name="work_phone" defaultValue={workPhone || ''} />
          </FormField>
          <br />
          {canUpdateBssoStatus && (
            <>
              <FormField label="BSSO Enabled" labelPosition="left">
                <SwitchContainer>
                  <Switch
                    checked={isBssoEnabled}
                    onChange={() => {
                      toggleIsBssoEnabled();
                      toggleBssoModalIsOpen();
                    }}
                  />
                  {!isBssoEnabled && (
                    <input type="hidden" name="bssoEnabled" value={false} />
                  )}
                </SwitchContainer>
              </FormField>
            </>
          )}
          <ButtonGroup>
            <Button kind="primary" type="submit">
              Update
            </Button>
            &nbsp; &nbsp;
            <a href={rootPath}>
              <Button kind="secondary">Cancel</Button>
            </a>
          </ButtonGroup>
        </PatchForm>
        <ReadOnlyModal
          isOpen={isOpen}
          toggleIsOpen={toggleIsOpen}
          readOnlyFields={readOnlyFields}
        />
        {isBssoEnabled ? (
          <BssoOptInModal
            show={bssoModalIsOpen}
            onHide={onHide}
            bssoUrl={bssoUrl}
          />
        ) : (
          <BssoOptOutModal
            show={bssoModalIsOpen}
            onHide={toggleBssoModalIsOpen}
          />
        )}
      </Container>
    </ThemeProvider>
  );
};

Edit.propTypes = {
  url: string.isRequired,
  email: string.isRequired,
  newUpdatePasswordPath: string.isRequired,
  firstName: string.isRequired,
  lastName: string.isRequired,
  workPhone: string,
  rootPath: string.isRequired,
  readOnlyFields: string.isRequired,
  bssoEnabled: bool.isRequired,
  bssoSettingEnabled: bool.isRequired,
  uuid: number,
  bssoUrl: string.isRequired,
  canUpdateBssoStatus: bool.isRequired,
  hasBConnectId: bool.isRequired,
};

export default Edit;
