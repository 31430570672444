import cn from 'classnames';
import startcase from 'lodash.startcase';

import { UserStatusProps } from './types';

// Should match what's in app/models/concerns/user_course_concern.rb
export const STATUS = {
  NOT_STARTED: 'NOT_STARTED',
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETED: 'COMPLETED',
};

const UserStatus = ({ status = STATUS.NOT_STARTED, score }) => (
  <span
    className={cn({
      'text-muted': status === STATUS.NOT_STARTED,
      'text-warning': status === STATUS.IN_PROGRESS,
      'text-success': status === STATUS.COMPLETED,
    })}>
    {startcase(status.toLowerCase())}
    {score && ` (${score}%)`}
  </span>
);

UserStatus.propTypes = UserStatusProps;

export default UserStatus;
