import {
  GlobalStyles,
  ThemeProvider,
  Typography,
} from '@bbnpm/bb-ui-framework';
import { string } from 'prop-types';
import styled from 'styled-components';

import Container from '../../Container';

const FlexBox = styled.div`
  display: flex;
`;
const TextBox = styled.div`
  width: 66%;
`;

const ImgBox = styled.div`
  width: 33%;
  padding: 0 15px 0 15px;
`;

const StyledImg = styled.img`
  max-width: 100%;
`;

const Index = ({ elpFactSheet, imgSrc, infoMailLink }) => {
  return (
    <ThemeProvider>
      <GlobalStyles />
      <Container>
        <Typography.PageTitle>
          <strong>Experiential Learning Partner (ELP) Program</strong>
        </Typography.PageTitle>
        <br />
        <FlexBox>
          <TextBox>
            <Typography.Text>
              The Bloomberg Experiential Learning Partner (ELP) program is
              designed to recognize and celebrate academic institutions that are
              leaders in experiential learning through the integration of the
              Bloomberg Terminal into their curricula. Institutions that have 3
              or more billable Terminals are automatically part of the program
              and receive various benefits on additional Bloomberg products and
              use of the ELP logo.
            </Typography.Text>
            <Typography.Text>
              Please refer to the{' '}
              <Typography.Link href={elpFactSheet} target="_blank">
                Program Fact Sheet
              </Typography.Link>{' '}
              for more information.
            </Typography.Text>
          </TextBox>
          <ImgBox>
            <StyledImg src={imgSrc} alt="logo" />
          </ImgBox>
        </FlexBox>
        <Typography.Text>
          To request access to use the ELP logo, please submit a request to{' '}
          <Typography.Link href={`mailto:${infoMailLink}`}>
            elp@bloomberg.net
          </Typography.Link>
          . You will be required to sign an addendum to your existing Bloomberg
          contract that authorizes use of the logo.
        </Typography.Text>
      </Container>
    </ThemeProvider>
  );
};

Index.propTypes = {
  elpFactSheet: string.isRequired,
  imgSrc: string.isRequired,
  infoMailLink: string.isRequired,
};

export default Index;
