import { Col, Row } from 'react-bootstrap';
import TextareaAutosize from 'react-textarea-autosize';

import AutosuggestInput from '../../AutosuggestInput';
import Button from '../../Button';
import Container from '../../Container';
import FileInput from '../../FileInput';
import ImageFileInput from '../../ImageFileInput';
import Input from '../../Input';
import PatchForm from '../../PatchForm';
import PostForm from '../../PostForm';
import { WebinarFormProps } from '../webinars/types';

const WebinarForm = ({
  adminWebinarsPath,
  adminHref,
  id,
  name,
  description,
  vimeoVideoId,
  occurredAt,
  active,
  durationMins,
  featured,
  thumbnail,
  slidesDocument,
  webinarSeries,
  allWebinarSeries,
  errors,
}) => {
  const [Form, action, submitText] = id
    ? [PatchForm, adminHref, 'Update']
    : [PostForm, adminWebinarsPath, 'Submit'];

  return (
    <Container>
      <div className="d-flex align-items-center mb-3">
        <h1>{id ? 'Edit' : 'Add'} Webinar</h1>
        <Button className="ml-5" variant="light" href={adminHref}>
          Cancel
        </Button>
        <Button className="ml-2" variant="light" href={adminWebinarsPath}>
          All Webinars
        </Button>
      </div>

      <Form
        action={action}
        submitText={submitText}
        encType="multipart/form-data">
        <div className="d-flex align-items-center mb-3">
          <Input
            type="switch"
            name="active"
            defaultChecked={active}
            formGroupProps={{ className: 'mb-0' }}
          />
          <Input
            type="switch"
            name="featured"
            label="Featured Webinar"
            defaultChecked={featured}
            formGroupProps={{ className: 'mb-0 ml-3' }}
          />
        </div>
        <Input
          name="name"
          required
          defaultValue={name ?? ''}
          errors={errors?.name}
          helpText="The public-facing name of this module"
        />
        <Input
          name="description"
          defaultValue={description ?? ''}
          as={TextareaAutosize}
        />
        <Row>
          <Col>
            <Input
              required
              name="vimeoVideoId"
              label="Vimeo Video ID"
              defaultValue={vimeoVideoId ?? ''}
              errors={vimeoVideoId?.name}
            />
          </Col>
          <Col>
            <Input
              required
              name="occurredAt"
              type="date"
              defaultValue={occurredAt ?? ''}
              errors={occurredAt?.name}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <AutosuggestInput
              required
              name="webinarSeriesId"
              label="Series"
              isFetchAsync={false}
              shouldRenderSuggestions={true}
              initialSuggestions={allWebinarSeries}
              entity={webinarSeries}
            />
          </Col>
          <Col>
            <Input
              required
              defaultValue={durationMins ?? 0}
              name="durationMins"
              label="Duration in Minutes"
              type="number"
              min={0}
              helpText="Duration in minutes - used for webinar display"
            />
          </Col>
        </Row>

        <ImageFileInput
          name="thumbnail"
          accept=".jpg, .png"
          helpText="upload the largest available version"
          value={thumbnail}
        />

        <FileInput
          name="slidesDocument"
          accept=".pdf, .doc"
          value={slidesDocument}
        />
      </Form>
    </Container>
  );
};
WebinarForm.propTypes = WebinarFormProps;

export default WebinarForm;
