import { useContext, useState } from 'react';
import { Alert, Button, Modal, ProgressBar } from 'react-bootstrap';

import { humanFileSize } from '../../../utils';
import Dropzone from '../../Dropzone';
import { UPLOAD_FILE_STATUSES, useUploadFile } from '../../utils';
import { RusticiContext } from './Index';
import { UploadModalProps } from './types';

const UploadModal = ({ show, onHide, courseModule = {}, refreshData }) => {
  const { rusticiBaseUrl, rusticiToken } = useContext(RusticiContext);
  const { rusticiId, markUploadedAdminCourseModulePath } = courseModule;

  const { uploadFile, uploadFileProgress, status, error, reset } =
    useUploadFile({
      rusticiToken,
      rusticiBaseUrl,
    });
  const now =
    uploadFileProgress.total > 0
      ? (uploadFileProgress.loaded / uploadFileProgress.total) * 100
      : 0;

  const [file, _setFile] = useState(null);

  const setFile = (...args) => {
    reset();
    _setFile(...args);
  };

  const handleHide = () => {
    setFile(null);
    onHide();
  };

  return (
    <Modal show={show} onHide={handleHide} centered backdrop="static">
      <Modal.Header>
        <Modal.Title>Upload SCORM Content</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!file ? (
          <>
            <p>
              To upload SCORM content for {courseModule.internalName}, use the
              box below.
            </p>
            <Dropzone onFile={setFile} accept=".zip">
              Drag &#39;n&#39; drop some files here, or click to select files
            </Dropzone>
          </>
        ) : status === UPLOAD_FILE_STATUSES.INIT ? (
          <>
            <p>
              File selected: <strong>{file.name}</strong> (
              {humanFileSize(file.size)})
            </p>
            <Button variant="secondary" onClick={() => setFile(null)}>
              Reset File
            </Button>
            <Button
              variant="dark"
              onClick={() =>
                uploadFile({
                  file,
                  id: rusticiId,
                  markUploadedPath: markUploadedAdminCourseModulePath,
                  onComplete: () => refreshData(),
                })
              }
              className="ml-2">
              Upload
            </Button>
          </>
        ) : status === UPLOAD_FILE_STATUSES.UPLOAD_STARTED ? (
          <ProgressBar now={now} label={`Uploading (${Math.floor(now)}%)`} />
        ) : status === UPLOAD_FILE_STATUSES.IMPORT_STARTED ? (
          <Alert variant="secondary">
            <i className="fas fa-fw fa-spinner fa-spin mr-1" />
            The file is being processed. Please be patient...
          </Alert>
        ) : status === UPLOAD_FILE_STATUSES.DONE ? (
          <Alert variant="success">
            The file has been successfully uploaded!
          </Alert>
        ) : status === UPLOAD_FILE_STATUSES.FAILED ? (
          <Alert variant="danger">
            <strong>Error:</strong> {error.message}
          </Alert>
        ) : null}
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={
            [
              UPLOAD_FILE_STATUSES.UPLOAD_STARTED,
              UPLOAD_FILE_STATUSES.IMPORT_STARTED,
            ].indexOf(status) !== -1
          }
          variant="secondary"
          onClick={handleHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

UploadModal.propTypes = UploadModalProps;

export default UploadModal;
