import { arrayOf, bool, func, number, shape, string } from 'prop-types';

import {
  TableProps,
  TradingChallengeProps,
  TradingChallengeRegionProps,
} from '../../types';

export const AssignRegionModalProps = {
  isOpen: bool,
  availableCountries: arrayOf(shape(CountryProps)),
  path: string,
  allowedRegions: arrayOf(string),
  toggleShowForm: func,
  refreshData: func,
  onSubmit: func,
  setAvailableCountries: func,
};

export const CountryProps = {
  id: number,
  countryName: string,
  region: string,
  countryCd: string,
};

export const IndexProps = {
  tradingChallenges: arrayOf(TradingChallengeProps),
  adminTradingChallengesPath: string,
  newAdminTradingChallengePath: string,
  adminTradingChallengeRegionsPath: string,
  ...TableProps,
};

export const TableActionsProps = {
  row: shape({ original: shape(TradingChallengeProps) }),
};

export const FormattedDateProps = {
  cell: shape({ value: string }),
};

export const StartCasedProps = {
  cell: shape({ value: string }),
};

export const RegionActionProps = {
  row: shape({ original: shape(TradingChallengeRegionProps) }),
  refreshData: func,
  setAvailableCountries: func,
};

export const RegionTableProps = {
  adminTradingChallengeRegionsPath: string,
  unassignedCountries: arrayOf({
    id: number,
    countryName: string,
    region: string,
    countryCd: string,
  }),
  allowedRegions: arrayOf(string),
  assignRegionsPath: string,
  ...TableProps,
};
