import { Button } from 'react-bootstrap';

import Container from '../../Container';
import DaperImg from '../../DaperImg';
import DateCell from '../../DateCell';
import VimeoPlayer from '../../VimeoPlayer';
import { WebinarProps } from '../../types';

const Show = ({
  name,
  description,
  createdAt,
  active,
  occurredAt,
  durationMins,
  thumbnail,
  slidesDocument,
  vimeoVideoId,
  editAdminWebinarPath,
  webinarSeries,
}) => {
  return (
    <Container>
      <div className="d-flex align-items-center mb-3">
        <h1>{name}</h1>
        <Button className="ml-5" variant="light" href={editAdminWebinarPath}>
          Edit
        </Button>
      </div>

      <p>
        <strong>Created at: </strong> {createdAt}
      </p>

      <p>
        <strong>Active: </strong> {active ? 'Yes' : 'No'}
      </p>

      <p>
        <strong>Occurred At: </strong> <DateCell value={occurredAt} />
      </p>

      <p>
        <strong>Duration (Minutes): </strong> {durationMins}
      </p>

      <p>
        <strong>Thumbnail: </strong>{' '}
        {thumbnail ? <DaperImg metadata={thumbnail} height={150} /> : 'None'}
      </p>

      <p>
        <strong>Slides: </strong>{' '}
        {slidesDocument && (
          <a href={slidesDocument.url} target="_blank" rel="noreferrer">
            {slidesDocument.filename}
          </a>
        )}
      </p>

      <p>
        <strong>Webinar Series: </strong>{' '}
        {webinarSeries && (
          <a href={webinarSeries.adminHref}>{webinarSeries.name}</a>
        )}
      </p>

      <h4>Vimeo Embed:</h4>

      <VimeoPlayer className="w-50" vimeoId={vimeoVideoId} />

      <h4>Description:</h4>
      <p className="text-pre-wrap">{description}</p>
    </Container>
  );
};

Show.propTypes = WebinarProps;

export default Show;
