import {
  Button,
  GlobalStyles,
  Table,
  ThemeProvider,
  Typography,
} from '@bbnpm/bb-ui-framework';
import cn from 'classnames';
import { arrayOf, bool, shape, string } from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useAsyncFn } from 'react-use';

import { get } from '../../api';
import AlertWithIcon from '../AlertWithIcon';
import Container from '../Container';
import Input from '../Input';
import PostForm from '../PostForm';
import { ProductProps } from '../types';

const SKUS = {
  BMCPROFESSIONAL: 'BMCPROFESSIONAL',
  BMCSTUDENT: 'BMCSTUDENT',
  BMCSTUDENTFREE: 'BMCSTUDENTFREE',
};

const toFixed = (value, precision = 0) => {
  const power = Math.pow(10, precision);
  return (Math.round(value * power) / power).toFixed(precision);
};

export const toCurrency = value => `$${toFixed(value, 2)}`;

const generateProductList = ({
  productMap,
  selectedSku,
  user,
  freeViaClassCode,
}) => {
  const bmcProfessionalIsSelected = selectedSku === SKUS.BMCPROFESSIONAL;
  const bmcProfessional = {
    ...productMap[SKUS.BMCPROFESSIONAL],
    name: 'Professional Rate',
    description: (
      <>
        Access to all{' '}
        <a href="/certifications" target="_blank">
          Bloomberg for Education content
        </a>
        . No refunds.
      </>
    ),
    isSelected: bmcProfessionalIsSelected && !freeViaClassCode,
  };

  const bmcStudentIsSelected = selectedSku === SKUS.BMCSTUDENT;
  const bmcStudent = {
    ...productMap[SKUS.BMCSTUDENT],
    name: 'Student Rate',
    description: bmcProfessionalIsSelected ? (
      user.institution ? (
        `${user.institution.name} is not eligible for student rate.`
      ) : (
        `@${
          user.email.split('@')[1]
        } is not registered in our database as an academic e-mail domain. Contact us to register your school's domain.`
      )
    ) : (
      <>
        Access to all{' '}
        <a href="/certifications" target="_blank">
          Bloomberg for Education content
        </a>
        . No refunds.
      </>
    ),
    descriptionClassName: bmcProfessionalIsSelected ? 'text-danger' : '',
    isSelected: bmcStudentIsSelected && !freeViaClassCode,
  };

  const productList = [bmcProfessional, bmcStudent];

  const bmcFreeStudentIsSelected = selectedSku === SKUS.BMCSTUDENTFREE;
  if (bmcFreeStudentIsSelected) {
    productList.push({
      ...productMap[SKUS.BMCSTUDENTFREE],
      name: 'Complimentary',
      description: `${user.institution.name} provides free access to all Bloomberg
      Market Concepts content.`,
      isSelected: true,
    });
  }

  if (freeViaClassCode && !bmcFreeStudentIsSelected) {
    productList.push({
      id: 'complimentary',
      price: 0,
      sku: selectedSku,
      name: 'Complimentary',
      description: `Class code "${freeViaClassCode}" provides free access to all Bloomberg
      Market Concepts content.`,
      isSelected: true,
    });
  }

  return productList;
};

const New = ({
  products,
  selectedSku,
  user,
  isConfirmationRequired,
  findByCodeBatClassesPath,
  findByCodeProductPromoCodesPath,
  paymentOrdersPath,
  ordersPath,
  tosUrl,
}) => {
  const { register, getValues, watch, setValue } = useForm();
  const tosAccepted = watch('tos', false);

  const [{ loading: batClassLoading, value: batClass }, checkClassCode] =
    useAsyncFn(
      async classCode => {
        try {
          return await get(findByCodeBatClassesPath, { classCode });
        } catch (error) {
          setValue('productVariationId', '');
          return {
            errors: [
              `Class code "${classCode}" is invalid, not eligible for use.`,
            ],
          };
        }
      },
      [findByCodeBatClassesPath],
    );

  const handleCheckClassCode = () =>
    checkClassCode(getValues('productVariationId'));

  const [{ loading: promoCodeLoading, value: promoCode }, checkPromoCode] =
    useAsyncFn(
      async code => {
        try {
          return await get(findByCodeProductPromoCodesPath, {
            code,
          });
        } catch (error) {
          setValue('promoCode', '');
          return { errors: [`Promo code "${code}" not found`] };
        }
      },
      [findByCodeProductPromoCodesPath],
    );

  const handleCheckPromoCode = () => checkPromoCode(getValues('promoCode'));

  const productMap = products.reduce((acc, p) => ({ ...acc, [p.sku]: p }), {});

  const productList = generateProductList({
    productMap,
    selectedSku,
    user,
    freeViaClassCode: batClass?.eligibleForFreeRate
      ? batClass?.classCode
      : false,
  });

  const price = productList.find(({ isSelected }) => isSelected).price;
  const discountedPrice = Math.min(
    price,
    promoCode?.discountedPrice ?? Infinity,
  );
  const discount = discountedPrice < price ? price - discountedPrice : 0;

  const isFree = discountedPrice === 0;

  const [Form, action] = isFree
    ? [PostForm, ordersPath]
    : ['form', paymentOrdersPath];

  return (
    <ThemeProvider>
      <GlobalStyles />
      <Container>
        <Typography.LargeTitle>
          Purchase Access to Bloomberg for Education Content
        </Typography.LargeTitle>
        {isConfirmationRequired && (
          <AlertWithIcon variant="warning">
            You cannot place an order until your email is confirmed!
          </AlertWithIcon>
        )}
        <Form action={action}>
          <Row>
            <Col sm={7}>
              {productList.map(
                ({
                  id,
                  sku,
                  price,
                  isSelected,
                  name,
                  description,
                  descriptionClassName,
                }) => (
                  <div
                    className={cn(
                      'border border-dark mb-4 p-3 font-size-sm bg-white',
                      {
                        'opacity-50': !isSelected,
                      },
                    )}
                    key={id}>
                    <h4 className="d-flex align-items-center mb-3">
                      <input
                        className="mr-2"
                        type="radio"
                        defaultChecked={isSelected}
                        defaultValue={sku}
                        disabled={!isSelected}
                        name="productSku"
                      />
                      {name}
                      <span className="ml-3">{toCurrency(price)}</span>
                    </h4>
                    <div className={descriptionClassName}>{description}</div>
                    <Typography.Hint>
                      *Prices are in United States Dollar (USD)
                    </Typography.Hint>
                    <br />
                    <Typography.Hint>
                      *Purchase of Bloomberg for Education does not grant access
                      to the Bloomberg Terminal
                    </Typography.Hint>
                  </div>
                ),
              )}
              <p className="text-right font-size-sm">
                <a href="/certifications">Skip for Now</a>
              </p>
            </Col>
            <Col sm={5}>
              <Input
                ref={register}
                name="productVariationId"
                label="Class Code"
                errors={batClass?.errors}
                helpText={
                  batClass?.classCode &&
                  !batClass.eligibleForFreeRate &&
                  'This class code is not associated with free BMC'
                }
                defaultValue={batClass?.classCode}
                readOnly={batClassLoading || !!batClass?.classCode}
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    handleCheckClassCode();
                  }
                }}
                inputAppendProps={{
                  variant: 'outline-dark',
                  children: batClass?.classCode ? 'Applied' : 'Apply',
                  disabled: batClassLoading || !!batClass?.classCode,
                  onClick: handleCheckClassCode,
                }}
              />

              <Input
                ref={register}
                name="promoCode"
                errors={promoCode?.errors}
                defaultValue={promoCode?.code}
                readOnly={promoCodeLoading || !!promoCode?.code}
                disabled={batClass?.eligibleForFreeRate}
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    handleCheckPromoCode();
                  }
                }}
                inputAppendProps={{
                  variant: 'outline-dark',
                  children: promoCode?.code ? 'Applied' : 'Apply',
                  disabled:
                    promoCodeLoading ||
                    !!promoCode?.code ||
                    batClass?.eligibleForFreeRate,
                  onClick: handleCheckPromoCode,
                }}
              />

              {promoCode && (
                <input
                  type="hidden"
                  name="promoCodeId"
                  defaultValue={promoCode.id}
                />
              )}

              <Table borders="none">
                <tbody>
                  <tr>
                    <td>
                      Access to Bloomberg for Education{' '}
                      {batClass?.freeBmc ? '(Waived)' : ''}
                    </td>
                    <td className="text-right">{toCurrency(price)}</td>
                  </tr>
                  {discount > 0 && (
                    <tr>
                      <td>Promo Code: {promoCode.code}</td>
                      <td className="text-right">-{toCurrency(discount)}</td>
                    </tr>
                  )}
                  <tr className="font-weight-bold">
                    <td>Subtotal</td>
                    <td className="text-right">
                      {toCurrency(discountedPrice)}
                    </td>
                  </tr>
                </tbody>
              </Table>

              {isFree && (
                <>
                  <div className="d-flex justify-content-between font-weight-bold font-size-lg border-top my-4 pt-4">
                    <div>Total</div>
                    <div>{toCurrency(discountedPrice)}</div>
                  </div>
                  <Input
                    ref={register}
                    name="tos"
                    type="checkbox"
                    label={
                      <div className="text-justify">
                        I signify that I have read and agree to the{' '}
                        <a href={tosUrl} target="_blank" rel="noreferrer">
                          Terms of Service
                        </a>
                        , and that I accept immediate access to the Bloomberg
                        Market Concepts course and agree to waive any withdrawal
                        rights available to me under applicable law.
                      </div>
                    }
                  />
                </>
              )}

              <Button
                disabled={isConfirmationRequired || (isFree && !tosAccepted)}
                type="submit"
                kind="primary">
                {isFree ? 'Complete' : 'Check Out'}
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
    </ThemeProvider>
  );
};

New.propTypes = {
  products: arrayOf(shape(ProductProps)).isRequired,
  selectedSku: string.isRequired,
  user: shape({
    email: string.isRequired,
    institution: shape({
      name: string.isRequired,
    }),
  }).isRequired,
  isConfirmationRequired: bool.isRequired,
  findByCodeBatClassesPath: string.isRequired,
  findByCodeProductPromoCodesPath: string.isRequired,
  paymentOrdersPath: string.isRequired,
  ordersPath: string.isRequired,
  tosUrl: string.isRequired,
};

export default New;
