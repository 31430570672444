import { Col, Row } from 'react-bootstrap';

import CardCarousel from '../CardCarousel';
import ClientDate from '../ClientDate';
import Container from '../Container';
import TabGrid from '../TabGrid';
import VimeoPlayer from '../VimeoPlayer';
import { useActiveFilterMemo } from '../utils';
import WebinarCard from './WebinarCard';
import { ShowProps } from './types';

const Show = ({
  id,
  name,
  description,
  occurredAt,
  vimeoVideoId,
  slidesDocument,
  webinarSeries,
  latestWebinars,
  allWebinarSeries,
}) => {
  const activeSeries = useActiveFilterMemo(allWebinarSeries);

  return (
    <Container>
      <VimeoPlayer vimeoId={vimeoVideoId} autoplay />
      <div className="d-flex align-items-center">
        <div className="mb-3">
          <h1 className="mb-0">{name}</h1>
          {webinarSeries?.active && (
            <>
              in{' '}
              <strong>
                <a className="link-unstyled" href={webinarSeries.href}>
                  {webinarSeries.name}
                </a>
              </strong>
            </>
          )}
        </div>
        {slidesDocument && (
          <div className="ml-auto">
            <strong>Download Slides:</strong>
            <a
              href={slidesDocument.url}
              target="_blank"
              rel="noreferrer"
              className="ml-2">
              <i className="fa-file-download fas fa-2x" />
            </a>
          </div>
        )}
      </div>

      <div className="d-flex mb-3">
        <div className="ml-auto">
          <ClientDate date={occurredAt} />
        </div>
      </div>

      {description && <p className="text-pre-wrap">{description}</p>}

      {webinarSeries?.active && (
        <>
          <h2 className="font-weight-normal with-angle-right">
            {webinarSeries.name} Series
          </h2>
          <CardCarousel spans={{ lg: 4, sm: 6 }}>
            {webinarSeries.webinars.map(webinar => (
              <WebinarCard
                key={webinar.id}
                webinar={webinar}
                isCurrent={webinar.id === id}
              />
            ))}
          </CardCarousel>
        </>
      )}

      <h2 className="font-weight-normal with-angle-right">Available Series</h2>
      <TabGrid className="mb-3">
        {activeSeries.map(({ name, id: seriesId, href }) => (
          <TabGrid.Tab
            key={seriesId}
            href={href}
            isCurrent={webinarSeries?.id === seriesId}>
            {name}
          </TabGrid.Tab>
        ))}
      </TabGrid>

      <h2 className="font-weight-normal with-angle-right">Latest Releases</h2>
      <Row>
        {latestWebinars.map(webinar => (
          <Col key={webinar.id} lg={4} sm={6}>
            <WebinarCard webinar={webinar} className="mx-3" />
          </Col>
        ))}
      </Row>
    </Container>
  );
};

Show.propTypes = ShowProps;

export default Show;
