import { Typography } from '@bbnpm/bb-ui-framework';

import { StyledListItem } from './styles';
import { CaseStudiesListProps } from './types';

const CaseStudiesList = ({ professorPacks, professorsCaseStudiesPath }) => (
  <>
    <Typography.PageTitle>
      <strong>Available case studies</strong>
    </Typography.PageTitle>
    {professorPacks.map((pack, i) => (
      <StyledListItem key={pack.id}>
        {i + 1}.{' '}
        <Typography.Link href={`${professorsCaseStudiesPath}/${pack.id}`}>
          {pack.title}
        </Typography.Link>
        <Typography.Text>{pack.description}</Typography.Text>
      </StyledListItem>
    ))}
  </>
);

CaseStudiesList.propTypes = CaseStudiesListProps;

export default CaseStudiesList;
