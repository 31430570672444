import { Modal, Table, Typography } from '@bbnpm/bb-ui-framework';
import { useState } from 'react';
import styled from 'styled-components';

import CheckOrMinusIcon from '../CheckOrMinusIcon';
import { StatusIcon } from '../CommonComponents';
import { PageWrapper } from '../CommonComponents';
import { FlexBox } from '../admin/users/styles';

const StyledRow = styled.tr`
  cursor: pointer;
  &:hover {
    background-color: #e1e1e1;
  }
`;

const TeamModal = ({ isOpen, handleClose, team }) => {
  const {
    teamMembers = [],
    teamName,
    active,
    createdAt,
    captainInfo = {},
  } = team || {};

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      portalContainerId="bfe-root"
      title={'Registration for Team: ' + teamName}>
      <Typography.SubSectionTitle>Team Info</Typography.SubSectionTitle>
      <FlexBox style={{ gap: '8rem' }}>
        <Table>
          <tbody>
            <tr>
              <td>Team Name: </td>
              <td>{teamName}</td>
            </tr>
            <tr>
              <td>Captain UUID: </td>
              <td>{captainInfo.uuid}</td>
            </tr>
          </tbody>
        </Table>
        <Table>
          <tbody>
            <tr>
              <td>Active: </td>
              <td>
                <CheckOrMinusIcon isTrue={active} />
              </td>
            </tr>
            <tr>
              <td>Created At: </td>
              <td>{createdAt}</td>
            </tr>
          </tbody>
        </Table>
      </FlexBox>

      <br />

      <Typography.SubSectionTitle>Team Members</Typography.SubSectionTitle>
      <Table>
        <thead>
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Member Type</th>
            <th>Email</th>
          </tr>
        </thead>
        <tbody>
          {teamMembers.map((member, idx) => (
            <tr key={`tm_modal_${idx}`}>
              <td>{member.firstName}</td>
              <td>{member.lastName}</td>
              <td>{member.memberType}</td>
              <td>{member.email}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Modal>
  );
};

const RegistrationsListPage = ({
  registrations,
  institution,
  tradingChallenge,
}) => {
  const [modalState, setModalState] = useState(null);

  return (
    <PageWrapper width="1140px">
      <TeamModal
        team={modalState}
        isOpen={Boolean(modalState)}
        handleClose={() => setModalState(null)}
      />
      <Typography.PageTitle>
        {institution.name} Registrations for {tradingChallenge.name}
      </Typography.PageTitle>

      <Typography.Text>
        Note: click on row to see full team details
      </Typography.Text>

      <Table hover={true}>
        <thead>
          <tr>
            <th>Team ID</th>
            <th>Team Name</th>
            <th>Captain Name</th>
            <th>Captain UUID</th>
            <th>Professor Name</th>
            <th>Created At</th>
            <th>Is Active</th>
          </tr>
        </thead>
        <tbody>
          {registrations.map((registration, idx) => (
            <StyledRow
              key={`${idx}-registrations-row-key`}
              onClick={() => {
                setModalState(registration);
              }}>
              <td>{registration.id}</td>
              <td>{registration.teamName}</td>
              <td>{registration?.captainInfo.name}</td>
              <td>{registration?.captainInfo.uuid}</td>
              <td>{registration?.professorName}</td>
              <td>{registration?.createdAt}</td>
              <td>
                <StatusIcon value={registration?.active} />
              </td>
            </StyledRow>
          ))}
        </tbody>
      </Table>
    </PageWrapper>
  );
};

export default RegistrationsListPage;
