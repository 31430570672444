import { DatePicker, Typography } from '@bbnpm/bb-ui-framework';
import { Col } from 'react-bootstrap';
import styled from 'styled-components';

import Container from '../../Container';

export const StyledDatePicker = styled(DatePicker)`
  * > hr {
    margin: 6.4px 0;
  }
`;

export const StyledStepperNav = styled.div`
  border-top: ${({ theme }) =>
    `1px solid ${theme.header.colors.navBar.border}`};
  background-color: ${({ theme }) => theme.colors.white};

  bottom: 28px;
  left: 0;
  position: fixed;
  z-index: 5;

  padding: 0.5rem 0;

  text-align: right;
  width: 100%;
`;

export const StyledStepperNavContainer = styled.div`
  margin: 0 auto;
  padding: 0 15px;
  width: 1440px;
  max-width: 100%;

  & > .bbui-button {
    margin-left: 1rem;
  }
`;

export const ButtonGroup = styled.div`
  > .bbui-button {
    margin-right: 1rem;
  }
`;

export const LearnerContainer = styled(Container)`
  padding: 0.5rem 2.375rem;
`;

export const LearnerProgressHeader = styled(Typography.SubSectionTitle)`
  border-bottom: 1px solid ${({ theme }) => theme.tables.colors.border};
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  height: 2rem;
`;

export const LearnerProgressContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 45%;
`;

export const StyledCol = styled(Col)`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const StyledTableTitle = styled.div`
  display: flex;
  justify-content: space-between;
`;

const statusColor = (theme, status) => {
  switch (status) {
    case 'IP':
      return theme.colors.blue[500];
    case 'CO':
      return theme.colors.green[400];
    default:
      return theme.colors.black;
  }
};

export const StatusText = styled(Typography.Hint)`
  color: ${({ theme, status }) => statusColor(theme, status)};
  font-weight: bold;
`;
