import { func, object, string } from 'prop-types';
import { useEffect, useState } from 'react';
import { Button, Col, Form, InputGroup } from 'react-bootstrap';
import { useToggle } from 'react-use';

import { get, post } from '../../api';
import AutosuggestInput from '../AutosuggestInput';
import Spinner from '../Spinner';
import ErrorAlert from './ErrorAlert';

const entityKeyFn = country => country.gec;
const entityValueFn = country => country.name;

const ContactInformationForm = ({
  countriesPath,
  action,
  email,
  loginName,
  setLoginName,
  cellPhone,
  setCellPhone,
  country,
  setCountry,
  nextStep,
}) => {
  const [isSubmitting, toggleIsSubmitting] = useToggle(false);
  const [isErrorPresent, setIsErrorPresent] = useState(false);
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    const getCountries = async () => {
      const { countries } = await get(countriesPath);
      setCountries(countries);
    };
    getCountries();
  }, [countriesPath, setCountries]);

  const handleSubmit = async e => {
    try {
      e.preventDefault();
      toggleIsSubmitting();

      // Dont go back to the service if loginName is already present
      if (!loginName) {
        const response = await post(action, {
          cellPhone: country.countryCode + cellPhone,
        });

        if (response.errors.failure) {
          setIsErrorPresent(true);
        } else {
          setLoginName(response['userLoginName']);
          toggleIsSubmitting();
          nextStep();
        }
      } else {
        toggleIsSubmitting();
        nextStep();
      }
    } catch (error) {
      toggleIsSubmitting();
      setIsErrorPresent(true);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      {isErrorPresent && <ErrorAlert />}
      <Form.Group>
        <Form.Label>Email</Form.Label>
        <Form.Control className="mb-3" defaultValue={email} disabled />
      </Form.Group>
      <Form.Group>
        <Form.Label>
          Mobile Phone
          <span className="text-danger"> *</span>
        </Form.Label>
        <Form.Row>
          <Col>
            <AutosuggestInput
              name="country"
              label={false}
              isFetchAsync={false}
              initialSuggestions={countries}
              entityKeyFn={entityKeyFn}
              entityValueFn={entityValueFn}
              shouldRenderSuggestions={true}
              entity={country}
              onChange={setCountry}
            />
          </Col>
          <Col>
            <InputGroup>
              {country && (
                <InputGroup.Prepend>
                  <InputGroup.Text>+{country.countryCode}</InputGroup.Text>
                </InputGroup.Prepend>
              )}
              <Form.Control
                value={cellPhone}
                placeholder={'xxx-xxx-xxxx'}
                onChange={e => setCellPhone(e.target.value)}
              />
            </InputGroup>
          </Col>
        </Form.Row>
      </Form.Group>

      <Button
        type="submit"
        className="px-4"
        disabled={!country || !cellPhone || isSubmitting}>
        {isSubmitting && <Spinner />}
        {isSubmitting ? ' Processing...' : 'Next'}
      </Button>
    </Form>
  );
};

ContactInformationForm.propTypes = {
  countriesPath: string.isRequired,
  action: string.isRequired,
  email: string.isRequired,
  loginName: string,
  setLoginName: func.isRequired,
  cellPhone: string.isRequired,
  setCellPhone: func.isRequired,
  country: object.isRequired,
  setCountry: func.isRequired,
  nextStep: func.isRequired,
};

export default ContactInformationForm;
