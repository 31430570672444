import { Col, Modal, Row } from 'react-bootstrap';
import TextareaAutosize from 'react-textarea-autosize';
import { useToggle } from 'react-use';

import AutosuggestInput from '../../AutosuggestInput';
import Button from '../../Button';
import Container from '../../Container';
import ImageFileInput from '../../ImageFileInput';
import Input from '../../Input';
import PatchForm from '../../PatchForm';
import PreviewModal from './PreviewModal';
import UploadVideo from './UploadVideo';
import { VideoFormProps } from './types';

const VideoForm = ({
  adminVideosPath,
  rusticiHost,
  rusticiBasePath,
  rusticiToken,
  rusticiCdnHost,
  types,
  adminHref,
  rusticiId,
  name,
  description,
  type,
  durationMins,
  numberOfQuestions,
  uploadedAt,
  active,
  featured,
  editAdminVideoPath,
  markUploadedAdminVideoPath,
  thumbnail,
  series,
  allSeries,
  errors,
}) => {
  const rusticiBaseUrl = rusticiHost + rusticiBasePath;
  const [isPreviewOpen, toggleIsPreviewOpen] = useToggle(false);
  const [isUploadNewOpen, toggleIsUploadNewOpen] = useToggle(false);
  const typeOptions = types.map(s => [s, s]);

  return (
    <Container>
      <div className="d-flex align-items-center mb-3">
        <h1>{uploadedAt ? 'Edit' : 'Upload'} Video</h1>
        <Button className="ml-5" variant="light" href={adminVideosPath}>
          All Videos
        </Button>
      </div>

      {uploadedAt ? (
        <PatchForm
          action={adminHref}
          submitText="Update"
          encType="multipart/form-data">
          <div className="d-flex align-items-center mb-3">
            <Input
              type="switch"
              name="active"
              defaultChecked={active}
              formGroupProps={{ className: 'mb-0' }}
            />
            <Input
              type="switch"
              name="featured"
              label="Featured Video"
              defaultChecked={featured}
              formGroupProps={{ className: 'mb-0 ml-3' }}
            />
            <div className="ml-auto">
              <Button
                icon="video"
                variant="outline-dark"
                onClick={toggleIsPreviewOpen}>
                Preview Video
              </Button>
              <PreviewModal
                show={isPreviewOpen}
                onHide={toggleIsPreviewOpen}
                rusticiId={rusticiId}
                rusticiBaseUrl={rusticiBaseUrl}
                rusticiToken={rusticiToken}
                rusticiCdnHost={rusticiCdnHost}
              />
              <Button
                variant="warning"
                onClick={toggleIsUploadNewOpen}
                className="ml-3">
                Upload New Video
              </Button>
              <Modal
                show={isUploadNewOpen}
                onHide={toggleIsUploadNewOpen}
                centered
                size="xl">
                <Modal.Header closeButton>
                  <Modal.Title>Upload New Video</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                  <UploadVideo
                    rusticiToken={rusticiToken}
                    rusticiBaseUrl={rusticiBaseUrl}
                    adminVideosPath={adminVideosPath}
                    editAdminVideoPath={editAdminVideoPath}
                    rusticiId={rusticiId}
                    mayCreateNewVersion
                  />
                </Modal.Body>
              </Modal>
            </div>
          </div>
          <Input
            name="name"
            required
            defaultValue={name ?? ''}
            errors={errors?.name}
            helpText="The public-facing name of this module"
          />
          <Input
            name="description"
            defaultValue={description ?? ''}
            as={TextareaAutosize}
          />
          <AutosuggestInput
            name="seriesId"
            label="Series"
            isFetchAsync={false}
            shouldRenderSuggestions={true}
            initialSuggestions={allSeries}
            entity={series}
          />

          <ImageFileInput
            name="thumbnail"
            accept=".jpg, .png"
            helpText="upload the largest available version"
            value={thumbnail}
          />
          <Row>
            <Col>
              <Input
                defaultValue={type ?? ''}
                name="type"
                as="select"
                options={typeOptions}
                includeBlank
              />
            </Col>
            <Col>
              <Input
                defaultValue={numberOfQuestions}
                name="numberOfQuestions"
                type="number"
                min={0}
                helpText="How many questions does this module have? (Used for scoring)"
              />
            </Col>
            <Col>
              <Input
                required
                defaultValue={durationMins}
                name="durationMins"
                label="Duration in Minutes"
                type="number"
                min={0}
                helpText="Duration in minutes - used for video display"
              />
            </Col>
          </Row>
        </PatchForm>
      ) : (
        <UploadVideo
          rusticiToken={rusticiToken}
          rusticiBaseUrl={rusticiBaseUrl}
          adminVideosPath={adminVideosPath}
          editAdminVideoPath={editAdminVideoPath}
          rusticiId={rusticiId}
          markUploadedAdminVideoPath={markUploadedAdminVideoPath}
        />
      )}
    </Container>
  );
};
VideoForm.propTypes = VideoFormProps;

export default VideoForm;
