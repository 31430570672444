import {
  Button,
  DataTable,
  FormField,
  Input,
  Typography,
} from '@bbnpm/bb-ui-framework';
import styled from 'styled-components';

import { RequiredLabel } from '../../CommonComponents';
import { TeamMemberFormSectionPropTypes } from '../types';

const TeamMemberTitle = styled.div`
  display: flex;
  align-items: baseline;
`;

export const newTeamMember = member => {
  return {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    department: '',
    title: '',
    memberType: 'member',
    ...member,
  };
};

const TeamMemberFormSection = ({
  teamMembers,
  setTeamMembers,
  formErrors,
  disabled,
}) => {
  const rows = teamMembers.map((teamMember, idx) => {
    const updateTeamMember = (key, val) => {
      const newTeamMembers = [...teamMembers];
      newTeamMembers[idx][key] = val;
      setTeamMembers(newTeamMembers);
    };

    return {
      id: idx,
      data: teamMember,
      updateTeamMember: updateTeamMember,
    };
  });

  return (
    <div id="team-members-section">
      <TeamMemberTitle>
        <Typography.SectionTitle>
          Team Member Information (min: 2, max: 4)
        </Typography.SectionTitle>
        <Button
          className="ml-3"
          disabled={teamMembers.length > 3 || disabled}
          onClick={() => setTeamMembers([...teamMembers, newTeamMember({})])}>
          Add Team Member
        </Button>
      </TeamMemberTitle>

      <DataTable
        columns={[
          {
            columnId: 'firstName',
            headerRenderer: () => <RequiredLabel>First Name</RequiredLabel>,
            dataRenderer: ({ updateTeamMember, data: teamMember, id: idx }) => (
              <FormField
                validationType={
                  formErrors.teamMembers?.[idx + 2]?.firstName && 'error'
                }
                validationContent={
                  formErrors.teamMembers?.[idx + 2]?.firstName &&
                  'First Name' + formErrors.teamMembers?.[idx + 2]?.firstName
                }>
                <Input
                  disabled={disabled}
                  type="text"
                  value={teamMember.firstName}
                  onChange={e => updateTeamMember('firstName', e.target.value)}
                />
              </FormField>
            ),
          },
          {
            columnId: 'lastName',
            headerRenderer: () => <RequiredLabel>Last Name</RequiredLabel>,
            dataRenderer: ({ updateTeamMember, data: teamMember, id: idx }) => (
              <FormField
                validationType={
                  formErrors.teamMembers?.[idx + 2]?.lastName && 'error'
                }
                validationContent={
                  formErrors.teamMembers?.[idx + 2]?.lastName &&
                  'Last Name' + formErrors.teamMembers?.[idx + 2]?.lastName
                }>
                <Input
                  disabled={disabled}
                  type="text"
                  value={teamMember.lastName}
                  onChange={e => updateTeamMember('lastName', e.target.value)}
                />
              </FormField>
            ),
          },
          {
            columnId: 'email',
            headerRenderer: () => (
              <RequiredLabel>Email (use official school email)</RequiredLabel>
            ),
            dataRenderer: ({ updateTeamMember, data: teamMember, id: idx }) => (
              <FormField
                validationType={
                  formErrors.teamMembers?.[idx + 2]?.email && 'error'
                }
                validationContent={
                  formErrors.teamMembers?.[idx + 2]?.email &&
                  'Email' + formErrors.teamMembers?.[idx + 2]?.email
                }>
                <Input
                  disabled={disabled}
                  type="text"
                  value={teamMember.email}
                  onChange={e => updateTeamMember('email', e.target.value)}
                />
              </FormField>
            ),
          },
          {
            columnId: 'delete',
            width: '3rem',
            headerRenderer: () => {},
            dataRenderer: ({ id }) =>
              id >= 2 && (
                <Button
                  icon="trash"
                  kind="destruction"
                  disabled={disabled}
                  onClick={() => {
                    const newTeamMembers = [...teamMembers];
                    newTeamMembers.splice(id, 1);
                    setTeamMembers(newTeamMembers);
                  }}
                />
              ),
          },
        ]}
        rows={rows}
      />
    </div>
  );
};

TeamMemberFormSection.propTypes = TeamMemberFormSectionPropTypes;

export default TeamMemberFormSection;
