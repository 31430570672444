import { Button, Typography } from '@bbnpm/bb-ui-framework';
import { Col, Row } from '@bloomberg/styled-flexboxgrid';
import { useState } from 'react';

import { noop } from '../../../utils';
import { StatusIcon } from '../../CommonComponents';
import PrimaryAdminKey from '../../PrimaryAdminKey';
import Table from '../../Table';
import { useFetchData } from '../../utils';
import LeaderboardActionsCell from './LeaderboardActionsCell';
import { LeaderboardsTableProps } from './types';

const columns = [
  { Header: 'Name', accessor: 'name', Cell: PrimaryAdminKey },
  { Header: 'Week/Position', accessor: 'position' },
  {
    Header: 'Active',
    accessor: 'active',
    disableSortBy: true,
    Cell: StatusIcon,
  },
  { accessor: 'id', disableSortBy: true, Cell: LeaderboardActionsCell },
];

const cb = ({ totalCount, pageSize, leaderboards }) => ({
  data: leaderboards,
  pageSize,
  totalCount,
});

const LeaderboardsTable = ({
  leaderboards,
  path,
  leaderboardTableProps,
  newAdminTradingChallengeLeaderboardPath,
}) => {
  const [{ refreshData }, setRefreshDataFn] = useState({ refreshData: noop });
  const fetchData = useFetchData(path, cb);

  return (
    <>
      <Row className="mt-3 d-flex justify-content-between">
        <Col>
          <Typography.SectionTitle>Leaderboards</Typography.SectionTitle>
        </Col>

        <Col>
          <Button
            kind="primary"
            as="a"
            href={newAdminTradingChallengeLeaderboardPath}>
            Add Leaderboard
          </Button>
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <Table
            columns={columns}
            initialData={leaderboards}
            refreshData={refreshData}
            onRefreshDataFn={setRefreshDataFn}
            fetchData={fetchData}
            initialPageSize={leaderboardTableProps.pageSize}
            initialPageIndex={leaderboardTableProps.pageIndex}
            initialSortBy={leaderboardTableProps.sortBy}
            initialTotalCount={leaderboardTableProps.totalCount}
          />
        </Col>
      </Row>
    </>
  );
};

LeaderboardsTable.propTypes = LeaderboardsTableProps;

export default LeaderboardsTable;
