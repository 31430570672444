import { GlobalStyles, ThemeProvider, Tooltip } from '@bbnpm/bb-ui-framework';

const RequiredForCertificationIcon = () => (
  <ThemeProvider>
    <GlobalStyles />
    <Tooltip content="Required for Certification">
      <i className="fas fa-award ml-3 text-primary" />
    </Tooltip>
  </ThemeProvider>
);

export default RequiredForCertificationIcon;
