import { Button } from '@bbnpm/bb-ui-framework';
import { bool, func, node, string } from 'prop-types';
import { useRef } from 'react';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useToggle } from 'react-use';

import { noop } from '../utils';

export const useAsyncModal = () => {
  const [isOpen, toggleIsOpen] = useToggle(false);
  const [loading, toggleLoading] = useToggle(false);
  const resolveFnRef = useRef();
  const promiseRef = useRef();
  const { register, errors, setError, handleSubmit } = useForm();

  const onSubmit = async values => {
    toggleLoading(true);
    try {
      const result = await new Promise((resolve, reject) =>
        resolveFnRef.current({
          resolve,
          reject,
          values,
        }),
      );
      toggleIsOpen(false);
      promiseRef.current?.resolve?.(result);
    } catch (error) {
      Object.keys(error).forEach(key => setError(key, { message: error[key] }));
    }
    toggleLoading(false);
  };

  const open = (resolveFn = resolveWithValues) => {
    toggleIsOpen(true);
    resolveFnRef.current = resolveFn;
    return new Promise((resolve, reject) => {
      promiseRef.current = { resolve, reject };
    });
  };

  const onHide = () => {
    toggleIsOpen(false);
    promiseRef.current?.reject?.();
  };

  return {
    register,
    errors,
    open,
    modalProps: {
      show: isOpen,
      onHide,
      handleSubmit,
      onSubmit,
      loading,
    },
  };
};

const AsyncModal = ({
  children,
  onHide,
  handleSubmit,
  onSubmit,
  loading,
  title,
  submitText = 'Submit',
  ...modalProps
}) => {
  return (
    <Modal {...modalProps} onHide={loading ? noop : onHide}>
      {title && (
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>{children}</Modal.Body>
        <Modal.Footer>
          <Button disabled={loading} onClick={onHide}>
            Cancel
          </Button>
          <Button disabled={loading} kind="primary" type="submit">
            {submitText}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export const resolveWithValues = ({ resolve, values }) => resolve(values);
export const resolveWithNoValue = ({ resolve }) => resolve();

AsyncModal.propTypes = {
  children: node.isRequired,
  onHide: func.isRequired,
  onSubmit: func.isRequired,
  handleSubmit: func.isRequired,
  loading: bool.isRequired,
  title: node,
  submitText: string,
};

export default AsyncModal;
