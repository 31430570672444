import { number, string } from 'prop-types';
import { ResponsiveEmbed } from 'react-bootstrap';

import { minutesFormatted } from '../utils';
import { daperUrl } from './DaperImg';

const CoverWithDuration = ({ width, daperKey, duration }) => (
  <ResponsiveEmbed
    aspectRatio="16by9"
    style={{
      backgroundImage: daperKey
        ? `url(${daperUrl({
            daperKey: daperKey,
            width,
            height: -1,
          })})`
        : '',
      backgroundSize: 'cover',
    }}>
    <div className="cover-with-duration">{minutesFormatted(duration)}</div>
  </ResponsiveEmbed>
);

CoverWithDuration.propTypes = {
  width: number.isRequired,
  daperKey: string,
  duration: number.isRequired,
};

export default CoverWithDuration;
