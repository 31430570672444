import { useMemo } from 'react';
import { Button, Dropdown } from 'react-bootstrap';

import Container from '../../Container';
import PostForm from '../../PostForm';
import ReversedCheckOrMinusCell from '../../ReversedCheckOrMinusCell';
import Table from '../../Table';
import { useFetchData } from '../../utils';
import { IndexProps, UserDropdownProps } from './types';

const UserDropdown = ({ row }) => (
  <Dropdown drop="left">
    <Dropdown.Toggle variant="outline-dark" className="py-0 border-0" size="sm">
      <i className="fas fa-lg fa-fw fa-ellipsis-h" />
    </Dropdown.Toggle>

    <Dropdown.Menu popperConfig={{ strategy: 'fixed' }}>
      <Dropdown.Item href={row.original.href}>
        <i className="fas fa-user fa-fw mr-2" />
        View User
      </Dropdown.Item>
      <Dropdown.Item href={row.original.editAdminUserPath}>
        <i className="fas fa-edit fa-fw mr-2" />
        Edit User
      </Dropdown.Item>
      <PostForm action={row.original.impersonateAdminUserPath}>
        <Dropdown.Item
          as="button"
          type="submit"
          disabled={row.original.isDeleted}>
          <i className="fas fa-user-secret fa-fw mr-2" />
          Masquerade as User
        </Dropdown.Item>
      </PostForm>
    </Dropdown.Menu>
  </Dropdown>
);

UserDropdown.propTypes = UserDropdownProps;

const columns = [
  { Header: 'ID', accessor: 'userid', filteredAs: 'number' },
  { Header: 'UUID', accessor: 'uuid', filteredAs: 'number' },
  { Header: 'Type', accessor: 'type' },
  { Header: 'First Name', accessor: 'firstName', filteredAs: 'string' },
  { Header: 'Last Name', accessor: 'lastName', filteredAs: 'string' },
  { Header: 'Email', accessor: 'email', filteredAs: 'string' },
  { Header: 'Created', accessor: 'createdAt' },
  { Header: 'Last Login', accessor: 'lastLogin' },
  {
    Header: 'Status',
    accessor: 'isDeleted',
    disableSortBy: true,
    Cell: ReversedCheckOrMinusCell,
  },
  {
    accessor: 'userid',
    id: 'actions',
    disableSortBy: true,
    Cell: UserDropdown,
  },
];

const cb = ({ totalCount, pageSize, users }) => ({
  data: users,
  pageSize,
  totalCount,
});

const scopes = [
  { id: 'isCandidate', title: 'Candidate' },
  { id: 'isHsStudent', title: 'High School Student' },
  { id: 'isProfessor', title: 'Professor' },
  { id: 'isSpecialist', title: 'Specialist' },
  { id: 'isAdmin', title: 'Administrator' },
];

const Index = ({
  adminUsersPath,
  newAdminUserPath,
  users,
  totalCount,
  pageSize,
  pageIndex,
  sortBy,
  adminInstitutionsPath,
  canAddUser,
}) => {
  const fetchData = useFetchData(adminUsersPath, cb);

  const additionalFilters = useMemo(
    () => [
      {
        Header: 'Institution',
        accessor: 'institutionId',
        filteredAs: 'autosuggest',
        path: adminInstitutionsPath,
      },
    ],
    [adminInstitutionsPath],
  );
  return (
    <Container>
      <div className="d-flex align-items-center mb-3">
        <h1>Users</h1>
        {canAddUser && (
          <Button href={newAdminUserPath} className="ml-5" variant="primary">
            Add User
          </Button>
        )}
      </div>

      <Table
        initialData={users}
        initialPageSize={pageSize}
        initialPageIndex={pageIndex}
        initialSortBy={sortBy}
        initialTotalCount={totalCount}
        initialScopes={{
          isCandidate: false,
          isHsStudent: false,
          isProfessor: false,
        }}
        availableScopes={scopes}
        additionalFilters={additionalFilters}
        columns={columns}
        fetchData={fetchData}
      />
    </Container>
  );
};

Index.propTypes = IndexProps;

export default Index;
