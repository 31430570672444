import { Button, Form, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import Input from '../../Input';
import { CourseModuleFormModalProps } from './types';

const CourseModuleFormModal = ({
  show,
  onHide,
  onSubmit,
  courseModule = {},
  courseModuleCategories,
}) => {
  const { register, handleSubmit } = useForm();
  return (
    <Modal show={show} onHide={onHide} centered>
      <Form
        onSubmit={handleSubmit((values, e) => {
          e.preventDefault();
          onSubmit(values);
        })}>
        <Modal.Header closeButton>
          <Modal.Title>
            {courseModule.id ? 'Edit' : 'New'} Course Module
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Input
            required
            ref={register}
            as="select"
            options={courseModuleCategories}
            defaultValue={courseModule?.courseCategory?.id ?? ''}
            name="courseCategoryId"
            label="Category"
          />
          <Input
            required
            ref={register}
            defaultValue={courseModule.name || ''}
            name="name"
            helpText="The public-facing name of this module"
          />
          <Input
            required
            ref={register}
            defaultValue={courseModule.internalName || ''}
            name="internalName"
            helpText="The internal name of this module"
          />
          <Input
            required
            ref={register}
            defaultValue={courseModule.numberOfQuestions || 0}
            name="numberOfQuestions"
            type="number"
            helpText="How many questions does this module have? (Used for scoring)"
          />
          <Input
            required
            ref={register}
            defaultValue={courseModule.durationMins || 0}
            name="durationMins"
            type="number"
            helpText="Module duration in minutes - Used for professor class components)"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Cancel
          </Button>
          <Button variant="primary" type="submit">
            {courseModule.id ? 'Update' : 'Create'}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

CourseModuleFormModal.propTypes = CourseModuleFormModalProps;

export default CourseModuleFormModal;
