import PropTypes from 'prop-types';
import { Fragment } from 'react';

const HiddenInputFieldsClassAssignments = ({
  classAssignments,
  deletionStatusMap,
  assignmentAttributesName,
  assignmentIdName,
}) => {
  return classAssignments.map((assignment, index) => (
    <Fragment key={assignment[assignmentIdName]}>
      <input
        type="hidden"
        name={`${assignmentAttributesName}[${index}][id]`}
        defaultValue={assignment.id || ''}
      />
      <input
        type="hidden"
        name={`${assignmentAttributesName}[${index}][${assignmentIdName}]`}
        defaultValue={assignment[assignmentIdName]}
      />
      <input
        type="hidden"
        name={`${assignmentAttributesName}[${index}][_destroy]`}
        defaultValue={
          deletionStatusMap[assignment[assignmentIdName]] ? '1' : '0'
        }
      />
    </Fragment>
  ));
};

HiddenInputFieldsClassAssignments.propTypes = {
  classAssignments: PropTypes.array.isRequired,
  deletionStatusMap: PropTypes.object.isRequired,
  assignmentAttributesName: PropTypes.string.isRequired,
  assignmentIdName: PropTypes.string.isRequired,
};

export default HiddenInputFieldsClassAssignments;
