import {
  Button,
  GlobalStyles,
  ThemeProvider,
  Typography,
} from '@bbnpm/bb-ui-framework';
import { Row } from '@bloomberg/styled-flexboxgrid';
import startcase from 'lodash.startcase';
import { useMemo, useState } from 'react';
import { useToggle } from 'react-use';

import { destroy, sendAsForm } from '../../../api';
import { BfeGrid } from '../../../styles/grid';
import Table from '../../Table';
import { useFetchData } from '../../utils';
import AssignRegionModal from './AssignRegionModal';
import { RegionActionProps, RegionTableProps, StartCasedProps } from './types';

const StartCased = ({ cell }) => (
  <Typography.Text>{startcase(cell.value)}</Typography.Text>
);
StartCased.propTypes = StartCasedProps;

const Actions = ({ row, refreshData, setAvailableCountries }) => {
  const handleDelete = async () => {
    try {
      const res = await destroy(row.original.adminHref);
      const removedCountry = res.countryCode;
      setAvailableCountries(countries =>
        [...countries, removedCountry].sort((a, b) => a.id - b.id),
      );
      refreshData();
    } catch (error) {
      alert('Something went wrong.');
    }
  };
  return <Button icon="trash" kind="destruction" onClick={handleDelete} />;
};
Actions.propTypes = RegionActionProps;

const columns = [
  {
    Header: 'Country',
    accessor: 'countryCode.countryName',
    disableSortBy: true,
  },
  { Header: 'Country Code ID', accessor: 'countryCodeId' },
  { Header: 'Region', accessor: 'region', Cell: StartCased },
  { accessor: 'id', id: 'actions', disableSortBy: true, Cell: Actions },
];

const cb = ({ totalCount, pageSize, tradingChallengeRegions }) => ({
  data: tradingChallengeRegions,
  pageSize,
  totalCount,
});

const scopes = [
  { id: 'isNorthAmerica', title: 'North America' },
  { id: 'isLatinAmerica', title: 'Latin America' },
  { id: 'isEurope', title: 'Europe' },
  { id: 'isMiddleEastAndAfrica', title: 'Middle East & Africa' },
  { id: 'isAsiaAndOceania', title: 'Asia & Oceania' },
];

const RegionTable = ({
  tradingChallengeRegions,
  totalCount,
  pageSize,
  pageIndex,
  sortBy,
  adminTradingChallengeRegionsPath,
  unassignedCountries,
  allowedRegions,
  assignRegionsPath,
}) => {
  const [showForm, toggleShowForm] = useToggle(false);
  const [availableCountries, setAvailableCountries] =
    useState(unassignedCountries);
  const fetchData = useFetchData(adminTradingChallengeRegionsPath, cb);
  const [{ refreshData }, setRefreshDataFn] = useState({
    refreshData: () => {},
  });
  const additionalFilters = useMemo(
    () => [
      {
        Header: 'Country Name',
        accessor: 'countryCodeCountryName',
        filteredAs: 'string',
      },
    ],
    [],
  );
  const handleAssignRegions = async formData => {
    await sendAsForm(assignRegionsPath, formData, 'post');
  };

  return (
    <ThemeProvider>
      <GlobalStyles />
      <BfeGrid>
        <Row className="d-flex align-items-center justify-content-between">
          <Typography.PageTitle>
            Global Trading Challenge Regions
          </Typography.PageTitle>
          <Button kind="primary" onClick={toggleShowForm}>
            Assign Regions
          </Button>
        </Row>
        <Table
          initialData={tradingChallengeRegions}
          columns={columns}
          initialPageSize={pageSize}
          initialPageIndex={pageIndex}
          initialSortBy={sortBy}
          initialTotalCount={totalCount}
          fetchData={fetchData}
          onRefreshDataFn={setRefreshDataFn}
          refreshData={refreshData}
          availableScopes={scopes}
          additionalFilters={additionalFilters}
          setAvailableCountries={setAvailableCountries}
        />

        <AssignRegionModal
          isOpen={showForm}
          availableCountries={availableCountries}
          toggleShowForm={toggleShowForm}
          path={assignRegionsPath}
          refreshData={refreshData}
          allowedRegions={allowedRegions}
          onSubmit={handleAssignRegions}
          setAvailableCountries={setAvailableCountries}
        />
      </BfeGrid>
    </ThemeProvider>
  );
};

RegionTable.propTypes = RegionTableProps;

export default RegionTable;
