import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import { shape, string } from 'prop-types';
import { useState } from 'react';

import { post } from '../../api';
import { formatISODateLocale, noop } from '../../utils';

const TerminalAccessRequest = ({
  terminalDrInfo,
  requestTerminalAccessUserPath,
}) => {
  const [terminalAccessRequestedAt, setTerminalAccessRequestedAt] = useState(
    terminalDrInfo?.terminal_access_requested_at,
  );

  const requestTerminalAccess = async () => {
    try {
      const res = await post(requestTerminalAccessUserPath);
      setTerminalAccessRequestedAt(
        res.terminalDrInfo?.terminal_access_requested_at,
      );
    } catch (error) {
      alert('Something went wrong. Please try again.');
    }
  };

  return (
    <div className="mb-3 d-flex align-items-center">
      <div onClick={terminalAccessRequestedAt ? noop : requestTerminalAccess}>
        <BootstrapSwitchButton
          width={150}
          disabled={true}
          onlabel="Requested"
          offlabel="Not Requested"
          checked={!!terminalAccessRequestedAt}
          onstyle="primary"
          offstyle="secondary"
        />
      </div>
      {terminalAccessRequestedAt && (
        <div className="ml-3">
          Request Submitted{' '}
          <strong>{formatISODateLocale(terminalAccessRequestedAt)}</strong>
        </div>
      )}
    </div>
  );
};

TerminalAccessRequest.propTypes = {
  requestTerminalAccessUserPath: string.isRequired,
  terminalDrInfo: shape({
    terminalAccessRequestedAt: string,
  }),
};

export default TerminalAccessRequest;
