import { string } from 'prop-types';
import { forwardRef } from 'react';
import { ResponsiveEmbed } from 'react-bootstrap';

const VideoPlayer = forwardRef(({ src, ...rest }, ref) => (
  <ResponsiveEmbed {...rest} aspectRatio="16by9">
    <iframe
      ref={ref}
      className="border-0"
      border="0"
      width="100%"
      height="100%"
      src={src}
      allow="autoplay; fullscreen"
    />
  </ResponsiveEmbed>
));

VideoPlayer.propTypes = {
  src: string.isRequired,
};

VideoPlayer.displayName = 'VideoPlayer';

export default VideoPlayer;
