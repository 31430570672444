import { Fragment, useMemo } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';

import { dateSorter } from '../../utils';
import CardCarousel from '../CardCarousel';
import DaperImg, { daperUrl } from '../DaperImg';
import TabGrid from '../TabGrid';
import VideoCard from './VideoCard';
import { IndexProps } from './types';

const Index = ({ videos }) => {
  const { latestVideos, featuredVideo, activeSeries } = useMemo(() => {
    const { activeVideos, featuredVideo, activeSeries } = videos.reduce(
      (acc, video) => {
        if (video.active) {
          acc.activeVideos.push(video);
          if (video.series?.active) {
            if (!acc.activeSeries[video.series.id]) {
              acc.activeSeries[video.series.id] = {
                ...video.series,
                videos: [video],
              };
            } else {
              acc.activeSeries[video.series.id].videos.push(video);
            }
          }
          if (!acc.featuredVideo && video.featured) acc.featuredVideo = video;
        }
        return acc;
      },
      { activeVideos: [], activeSeries: {} },
    );
    const sortedVideos = [...activeVideos].sort(
      dateSorter({ prop: 'createdAt' }),
    );
    return {
      activeVideos,
      activeSeries: Object.values(activeSeries),
      featuredVideo,
      latestVideos: sortedVideos.slice(0, 3),
    };
  }, [videos]);

  return (
    <>
      <div className="py-5 text-white bg-dark position-relative overflow-hidden">
        {featuredVideo && (
          <div
            style={{
              position: 'absolute',
              top: -5,
              right: -5,
              bottom: -5,
              left: -5,
              opacity: 0.3,
              backgroundImage: featuredVideo.thumbnail?.daperKey
                ? `url(${daperUrl({
                    daperKey: featuredVideo.thumbnail.daperKey,
                    width: 1110 * 2,
                    height: -1,
                  })})`
                : '',
              filter: 'blur(5px)',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          />
        )}
        <Container fluid="xl" className="position-relative">
          <h1>Videos</h1>
          <p className="font-size-lg">
            Bite-sized interactive videos on top themes by Bloomberg and
            industry experts.
          </p>
          {featuredVideo && (
            <div
              className="p-4"
              style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }}>
              <Row>
                <Col>
                  <DaperImg
                    fluid
                    metadata={featuredVideo.thumbnail}
                    width={532 * 2}
                  />
                </Col>
                <Col>
                  <p className="text-muted">Featured</p>
                  <h4>{featuredVideo.name}</h4>
                  <p className="text-pre-wrap">{featuredVideo.description}</p>
                  <Button
                    href={featuredVideo.href}
                    variant="primary"
                    className="mt-5 px-5">
                    Watch
                  </Button>
                </Col>
              </Row>
            </div>
          )}
        </Container>
      </div>
      <Container fluid="xl" className="py-3">
        <h2 className="font-weight-normal with-angle-right">
          Available Series
        </h2>
        <TabGrid className="mb-3">
          {activeSeries.map(({ name, id, href }) => (
            <TabGrid.Tab key={id} href={href}>
              {name}
            </TabGrid.Tab>
          ))}
        </TabGrid>

        <h2 className="font-weight-normal with-angle-right">Latest Releases</h2>
        <Row>
          {latestVideos.map(video => (
            <Col lg={4} sm={6} key={video.id} className="mb-3">
              <VideoCard video={video} />
            </Col>
          ))}
        </Row>

        {activeSeries.map(({ id, name, videos }) => (
          <Fragment key={id}>
            <h2 className="font-weight-normal with-angle-right">{name}</h2>
            <CardCarousel spans={{ lg: 4, sm: 6 }} className="mb-3">
              {videos.map(video => (
                <VideoCard key={video.id} video={video} />
              ))}
            </CardCarousel>
          </Fragment>
        ))}
      </Container>
    </>
  );
};

Index.propTypes = IndexProps;

export default Index;
