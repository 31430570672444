import {
  Button,
  Dropdown,
  FormField,
  Input,
  ThemeProvider,
  Typography,
} from '@bbnpm/bb-ui-framework';
import { useState } from 'react';

import { RequiredLabel } from '../../CommonComponents';
import Container from '../../Container';
import PatchForm from '../../PatchForm';
import PostForm from '../../PostForm';
import { PurchaseEntitlementFormProps } from './types';

const PurchaseEntitlementForm = ({
  id,
  productSku,
  href,
  reason,
  products,
  user,
  adminPurchaseEntitlementsHref,
  errors = {},
}) => {
  const [Form, action, submitText] = id
    ? [PatchForm, href, 'Update']
    : [PostForm, adminPurchaseEntitlementsHref, 'Create'];
  const [selectedSku, setSelectedSku] = useState(
    productSku ? productSku : products[0][0],
  );

  return (
    <ThemeProvider>
      <Container>
        <div className="d-flex align-items-center">
          <Typography.PageTitle>
            {id ? 'Edit' : 'Add'} BMC Type
          </Typography.PageTitle>
          <a href={id ? href : user.href}>
            <Button className="ml-4" kind="secondary">
              Cancel
            </Button>
          </a>
        </div>

        <Typography.Text>
          <strong>Name: </strong>
          {user.fullName}
        </Typography.Text>
        <Typography.Text>
          <strong>Email: </strong>
          {user.email}
        </Typography.Text>
        <Typography.Text>
          <strong>User Type: </strong>
          {user.type}
        </Typography.Text>

        <Form action={action} submitText={submitText}>
          <FormField
            label={<RequiredLabel>Product Sku</RequiredLabel>}
            validationType={errors.productSku && 'error'}
            validationContent={errors.productSku}>
            <Dropdown
              options={products.map(([k, v]) => ({
                label: k,
                value: v,
              }))}
              onItemSelect={selected => setSelectedSku(selected?.value)}
              defaultValue={selectedSku}
            />
            <input type="hidden" name="productSku" defaultValue={selectedSku} />
          </FormField>
          <FormField
            label="Reason"
            validationType={errors.reason && 'error'}
            validationContent={errors.reason}
            className="mb-2">
            <Input defaultValue={reason || ''} name="reason" />
          </FormField>
          <input type="hidden" name="userId" defaultValue={user.userid} />
        </Form>
      </Container>
    </ThemeProvider>
  );
};

PurchaseEntitlementForm.propTypes = PurchaseEntitlementFormProps;

export default PurchaseEntitlementForm;
