import { Button, Typography } from '@bbnpm/bb-ui-framework';
import { arrayOf, number, oneOfType, shape, string } from 'prop-types';
import { useState } from 'react';
import { useToggle } from 'react-use';

import { create } from '../../api';
import { noop } from '../../utils';
import AsyncModal, { useAsyncModal } from '../AsyncModal';
import Input from '../Input';
import PrimaryProfessorKey from '../PrimaryProfessorKey';
import Table from '../Table';
import { BatClassProps } from '../types';
import { useFetchData } from '../utils';
import { AddClassButton, StyledLink, TitleFooterItem } from './styles';

const PrimaryKey = ({ row, value }) => (
  <StyledLink title="See details" href={row.original.href}>
    {value}
  </StyledLink>
);

const columns = [
  {
    Header: 'ID',
    accessor: 'classCode',
    disableSortBy: true,
    Cell: PrimaryKey,
  },
  {
    Header: 'Name',
    accessor: 'nameAlias',
    disableSortBy: true,
  },
];

const professorColumns = [
  {
    Header: 'Class',
    accessor: 'nameAlias',
    disableSortBy: true,
    Cell: PrimaryProfessorKey,
  },
  {
    Header: 'Status',
    accessor: 'status',
    disableSortBy: true,
  },
];

const cb = ({ totalCount, pageSize, batClasses: data }) => ({
  data: findLatest(data),
  pageSize,
  totalCount,
});

const findLatest = data => data.toReversed().slice(0, 2);

const LatestEnrollments = ({
  type,
  batClasses,
  batClassesPath,
  createWithClassCodeClassEnrollmentsPath,
  professorNewClassPath,
}) => {
  const [hasClasses, toggleHasClasses] = useToggle(batClasses.length > 0);
  const [{ refreshData }, setRefreshDataFn] = useState({ refreshData: noop });
  const { register, errors, open, modalProps } = useAsyncModal();
  const fetchData = useFetchData(batClassesPath, cb);

  const handleAddClass = async () => {
    await open(async ({ resolve, reject, values: { classCode } }) => {
      try {
        resolve(
          await create(createWithClassCodeClassEnrollmentsPath, { classCode }),
        );
        toggleHasClasses(true);
        refreshData();
      } catch (error) {
        reject({
          classCode:
            error?.response?.data?.errors?.batClass?.[0] ??
            'Something went wrong. Try again or use different code',
        });
      }
    });
    refreshData();
  };

  const displayTitle = type => {
    switch (type) {
      case 'Professor':
        return (
          <div className="d-flex align-items-baseline">
            <Typography.SectionTitle>Classes</Typography.SectionTitle>
            &nbsp; &nbsp;
            {batClasses.length > 0 && (
              <StyledLink href={professorNewClassPath}>Create Class</StyledLink>
            )}
          </div>
        );
      case 'Candidate':
      default:
        return (
          <div className="d-flex align-items-baseline">
            <Typography.SectionTitle>
              Latest Class Enrollments
            </Typography.SectionTitle>
            &nbsp;
            {batClasses.length > 0 && (
              <AddClassButton onClick={handleAddClass} kind="tertiary">
                Add Class
              </AddClassButton>
            )}
          </div>
        );
    }
  };

  const typeSpecificButton = type => {
    // Primary buttons when no classes
    switch (type) {
      case 'Professor':
        return (
          <Button kind="primary" as="a" href={professorNewClassPath}>
            Create Class
          </Button>
        );
      case 'Candidate':
      default:
        return (
          <Button kind="primary" onClick={handleAddClass}>
            Add Class
          </Button>
        );
    }
  };

  return (
    <TitleFooterItem className="col-md-6">
      {displayTitle(type)}
      <div className="col-6 px-0">
        <Table
          size="sm"
          columns={type === 'Professor' ? professorColumns : columns}
          initialData={findLatest(batClasses)}
          fetchData={fetchData}
          onRefreshDataFn={setRefreshDataFn}
          variant="revamp"
        />
        {!hasClasses && typeSpecificButton(type)}

        <AsyncModal {...modalProps} title="Add Class">
          <Input
            ref={register}
            required
            name="classCode"
            errors={errors?.classCode ? [errors.classCode.message] : []}
          />
        </AsyncModal>
      </div>
    </TitleFooterItem>
  );
};

LatestEnrollments.propTypes = {
  type: string.isRequired,
  batClassesPath: string,
  batClasses: arrayOf(shape(BatClassProps)).isRequired,
  createWithClassCodeClassEnrollmentsPath: string,
  professorNewClassPath: string,
};

PrimaryKey.propTypes = {
  row: shape({
    original: shape({
      href: string.isRequired,
    }).isRequired,
  }).isRequired,
  value: oneOfType([string, number]).isRequired,
};

export default LatestEnrollments;
