import { Button, Modal, Typography } from '@bbnpm/bb-ui-framework';
import { useToggle } from 'react-use';

import { destroy } from '../../../api';
import {
  DeleteRegistrationModalProps,
  RegistrationActionCellProps,
} from './types';

const RegistrationActionsCell = ({ row, refreshData }) => {
  const [showDeleteModal, toggleShowDeleteModal] = useToggle(false);

  return (
    <div className="d-flex justify-content-end">
      <Button kind="destruction" icon="trash" onClick={toggleShowDeleteModal} />

      <DeleteRegistrationModal
        row={row}
        showDeleteModal={showDeleteModal}
        toggleShowDeleteModal={toggleShowDeleteModal}
        refreshData={refreshData}
      />
    </div>
  );
};

RegistrationActionsCell.propTypes = RegistrationActionCellProps;

export default RegistrationActionsCell;

const DeleteRegistrationModal = ({
  row,
  showDeleteModal,
  toggleShowDeleteModal,
  refreshData,
}) => {
  const handleDelete = async () => {
    try {
      await destroy(row.original.adminHref);
      refreshData();
    } catch (error) {
      alert('Something went wrong.');
    }
    toggleShowDeleteModal();
  };
  return (
    <Modal
      portalContainerId="bfe-root"
      title={`Delete Registration: ${row.original.teamName}`}
      isOpen={showDeleteModal}
      primaryActions={
        <>
          <Button kind="secondary" onClick={toggleShowDeleteModal}>
            Cancel
          </Button>
          <Button kind="destruction" onClick={handleDelete}>
            Delete
          </Button>
        </>
      }>
      <Typography.Text>
        Deleting this leaderboard will delete all records associated with{' '}
        <strong>{row.original.teamName}</strong>. Please click delete to
        confirm.
      </Typography.Text>
    </Modal>
  );
};

DeleteRegistrationModal.propTypes = DeleteRegistrationModalProps;
