import { number, oneOfType, shape, string } from 'prop-types';

const PrimaryAdminKey = ({ row, value }) => (
  <a title="See details" href={row.original.adminHref}>
    {value}
  </a>
);

PrimaryAdminKey.propTypes = {
  row: shape({
    original: shape({
      adminHref: string.isRequired,
    }).isRequired,
  }).isRequired,
  value: oneOfType([string, number]).isRequired,
};

export default PrimaryAdminKey;
