import {
  Autocomplete,
  FormField,
  Input,
  Typography,
  useAsyncAutocomplete,
} from '@bbnpm/bb-ui-framework';
import { useState } from 'react';

import { get } from '../../../../api';
import { RequiredLabel } from '../../../CommonComponents';
import { FlexGrowCol, StyledRow } from '../styles';

const CaptainSection = ({
  captainInfo,
  setCaptainInfo,
  adminUsersPath,
  adminCaptainDetailsPath,
  disabled,
}) => {
  const [institution, setInstitution] = useState(null);

  const fetchUserByUuid = async uuid => {
    if (!uuid) return [];
    const { users } = await get(adminUsersPath, { uuid_eq: uuid });
    return users;
  };

  const getCaptainDetails = async userid => {
    const { email, institution } = await get(adminCaptainDetailsPath, {
      userid,
    });
    return { email, institution };
  };

  const [asyncAutocompleteProps] = useAsyncAutocomplete({
    initialOptions: [],
    fetchOptions: fetchUserByUuid,
    onFetchSuccess: users => {
      return users.map(user => {
        if (!user) return null;

        return { label: user.uuid.toString(), value: user };
      });
    },
  });

  return (
    <>
      <Typography.SectionTitle>Captain Information</Typography.SectionTitle>
      <StyledRow>
        <FlexGrowCol>
          <FormField label={<RequiredLabel>Captain UUID</RequiredLabel>}>
            <Autocomplete
              placeholder={'Search user by UUID'}
              minChar={0}
              {...asyncAutocompleteProps}
              disabled={disabled}
              onItemSelect={async item => {
                const { value: user } = item;
                const { email, institution } = await getCaptainDetails(
                  user.userid,
                );

                setCaptainInfo({
                  memberType: 'captain',
                  email,
                  firstName: user.firstName,
                  lastName: user.lastName,
                });
                setInstitution(institution);
              }}
            />
          </FormField>
        </FlexGrowCol>

        <FlexGrowCol>
          <FormField
            disableAutoMargin={true}
            label={<RequiredLabel>First Name</RequiredLabel>}>
            <Input
              type="text"
              defaultValue={captainInfo?.firstName || ''}
              disabled
              required
            />
          </FormField>
        </FlexGrowCol>

        <FlexGrowCol>
          <FormField
            disableAutoMargin={true}
            label={<RequiredLabel>Last Name</RequiredLabel>}>
            <Input
              type="text"
              defaultValue={captainInfo?.lastName || ''}
              disabled
              required
            />
          </FormField>
        </FlexGrowCol>

        <FlexGrowCol>
          <FormField
            disableAutoMargin={true}
            label={<RequiredLabel>Email</RequiredLabel>}>
            <Input
              type="text"
              defaultValue={captainInfo?.email || ''}
              disabled
            />
          </FormField>
        </FlexGrowCol>

        <FlexGrowCol>
          <FormField
            disableAutoMargin={true}
            label={<RequiredLabel>Institution</RequiredLabel>}>
            <Input
              type="text"
              defaultValue={institution?.name || ''}
              disabled
              required
            />
          </FormField>
        </FlexGrowCol>
      </StyledRow>
    </>
  );
};

export default CaptainSection;
