import { Button } from 'react-bootstrap';

import Container from '../../Container';
import DeleteButton from '../../DeleteButton';
import PrimaryKey from '../../PrimaryKey';
import Table from '../../Table';
import { ShowProps } from './types';

const columns = [
  { Header: 'ID', accessor: 'id', Cell: PrimaryKey },
  { Header: 'Order', accessor: 'listOrder' },
  { Header: 'Title', accessor: 'title' },
  { Header: 'Created At', accessor: 'createdAt' },
];

const Show = ({
  title,
  sectionType,
  createdAt,
  professorPack,
  href,
  editAdminPackSectionPath,
  packResources,
  newAdminPackSectionPackResourcePath,
}) => (
  <Container>
    <div className="d-flex align-items-center">
      <h1>{title}</h1>
      <Button
        className="ml-4"
        variant="outline-dark"
        href={editAdminPackSectionPath}
        size="sm">
        Edit Case Study Section
      </Button>

      <DeleteButton size="sm" className="ml-2" variant="danger" action={href}>
        <i className="fas fa-eraser mr-2" />
        Delete
      </DeleteButton>
    </div>

    <p>
      Created at {createdAt}. Part of{' '}
      <a href={professorPack.adminHref}>{professorPack.title}</a>
    </p>

    <p>Type: {sectionType}</p>

    <div className="d-flex align-items-center mb-3">
      <h4 className="m-0">Case Study Resources</h4>
      <Button
        className="ml-4"
        variant="outline-dark"
        href={newAdminPackSectionPackResourcePath}
        size="sm">
        New Case Study Resource
      </Button>
    </div>

    <Table
      columns={columns}
      initialData={packResources}
      initialSortBy="listOrder ASC"
    />
  </Container>
);

Show.propTypes = ShowProps;

export default Show;
