import { ToggleButtonBar } from '@bbnpm/bb-ui-framework';

import { TableScopesProps } from './types';

const TableScopes = ({ availableScopes, scopes, onScopesSet }) => {
  const toggle = id =>
    onScopesSet(
      availableScopes.reduce(
        (acc, { id: _id }) => ({
          ...acc,
          [_id]: id === _id ? !scopes[id] : false,
        }),
        {},
      ),
    );

  return availableScopes.length ? (
    <ToggleButtonBar
      defaultSelectedValue={'all'}
      onChange={value => (value === 'all' ? toggle() : toggle(value))}
      options={[
        { label: 'All', value: 'all' },
        ...availableScopes.map(({ id, title }) => ({
          label: title,
          value: id,
        })),
      ]}
    />
  ) : null;
};

TableScopes.propTypes = TableScopesProps;

export default TableScopes;
