import { exact, number, oneOf, string } from 'prop-types';
import { useRef } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useFullscreen, useToggle } from 'react-use';

import Container from '../Container';
import PostForm from '../PostForm';
import VideoPlayer from '../VideoPlayer';

const PrevNext = ({ name, action, dir }) =>
  action ? (
    <PostForm
      action={action}
      submitProps={{
        className: 'btn-block',
        variant: 'outline-dark',
      }}
      submitText={
        dir === 'left' ? (
          <>
            <i className="fas fa-caret-left mr-2" /> Previous Module: {name}
          </>
        ) : (
          <>
            Next Module: {name} <i className="fas fa-caret-right ml-2" />
          </>
        )
      }
    />
  ) : (
    <Button className="btn-block" variant="outline-dark" disabled>
      {dir === 'left' && <i className="fas fa-caret-left mr-2" />}
      No {dir === 'left' ? 'Previous' : 'Next'} Module
      {dir === 'right' && <i className="fas fa-caret-right ml-2" />}
    </Button>
  );

const prevNextProps = {
  name: string,
  action: string,
};

PrevNext.propTypes = {
  ...prevNextProps,
  dir: oneOf(['left', 'right']).isRequired,
};

const Watch = ({
  courseVersion,
  courseModule,
  launchLink,
  next = {},
  prev = {},
}) => {
  const ref = useRef(null);
  const [show, toggle] = useToggle(false);
  const isFullscreen = useFullscreen(ref, show, {
    onClose: () => toggle(false),
  });
  return (
    <Container>
      <div className="d-flex align-items-center">
        <h1 className="mb-0">
          {courseVersion.name}: {courseModule.name}
        </h1>
        {!isFullscreen && (
          <Button
            onClick={() => toggle(true)}
            className="ml-auto"
            variant="outline-dark">
            <i className="fas fa-expand mr-1" /> Fullscreen
          </Button>
        )}
        <Button href={courseVersion.path} className="ml-2" variant="light">
          Go Back
        </Button>
      </div>
      <VideoPlayer ref={ref} src={launchLink} />
      <Row>
        <Col>
          <PrevNext {...prev} dir="left" />
        </Col>
        <Col>
          <PrevNext {...next} dir="right" />
        </Col>
      </Row>
    </Container>
  );
};

Watch.propTypes = {
  courseVersion: exact({
    id: number.isRequired,
    name: string.isRequired,
    path: string.isRequired,
  }).isRequired,
  courseModule: exact({
    id: number.isRequired,
    name: string.isRequired,
  }).isRequired,
  launchLink: string.isRequired,
  next: exact(prevNextProps),
  prev: exact(prevNextProps),
};

export default Watch;
