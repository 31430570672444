import cn from 'classnames';
import { shape, string } from 'prop-types';
import { useRef } from 'react';
import { Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import Button from './Button';
import Input from './Input';
import { FileProps } from './types';

const FileInput = ({ name, value, helpText, ...rest }) => {
  const inputRef = useRef(null);

  const { register, watch } = useForm();
  const files = watch(name);

  const fileChosen = files && files[0];

  return (
    <Input
      ref={ref => {
        inputRef.current = ref;
        register(ref);
      }}
      type="file"
      name={name}
      helpText={!fileChosen && !value && helpText}
      inputRender={inputProps => (
        <>
          <Form.Control
            {...inputProps}
            className={cn({ 'd-none': fileChosen || value })}
          />
          {(fileChosen || value) && (
            <div className="d-flex align-items-center">
              <i className="fa-file-alt fas fa-4x mr-3" />
              <div>
                <p>
                  {fileChosen ? (
                    files[0].name
                  ) : (
                    <a href={value.url} target="_blank" rel="noreferrer">
                      {value.filename}
                    </a>
                  )}
                </p>
                <Button
                  onClick={() => inputRef.current.click()}
                  size="sm"
                  className="mr-2"
                  variant="outline-dark">
                  Change
                </Button>
                <span className="font-size-sm text-muted">{helpText}</span>
              </div>
            </div>
          )}
        </>
      )}
      {...rest}
    />
  );
};
FileInput.propTypes = {
  name: string.isRequired,
  url: string,
  value: shape(FileProps),
  helpText: string,
};

export default FileInput;
