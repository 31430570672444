import {
  DataTable,
  GlobalStyles,
  ThemeProvider,
  Typography,
} from '@bbnpm/bb-ui-framework';
import { createSlice } from '@reduxjs/toolkit';
import dequal from 'dequal';
import { arrayOf, bool, shape, string } from 'prop-types';
import {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import { Row } from 'react-bootstrap';
import { usePagination, useSortBy, useTable } from 'react-table';
import { useFirstMountState, usePrevious } from 'react-use';

import { Expander } from '../../CollapsibleTable';
import TableControls from '../../Table/TableControls';
import TableDropdown from '../../Table/TableDropdown';
import { TableProps } from '../../Table/types';
import useFilterPlugin from '../../Table/useFilterPlugin';
import useTableScopePlugin from '../../Table/useTableScopePlugin';
import Tooltip from '../../Tooltip';
import { useFetchData } from './../../utils';
import {
  LearnerContainer,
  LearnerProgressContainer,
  LearnerProgressHeader,
  StatusText,
  StyledCol,
} from './ClassForm.styles';

const initialState = { isLoading: false };

const cb = ({ totalCount, pageSize, learners }) => ({
  data: learners,
  pageSize,
  totalCount,
});

const { reducer, actions } = createSlice({
  name: 'data',
  initialState,
  reducers: {
    dataFetched(state) {
      state.isLoading = true;
    },
    dataReceived(state, { payload }) {
      const { data = [], pageSize, totalCount } = payload;
      Object.assign(state, {
        data,
        totalCount,
        isLoading: false,
        initialPageCount: Math.ceil(totalCount / pageSize),
      });
    },
  },
});

const validFiltersFor = filters =>
  filters.reduce(
    (acc, { id, predicate, value }) =>
      id && predicate && value
        ? {
            ...acc,
            [`${id}_${predicate}`]: value,
          }
        : acc,
    {},
  );

const toParams = ({ scopes, filters, pageIndex, pageSize, sortBy }) => {
  const params = {
    ...scopes,
    ...validFiltersFor(filters),
    pageIndex: pageIndex + 1,
    pageSize,
  };
  if (sortBy.length) {
    params.sortBy = `${sortBy[0].id} ${sortBy[0].desc ? 'DESC' : 'ASC'}`;
  }
  return params;
};

const shouldResetPageIndex = (state, prevState) => {
  return !dequal(
    { ...toParams(state), pageIndex: null },
    { ...toParams(prevState), pageIndex: null },
  );
};

const ReportTable = memo(
  ({
    columns,
    fetchPath,
    initialData,
    initialPageSize = 10,
    initialPageIndex = 1,
    initialSortBy = '',
    initialTotalCount = initialData ? initialData.length : 0,
    initialScopes = {},
    availableScopes = [],
    additionalFilters = [],
    onRefreshDataFn,
  }) => {
    const fetchData = useFetchData(fetchPath, cb);

    const [openRows, setOpenRows] = useState([]);
    const toggleOpenRow = rowId =>
      setOpenRows(rows =>
        rows.includes(rowId)
          ? rows.filter(id => id !== rowId)
          : [...rows, rowId],
      );

    const [{ data, initialPageCount, totalCount, isLoading }, dispatch] =
      useReducer(
        reducer,
        reducer(
          initialState,
          actions.dataReceived({
            data: initialData,
            pageSize: initialPageSize,
            totalCount: initialTotalCount,
          }),
        ),
      );

    const [id, dir] = initialSortBy.split(' ');
    const sortByObj = { id, desc: (dir || '').toUpperCase() === 'DESC' };

    const {
      headerGroups,
      page,
      pageCount,
      canPreviousPage,
      canNextPage,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      state: tableState,
      setFilters,
      setScopes,
    } = useTable(
      {
        initialState: {
          pageSize: initialPageSize,
          pageIndex: initialPageIndex - 1,
          sortBy: sortByObj.id ? [sortByObj] : [],
          filters: [],
          scopes: initialScopes,
        },

        stateReducer: (state, action, prevState) => {
          if (shouldResetPageIndex(state, prevState)) {
            return { ...state, pageIndex: 0 };
          } else {
            return state;
          }
        },

        columns,
        data,

        // Sorting options
        manualSortBy: !!fetchData,
        autoResetSortBy: false,
        disableSortRemove: true,
        disableMultiSort: true,

        // Pagination options
        manualPagination: !!fetchData,
        autoResetPage: false,
        pageCount: initialPageCount,
      },
      useSortBy,
      usePagination,
      useFilterPlugin,
      useTableScopePlugin,
    );

    const { pageSize, pageIndex, sortBy, filters, scopes } = tableState;

    const params = useMemo(
      () => toParams({ scopes, filters, pageIndex, pageSize, sortBy }),
      [scopes, filters, pageIndex, pageSize, sortBy],
    );
    const prevParams = usePrevious(params);

    const refreshData = useCallback(async () => {
      dispatch(actions.dataFetched());
      const data = await fetchData(params);
      dispatch(actions.dataReceived(data));
    }, [dispatch, fetchData, params]);

    useEffect(() => {
      if (onRefreshDataFn) {
        onRefreshDataFn({ refreshData });
      }
    }, [onRefreshDataFn, refreshData]);

    const isFirstRun = useFirstMountState();
    const hasInitialData = Array.isArray(initialData);
    const needsInitialData = isFirstRun && !hasInitialData;

    useEffect(() => {
      const paramsChanged = prevParams && !dequal(params, prevParams);
      if (fetchData && (paramsChanged || needsInitialData)) {
        refreshData();
      }
    }, [params, prevParams, needsInitialData, fetchData, refreshData]);

    // This is for when the component is given a new `fetchData` fn
    // This should cause `refreshData` to be called
    const prevFetchData = usePrevious(fetchData);
    useEffect(() => {
      if (!isFirstRun && fetchData && fetchData !== prevFetchData) {
        refreshData();
      }
    }, [prevFetchData, fetchData, refreshData, isFirstRun]);

    const tooltipStatus = status => {
      switch (status) {
        case 'NS':
          return 'Not Started';
        case 'IP':
          return 'In Progress';
        case 'CO':
          return 'Completed';
      }
    };

    const rows = page.flatMap(({ original }, index) => {
      const rowId = String(index);
      return [
        {
          rowId,
          data: { ...original },
        },
        ...(openRows.includes(rowId)
          ? [
              {
                rowId: `child-${index}}`,
                data: { ...original },
                rowRenderer: ({ rowConfig: { data } }) => {
                  return (
                    <LearnerContainer>
                      <Row>
                        <StyledCol xs="6">
                          <LearnerProgressHeader>
                            Courses Status
                          </LearnerProgressHeader>
                          {data.courseStatus.map(({ name, status }) => (
                            <LearnerProgressContainer key={name}>
                              <Typography.Hint>{name}</Typography.Hint>
                              &nbsp;
                              {typeof status === 'number' ? (
                                <StatusText>{status + '%'}</StatusText>
                              ) : (
                                <Tooltip tooltip={tooltipStatus(status)}>
                                  <StatusText status={status}>
                                    {status}
                                  </StatusText>
                                </Tooltip>
                              )}
                            </LearnerProgressContainer>
                          ))}
                        </StyledCol>
                        <StyledCol xs="6">
                          <LearnerProgressHeader>
                            Videos Status
                          </LearnerProgressHeader>
                          {data.videoStatus.map(({ name, status }) => (
                            <LearnerProgressContainer key={name}>
                              <Typography.Hint>{name}</Typography.Hint>
                              &nbsp;
                              <Tooltip tooltip={tooltipStatus(status)}>
                                <StatusText status={status}>
                                  {status}
                                </StatusText>
                              </Tooltip>
                            </LearnerProgressContainer>
                          ))}
                        </StyledCol>
                      </Row>
                    </LearnerContainer>
                  );
                },
              },
            ]
          : []),
      ];
    });

    const mergedColumns = [
      {
        columnId: 'expander',
        headerRenderer: () => '',
        dataRenderer: ({ rowId }) => {
          const isChild = rowId.includes('child');
          return !isChild ? (
            <Expander
              expanded={openRows.includes(rowId)}
              onClick={() => toggleOpenRow(rowId)}
            />
          ) : (
            ''
          );
        },
        width: '32px',
      },
      {
        columnId: 'firstName',
        headerRenderer: () => 'First Name',
      },
      { columnId: 'lastName', headerRenderer: () => 'Last Name' },
      { columnId: 'createdAt', headerRenderer: () => 'Signed Up' },
      {
        columnId: 'status',
        headerRenderer: () => 'Status',
      },
    ].map(column => {
      headerGroups &&
        headerGroups.forEach(({ headers }) => {
          headers.forEach(header => {
            if (column.columnId === header.id && header.canSort) {
              column.onSort = sortDirection =>
                header.toggleSortBy(sortDirection === 'DESC');
              column.sortDirections = ['ASC', 'DESC'];
            }
          });
        });

      return column;
    });
    return (
      <ThemeProvider>
        <GlobalStyles />
        <div className="d-flex flex-column align-items-stretch">
          <TableControls
            {...{
              columns,
              pageCount,
              totalCount,
              page,
              canPreviousPage,
              canNextPage,
              gotoPage,
              nextPage,
              previousPage,
              setPageSize,
              pageSize,
              pageIndex,
              isLoading,
              filters,
              onFiltersSet: setFilters,
              availableScopes,
              scopes,
              onScopesSet: setScopes,
              additionalFilters,
            }}
          />

          <div className="w-100 overflow-auto">
            <DataTable
              defaultSortBy="lastName"
              columns={mergedColumns}
              rows={rows}
            />
          </div>
        </div>
      </ThemeProvider>
    );
  },
);

ReportTable.displayName = 'Table';

ReportTable.Dropdown = TableDropdown;

ReportTable.propTypes = {
  ...TableProps,
  columns: arrayOf(
    shape({ Header: string, accessor: string, disableSortBy: bool }),
  ),
  fetchPath: string,
};

export default ReportTable;
