import {
  GlobalStyles,
  Icon, // ProgressSpinner,
  ThemeProvider,
  Typography,
} from '@bbnpm/bb-ui-framework';
import { Col, Row } from '@bloomberg/styled-flexboxgrid';
import { number, shape, string } from 'prop-types';
import styled from 'styled-components';

import { BfeGrid } from '../../styles/grid';

const StyledContainer = styled.div`
  background-color: white;
  padding: 2rem;
`;

const courseTitlePalette = ({ theme, courseTitle }) => {
  switch (courseTitle) {
    case 'Bloomberg Market Concepts':
      return theme.colors.amber[600];

    case 'Environmental Social Governance':
      return theme.colors.green[600];

    default:
      return theme.colors.amber[600];
  }
};

const TextColorSwitcher = styled.span`
  color: ${({ courseTitle, theme }) =>
    courseTitlePalette({ courseTitle, theme })};
`;

const Show = ({ fullName, certifiedAt, certificateId, courseCategory }) => (
  <ThemeProvider>
    <GlobalStyles />
    <div className="bg-polka-dots">
      <BfeGrid>
        <Row>
          <Col xs={12} lg={8} lgOffset={2}>
            <StyledContainer>
              <Typography.LargeTitle>
                {courseCategory.title}
              </Typography.LargeTitle>
              <Typography.LargeText>
                {courseCategory.longDescription}
              </Typography.LargeText>
              <hr />
              <Typography.Text>
                This is to acknowledge that{' '}
                <strong>
                  <TextColorSwitcher courseTitle={courseCategory.title}>
                    {fullName}
                  </TextColorSwitcher>
                </strong>{' '}
                has completed courses required for{' '}
                {courseCategory.name.toUpperCase()} certification on{' '}
                <TextColorSwitcher courseTitle={courseCategory.title}>
                  {certifiedAt}
                </TextColorSwitcher>
                .
              </Typography.Text>
              <Typography.Text>
                Certificate ID:{' '}
                <TextColorSwitcher courseTitle={courseCategory.title}>
                  {certificateId}
                </TextColorSwitcher>
              </Typography.Text>

              <object
                type="application/pdf"
                data={
                  `/certificates/${certificateId}/generate_preview` +
                  '#toolbar=0&navpanes=0&scrollbar=0&statusbar=0&messages=0&scrollbar=0&view=Fit'
                }
                height="566"
                width="800"
              />

              <Typography.Text>
                Verified by:{' '}
                <Typography.Link
                  href="https://www.bloomberg.com/professional/expertise/education/"
                  target="_blank"
                  rel="noreferrer">
                  Bloomberg for Education
                </Typography.Link>{' '}
                <TextColorSwitcher courseTitle={courseCategory.title}>
                  <Icon name="check-circle" />
                </TextColorSwitcher>
              </Typography.Text>
            </StyledContainer>
          </Col>
        </Row>
      </BfeGrid>
    </div>
  </ThemeProvider>
);

Show.propTypes = {
  fullName: string,
  certifiedAt: string,
  certificateId: string,
  courseCategory: shape({
    title: string,
    longDescription: string,
    certificateThumbnail: shape({
      daperKey: string.isRequired,
      width: number.isRequired,
      height: number.isRequired,
    }).isRequired,
  }),
};

export default Show;
