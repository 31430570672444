import {
  Button as BBUIButton,
  Icon as BBUIIcon,
  Table as BBUITable,
  GlobalStyles,
  ThemeProvider,
  Token,
  Typography,
} from '@bbnpm/bb-ui-framework';
import { Col, Row } from '@bloomberg/styled-flexboxgrid';

import { post } from '../../../api';
import Button from '../../Button';
import CheckOrMinusIcon from '../../CheckOrMinusIcon';
import Container from '../../Container';
import PostForm from '../../PostForm';
import PrimaryAdminKey from '../../PrimaryAdminKey';
import PrimaryKey from '../../PrimaryKey';
import ReversedCheckOrMinusCell from '../../ReversedCheckOrMinusCell';
import Table from '../../Table';
import { useFetchData } from '../../utils';
import CertificationTable from './CertifcationTable';
import DeleteUserButton from './DeleteUserButton';
import { BoldSectionTitle, FlexBox, StyledButton } from './styles';
import {
  BatClassPrimaryAdminKeyProps,
  CourseNameProps,
  ProfessorLinkProps,
  RusticiRegistrationsMarkCompletedProps,
  RusticiRegistrationsRefreshScoreProps,
  RusticiRegistrationsResetScoreProps,
  ShowProps,
} from './types';

const ProfessorLink = ({ value }) => {
  if (value?.href && value?.fullName) {
    return <a href={value.href}>{value.fullName}</a>;
  } else {
    return '';
  }
};

ProfessorLink.propTypes = ProfessorLinkProps;

const BatClassPrimaryAdminKey = ({ value: original }) => (
  <PrimaryAdminKey row={{ original }} value={original.id} />
);
BatClassPrimaryAdminKey.propTypes = BatClassPrimaryAdminKeyProps;

const classEnrollmentsColumns = [
  { Header: 'ID', accessor: 'batClass', Cell: BatClassPrimaryAdminKey },
  { Header: 'Class Code', accessor: 'batClass.classCode' },
  { Header: 'Name', accessor: 'batClass.nameAlias' },
  { Header: 'Enrolled At', accessor: 'createdAt' },
  {
    Header: 'Professor',
    accessor: 'batClass.professor',
    Cell: ProfessorLink,
  },
  { Header: 'Deadline', accessor: 'batClass.expiredAt', disableSortBy: true },
];

const CourseName = ({ value: { adminHref, internalName } }) => (
  <a href={adminHref}>{internalName}</a>
);

CourseName.propTypes = CourseNameProps;

const courseRegistrationsColumns = [
  { Header: 'ID', accessor: 'id' },
  {
    Header: 'Course Name',
    accessor: 'courseVersion',
    Cell: CourseName,
  },
  { Header: 'Enrollment Date', accessor: 'createdAt', disableSortBy: true },
  { Header: 'Status', accessor: 'status' },
  { Header: 'Score', accessor: 'score', disableSortBy: true },
];

const RusticiRegistrationMarkCompleted = ({ row }) => {
  const handleMarkCompleted = async () => {
    if (window.confirm('This will mark this module completed, are you sure?')) {
      try {
        await post(row.original.markCompletedPath);
        window.Turbolinks.reload();
      } catch (error) {
        alert('Registration mark completed failed');
      }
    }
  };
  return (
    <StyledButton
      disabled={row.original.completedAt !== '--'}
      onClick={handleMarkCompleted}>
      <BBUIIcon name="pencil" />
    </StyledButton>
  );
};

RusticiRegistrationMarkCompleted.propTypes =
  RusticiRegistrationsMarkCompletedProps;

const RusticiRegistrationsRefreshScore = ({ row }) => {
  const handleRefreshRusticiDataClick = async () => {
    try {
      await post(row.original.refreshRusticiDataPath);
      window.Turbolinks.reload();
    } catch (error) {
      alert('Rustici data refresh failed');
    }
  };

  return (
    !row.original.withoutRustici && (
      <Button
        title="Refresh Rustici data"
        icon="redo"
        variant="light"
        size="sm"
        onClick={handleRefreshRusticiDataClick}
      />
    )
  );
};

RusticiRegistrationsRefreshScore.propTypes =
  RusticiRegistrationsRefreshScoreProps;

const RusticiRegistrationsResetScore = ({ row }) => {
  const handleResetRusticiScoresClick = async () => {
    if (window.confirm('This will delete the score, are you sure?')) {
      try {
        await post(row.original.resetRusticiRegScoresPath);
        window.Turbolinks.reload();
      } catch (error) {
        alert('Registration score reset failed');
      }
    }
  };

  return (
    <Button
      title="Reset registration score"
      icon="eraser"
      variant="danger"
      size="sm"
      onClick={handleResetRusticiScoresClick}
    />
  );
};

RusticiRegistrationsResetScore.propTypes = RusticiRegistrationsResetScoreProps;

const courseModuleRegistrationsColumns = [
  { Header: 'ID', accessor: 'id' },
  { Header: 'Internal Name', accessor: 'internalName' },
  { Header: 'Module ID', accessor: 'courseModuleId' },
  { Header: 'Enrollment Date', accessor: 'createdAt', disableSortBy: true },
  { Header: 'Score', accessor: 'score', disableSortBy: true },
  {
    Header: 'Rustici?',
    accessor: 'withoutRustici',
    disableSortBy: true,
    Cell: ReversedCheckOrMinusCell,
  },
  { Header: 'Completed At', accessor: 'completedAt', disableSortBy: true },
  {
    Header: 'Mark Completed',
    accessor: '',
    id: 'markCompleted',
    disableSortBy: true,
    Cell: RusticiRegistrationMarkCompleted,
  },
  {
    Header: 'Refresh Score',
    accessor: '',
    id: 'refreshScore',
    disableSortBy: true,
    Cell: RusticiRegistrationsRefreshScore,
  },
  {
    Header: 'Reset Score',
    accessor: '',
    id: 'resetScore',
    disableSortBy: true,
    Cell: RusticiRegistrationsResetScore,
  },
];

const videoRegistrationsColumns = [
  { Header: 'ID', accessor: 'id' },
  { Header: 'Name', accessor: 'video.name' },
  { Header: 'Enrollment Date', accessor: 'createdAt' },
  { Header: 'Score', accessor: 'score', disableSortBy: true },
  { Header: 'Completed At', accessor: 'completedAt' },
  {
    Header: 'Refresh Score',
    accessor: '',
    id: 'refreshScore',
    disableSortBy: true,
    Cell: RusticiRegistrationsRefreshScore,
  },
  {
    Header: 'Reset Score',
    accessor: '',
    id: 'resetScore',
    disableSortBy: true,
    Cell: RusticiRegistrationsResetScore,
  },
];

const ordersColumns = [
  { Header: 'ID', accessor: 'id', Cell: PrimaryKey, disableSortBy: true },
  { Header: 'Created At', accessor: 'createdAt', disableSortBy: true },
  { Header: 'Name', accessor: 'name', disableSortBy: true },
  { Header: 'Total', accessor: 'total', disableSortBy: true },
  { Header: 'Promo', accessor: 'promo', disableSortBy: true },
];

const classesColumns = [
  { Header: 'ID', accessor: 'id', Cell: PrimaryAdminKey },
  { Header: 'Class Code', accessor: 'classCode' },
  { Header: 'Name', accessor: 'nameAlias' },
  { Header: 'Deadline', accessor: 'expiredAt', disableSortBy: true },
];

const cb = ({ totalCount, pageSize, classes }) => ({
  data: classes,
  pageSize,
  totalCount,
});

const Show = ({
  userid,
  email,
  firstName,
  lastName,
  createdAt,
  isDeleted,
  type,
  bmcSubscription,
  certifiedAt,
  professorInfo,
  impersonateAdminUserPath,
  resetTerminalDrSessionAdminUserPath,
  classEnrollments,
  videoRegistrations,
  courseRegistrations,
  courseModuleRegistrations,
  classesAdminUserPath,
  editAdminUserPath,
  confirmedAt,
  orders,
  institution,
  customer,
  managerRemoveUserPath,
  certificationsInfo,
  isManager,
  uuid,
  newPurchaseEntitlementPath,
  permissionTypes,
  canAssignSubscription,
}) => {
  const fetchClassesData = useFetchData(classesAdminUserPath, cb);

  const handleTerminalDrSessionResetClick = async () => {
    try {
      await post(resetTerminalDrSessionAdminUserPath);
      window.Turbolinks.reload();
    } catch (error) {
      alert('Terminal Dr session reset failed');
    }
  };

  const isProfessor = type === 'Professor';
  const hasPermissions = permissionTypes.length > 0;
  return (
    <ThemeProvider>
      <GlobalStyles />
      <Container>
        <FlexBox>
          <Typography.PageTitle>
            {firstName} {lastName} <Typography.Text>({type})</Typography.Text>
          </Typography.PageTitle>
          <FlexBox>
            <PostForm
              action={impersonateAdminUserPath}
              submitText={
                <>
                  <BBUIIcon name="user-headset" /> &nbsp; Masquerade
                </>
              }
              submitProps={{
                disabled: isDeleted,
              }}
            />
            &nbsp;
            <BBUIButton href={editAdminUserPath} as="a">
              Edit User
            </BBUIButton>{' '}
            &nbsp;
            <BBUIButton onClick={handleTerminalDrSessionResetClick}>
              <BBUIIcon name="refresh" /> &nbsp; Reset Terminal Dr Session
            </BBUIButton>
            &nbsp;
            {managerRemoveUserPath && (
              <DeleteUserButton
                name={firstName + ' ' + lastName}
                action={managerRemoveUserPath}
              />
            )}
          </FlexBox>
        </FlexBox>
        <Typography.Text>Account created at {createdAt}.</Typography.Text>

        {hasPermissions && (
          <Row>
            <Col>
              <Typography.Text>Permission Types: </Typography.Text>
            </Col>

            {permissionTypes.map(({ id, name }) => (
              <Col key={id}>
                <Token>{name.toUpperCase()}</Token>
              </Col>
            ))}
          </Row>
        )}
        <BoldSectionTitle>Account Information</BoldSectionTitle>
        <FlexBox>
          {[
            [
              ['Active', <CheckOrMinusIcon key="n/a" isTrue={!isDeleted} />],
              ['ID', userid],
              ['First Name', firstName],
              ['Last Name', lastName],
            ],
            [
              [
                'Institution',
                institution ? (
                  <a href={institution.href}>{institution.name}</a>
                ) : (
                  '--'
                ),
              ],
              [
                'Email',
                <>
                  <a href={`mailto:${email}`}>{email}</a>
                </>,
              ],
              ['Email Activated At', confirmedAt],
              [
                'BMC Type',
                bmcSubscription ? (
                  <a href={bmcSubscription.purchaseEntitlement.href}>
                    {bmcSubscription.productSku}
                  </a>
                ) : canAssignSubscription ? (
                  <BBUIButton>
                    <a href={newPurchaseEntitlementPath}>Assign Subscription</a>
                  </BBUIButton>
                ) : (
                  '--'
                ),
              ],
            ],
            [
              ['Certified At', certifiedAt],
              ['BBG Customer ID', customer ? customer.bbgCustomerId : '--'],
              ['UUID', uuid ? uuid : '--'],
              isProfessor
                ? [
                    'Professor Status Confirmed',
                    <CheckOrMinusIcon
                      key="n/a"
                      isTrue={!!professorInfo && professorInfo.accountVerified}
                    />,
                  ]
                : [],
            ],
          ].map((table, i) => (
            <div key={i}>
              <BBUITable>
                <tbody>
                  {table.map(([k, v], j) => (
                    <tr key={j}>
                      <td>{k}</td>
                      <td>{v}</td>
                    </tr>
                  ))}
                </tbody>
              </BBUITable>
            </div>
          ))}
        </FlexBox>

        <>
          <BoldSectionTitle>Certifications</BoldSectionTitle>
          <CertificationTable
            certificationsInfo={certificationsInfo}
            addCertificationEnabled={isManager}
          />
        </>

        {orders && (
          <>
            <BoldSectionTitle>Orders</BoldSectionTitle>
            <Table size="sm" columns={ordersColumns} initialData={orders} />
          </>
        )}

        {classEnrollments && (
          <>
            <BoldSectionTitle>Classes</BoldSectionTitle>
            <Table
              size="sm"
              columns={classEnrollmentsColumns}
              initialData={classEnrollments}
            />
          </>
        )}

        {courseRegistrations && (
          <>
            <BoldSectionTitle>Course Registrations</BoldSectionTitle>
            <Table
              size="sm"
              columns={courseRegistrationsColumns}
              initialData={courseRegistrations}
            />
          </>
        )}

        {courseModuleRegistrations && (
          <>
            <BoldSectionTitle>Course Module Registrations</BoldSectionTitle>
            <Table
              size="sm"
              columns={courseModuleRegistrationsColumns}
              initialData={courseModuleRegistrations}
            />
          </>
        )}

        {videoRegistrations && (
          <>
            <BoldSectionTitle>Video Registrations</BoldSectionTitle>
            <Table
              size="sm"
              columns={videoRegistrationsColumns}
              initialData={videoRegistrations}
            />
          </>
        )}

        {classesAdminUserPath && (
          <>
            <BoldSectionTitle>Classes</BoldSectionTitle>
            <Table
              size="sm"
              columns={classesColumns}
              fetchData={fetchClassesData}
            />
          </>
        )}
      </Container>
    </ThemeProvider>
  );
};

Show.propTypes = ShowProps;

export default Show;
