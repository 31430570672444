import { arrayOf, bool, func, shape, string } from 'prop-types';

import { TableProps, TradingChallengeRegistrationProps } from '../../types';

export const EditModalProps = {
  isOpen: bool.isRequired,
  toggleIsOpen: func.isRequired,
  teamName: string.isRequired,
  handleEdit: func.isRequired,
};

export const RegistrationActionCellProps = {
  row: shape(TradingChallengeRegistrationProps),
  refreshData: func,
};

export const DeleteRegistrationModalProps = {
  row: shape(TradingChallengeRegistrationProps),
  showDeleteModal: bool.isRequired,
  toggleShowDeleteModal: func,
  refreshData: func,
};

export const ShowProps = {
  ...TradingChallengeRegistrationProps,
};

export const RegistrationsTableProps = {
  registrations: arrayOf(shape(TradingChallengeRegistrationProps)),
  RegistrationsTableProps: shape(TableProps),
  path: string,
  adminInstitutionsPath: string,
};
