import {
  GlobalStyles,
  ThemeProvider,
  Typography,
} from '@bbnpm/bb-ui-framework';
import { string } from 'prop-types';
import styled from 'styled-components';

import Container from './Container';

const StyledLink = styled(Typography.Link)`
  color: ${({ theme }) => theme.colors.blue[400]} !important;
  text-decoration: none !important;
`;

const TerminalGuide = ({
  welcomeToTheTerminal,
  guideGsStudentsEng,
  guideGsStudentsSch,
  guideGsStudentsSpa,
  guideGsStudentsPor,
}) => {
  return (
    <ThemeProvider>
      <GlobalStyles />
      <Container>
        <Typography.PageTitle>
          <strong>How to use the Terminal</strong>
        </Typography.PageTitle>
        <Typography.Text>
          1.{' '}
          <StyledLink href={welcomeToTheTerminal} target="_blank">
            Introductory video of the Terminal&apos;s most need-to-know Features
          </StyledLink>
          <br />
          2.{' '}
          <StyledLink href={guideGsStudentsEng} target="_blank">
            Getting Started Guide for Students (English)
          </StyledLink>
          <br />
          3.{' '}
          <StyledLink href={guideGsStudentsSch} target="_blank">
            Getting Started Guide for Students (Simplified Chinese)
          </StyledLink>
          <br />
          4.{' '}
          <StyledLink href={guideGsStudentsSpa} target="_blank">
            Getting Started Guide for Students (Spanish)
          </StyledLink>
          <br />
          5.{' '}
          <StyledLink href={guideGsStudentsPor} target="_blank">
            Getting Started Guide for Students (Portuguese)
          </StyledLink>
        </Typography.Text>
      </Container>
    </ThemeProvider>
  );
};

TerminalGuide.propTypes = {
  welcomeToTheTerminal: string.isRequired,
  guideGsStudentsEng: string.isRequired,
  guideGsStudentsSch: string.isRequired,
  guideGsStudentsSpa: string.isRequired,
  guideGsStudentsPor: string.isRequired,
};

export default TerminalGuide;
