import { Button, Typography } from '@bbnpm/bb-ui-framework';
import { useState } from 'react';
import { useToggle } from 'react-use';

import { create, destroy, update } from '../../../api';
import CheckOrMinusCell from '../../CheckOrMinusCell';
import { PageWrapper } from '../../CommonComponents';
import PrimaryAdminKey from '../../PrimaryAdminKey';
import Table from '../../Table';
import { useFetchData } from '../../utils';
import WebinarSeriesFormModal from './WebinarSeriesFormModal';
import { IndexProps, WebinarSeriesActionsProps } from './types';

const WebinarSeriesActions = ({ row, refreshData }) => {
  const [showEditForm, toggleShowEditForm] = useToggle(false);

  const handleEditSubmit = async values => {
    await update(row.original.adminHref, values);
    refreshData();
    toggleShowEditForm();
  };

  const handleDelete = async () => {
    try {
      await destroy(row.original.adminHref);
      refreshData();
    } catch (error) {
      alert('Something went wrong.');
    }
  };

  return (
    <span className="text-nowrap">
      <Button
        title="Edit"
        icon="pencil"
        kind="secondary"
        size="sm"
        onClick={toggleShowEditForm}
      />
      <Button
        title="Delete"
        icon="trash"
        kind="destruction"
        size="sm"
        className="ml-2"
        onClick={handleDelete}
      />
      <WebinarSeriesFormModal
        show={showEditForm}
        onHide={toggleShowEditForm}
        onSubmit={handleEditSubmit}
        webinarSeries={row.original}
      />
    </span>
  );
};

WebinarSeriesActions.propTypes = WebinarSeriesActionsProps;

const columns = [
  { Header: 'ID', accessor: 'id', filteredAs: 'number', Cell: PrimaryAdminKey },
  { Header: 'Created', accessor: 'createdAt' },
  { Header: 'Name', accessor: 'name', filteredAs: 'string' },
  { Header: 'Webinars', accessor: 'webinarsCount' },
  {
    Header: 'Active',
    id: 'active',
    accessor: 'active',
    Cell: CheckOrMinusCell,
  },
  {
    Header: 'Restricted for Professors',
    id: 'restrictedForProfessors',
    accessor: 'restrictedForProfessors',
    Cell: CheckOrMinusCell,
  },
  {
    accessor: 'n/a',
    id: 'actions',
    disableSortBy: true,
    Cell: WebinarSeriesActions,
  },
];

const cb = ({ totalCount, pageSize, webinarSeries }) => ({
  data: webinarSeries,
  pageSize,
  totalCount,
});

const Index = ({
  adminWebinarSeriesPath,
  path,
  webinarSeries,
  totalCount,
  pageSize,
  pageIndex,
  sortBy,
}) => {
  const [showAddForm, toggleShowAddForm] = useToggle(false);
  const [{ refreshData }, setRefreshDataFn] = useState({
    refreshData: () => {},
  });
  const fetchData = useFetchData(path, cb);

  const handleAddSubmit = async values => {
    await create(adminWebinarSeriesPath, values);
    refreshData();
    toggleShowAddForm();
  };

  return (
    <PageWrapper>
      <WebinarSeriesFormModal
        show={showAddForm}
        onHide={toggleShowAddForm}
        onSubmit={handleAddSubmit}
      />
      <div className="d-flex align-items-center mb-3">
        <Typography.LargeTitle>Webinar Series</Typography.LargeTitle>
        <Button className="ml-5" kind="primary" onClick={toggleShowAddForm}>
          Add Webinar Series
        </Button>
      </div>
      <Table
        initialData={webinarSeries}
        initialPageSize={pageSize}
        initialPageIndex={pageIndex}
        initialSortBy={sortBy}
        initialTotalCount={totalCount}
        columns={columns}
        fetchData={fetchData}
        onRefreshDataFn={setRefreshDataFn}
        refreshData={refreshData}
      />
    </PageWrapper>
  );
};

Index.propTypes = IndexProps;

export default Index;
