import { Button, Form, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import Input from '../../Input';
import { SeriesFormModalProps } from './types';

const SeriesFormModal = ({ show, onHide, onSubmit, series = {} }) => {
  const { register, handleSubmit } = useForm();

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Form
        onSubmit={handleSubmit((values, e) => {
          e.preventDefault();
          onSubmit(values);
        })}>
        <Modal.Header closeButton>
          <Modal.Title>{series.id ? 'Edit' : 'Add'} Series</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Input
            required
            ref={register}
            defaultValue={series.name || ''}
            name="name"
          />
          <Input
            required
            ref={register}
            defaultValue={series.description || ''}
            name="description"
            as="textarea"
          />
          <Input
            ref={register}
            type="switch"
            name="active"
            defaultChecked={series.active}
            formGroupProps={{ className: 'mb-0' }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Cancel
          </Button>
          <Button variant="primary" type="submit">
            {series.id ? 'Update' : 'Create'}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

SeriesFormModal.propTypes = SeriesFormModalProps;

export default SeriesFormModal;
