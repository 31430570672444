import { Button, Modal } from '@bbnpm/bb-ui-framework';
import { useState } from 'react';
import styled from 'styled-components';

import ImageFileInput from '../../ImageFileInput';
import Input from '../../Input';
import PatchForm from '../../PatchForm';
import PostForm from '../../PostForm';
import { CourseFormModalProps } from './types';

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const CourseFormModal = ({
  show,
  onHide,
  course = {},
  courseCategories,
  adminCoursesPath,
}) => {
  const [Form, action, submitText] = course.id
    ? [PatchForm, course.href, 'Update']
    : [PostForm, adminCoursesPath, 'Create'];

  const [isSubmitting, setIsSubmitting] = useState(false);

  return (
    <Modal
      portalContainerId="bfe-root"
      isOpen={show}
      onClose={onHide}
      title={course.id ? 'Edit Course' : 'Add Course'}>
      <Form
        action={action}
        encType="multipart/form-data"
        onSubmit={() => setIsSubmitting(true)}>
        <Input
          required
          as="select"
          options={courseCategories}
          defaultValue={course?.courseCategory?.id ?? ''}
          name="courseCategoryId"
          label="Category"
        />
        <Input
          required
          defaultValue={course.name || ''}
          name="name"
          helpText="The name of this course. Versions can inherit this name"
        />
        <Input
          required
          defaultValue={course.description || ''}
          name="description"
          as="textarea"
        />
        <Input
          required
          defaultValue={course.shortDescription || ''}
          name="shortDescription"
          as="textarea"
        />
        <Input
          name="overview"
          defaultValue={course.overview ?? ''}
          as="textarea"
        />
        <ImageFileInput
          name="thumbnail"
          accept=".jpg, .png"
          helpText="upload the largest available version"
          value={course.thumbnail}
        />
        {course.id && (
          <Input
            required
            defaultValue={course.position}
            type="number"
            name="position"
          />
        )}
        <ButtonGroup>
          <Button kind="secondary" onClick={onHide} disabled={isSubmitting}>
            Cancel
          </Button>
          &nbsp; &nbsp;
          <Button kind="primary" type="submit" disabled={isSubmitting}>
            {submitText}
          </Button>
        </ButtonGroup>
      </Form>
    </Modal>
  );
};

CourseFormModal.propTypes = CourseFormModalProps;

export default CourseFormModal;
