import { arrayOf, func, shape, string } from 'prop-types';

import { PromoItemProps, TableProps } from '../../types';

export const PromoItemFormProps = {
  ...PromoItemProps,
  adminPromoItemsPath: string.isRequired,
};

export const IndexProps = {
  ...TableProps,
  promoItems: arrayOf(shape(PromoItemProps)).isRequired,
  updateOrderAdminPromoItemsPath: string.isRequired,
  adminPromoItemsPath: string.isRequired,
  newAdminPromoItemPath: string.isRequired,
};

export const ActionsProps = {
  row: shape({
    original: shape(PromoItemProps).isRequired,
  }).isRequired,
};

export const StatusProps = {
  cell: shape({ value: PromoItemProps.active }),
};

export const PromoItemPositionSelectorProps = {
  promoItems: arrayOf(shape(PromoItemProps)).isRequired,
  refreshData: func.isRequired,
  updateOrderAdminPromoItemsPath: string.isRequired,
};
