import {
  Autocomplete,
  Button,
  Dropdown,
  Flyout,
  Icon,
  Input,
  Stack,
  Table,
  useAsyncAutocomplete,
} from '@bbnpm/bb-ui-framework';
import { useCallback, useMemo, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import styled from 'styled-components';

import { get } from '../../api';
import { getGuid } from '../../utils';
import { TableFiltersProps } from './types';

const StyledFlyout = styled(Flyout)`
  .flyout-content {
    overflow-y: initial;
  }
`;

// This needs to match what exists here:
// app/controllers/admin/base_controller.rb
const predicates = {
  string: [
    ['cont', 'Contains'],
    ['eq', 'Equals'],
    ['start', 'Starts With'],
    ['end', 'Ends With'],
  ],
  number: [
    ['eq', 'Equals'],
    ['gt', 'Greater Than'],
    ['lt', 'Less Than'],
  ],
  autosuggest: [['eq', 'Equals']],
};

const newFilter = ({ id = '', predicate = '', value = '', guid } = {}) => ({
  guid: guid || getGuid(),
  id,
  predicate,
  value,
});

const TableFilters = ({
  columns,
  filters,
  onFiltersSet,
  additionalFilters,
  variant,
}) => {
  const [flyoutInstance, setFlyoutInstance] = useState({});

  const availableFilters = useMemo(
    () =>
      [...columns, ...additionalFilters].reduce(
        (acc, { accessor, filteredAs, ...rest }) =>
          filteredAs
            ? { ...acc, [accessor]: { accessor, filteredAs, ...rest } }
            : acc,
        {},
      ),
    [columns, additionalFilters],
  );

  const availableFilterIds = Object.keys(availableFilters);

  const newValidFilter = useCallback(
    ({ id, guid }) =>
      newFilter({
        guid,
        id,
        predicate: predicates[availableFilters[id].filteredAs][0][0],
      }),
    [availableFilters],
  );

  const defaultValues = useMemo(
    () => ({
      filters: availableFilterIds.map(id => newValidFilter({ id })),
    }),
    [availableFilterIds, newValidFilter],
  );

  const validFiltersCount = useMemo(
    () =>
      filters.reduce(
        (acc, { id, predicate, value }) =>
          id && predicate && value ? acc + 1 : acc,
        0,
      ),
    [filters],
  );

  const { register, control, handleSubmit, reset } = useForm({ defaultValues });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'filters',
    keyName: 'gid',
  });

  const onSubmit = ({ filters }) => {
    onFiltersSet(filters || []);
    flyoutInstance.hide();
  };

  const [{ onThrottledInputChange, ...rest }, { setOptions }] =
    useAsyncAutocomplete();

  const institutionsAutocomplete = useCallback(
    (inputText, id) => {
      onThrottledInputChange(inputText);
      get(availableFilters[id].path, {
        nameCont: inputText,
      }).then(({ institutions }) => {
        setOptions(
          institutions.map(institution => ({
            label: institution.name,
            value: institution.id,
          })),
          inputText,
        );
      });
    },
    [onThrottledInputChange, setOptions],
  );

  return availableFilterIds.length ? (
    <StyledFlyout
      maxWidth={'100%'}
      onCreate={setFlyoutInstance}
      content={
        <form onSubmit={handleSubmit(onSubmit)} style={{ width: 500 }}>
          <Table>
            <tbody>
              {fields.map(({ guid, id, predicate, value }, index) => {
                const type = id ? availableFilters[id].filteredAs : null;

                return (
                  <tr key={guid}>
                    <td width="125px">
                      <Controller
                        name={`filters[${index}].id`}
                        control={control}
                        render={field => (
                          <Dropdown
                            {...field}
                            defaultValue={id}
                            onItemSelect={({ value }) => field.onChange(value)}
                            options={availableFilterIds.map(id => ({
                              label: availableFilters[id].Header,
                              value: availableFilters[id].accessor,
                            }))}
                          />
                        )}
                      />
                    </td>
                    {type === 'autosuggest' ? (
                      <td colSpan="2">
                        <input
                          type="hidden"
                          name={`filters[${index}].predicate`}
                          ref={register()}
                          defaultValue={predicate}
                        />
                        <Controller
                          name={`filters[${index}].value`}
                          control={control}
                          render={field => (
                            <Autocomplete
                              {...field}
                              {...rest}
                              onItemSelect={option =>
                                field.onChange(option ? option.value : null)
                              }
                              onThrottledInputChange={inputText =>
                                institutionsAutocomplete(inputText, id)
                              }
                              placeholder="Search look for institutions"
                              popperOptions={{
                                strategy: 'fixed',
                              }}
                            />
                          )}
                        />
                      </td>
                    ) : (
                      <>
                        <td width="125px">
                          <Controller
                            name={`filters[${index}].predicate`}
                            control={control}
                            render={field => (
                              <Dropdown
                                {...field}
                                defaultValue={predicate}
                                onItemSelect={({ value }) =>
                                  field.onChange(value)
                                }
                                options={predicates[type].map(([k, v]) => ({
                                  label: v,
                                  value: k,
                                }))}
                              />
                            )}
                          />
                        </td>

                        <td>
                          <Input
                            defaultValue={value}
                            name={`filters[${index}].value`}
                            ref={register()}
                            type={type === 'number' ? 'number' : 'text'}
                          />
                        </td>
                      </>
                    )}
                    {variant !== 'tradingChallenge' && (
                      <td>
                        <Button
                          icon="x"
                          kind="tertiary"
                          onClick={() => remove(index)}
                        />
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <Stack direction="row">
            {variant !== 'tradingChallenge' && (
              <Button
                onClick={() =>
                  append(newValidFilter({ id: availableFilterIds[0] }))
                }
                disabled={fields.length >= availableFilterIds.length}>
                <Icon name="plus" /> Add Filter
              </Button>
            )}
            <Button
              kind="destruction"
              onClick={() => {
                reset(defaultValues);
                handleSubmit(onSubmit)();
                flyoutInstance.hide();
              }}>
              Reset Filters
            </Button>
            <Button
              kind="primary"
              onClick={() => {
                handleSubmit(onSubmit)();
                flyoutInstance.hide();
              }}>
              Apply Filters
            </Button>
          </Stack>
        </form>
      }>
      <Button>
        <Icon name={validFiltersCount ? 'filter-clear' : 'filter'} /> Filters
      </Button>
    </StyledFlyout>
  ) : null;
};

TableFilters.propTypes = TableFiltersProps;

export default TableFilters;
