import { Button } from 'react-bootstrap';

import Container from '../../Container';
import PrimaryKey from '../../PrimaryKey';
import Table from '../../Table';
import { useFetchData } from '../../utils';
import { IndexProps } from './types';

const columns = [
  { Header: 'ID', accessor: 'id', filteredAs: 'number', Cell: PrimaryKey },
  { Header: 'Name', accessor: 'name', filteredAs: 'string' },
  { Header: 'bbgFirmId', accessor: 'bbgFirmId', filteredAs: 'number' },
];

const cb = ({ totalCount, pageSize, firms }) => ({
  data: firms,
  pageSize,
  totalCount,
});

const Index = ({
  totalCount,
  pageSize,
  pageIndex,
  sortBy,
  adminFirmsPath,
  firms,
  newAdminFirmPath,
}) => {
  const fetchData = useFetchData(adminFirmsPath, cb);
  return (
    <Container>
      <div className="d-flex align-items-center mb-3">
        <h1>Firms</h1>
        <Button href={newAdminFirmPath} className="ml-5" variant="primary">
          Add Firm
        </Button>
      </div>
      <Table
        initialData={firms}
        initialPageSize={pageSize}
        initialPageIndex={pageIndex}
        initialSortBy={sortBy}
        initialTotalCount={totalCount}
        columns={columns}
        fetchData={fetchData}
      />
    </Container>
  );
};

Index.propTypes = IndexProps;

export default Index;
