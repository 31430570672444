import { useEffect } from 'react';

function bootstrap({
  loginPath,
  signUpPath,
  aboutUrl,
  privacyPolicyUrl,
  tosUrl,
}) {
  window.bbwc.landingPage.bootstrap('landing-page', {
    caller: 'bfe',
    productTitle: 'Bloomberg for Education',
    productSignInDescription:
      'Login to your account and get access to Bloomberg Market Concepts (BMC) and more CPD accredited content.',
    productSignInRoute: loginPath,
    productSignUpRoute: {
      linkLocation: signUpPath,
    },
    productLearnMoreRoute: {
      linkLocation:
        'https://www.bloomberg.com/professional/expertise/education/',
    },
    marketingContent: {
      'Bloomberg Certificates': {
        description:
          'Self-paced e-learning certifications that provide an interactive experience through the lens of the Bloomberg Terminal. Bloomberg Market Concepts (BMC) certificate and Bloomberg ESG Certificate are now available.',
      },
      'Continuous Learning': {
        description:
          "Keep up to speed with our Webinars and bite-sized interactive videos that focus on current events. The latest happenings in the markets and the finance industry, created by Bloomberg's industry experts.",
      },
      'Bloomberg Finance Labs': {
        description:
          'Building a Bloomberg Finance Lab helps students build the skills needed for a career in finance, enhances class curricula and better positions a university as a leader in the academic space.',
      },
      'Bloomberg Trading Challenge': {
        description:
          'The Bloomberg Global Trading Challenge is the only university investment competition to take place entirely within the Bloomberg Terminal.',
      },
      'Experiential Learning Partner Rewards': {
        description:
          'The ELP program is designed to recognize and celebrate academic institutions that are leaders in experimental learning through the integration of the Bloomberg Terminal into their curricula.',
      },
      Awards: {
        description:
          'Bloomberg for Education has won the 2023 Teaching & Learning Awards of Excellence in the Higher Education Category for exceptionally supporting teachers and students in innovative ways.',
        contentUrl: {
          href: 'https://www.bloomberg.com/company/press/bloomberg-for-education-wins-2023-tech-learning-award-for-excellence-in-higher-education/',
          target: '_blank',
          label: 'Learn More',
        },
      },
      // "What's New": {
      //   description:
      //     'Bloomberg.com news group subscription available to Schools and Universities. BFE content is now part of the Continuing Professional Development (CPD) accreditation.',
      // },
    },
    footerConfig: {
      links: {
        'About Us': {
          linkLocation: aboutUrl,
        },
        'Privacy Policy': {
          linkLocation: privacyPolicyUrl,
        },
        'Terms of Service': {
          linkLocation: tosUrl,
        },
        Contact: {
          linkLocation: 'mailto:bbg.edu@bloomberg.net',
        },
      },
    },
  });
}

export default function LandingPage(props) {
  useEffect(() => {
    // create a script element on JS side, alternatively
    // can be done via html as well without JS
    const bbwcScript = document.createElement('script');

    // append script to body, and start interval
    document.body.appendChild(bbwcScript);

    bbwcScript.onload = () => bootstrap(props);
    bbwcScript.type = 'text/javascript';
    bbwcScript.src = `https://bbwc.bloomberg.com/dist/generic/landing-page-2.1.js`;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <div id="landing-page" />;
}
