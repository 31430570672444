import { Button, Typography } from '@bbnpm/bb-ui-framework';
import { object, string } from 'prop-types';
import styled from 'styled-components';

const ItemContainer = styled.div`
  display: flex;
  min-height: 16rem;
  padding: 1rem;
`;

const ImgContainer = styled.div`
  width: 30%;
`;

const SyledImg = styled.img`
  max-height: 80%;
  max-width: 80%;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 70%;
`;

const ActionContainer = styled.div`
  display: flex;
  align-items: center;
`;

const CaseStudiesListItem = ({ caseStudy, defaultLogoPath }) => {
  const { professorPack, logoPath } = caseStudy;
  return (
    <ItemContainer>
      <ImgContainer>
        <SyledImg src={logoPath ? logoPath : defaultLogoPath} alt="Logo" />
      </ImgContainer>
      <InfoContainer>
        <Typography.SectionTitle>{professorPack.title}</Typography.SectionTitle>
        <Typography.Text>{professorPack.description}</Typography.Text>
        <ActionContainer>
          <a href={professorPack.href}>
            <Button kind="primary">Access Case Study</Button>
          </a>
          &nbsp; &nbsp;
          <Typography.Text>
            <strong>Class Code: </strong> {caseStudy.classCodeMarkup}
          </Typography.Text>
        </ActionContainer>
      </InfoContainer>
    </ItemContainer>
  );
};

CaseStudiesListItem.propTypes = {
  caseStudy: object.isRequired,
  defaultLogoPath: string.isRequired,
};

export default CaseStudiesListItem;
