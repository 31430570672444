import {
  Button,
  GlobalStyles,
  ThemeProvider,
  Typography,
} from '@bbnpm/bb-ui-framework';
import { Fragment, useState } from 'react';

import { destroy } from '../../../api';
import { noop } from '../../../utils/index';
import CheckOrMinusIcon from '../../CheckOrMinusIcon';
import Container from '../../Container';
import PrimaryAdminKey from '../../PrimaryAdminKey';
import Table from '../../Table';
import { useFetchData } from '../../utils';
import {
  DocumentsActionsProps,
  IndexProps,
  StatusProps,
  TopicsProps,
} from './types';

const DocumentsActions = ({ row, refreshData }) => {
  const handleDelete = async () => {
    if (
      window.confirm(
        'Are you sure you want to permanently delete this document?',
      )
    ) {
      try {
        await destroy(row.original.adminToolkitDocumentPath);
        refreshData();
      } catch (error) {
        alert('Something went wrong.');
      }
    }
  };

  return (
    <span className="text-nowrap">
      <Button
        as="a"
        href={row.original.editAdminToolkitDocumentPath}
        title="Edit"
        icon="pencil"
        kind="secondary"
      />
      <Button
        title="Delete"
        icon="trash"
        kind="destruction"
        className="ml-2"
        onClick={handleDelete}
      />
    </span>
  );
};

DocumentsActions.propTypes = DocumentsActionsProps;

const Topics = ({ row }) => {
  return (row.original.tableTopics ?? []).map((topic, index) => {
    return (
      <Fragment key={index}>
        <a href={topic.href}>{topic.title}</a>
        {index < row.original.tableTopics.length - 1 && ', '}
      </Fragment>
    );
  });
};
Topics.propTypes = TopicsProps;

const Status = ({ cell }) => <CheckOrMinusIcon isTrue={!!cell.value} />;
Status.propTypes = StatusProps;

const ToolkitType = ({ row }) => (
  <a href={row.original.toolkitType?.adminHref}>
    {row.original.toolkitType?.title}
  </a>
);
ToolkitType.propTypes = TopicsProps;

export const columns = [
  { Header: 'ID', accessor: 'id', Cell: PrimaryAdminKey },
  { Header: 'Title', accessor: 'title' },
  { Header: 'Description', accessor: 'description' },
  { Header: 'Created At', accessor: 'createdAt' },
  {
    Header: 'Active',
    accessor: 'active',
    disableSortBy: true,
    Cell: Status,
  },
  {
    Header: 'Topics',
    accessor: 'n/a',
    disableSortBy: true,
    Cell: Topics,
  },
  {
    Header: 'Document Type',
    accessor: 'n/a',
    id: 'toolkit_tag.title',
    disableSortBy: true, // temporarily disabling; need to fix the SQL fetch before re-enabling this
    Cell: ToolkitType,
  },
  {
    accessor: 'n/a',
    id: 'actions',
    disableSortBy: true,
    Cell: DocumentsActions,
  },
];

const cb = ({ totalCount, pageSize, toolkitDocuments }) => ({
  data: toolkitDocuments,
  pageSize,
  totalCount,
});

const Index = ({
  totalCount,
  pageSize,
  pageIndex,
  sortBy,
  toolkitDocuments,
  newAdminToolkitDocumentPath,
  adminToolkitDocumentsPath,
  adminToolkitTagsPath,
}) => {
  const [{ refreshData }, setRefreshDataFn] = useState({ refreshData: noop });
  const fetchData = useFetchData(adminToolkitDocumentsPath, cb);
  return (
    <ThemeProvider>
      <GlobalStyles />
      <Container>
        <div className="d-flex align-items-center mb-3">
          <Typography.PageTitle>Toolkit Documents</Typography.PageTitle>
          <Button
            as="a"
            href={newAdminToolkitDocumentPath}
            className="ml-4"
            kind="primary">
            Add Toolkit Document
          </Button>
          <Button
            as="a"
            href={adminToolkitTagsPath}
            className="ml-4"
            kind="secondary">
            Manage Toolkit Tags
          </Button>
        </div>

        <Table
          initialData={toolkitDocuments}
          columns={columns}
          initialSortBy={sortBy}
          initialPageSize={pageSize}
          initialPageIndex={pageIndex}
          initialTotalCount={totalCount}
          fetchData={fetchData}
          onRefreshDataFn={setRefreshDataFn}
          refreshData={refreshData}
        />
      </Container>
    </ThemeProvider>
  );
};

Index.propTypes = IndexProps;

export default Index;
