import { arrayOf, bool, func, number, shape, string } from 'prop-types';

import { TableProps, WebinarProps, WebinarSeriesProps } from '../../types';

export const WebinarSeriesActionsProps = {
  row: shape({
    original: shape(WebinarSeriesProps),
  }),
  refreshData: func,
};

export const WebinarSeriesFormModalProps = {
  show: bool,
  onHide: func,
  onSubmit: func,
  webinarSeries: shape(WebinarSeriesProps),
};

export const IndexProps = {
  ...TableProps,
  adminWebinarSeriesPath: string,
  path: string,
  webinarSeries: arrayOf(shape(WebinarSeriesProps)),
};

export const ShowProps = {
  ...TableProps,
  ...WebinarSeriesProps,
  webinars: arrayOf(shape(WebinarProps)).isRequired,
  path: string.isRequired,
};

export const FeaturedWebinarPanelProps = {
  webinar: shape(WebinarProps),
};

export const FeaturedCellProps = {
  row: shape({
    original: shape(WebinarProps).isRequired,
  }).isRequired,
  webinarSeriesUpdatePath: string,
  featuredWebinarId: number,
  setFeaturedWebinarId: func,
};
