import {
  Button,
  GlobalStyles,
  ThemeProvider,
  Typography,
} from '@bbnpm/bb-ui-framework';
import { createContext, useState } from 'react';
import { useToggle } from 'react-use';

import { create } from '../../../api';
import { noop } from '../../../utils';
import Container from '../../Container';
import Table from '../../Table';
import { useFetchData } from '../../utils';
import CourseModuleActions from './CourseModuleActions';
import CourseModuleFormModal from './CourseModuleFormModal';
import { IndexProps } from './types';

export const RusticiContext = createContext();

function withRusticiContext(Component) {
  const WithRusticiContext = props => {
    const {
      rusticiHost,
      rusticiBasePath,
      rusticiToken,
      rusticiCdnHost,
      ...rest
    } = props;
    return (
      <RusticiContext.Provider
        value={{
          rusticiToken,
          rusticiBaseUrl: rusticiHost + rusticiBasePath,
          rusticiCdnHost,
        }}>
        <Component {...rest} />
      </RusticiContext.Provider>
    );
  };
  WithRusticiContext.propTypes = IndexProps;
  return WithRusticiContext;
}

const columns = [
  { Header: 'ID', accessor: 'id', filteredAs: 'number' },
  { Header: 'Category', accessor: 'courseCategory.name' },
  { Header: 'Created', accessor: 'createdAt' },
  { Header: 'Uploaded', accessor: 'uploadedAt' },
  { Header: 'Public Name', accessor: 'name', filteredAs: 'string' },
  {
    Header: 'Internal Name',
    accessor: 'internalName',
    filteredAs: 'string',
  },
  { Header: '# Qs', accessor: 'numberOfQuestions' },
  { Header: 'Mins', accessor: 'durationMins' },
  {
    accessor: 'userid',
    id: 'actions',
    disableSortBy: true,
    Cell: CourseModuleActions,
  },
];

const scopes = [
  { id: 'bmc', title: 'BMC' },
  { id: 'esg', title: 'ESG' },
  { id: 'bff', title: 'BFF' },
];

const initialScopes = {
  bmc: false,
  esg: false,
  bff: false,
};

const cb = ({ totalCount, pageSize, courseModules }) => ({
  data: courseModules,
  pageSize,
  totalCount,
});

const Index = ({
  adminCourseModulesPath,
  path,
  courseModules,
  totalCount,
  pageSize,
  pageIndex,
  sortBy,
  courseModuleCategories,
}) => {
  const [showAddForm, toggleShowAddForm] = useToggle(false);

  const [{ refreshData }, setRefreshDataFn] = useState({ refreshData: noop });
  const fetchData = useFetchData(path, cb);

  const handleAddSubmit = async values => {
    await create(adminCourseModulesPath, values);
    refreshData();
    toggleShowAddForm();
  };

  return (
    <ThemeProvider>
      <GlobalStyles />
      <Container>
        <CourseModuleFormModal
          show={showAddForm}
          courseModuleCategories={courseModuleCategories}
          onHide={toggleShowAddForm}
          onSubmit={handleAddSubmit}
        />
        <div className="d-flex align-items-center mb-3">
          <Typography.LargeTitle>Course Modules</Typography.LargeTitle>
          &nbsp; &nbsp;
          <Button kind="primary" onClick={toggleShowAddForm}>
            Add Course Module
          </Button>
        </div>
        <Table
          initialData={courseModules}
          initialPageSize={pageSize}
          initialPageIndex={pageIndex}
          initialSortBy={sortBy}
          initialTotalCount={totalCount}
          columns={columns}
          fetchData={fetchData}
          onRefreshDataFn={setRefreshDataFn}
          refreshData={refreshData}
          initialScopes={initialScopes}
          availableScopes={scopes}
          courseModuleCategories={courseModuleCategories}
        />
      </Container>
    </ThemeProvider>
  );
};

Index.propTypes = IndexProps;

export default withRusticiContext(Index);
