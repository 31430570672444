import {
  Button,
  FileInput,
  FormField,
  Modal,
  Typography,
} from '@bbnpm/bb-ui-framework';
import { useState } from 'react';
import { useToggle } from 'react-use';

import { ButtonContainer, RequiredLabel } from '../../CommonComponents';
import Container from '../../Container';

const UploadModal = ({ isOpen, toggleIsOpen, onSubmit }) => {
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [isDisabled, toggleIsDisabled] = useToggle(true);

  const submitForm = () => {
    const bodyFormData = new FormData();
    bodyFormData.append('leaderboardCsv', file);

    onSubmit(bodyFormData);
  };

  return (
    <Modal
      isOpen={isOpen}
      title="Upload Leaderboard CSV"
      portalContainerId="bfe-root"
      onClose={toggleIsOpen}
      primaryActions={
        <ButtonContainer>
          <Button
            kind="primary"
            onClick={() => {
              submitForm();
              toggleIsOpen();
            }}
            disabled={isDisabled}>
            Upload
          </Button>
          <Button kind="secondary" onClick={toggleIsOpen} className="mx-1">
            Cancel
          </Button>
        </ButtonContainer>
      }>
      <Container>
        <FormField
          label={<RequiredLabel>CSV File</RequiredLabel>}
          className="mb-3">
          <FileInput
            multiple={false}
            name="leaderboardCsv"
            onChange={e => {
              setFile(e.target.files[0]);
              setFileName(e.target.name);
              toggleIsDisabled(false);
            }}
            accept=".csv"
          />
          {fileName && <Typography.Text>{fileName}</Typography.Text>}
        </FormField>
      </Container>
    </Modal>
  );
};

export default UploadModal;
