import { Fragment, useMemo } from 'react';
import { Form as BsForm, Col, InputGroup, Row, Table } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import TextareaAutosize from 'react-textarea-autosize';
import { useList } from 'react-use';

import { getGuid } from '../../../utils';
import AutosuggestInput from '../../AutosuggestInput';
import Button from '../../Button';
import Container from '../../Container';
import ImageFileInput from '../../ImageFileInput';
import Input from '../../Input';
import PatchForm from '../../PatchForm';
import PostForm from '../../PostForm';
import { InstitutionFormProps } from './types';

const entityValueFn = countryCode => countryCode.countryName;
const getSectionSuggestions = section => section.countryCodes;

const onSuggestionsFetchRequested = ({ entities, entityValueFn, value }) =>
  entities
    .map(section => ({
      title: section.title,
      countryCodes: section.countryCodes.filter(entity =>
        entityValueFn(entity).toLowerCase().includes(value.toLowerCase()),
      ),
    }))
    .filter(section => section.countryCodes.length > 0);

const InstitutionForm = ({
  id,
  name = '',
  notes = '',
  studentRateEligible = false,
  caseStudiesAccess = false,
  professorToolkitAccess = false,
  unlimitedBmcAccess = false,
  emailDomains: initialEmailDomains = [],
  href,
  adminInstitutionsPath,
  countryCode,
  countryCodes: initialCountryCodes,
  sponsoredLogo,
  layoutThemes: initialLayoutThemes,
  layoutTheme,
  category: initialCategory,
  categories,
}) => {
  const [Form, action, submitText] = id
    ? [PatchForm, href, 'Update']
    : [PostForm, adminInstitutionsPath, 'Submit'];
  const { register, watch } = useForm();
  const category = watch('category', initialCategory);
  const [emailDomains, emailDomainActions] = useList(initialEmailDomains);
  const handleAddDomain = () => emailDomainActions.push({ guid: getGuid() });
  const [removedEmailDomains, { push: addRemovedDomain }] = useList([]);
  const layoutThemes = initialLayoutThemes.map(({ id, name }) => [id, name]);

  const countryCodes = useMemo(
    () =>
      Object.values(
        initialCountryCodes.reduce((acc, countryCode) => {
          if (!acc[countryCode.region]) {
            acc[countryCode.region] = {
              title: countryCode.region,
              countryCodes: [],
            };
          }
          acc[countryCode.region].countryCodes.push(countryCode);
          return acc;
        }, {}),
      ),
    [initialCountryCodes],
  );

  return (
    <Container>
      <div className="d-flex align-items-center">
        <h1>{id ? 'Edit' : 'Add'} Institution</h1>
        {href && (
          <Button className="ml-4" variant="light" href={href} size="sm">
            Cancel
          </Button>
        )}
      </div>
      <Form
        action={action}
        encType="multipart/form-data"
        submitText={submitText}>
        <Input required name="name" defaultValue={name} />
        <AutosuggestInput
          required
          multiSection
          getSectionSuggestions={getSectionSuggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          name="countryCodeId"
          label="Country"
          isFetchAsync={false}
          shouldRenderSuggestions={true}
          entityValueFn={entityValueFn}
          initialSuggestions={countryCodes}
          entity={countryCode}
        />
        <Input name="notes" as={TextareaAutosize} defaultValue={notes} />

        <Input
          ref={register}
          as="select"
          options={categories}
          defaultValue={category}
          name="category"
          label="Category"
        />

        <Input
          as="select"
          options={layoutThemes}
          includeBlank
          defaultValue={layoutTheme?.id ?? ''}
          name="layoutThemeId"
          label="Sponsored Layout Theme"
        />

        <ImageFileInput
          label="Sponsored Logo"
          name="sponsoredLogo"
          accept=".jpg, .png"
          helpText="upload the largest available version"
          value={sponsoredLogo}
        />

        <div className="d-flex align-items-center border-top pt-3 my-3">
          <h4 className="m-0">Email Domains</h4>
          <Button
            className="ml-4"
            onClick={handleAddDomain}
            variant="outline-dark"
            size="sm">
            Add Domain
          </Button>
        </div>

        <Table size="sm">
          <thead>
            <tr>
              <th>Domain</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {emailDomains.map(
              ({ id, domain = '', errors = {}, guid = '' }, index) => (
                <tr key={`${guid}:${domain}`}>
                  <td>
                    <InputGroup size="sm">
                      <InputGroup.Prepend>
                        <InputGroup.Text>@</InputGroup.Text>
                      </InputGroup.Prepend>
                      <BsForm.Control
                        required
                        isInvalid={!!errors.domain}
                        readOnly={!!id}
                        defaultValue={domain}
                        name="emailDomainsAttributes[][domain]"
                        placeholder="example.edu"
                      />
                      {errors.domain && (
                        <BsForm.Control.Feedback type="invalid">
                          {errors.domain.join(', ')}
                        </BsForm.Control.Feedback>
                      )}
                      <input
                        type="hidden"
                        defaultValue={id}
                        name="emailDomainsAttributes[][id]"
                      />
                    </InputGroup>
                  </td>
                  <td>
                    <Button
                      variant="link"
                      className="text-danger"
                      icon="trash-alt"
                      size="sm"
                      onClick={() => {
                        if (id) addRemovedDomain(id);
                        emailDomainActions.removeAt(index);
                      }}
                    />
                  </td>
                </tr>
              ),
            )}
          </tbody>
        </Table>

        {removedEmailDomains.map(id => (
          <Fragment key={id}>
            <input
              type="hidden"
              name="emailDomainsAttributes[][id]"
              defaultValue={id}
            />
            <input
              type="hidden"
              name="emailDomainsAttributes[][_destroy]"
              defaultValue="1"
            />
          </Fragment>
        ))}

        <h4 className="border-top pt-3 mt-3">Features</h4>
        <Row>
          <Col>
            <Input
              name="studentRateEligible"
              defaultChecked={studentRateEligible}
              type="switch"
              helpText="This refers to discounted price students receive for BMC content"
            />
            <Input
              name="caseStudiesAccess"
              defaultChecked={caseStudiesAccess}
              type="switch"
              helpText="Users that sign-up with an e-mail domain tied to this institution will get access to case studies"
            />
          </Col>
          <Col>
            <Row>
              <Col>
                <Input
                  label="Unlimited BMC Access"
                  name="unlimitedBmcAccess"
                  defaultChecked={unlimitedBmcAccess}
                  type="switch"
                  helpText="Users that sign-up with an e-mail domain tied to this institution will get free BMC"
                />
                <Input
                  label="Resource Center Access"
                  name="professorToolkitAccess"
                  defaultChecked={professorToolkitAccess}
                  type="switch"
                  helpText="Users that sign-up with an e-mail domain tied to this institution will get access to the resource center"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

InstitutionForm.propTypes = InstitutionFormProps;

export default InstitutionForm;
