import { arrayOf, shape, string } from 'prop-types';

import { TableProps, WebinarProps, WebinarSeriesProps } from '../../types';

export const IndexProps = {
  ...TableProps,
  newAdminWebinarPath: string,
  adminWebinarsPath: string,
  webinars: arrayOf(shape(WebinarProps)),
};

export const WebinarFormProps = {
  ...WebinarProps,
  adminWebinarsPath: string,
  allWebinarSeries: arrayOf(shape(WebinarSeriesProps)),
};

export const ActionsProps = {
  row: shape({
    original: shape(WebinarProps).isRequired,
  }).isRequired,
};
