import {
  Checkbox,
  FormField,
  GlobalStyles,
  Input,
  ThemeProvider,
  Typography,
} from '@bbnpm/bb-ui-framework';
import { string } from 'prop-types';
import { useToggle } from 'react-use';

import Container from '../Container';
import PostForm from '../PostForm';
import {
  StyledActionContainer,
  StyledBorderContainer,
  StyledBssoButton,
  StyledButton,
  StyledCaption,
  StyledLink,
  StyledLinksContainer,
  StyledTitleContainer,
} from './styles';

const New = ({
  url,
  newUserRegistrationPath,
  newUserPasswordPath,
  newUserConfirmationPath,
  bssoAuthorizePath,
}) => {
  const [rememberMe, toggleRememberMe] = useToggle(false);

  return (
    <ThemeProvider>
      <GlobalStyles />
      <Container>
        <StyledBorderContainer>
          <StyledTitleContainer>
            <Typography.PageTitle>
              <strong>Log In</strong>
            </Typography.PageTitle>
          </StyledTitleContainer>

          <PostForm action={bssoAuthorizePath}>
            <StyledBssoButton
              type="submit"
              kind="tertiary"
              icon="bloomberg-terminal">
              Bloomberg Professional Client Log In
            </StyledBssoButton>
          </PostForm>

          <StyledTitleContainer margin="0 0 1rem 0">
            <Typography.Label>OR</Typography.Label>
          </StyledTitleContainer>

          <PostForm action={url}>
            <FormField>
              <Input name="user[email]" placeholder="Email (Username)" />
            </FormField>
            <FormField>
              <Input
                name="user[password]"
                type="password"
                placeholder="Password"
              />
            </FormField>
            <StyledActionContainer>
              <Checkbox label="Remember Me" onClick={toggleRememberMe} />
              <Input
                type="hidden"
                name="user[remember_me]"
                value={rememberMe ? '1' : '0'}
              />
              <StyledCaption>
                Don&apos;t have an account?{' '}
                <StyledLink href={newUserRegistrationPath}>Sign Up</StyledLink>
              </StyledCaption>
            </StyledActionContainer>
            <FormField>
              <StyledButton kind="secondary" type="submit">
                Log In
              </StyledButton>
            </FormField>
            <Typography.Hint>
              <StyledLinksContainer>
                <StyledLink href={newUserPasswordPath}>
                  Forgot your password?
                </StyledLink>{' '}
                <br />
                <StyledLink href={newUserConfirmationPath}>
                  Didn&apos;t receive activation instructions?
                </StyledLink>
              </StyledLinksContainer>
            </Typography.Hint>
          </PostForm>
        </StyledBorderContainer>
      </Container>
    </ThemeProvider>
  );
};

New.propTypes = {
  url: string.isRequired,
  newUserRegistrationPath: string.isRequired,
  newUserPasswordPath: string.isRequired,
  newUserConfirmationPath: string.isRequired,
  bssoAuthorizePath: string.isRequired,
};

export default New;
