import { Button, Modal, Typography } from '@bbnpm/bb-ui-framework';
import { useToggle } from 'react-use';

import { destroy } from '../../../api';

const ActionsCell = ({ row, refreshData }) => {
  const [showDeleteModal, toggleShowDeleteModal] = useToggle(false);
  return (
    <div>
      <Button kind="destruction" icon="trash" onClick={toggleShowDeleteModal} />

      <DeleteModal
        row={row}
        showDeleteModal={showDeleteModal}
        toggleShowDeleteModal={toggleShowDeleteModal}
        refreshData={refreshData}
      />
    </div>
  );
};

export default ActionsCell;

const DeleteModal = ({
  row,
  showDeleteModal,
  toggleShowDeleteModal,
  refreshData,
}) => {
  const handleDelete = async () => {
    try {
      await destroy(row.original.adminHref);
      refreshData();
    } catch (error) {
      alert('Something went wrong.');
    }
    toggleShowDeleteModal();
  };
  return (
    <Modal
      portalContainerId="bfe-root"
      title={`Delete Class Enrollment`}
      isOpen={showDeleteModal}
      primaryActions={
        <>
          <Button kind="secondary" onClick={toggleShowDeleteModal}>
            Cancel
          </Button>
          <Button kind="destruction" onClick={handleDelete}>
            Delete
          </Button>
        </>
      }>
      <Typography.Text>
        Please click delete to confirm removing{' '}
        <strong>{row.original.user.fullName}</strong> from class.
      </Typography.Text>
    </Modal>
  );
};
