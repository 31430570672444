import {
  Button,
  GlobalStyles,
  Modal,
  ThemeProvider,
  Typography,
} from '@bbnpm/bb-ui-framework';
import { useState } from 'react';

import { destroy } from '../../../api';
import CheckOrMinusIcon from '../../CheckOrMinusIcon';
import Container from '../../Container';
import DaperImgCell from '../../DaperImgCell';
import Table from '../../Table';
import { useFetchData } from '../../utils';
import { useIsMounted } from '../../utils';
import PromoItemPositionSelector from './PromoItemPositionSelector';
import { ActionsProps, IndexProps, StatusProps } from './types';

const Actions = ({ row, refreshData }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const onClose = () => setShowDeleteModal(false);
  const rowData = row.original;
  const handleDelete = async () => {
    try {
      await destroy(row.original.adminPromoItemPath);
      onClose();
      refreshData();
    } catch (error) {
      alert('Something went wrong.');
    }
  };

  return (
    <span className="text-nowrap">
      <Button
        as="a"
        title="Edit"
        icon="pencil"
        kind="secondary"
        size="sm"
        href={row.original.editAdminPromoItemPath}
      />
      <Button
        title="Delete"
        icon="trash"
        kind="destruction"
        size="sm"
        className="ml-2"
        onClick={() => setShowDeleteModal(true)}
      />
      <Modal
        portalContainerId="bfe-root"
        isOpen={showDeleteModal}
        onClose={onClose}
        title="Confirm Deletion"
        primaryActions={
          <>
            <Button kind="secondary" onClick={onClose} size="sm">
              Cancel
            </Button>
            <Button kind="destruction" onClick={handleDelete} size="sm">
              Delete
            </Button>
          </>
        }>
        <Typography.Text>
          Are you sure you want to delete this item:
        </Typography.Text>
        <Typography.CodeBlock>
          id: {rowData.id} - {rowData.title}
        </Typography.CodeBlock>
      </Modal>
    </span>
  );
};
Actions.propTypes = ActionsProps;

const Status = ({ cell }) => <CheckOrMinusIcon isTrue={!!cell.value} />;
Status.propTypes = StatusProps;

const columns = [
  { Header: 'ID', accessor: 'id', filteredAs: 'number' },
  {
    Header: 'Thumbnail',
    accessor: 'thumbnail',
    disableSortBy: true,
    cellProps: { className: 'text-center' },
    Cell: DaperImgCell,
  },
  { Header: 'Pos', accessor: 'position' },
  { Header: 'Created', accessor: 'createdAt' },
  { Header: 'Title', accessor: 'title', filteredAs: 'string' },
  {
    Header: 'Show For',
    accessor: 'showFor',
    filteredAs: 'string',
    disableSortBy: true,
  },
  {
    Header: 'Active',
    accessor: 'active',
    disableSortBy: true,
    Cell: Status,
  },
  {
    accessor: 'n/a',
    id: 'actions',
    disableSortBy: true,
    Cell: Actions,
  },
];

const cb = ({ totalCount, pageSize, promoItems }) => ({
  data: promoItems,
  pageSize,
  totalCount,
});

const Index = ({
  promoItems,
  adminPromoItemsPath,
  newAdminPromoItemPath,
  updateOrderAdminPromoItemsPath,
  totalCount,
  pageSize,
  pageIndex,
  sortBy,
}) => {
  const fetchData = useFetchData(adminPromoItemsPath, cb);
  const [{ refreshData }, setRefreshDataFn] = useState({
    refreshData: () => {},
  });
  const isMounted = useIsMounted();

  return (
    <ThemeProvider>
      <GlobalStyles />
      <Container>
        <div className="d-flex align-items-center mb-3">
          <Typography.PageTitle>Promo Items</Typography.PageTitle>
          <Button
            as="a"
            href={newAdminPromoItemPath}
            className="ml-4"
            kind="primary">
            Add Promo Item
          </Button>
        </div>

        <Table
          initialData={promoItems}
          columns={columns}
          initialPageSize={pageSize}
          initialPageIndex={pageIndex}
          initialSortBy={sortBy}
          initialTotalCount={totalCount}
          fetchData={fetchData}
          onRefreshDataFn={setRefreshDataFn}
          refreshData={refreshData}
        />
        {isMounted && (
          <PromoItemPositionSelector
            promoItems={promoItems}
            updateOrderAdminPromoItemsPath={updateOrderAdminPromoItemsPath}
            refreshData={refreshData}
          />
        )}
      </Container>
    </ThemeProvider>
  );
};

Index.propTypes = IndexProps;

export default Index;
