import cn from 'classnames';
import { bool, string } from 'prop-types';
import { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useToggle } from 'react-use';

import { post } from '../../api';
import Input from '../Input';
import Spinner from '../Spinner';
import ErrorAlert from './ErrorAlert';

const IsValidIcon = ({ isValid }) => (
  <i
    className={cn(
      'fas fa-lg mr-2',
      `fa-${isValid ? 'check' : 'times'}-circle`,
      `text-${isValid ? 'success' : 'danger'}`,
    )}
  />
);

IsValidIcon.propTypes = {
  isValid: bool,
};

const passwordCriteria = [
  [/[A-Z]/, 'Contain at least 1 uppercase letter'],
  [/[a-z]/, 'Contain at least 1 lowercase letter'],
  [/[0-9]/, 'Contain at least 1 number'],
  [/[!@#$%^&*()_+={}[\]<>~:;"']/, 'Contain at least 1 special character'],
];

const CreateAccountForm = ({
  action,
  userCreatedConfirmationPath,
  loginName,
  cellPhone,
  countryCallingCode,
}) => {
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [isSubmitting, toggleIsSubmitting] = useToggle(false);
  const [isErrorPresent, setIsErrorPresent] = useState(false);

  const passwordCriteriaChecked = passwordCriteria.map(([regex, desc]) => [
    regex.test(password),
    desc,
  ]);

  const isCorrectLength = password.length >= 8 && password.length <= 12;

  const meetsAtLeastThree =
    passwordCriteriaChecked.map(([isValid]) => isValid).filter(Boolean)
      .length >= 3;

  const passwordConfirmMatches = password === passwordConfirmation;

  const handleSubmit = async e => {
    try {
      e.preventDefault();
      toggleIsSubmitting();

      const response = await post(action, {
        password,
        countryPhoneCode: countryCallingCode,
        cellPhoneNumberLocal: cellPhone,
      });

      if (!response.userCreated || response.errors.failure) {
        setIsErrorPresent(true);
        toggleIsSubmitting();
      } else {
        window.Turbolinks.visit(userCreatedConfirmationPath);
      }
    } catch (error) {
      toggleIsSubmitting();
      setIsErrorPresent(true);
    }
  };

  return (
    <Row>
      <Col md={{ span: 6, offset: 3 }}>
        <form onSubmit={handleSubmit}>
          {isErrorPresent && <ErrorAlert />}

          <Input
            label={`Create a password for ${loginName || ''}:`}
            placeholder="Password"
            name="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            type="password"
          />

          <Input
            label={false}
            placeholder="Confirm Password"
            value={passwordConfirmation}
            name="passwordConfirmation"
            onChange={e => setPasswordConfirmation(e.target.value)}
            type="password"
          />

          <p className="font-weight-bold">Password must:</p>

          <ul className="font-size-sm list-unstyled pl-4">
            <li>
              <IsValidIcon isValid={isCorrectLength} />
              Contain 8-12 characters
            </li>
            <li>
              <div className="font-weight-bold">
                <IsValidIcon isValid={meetsAtLeastThree} />
                Meet 3 of the following 4 criteria
              </div>
              <ul className="list-unstyled pl-4">
                {passwordCriteriaChecked.map(([isValid, desc], i) => (
                  <li key={i}>
                    <IsValidIcon isValid={isValid} /> {desc}
                  </li>
                ))}
              </ul>
            </li>
          </ul>

          <Button
            type="submit"
            className="px-4"
            disabled={
              !isCorrectLength ||
              !meetsAtLeastThree ||
              isSubmitting ||
              !passwordConfirmMatches
            }>
            {isSubmitting && <Spinner />}
            {isSubmitting ? ' Processing...' : 'Create Account'}
          </Button>
        </form>
      </Col>
    </Row>
  );
};

CreateAccountForm.propTypes = {
  action: string.isRequired,
  userCreatedConfirmationPath: string.isRequired,
  loginName: string.isRequired,
  cellPhone: string.isRequired,
  countryCallingCode: string.isRequired,
};

export default CreateAccountForm;
