import { Typography } from '@bbnpm/bb-ui-framework';
import cn from 'classnames';
import { node, oneOfType, string } from 'prop-types';
import { isValidElement } from 'react';

import { StyledPageTitle, TitleContainer, TitleHero } from './styles';

const TitleCard = ({
  title,
  description,
  backgroundImg,
  containerClassName,
  sideContent,
}) => (
  <TitleHero
    style={{
      backgroundImage: `url(${backgroundImg})`,
      backgroundRepeat: 'repeat-y',
    }}>
    <TitleContainer
      className={cn(
        containerClassName,
        sideContent ? 'd-flex justify-content-between' : undefined,
      )}>
      <div>
        <StyledPageTitle>{title}</StyledPageTitle>
        {isValidElement(description) ? (
          description
        ) : (
          <Typography.Text>{description}</Typography.Text>
        )}
      </div>
      {sideContent}
    </TitleContainer>
  </TitleHero>
);

TitleCard.propTypes = {
  title: string.isRequired,
  description: oneOfType([string, node]).isRequired,
  backgroundImg: string,
  containerClassName: string,
  sideContent: node,
};

export default TitleCard;
