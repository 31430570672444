import { Button, Typography } from '@bbnpm/bb-ui-framework';
import { Col } from 'react-bootstrap';

import DaperImg from '../DaperImg';
import {
  CourseCategoryInfo,
  StyledCourseCard,
  StyledRow,
} from './courseCategories.styles';
import { CourseCardProps } from './types';

const certificationStatus = status => {
  switch (status) {
    case 'NOT_STARTED':
      return 'Learn More';
    case 'IN_PROGRESS':
      return 'In Progress';
    case 'COMPLETED':
      return 'Completed';
    default:
      return 'Learn More';
  }
};

const CourseCard = ({ courseCategory }) => (
  <StyledCourseCard
    href={`/courses?course_category_id=${courseCategory.id}`}
    className="link-unstyled mb-4">
    <StyledRow>
      <Col xs={5}>
        {courseCategory.thumbnail && (
          <DaperImg
            metadata={courseCategory.thumbnail}
            style={{
              margin: '1.5rem 0',
              padding: '0.25rem',
              backgroundColor: 'white',
              width: '100%',
            }}
          />
        )}
      </Col>
      <Col xs={7}>
        <CourseCategoryInfo>
          <Typography.PageTitle>{courseCategory.title}</Typography.PageTitle>
          <Typography.Text>{courseCategory.description}</Typography.Text>
          <Button kind="secondary">
            {certificationStatus(courseCategory.certificationStatus)}
          </Button>
        </CourseCategoryInfo>
      </Col>
    </StyledRow>
  </StyledCourseCard>
);

CourseCard.propTypes = CourseCardProps;

export default CourseCard;
