import { Token, Typography } from '@bbnpm/bb-ui-framework';
import { shape, string } from 'prop-types';

import DaperImg from '../DaperImg';
import { ImageFileProps } from '../types';
import { TitleFooterItem } from './styles';

const KeepWatching = ({
  href,
  thumbnail,
  courseVersionName,
  courseCategoryTitle,
}) => (
  <TitleFooterItem className="col-md-6" as="a" href={href}>
    <Typography.SectionTitle>Keep Watching</Typography.SectionTitle>
    <div className="d-flex h-60">
      <DaperImg metadata={thumbnail} height={100} />
      <div className="pl-3">
        <Typography.SubSectionTitle>
          {courseVersionName}
        </Typography.SubSectionTitle>
        <Typography.Label>in {courseCategoryTitle}</Typography.Label>
        <br />
        <Token className="mt-1">In Progress</Token>
      </div>
    </div>
  </TitleFooterItem>
);

KeepWatching.propTypes = {
  href: string.isRequired,
  courseVersionName: string.isRequired,
  courseCategoryTitle: string.isRequired,
  thumbnail: shape(ImageFileProps),
};

export default KeepWatching;
