import { useState } from 'react';
import { Form, Media } from 'react-bootstrap';

import { update } from '../../../api';
import Button from '../../Button';
import CheckOrMinusCell from '../../CheckOrMinusCell';
import Container from '../../Container';
import DaperImg from '../../DaperImg';
import DaperImgCell from '../../DaperImgCell';
import DateCell from '../../DateCell';
import DurationCell from '../../DurationCell';
import Table from '../../Table';
import { useFetchData } from '../../utils';
import {
  ActionsProps,
  FeaturedCellProps,
  FeaturedVideoPanelProps,
  ShowProps,
} from './types';

const FeaturedVideoPanel = ({ video }) => {
  return (
    <div>
      {video ? (
        <Media>
          {video.thumbnail && (
            <a title="Update video details" href={video.editAdminVideoPath}>
              <DaperImg
                metadata={video.thumbnail}
                width={150}
                height={100}
                className="mr-3"
              />
            </a>
          )}
          <Media.Body>
            <h5>{video.name}</h5>
            <p>{video.description}</p>
          </Media.Body>
        </Media>
      ) : (
        <p className="text-muted">
          No featured video selected. Select one from list below.
        </p>
      )}
    </div>
  );
};

FeaturedVideoPanel.propTypes = FeaturedVideoPanelProps;

const Actions = ({ row }) => {
  return (
    <span className="text-nowrap">
      <Button
        title="Edit"
        icon="edit"
        variant="light"
        size="sm"
        href={row.original.editAdminVideoPath}
      />
    </span>
  );
};
Actions.propTypes = ActionsProps;

const FeaturedCell = ({
  row,
  seriesUpdatePath,
  featuredVideoId,
  setFeaturedVideoId,
}) => {
  const { id } = row.original;
  const isFeatured = id === featuredVideoId;
  const onSwitchToggled = async () => {
    try {
      const nextFeaturedVideoId = isFeatured ? null : id;
      await update(seriesUpdatePath, { featuredVideoId: nextFeaturedVideoId });
      setFeaturedVideoId(nextFeaturedVideoId);
    } catch (error) {
      alert('Something went wrong');
    }
  };

  return (
    <span key={id} className="text-nowrap">
      <Form.Switch
        id={id}
        label=""
        onChange={onSwitchToggled}
        checked={isFeatured}
      />
    </span>
  );
};

FeaturedCell.propTypes = FeaturedCellProps;

const columns = [
  { Header: 'ID', accessor: 'id' },
  {
    Header: 'Thumbnail',
    accessor: 'thumbnail',
    disableSortBy: true,
    cellProps: { className: 'text-center' },
    Cell: DaperImgCell,
  },
  { Header: 'Name', accessor: 'name' },
  {
    Header: 'Active',
    accessor: 'active',
    Cell: CheckOrMinusCell,
  },
  {
    Header: 'Created',
    accessor: 'createdAt',
    Cell: DateCell,
  },
  {
    Header: 'Uploaded',
    accessor: 'uploadedAt',
    Cell: DateCell,
  },
  {
    Header: 'Type',
    accessor: 'type',
  },
  {
    Header: '# Qs',
    accessor: 'numberOfQuestions',
  },
  {
    Header: 'Time',
    accessor: 'durationMins',
    Cell: DurationCell,
  },
  {
    accessor: 'n/a',
    id: 'Featured',
    Header: 'Featured',
    disableSortBy: true,
    cellProps: { className: 'text-center' },
    Cell: FeaturedCell,
  },
  {
    accessor: 'n/a',
    id: 'actions',
    disableSortBy: true,
    Cell: Actions,
  },
];

const cb = ({ totalCount, pageSize, videos }) => ({
  data: videos,
  pageSize,
  totalCount,
});

const leadVideo = (featuredVideoId, videos) => {
  let featuredVideo = null;
  if (featuredVideoId) {
    featuredVideo = videos.find(v => v.id === featuredVideoId);
  }
  return featuredVideo;
};

const Show = ({
  name,
  description,
  createdAt,
  active,
  featuredVideoId: initialFeaturedVideoId,
  videos,
  path,
  adminHref,
  totalCount,
  pageSize,
  pageIndex,
  sortBy,
}) => {
  const fetchData = useFetchData(path, cb);
  const [featuredVideoId, setFeaturedVideoId] = useState(
    initialFeaturedVideoId,
  );

  return (
    <Container>
      <h1>{name}</h1>

      <p>
        <strong>Created at: </strong> {createdAt}
      </p>

      <p>
        <strong>Active: </strong> {active ? 'Yes' : 'No'}
      </p>

      <h4>Description:</h4>
      <p>{description}</p>

      <h4>Featured Video:</h4>
      <FeaturedVideoPanel video={leadVideo(featuredVideoId, videos)} />

      <Table
        initialData={videos}
        columns={columns}
        initialPageSize={pageSize}
        initialPageIndex={pageIndex}
        initialSortBy={sortBy}
        initialTotalCount={totalCount}
        fetchData={fetchData}
        featuredVideoId={featuredVideoId}
        setFeaturedVideoId={setFeaturedVideoId}
        seriesUpdatePath={adminHref}
      />
    </Container>
  );
};

Show.propTypes = ShowProps;

export default Show;
