import { Button, Col, Row } from 'react-bootstrap';

import PostForm from '../PostForm';
import UserStatus, { STATUS } from '../UserStatus';
import { CourseModuleProps } from './types';

const CourseModule = ({
  name,
  position,
  registerCourseModuleCourseVersionPath,
  status,
  needsReset,
}) => (
  <Row noGutters className="course-module">
    <Col xs={1}>
      <div className="align-items-center d-flex h-100 justify-content-center h1">
        {position + 1}
      </div>
    </Col>
    <Col className="py-2 pl-2">
      <div>
        <strong>{name}</strong>
      </div>
      <Row className="align-items-center">
        <Col>
          <UserStatus status={status} />
        </Col>
        <Col xs="auto">
          {needsReset ? (
            <Button disabled className="px-5" variant="secondary">
              Reset Course to Resume
            </Button>
          ) : (
            <PostForm
              action={registerCourseModuleCourseVersionPath}
              submitText={
                status && status !== STATUS.NOT_STARTED ? 'Resume' : 'Start'
              }
              submitProps={{ className: 'px-5', variant: 'outline-dark' }}
            />
          )}
        </Col>
      </Row>
    </Col>
  </Row>
);

CourseModule.propTypes = CourseModuleProps;

export default CourseModule;
