import { Typography } from '@bbnpm/bb-ui-framework';
import styled from 'styled-components';

export const AddContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 20px;
`;

export const StyledLink = styled.a`
  color: ${({ theme }) => theme.colors.black};
  &:hover {
    text-decoration: none;
  }
`;

export const StyledGroupLabel = styled.div`
  padding-top: 20px;
  display: flex;
  align-items: baseline;
`;

export const StyledNumberText = styled(Typography.Text)`
  color: ${({ theme }) => theme.colors.amber[500]};
`;

export const StyledItemLabel = styled.div`
  width: 1000px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
`;

export const StyledCompleted = styled(Typography.Caption)`
  color: ${({ theme }) => theme.colors.green[600]};
`;

export const StyledInProgress = styled(Typography.Caption)`
  color: ${({ theme }) => theme.colors.blue[600]};
`;

export const StyledScore = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
