import { string } from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import { useToggle } from 'react-use';

const ChangeReadonlyButton = ({ readOnlyFields }) => {
  const [isOpen, toggleIsOpen] = useToggle(false);
  return (
    <>
      <Button variant="link" className="btn-text" onClick={toggleIsOpen}>
        Change
      </Button>
      <Modal show={isOpen} onHide={toggleIsOpen}>
        <Modal.Header>
          <Modal.Title>Update profile information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            You are not authorized to update the following information:{' '}
            {readOnlyFields}
          </p>
          <div>
            If you would like to update them, please send an email to{' '}
            <a href={`mailto:bbg.edu@bloomberg.net`}>bbg.edu@bloomberg.net</a>.
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="dark" onClick={toggleIsOpen}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

ChangeReadonlyButton.propTypes = { readOnlyFields: string.isRequired };

export default ChangeReadonlyButton;
