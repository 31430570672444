import { func, string } from 'prop-types';
import { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useToggle } from 'react-use';

import { post } from '../../api';
import Spinner from '../Spinner';
import ErrorAlert from './ErrorAlert';

const VerificationMethodForm = ({
  action,
  nextStep,
  prevStep,
  email,
  cellPhone,
  countryCallingCode,
  verificationMethod,
  setVerificationMethod,
  incTokenSentCounter,
}) => {
  const [isSubmitting, toggleIsSubmitting] = useToggle(false);
  const [isErrorPresent, setIsErrorPresent] = useState(false);

  const handleSubmit = async e => {
    // Move to the nex wizard page if no errors are found.
    try {
      e.preventDefault();
      toggleIsSubmitting();

      const response = await post(action, {
        tokenDeliveryMethod: verificationMethod,
        cellPhone: countryCallingCode + cellPhone,
      });

      if (response.errors.failure) {
        setIsErrorPresent(true);
        toggleIsSubmitting();
      } else {
        toggleIsSubmitting();
        incTokenSentCounter();
        nextStep();
      }
    } catch (error) {
      setIsErrorPresent(true);
      toggleIsSubmitting();
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col md={{ span: 6, offset: 3 }}>
          {isErrorPresent && <ErrorAlert />}
          <p>Select a verification method:</p>

          <Form.Group as={Form.Row} className="mb-0" controlId="email">
            <Form.Label column sm={3}>
              Email
            </Form.Label>
            <Col sm={9} className="d-flex align-items-center">
              <Form.Check
                name="verification_method"
                type="radio"
                checked={verificationMethod === 'METHOD_EMAIL'}
                onChange={e => setVerificationMethod(e.target.value)}
                label={email}
                value="METHOD_EMAIL"
              />
            </Col>
          </Form.Group>

          <Form.Group as={Form.Row} className="mb-0" controlId="sms">
            <Form.Label column sm={3}>
              SMS Text
            </Form.Label>
            <Col sm={9} className="d-flex align-items-center">
              <Form.Check
                name="verification_method"
                type="radio"
                checked={verificationMethod === 'METHOD_SMS'}
                onChange={e => setVerificationMethod(e.target.value)}
                label={`+${countryCallingCode}-${cellPhone}`}
                value="METHOD_SMS"
              />
            </Col>
          </Form.Group>

          <div className="d-flex justify-content-between mt-2">
            <Button className="px-3" disabled={isSubmitting} onClick={prevStep}>
              Previous
            </Button>
            <Button type="submit" className="px-4" disabled={isSubmitting}>
              {isSubmitting && <Spinner />}
              {isSubmitting ? ' Processing...' : 'Next'}
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

VerificationMethodForm.propTypes = {
  action: string.isRequired,
  nextStep: func.isRequired,
  prevStep: func.isRequired,
  email: string.isRequired,
  cellPhone: string.isRequired,
  countryCallingCode: string.isRequired,
  verificationMethod: string.isRequired,
  setVerificationMethod: func.isRequired,
  incTokenSentCounter: func.isRequired,
};

export default VerificationMethodForm;
