import {
  Button,
  FormField,
  GlobalStyles,
  Input,
  ThemeProvider,
  Typography,
} from '@bbnpm/bb-ui-framework';
import { object, string } from 'prop-types';

import Container from '../Container';
import PostForm from '../PostForm';

const New = ({ url, errors = {} }) => {
  const formStyle = {
    width: '95%',
    maxWidth: '400px',
    marginRight: 'auto',
    marginLeft: 'auto',
  };
  return (
    <ThemeProvider>
      <GlobalStyles />
      <Container>
        <PostForm action={url} style={formStyle}>
          <Typography.PageTitle>
            <strong>Resend Account Activation Link</strong>
          </Typography.PageTitle>
          <FormField
            label="Email"
            validationType={errors.email?.length && 'error'}
            validationContent={errors.email
              ?.map(error => 'Email ' + error)
              .join(', ')}
            labelDescription="Required">
            <Input name="user[email]" required />
          </FormField>
          <br />
          <Button kind="primary" type="submit">
            Resend Account Activation Link
          </Button>
        </PostForm>
      </Container>
    </ThemeProvider>
  );
};

New.propTypes = { url: string.isRequired, errors: object };

export default New;
