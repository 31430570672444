import { number, oneOfType, shape, string } from 'prop-types';

const PrimaryKey = ({ row, value }) => (
  <a title="See details" href={row.original.href}>
    {value}
  </a>
);

PrimaryKey.propTypes = {
  row: shape({
    original: shape({
      href: string.isRequired,
    }).isRequired,
  }).isRequired,
  value: oneOfType([string, number]).isRequired,
};

export default PrimaryKey;
