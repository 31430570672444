import {
  Button,
  ContextualMessage,
  Dropdown,
  FormField,
  GlobalStyles,
  Input,
  Stack,
  Switch,
  ThemeProvider,
  Typography,
} from '@bbnpm/bb-ui-framework';
import produce from 'immer';
import _ from 'lodash';
import { useState } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { Form as BsForm, Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import Container from '../../Container';
import PatchForm from '../../PatchForm';
import PostForm from '../../PostForm';
import StickyFooter from '../../StickyFooter';
import { useIsMounted } from '../../utils';
import CourseModuleDroppable from './CourseModuleDroppable';
import { FormFieldContainer, StyledLink } from './styles';
import { CourseVersionFormProps } from './types';

const SELECTABLE_ID = 'SELECTABLE_ID';
const SELECTED_ID = 'SELECTED_ID';
const idToKey = {
  [SELECTABLE_ID]: 'selectableCourseModules',
  [SELECTED_ID]: 'selectedCourseModules',
};

const CourseVersionForm = ({
  id,
  adminHref,
  internalName,
  name,
  icon,
  active: initialActive,
  requiredForCertification,
  upgradable,
  courseRegistrationsCount,
  course,
  courseModules,
  availableCourseModules,
  adminCourseCourseVersionsPath,
  activeCategories,
  errors,
}) => {
  const [Form, action, submitText] = id
    ? [PatchForm, adminHref, 'Update']
    : [PostForm, adminCourseCourseVersionsPath, 'Submit'];

  const { register, watch } = useForm();
  const active = watch('active', initialActive);

  // This is needed because DnD doesn't work with SSR
  const isMounted = useIsMounted();

  const isDnDDisabled = (courseRegistrationsCount || 0) > 0;

  const [state, setState] = useState({
    selectableCourseModules: availableCourseModules,
    selectedCourseModules: courseModules || [],
  });
  const { selectableCourseModules, selectedCourseModules } = state;

  const onDragEnd = ({ source, destination }) => {
    // dropped outside the list
    if (!destination) {
      return;
    }

    const sourceKey = idToKey[source.droppableId];
    const destKey = idToKey[destination.droppableId];

    setState(
      produce(state, draftState => {
        const [removed] = draftState[sourceKey].splice(source.index, 1);
        draftState[destKey].splice(destination.index, 0, removed);
      }),
    );
  };

  const [filterCategory, setFilterCategory] = useState(null);

  return (
    <ThemeProvider>
      <GlobalStyles />
      <Container>
        <Typography.PageTitle className="mb-3">
          {id ? 'Edit' : 'New'} Version of &quot;{course.name}&quot;
        </Typography.PageTitle>
        <Form
          action={action}
          submitRender={({ isSubmitting }) => (
            <div className="mt-3 text-center">
              <StickyFooter>
                <Button kind="primary" type="submit" disabled={isSubmitting}>
                  {submitText}
                </Button>
              </StickyFooter>
            </div>
          )}>
          <BsForm.Row>
            <Col>
              <FormFieldContainer>
                <FormField
                  label="Internal Name"
                  labelDescription="This is the name used internally"
                  validationType={errors?.internalName?.length && 'error'}
                  validationContent={errors?.internalName
                    ?.map(error => 'Internal name ' + error)
                    .join(', ')}>
                  <Input
                    name="internalName"
                    defaultValue={internalName || ''}
                    required
                  />
                </FormField>
              </FormFieldContainer>
            </Col>
            <Col>
              <FormFieldContainer>
                <FormField
                  label="Name"
                  labelDescription="This is the name visible to the public. Leave blank to inherit from course.">
                  <Input
                    name="name"
                    placeholder={course.name}
                    defaultValue={name || ''}
                    required
                  />
                </FormField>
              </FormFieldContainer>
            </Col>
          </BsForm.Row>
          <BsForm.Row>
            <Col>
              <FormFieldContainer>
                <FormField
                  label="Icon"
                  labelDescription={
                    <Typography.FormLabel>
                      FontAwesome icon.{' '}
                      <StyledLink
                        target="_blank"
                        rel="noreferrer"
                        href="https://fontawesome.com/icons?d=gallery&s=solid&m=free">
                        See list here
                      </StyledLink>
                    </Typography.FormLabel>
                  }>
                  <Input
                    name="icon"
                    placeholder="graduation-cap"
                    defaultValue={icon || ''}
                  />
                </FormField>
              </FormFieldContainer>
            </Col>
            <Col>
              <FormFieldContainer>
                <Stack>
                  <Switch
                    label="Activate this version?"
                    labelPosition="right"
                    name="active"
                    ref={register}
                    defaultChecked={initialActive}
                  />
                  {active && !initialActive && (
                    <ContextualMessage
                      kind="warning"
                      message="Making this version active will de-activate the existing active version of this course, if one is currently active."
                    />
                  )}
                  {!active && initialActive && (
                    <ContextualMessage
                      kind="warning"
                      message="Making this version inactive will effectively remove this course from the catalog."
                    />
                  )}
                  <Switch
                    label="Is this version required for certification?"
                    labelPosition="right"
                    name="requiredForCertification"
                    defaultChecked={requiredForCertification}
                  />
                  <Switch
                    label="Is this version upgradable?"
                    labelPosition="right"
                    name="upgradable"
                    defaultChecked={upgradable}
                  />
                </Stack>
              </FormFieldContainer>
            </Col>
          </BsForm.Row>

          {isDnDDisabled && (
            <ContextualMessage message="Because this version has users, changing its modules is disabled." />
          )}

          {isMounted && (
            <div className="pt-4 border-top mt-4">
              <DragDropContext onDragEnd={onDragEnd}>
                <Dropdown
                  placeholder="Filter by Course Module by Category"
                  value={filterCategory}
                  clearable={true}
                  onItemSelect={option =>
                    setFilterCategory(_.get(option, 'value', null))
                  }
                  options={activeCategories.map(name => {
                    return { label: name.toUpperCase(), value: name };
                  })}
                />
                <Row className="mb-3 mt-3">
                  <Col sm={6}>
                    <div
                      className="d-flex flex-column bg-light p-3 h-100"
                      style={{ opacity: isDnDDisabled ? 0.5 : 1 }}>
                      <h4 className="text-center">Available Modules</h4>
                      <CourseModuleDroppable
                        disabled={isDnDDisabled}
                        droppableId={SELECTABLE_ID}
                        courseModules={selectableCourseModules}
                        filterCategory={filterCategory}
                      />
                    </div>
                  </Col>
                  <Col sm={6}>
                    <div className="d-flex flex-column bg-light p-3 h-100">
                      <h4 className="text-center">Modules in this Version</h4>
                      <CourseModuleDroppable
                        disabled={isDnDDisabled}
                        droppableId={SELECTED_ID}
                        courseModules={selectedCourseModules}
                        filterCategory={filterCategory}
                        selected={true}
                      />
                    </div>
                  </Col>
                </Row>
              </DragDropContext>
            </div>
          )}
        </Form>
      </Container>
    </ThemeProvider>
  );
};

CourseVersionForm.propTypes = CourseVersionFormProps;
export default CourseVersionForm;
