import { object, string } from 'prop-types';
import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useNumber } from 'react-use';

import Container from '../Container';
import ContactInformationForm from './ContactInformationForm';
import CreateAccountForm from './CreateAccountForm';
import IdentityVerificationForm from './IdentityVerificationForm';
import VerificationMethodForm from './VerificationMethodForm';
import WizardHeaderNav from './WizardHeaderNav';

const New = ({
  countriesPath,
  initialCountry,
  email,
  userLoginName,
  reserveUserNamePath,
  sendTokenRequestPath,
  validateTokenRequestPath,
  createUserAccountRequestPath,
  userCreatedConfirmationPath,
}) => {
  const [loginName, setLoginName] = useState(userLoginName);
  const [cellPhone, setCellPhone] = useState('');
  const [step, { inc, dec }] = useNumber(1, 4, 1);
  const nextStep = () => inc();
  const prevStep = () => dec();
  const [country, setCountry] = useState(initialCountry);
  const [verificationMethod, setVerificationMethod] = useState('METHOD_EMAIL');
  const [token, setToken] = useState('');
  const [tokenSentCounter, { inc: incTokenSentCounter }] = useNumber(0);

  const countryCallingCode = country ? country.countryCode : '';

  const content =
    step === 1 ? (
      <ContactInformationForm
        country={country}
        setCountry={setCountry}
        action={reserveUserNamePath}
        nextStep={nextStep}
        email={email}
        loginName={loginName}
        setLoginName={setLoginName}
        cellPhone={cellPhone}
        setCellPhone={setCellPhone}
        countriesPath={countriesPath}
      />
    ) : step === 2 ? (
      <VerificationMethodForm
        action={sendTokenRequestPath}
        nextStep={nextStep}
        prevStep={prevStep}
        email={email}
        cellPhone={cellPhone}
        countryCallingCode={countryCallingCode}
        verificationMethod={verificationMethod}
        setVerificationMethod={setVerificationMethod}
        incTokenSentCounter={incTokenSentCounter}
      />
    ) : step === 3 ? (
      <IdentityVerificationForm
        action={validateTokenRequestPath}
        actionTokenRequest={sendTokenRequestPath}
        nextStep={nextStep}
        prevStep={prevStep}
        email={email}
        cellPhone={cellPhone}
        countryCallingCode={countryCallingCode}
        verificationMethod={verificationMethod}
        token={token}
        setToken={setToken}
        tokenSentCounter={tokenSentCounter}
        incTokenSentCounter={incTokenSentCounter}
      />
    ) : (
      <CreateAccountForm
        action={createUserAccountRequestPath}
        userCreatedConfirmationPath={userCreatedConfirmationPath}
        loginName={loginName}
        cellPhone={cellPhone}
        countryCallingCode={countryCallingCode}
      />
    );

  return (
    <Container>
      <Row>
        <Col md={{ span: 8, offset: 2 }}>
          <WizardHeaderNav step={step} />
          {content}
        </Col>
      </Row>
    </Container>
  );
};

New.propTypes = {
  countriesPath: string.isRequired,
  initialCountry: object.isRequired,
  email: string.isRequired,
  userLoginName: string,
  reserveUserNamePath: string.isRequired,
  sendTokenRequestPath: string.isRequired,
  validateTokenRequestPath: string.isRequired,
  createUserAccountRequestPath: string.isRequired,
  userCreatedConfirmationPath: string.isRequired,
};

export default New;
