import {
  Input as BBUIInput,
  Button,
  FormField,
  GlobalStyles,
  Modal,
  Stepper,
  ThemeProvider,
  Typography,
} from '@bbnpm/bb-ui-framework';
import camelcase from 'lodash.camelcase';
import uniq from 'lodash.uniq';
import { array, bool, number, object, string } from 'prop-types';
import { useMemo, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useMap, useToggle } from 'react-use';

import { resolveWithNoValue, useAsyncModal } from '../../AsyncModal';
import Container from '../../Container';
import Hero from '../../Hero';
import PatchForm from '../../PatchForm';
import PostForm from '../../PostForm';
import { useWizard } from '../../utils';
import AssignedCaseStudies from './AssignedCaseStudies';
import AssignedCourses from './AssignedCourses';
import AssignedVideos from './AssignedVideos';
import {
  StyledDatePicker,
  StyledStepperNav,
  StyledStepperNavContainer,
} from './ClassForm.styles';
import HiddenInputFieldsClassAssignments from './HiddenInputFieldsClassAssignments';

const GROUPS = {
  COURSES: 'COURSES',
  VIDEOS: 'VIDEOS',
  CASE_STUDIES: 'CASE_STUDIES',
};

const steps = Object.keys(GROUPS);

const trackClassFormEvent = (
  flatCourseContent,
  deletionStatusCourseMap,
  id,
) => {
  try {
    const courseTypes = [];
    const courseCategoryById = {};
    flatCourseContent.forEach(course => {
      courseCategoryById[course.courseVersionId] = course.courseCategoryName;
    });

    for (const courseId in deletionStatusCourseMap) {
      // false here means selected
      if (deletionStatusCourseMap[courseId] === false) {
        courseTypes.push(camelcase('with ' + courseCategoryById[courseId]));
      }
    }

    window.bfeAnalytics.trackEvent({
      category: 'professorAction',
      action: id ? 'updateClassFormSubmitted' : 'addClassFormSubmitted',
      label: uniq(courseTypes).join(''),
    });
  } catch {
    // analytics should fail silently
  }
};

const ClassForm = ({
  id,
  professorsClassesPath,
  professorHref,
  errors,
  expiredAtWithTime,
  nameAlias,
  classCourses,
  caseStudyAssignments,
  videoAssignments,
  canAccessProfessorPacks,
}) => {
  // flattening all course content
  const flatCourseContent = classCourses
    .map(classCourse => classCourse.courseContent)
    .flat();

  //courses - deletion status map
  const initialCoursesDeletionStatusMap = useMemo(() => {
    let deletionStatusMap = {};
    flatCourseContent.forEach(
      course =>
        (deletionStatusMap[course.courseVersionId] = course.setForDeletion),
    );
    return deletionStatusMap;
  }, [flatCourseContent]);

  const [deletionStatusCourseMap, { set: setDeletionStatusCourse }] = useMap(
    initialCoursesDeletionStatusMap,
  );

  //case studies - deletion status map
  const initialCaseStudiesDeletionStatusMap = useMemo(() => {
    let deletionStatusMap = {};
    caseStudyAssignments.forEach(
      cs => (deletionStatusMap[cs.professorPackId] = cs.setForDeletion),
    );
    return deletionStatusMap;
  }, [caseStudyAssignments]);

  const [deletionStatusCaseStudyMap, { set: setDeletionCaseStudy }] = useMap(
    initialCaseStudiesDeletionStatusMap,
  );

  //videos - deletion status map
  const initialVideosDeletionStatusMap = useMemo(() => {
    let deletionStatusMap = {};
    videoAssignments.forEach(
      va => (deletionStatusMap[va.videoId] = va.setForDeletion),
    );
    return deletionStatusMap;
  }, [videoAssignments]);

  const [deletionStatusVideoMap, { set: setDeletionStatusVideo }] = useMap(
    initialVideosDeletionStatusMap,
  );

  const { nextStep, hasNextStep, prevStep, hasPrevStep, value } =
    useWizard(steps);

  const [Form, action, submitText] = id
    ? [PatchForm, professorHref, 'Update']
    : [PostForm, professorsClassesPath, 'Submit'];

  const formRef = useRef();

  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  // expiredAtWithTime needs to be parsed back into a valid Date object
  const [deadline, setDeadline] = useState(
    expiredAtWithTime ? new Date(expiredAtWithTime) : tomorrow,
  );

  const { open, modalProps } = useAsyncModal();

  const [isSubmitting, toggleIsSubmitting] = useToggle(false);

  const handleSubmit = async e => {
    e.preventDefault();
    toggleIsSubmitting();

    const hasNoCoursesAndNoVideos =
      Object.values(deletionStatusCourseMap).every(Boolean) &&
      Object.values(deletionStatusVideoMap).every(Boolean);

    try {
      if (hasNoCoursesAndNoVideos) await open(resolveWithNoValue);
      formRef.current.submit();
      trackClassFormEvent(flatCourseContent, deletionStatusCourseMap, id);
    } catch {
      toggleIsSubmitting();
    }
  };

  return (
    <ThemeProvider>
      <GlobalStyles />

      <Hero
        title={'Add Class'}
        description={
          'Classes allow you to assign courses, videos and case studies to a group of learners and monitor their progress and scores.'
        }
      />

      <Container>
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          className="mb-3"
          action={action}
          submitRender={() => (
            <StyledStepperNav>
              <StyledStepperNavContainer>
                {hasPrevStep && (
                  <Button
                    disabled={isSubmitting}
                    onClick={prevStep}
                    kind="secondary">
                    Previous
                  </Button>
                )}
                {hasNextStep ? (
                  <Button kind="secondary" onClick={nextStep}>
                    Next
                  </Button>
                ) : (
                  <Button disabled={isSubmitting} kind="primary" type="submit">
                    {submitText}
                  </Button>
                )}
                <Button
                  as="a"
                  disabled={isSubmitting}
                  href={action}
                  kind="destruction">
                  Cancel
                </Button>
              </StyledStepperNavContainer>
            </StyledStepperNav>
          )}>
          <Typography.PageTitle>Insert class details</Typography.PageTitle>
          <Row>
            <Col>
              <FormField
                htmlFor="class-name"
                label="Class Name"
                labelDescription="Required"
                validationType={errors?.nameAlias ? 'error' : ''}>
                <BBUIInput
                  id="class-name"
                  name="nameAlias"
                  placeholder="Viewable by learners"
                  defaultValue={nameAlias}
                />
              </FormField>
            </Col>
            <Col>
              <FormField
                htmlFor="expired-at"
                label="Deadline"
                labelDescription="Required"
                validationType={errors?.expiredAt ? 'error' : ''}>
                <StyledDatePicker
                  name="expiredAt"
                  dateFormat="yyyy-M-dd"
                  dateSelected={deadline}
                  onDateChange={setDeadline}
                  datePlaceholderText="Deadline for learners to complete class"
                  inputWidth="100%"
                  leftAlign={true}
                  minDate={tomorrow}
                />
              </FormField>
            </Col>
          </Row>

          {canAccessProfessorPacks && (
            <HiddenInputFieldsClassAssignments
              classAssignments={caseStudyAssignments}
              deletionStatusMap={deletionStatusCaseStudyMap}
              assignmentIdName={'professorPackId'}
              assignmentAttributesName={'caseStudyAssignmentsAttributes'}
            />
          )}

          <HiddenInputFieldsClassAssignments
            classAssignments={flatCourseContent}
            deletionStatusMap={deletionStatusCourseMap}
            assignmentIdName={'courseVersionId'}
            assignmentAttributesName={'classCoursesAttributes'}
          />

          <HiddenInputFieldsClassAssignments
            classAssignments={videoAssignments}
            deletionStatusMap={deletionStatusVideoMap}
            assignmentIdName={'videoId'}
            assignmentAttributesName={'classVideosAttributes'}
          />
        </Form>

        <Typography.PageTitle>
          Select the content to be included in this class
        </Typography.PageTitle>
        <Stepper>
          <Stepper.Step
            state={value === GROUPS.COURSES ? 'active' : 'disabled'}>
            Courses
          </Stepper.Step>
          <Stepper.Step state={value === GROUPS.VIDEOS ? 'active' : 'disabled'}>
            Videos
          </Stepper.Step>
          <Stepper.Step
            state={value === GROUPS.CASE_STUDIES ? 'active' : 'disabled'}>
            Case Studies
          </Stepper.Step>
        </Stepper>

        <br></br>

        {value === GROUPS.COURSES && (
          <AssignedCourses
            classCourses={classCourses}
            deletionStatusCourseMap={deletionStatusCourseMap}
            setDeletionStatusCourse={setDeletionStatusCourse}
          />
        )}
        {value === GROUPS.VIDEOS && (
          <AssignedVideos
            videoAssignments={videoAssignments}
            deletionStatusVideoMap={deletionStatusVideoMap}
            setDeletionStatusVideo={setDeletionStatusVideo}
          />
        )}
        {value === GROUPS.CASE_STUDIES && (
          <AssignedCaseStudies
            canAccessProfessorPacks={canAccessProfessorPacks}
            caseStudyAssignments={caseStudyAssignments}
            deletionStatusCaseStudyMap={deletionStatusCaseStudyMap}
            setDeletionCaseStudy={setDeletionCaseStudy}
          />
        )}
        <Modal
          isOpen={modalProps.show}
          title="Add Class"
          portalContainerId="bfe-root"
          primaryActions={
            <>
              <Button kind="secondary" onClick={modalProps.onHide}>
                Cancel
              </Button>
              <Button
                kind="primary"
                onClick={modalProps.handleSubmit(modalProps.onSubmit)}>
                Submit
              </Button>
            </>
          }>
          You will only <strong>see</strong> progress for courses / videos which
          have been <strong>added</strong> to the class.
        </Modal>
      </Container>
    </ThemeProvider>
  );
};

ClassForm.propTypes = {
  id: number,
  expiredAtWithTime: string,
  nameAlias: string,
  errors: object,
  professorHref: string,
  professorsClassesPath: string.isRequired,
  canAccessProfessorPacks: bool.isRequired,
  classCourses: array.isRequired,
  caseStudyAssignments: array.isRequired,
  videoAssignments: array.isRequired,
};

export default ClassForm;
