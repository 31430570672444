import { useState } from 'react';
import { Form, Media } from 'react-bootstrap';

import { update } from '../../../api';
import CheckOrMinusCell from '../../CheckOrMinusCell';
import Container from '../../Container';
import DaperImg from '../../DaperImg';
import DaperImgCell from '../../DaperImgCell';
import DateCell from '../../DateCell';
import PrimaryAdminKey from '../../PrimaryAdminKey';
import Table from '../../Table';
import { useFetchData } from '../../utils';
import {
  FeaturedCellProps,
  FeaturedWebinarPanelProps,
  ShowProps,
} from './types';

const FeaturedWebinarPanel = ({ webinar }) => {
  return (
    <div>
      {webinar ? (
        <Media>
          {webinar.thumbnail && (
            <a
              title="Update webinar details"
              href={webinar.editAdminWebinarPath}>
              <DaperImg
                metadata={webinar.thumbnail}
                width={150}
                height={100}
                className="mr-3"
              />
            </a>
          )}
          <Media.Body>
            <h5>{webinar.name}</h5>
            <p>{webinar.description}</p>
          </Media.Body>
        </Media>
      ) : (
        <p className="text-muted">
          No featured webinar selected. Select one from list below.
        </p>
      )}
    </div>
  );
};

FeaturedWebinarPanel.propTypes = FeaturedWebinarPanelProps;

const FeaturedCell = ({
  row,
  webinarSeriesUpdatePath,
  featuredWebinarId,
  setFeaturedWebinarId,
}) => {
  const { id } = row.original;
  const isFeatured = id === featuredWebinarId;
  const onSwitchToggled = async () => {
    try {
      const nextFeaturedWebinarId = isFeatured ? null : id;
      await update(webinarSeriesUpdatePath, {
        featuredWebinarId: nextFeaturedWebinarId,
      });
      setFeaturedWebinarId(nextFeaturedWebinarId);
    } catch (error) {
      alert('Something went wrong');
    }
  };

  return (
    <span key={id} className="text-nowrap">
      <Form.Switch
        id={id}
        label=""
        onChange={onSwitchToggled}
        checked={isFeatured}
      />
    </span>
  );
};

FeaturedCell.propTypes = FeaturedCellProps;

const columns = [
  { Header: 'ID', accessor: 'id', Cell: PrimaryAdminKey },
  {
    Header: 'Thumbnail',
    accessor: 'thumbnail',
    disableSortBy: true,
    cellProps: { className: 'text-center' },
    Cell: DaperImgCell,
  },
  { Header: 'Name', accessor: 'name' },
  {
    Header: 'Active',
    accessor: 'active',
    disableSortBy: true,
    Cell: CheckOrMinusCell,
  },
  {
    Header: 'Featured',
    accessor: 'featured',
    disableSortBy: true,
    Cell: CheckOrMinusCell,
  },
  { Header: 'Vimeo ID', accessor: 'vimeoVideoId' },
  {
    Header: 'Created',
    accessor: 'createdAt',
    Cell: DateCell,
  },
  {
    Header: 'Occurred',
    accessor: 'occurredAt',
    Cell: DateCell,
  },
  {
    accessor: 'n/a',
    id: 'Featured',
    Header: 'Featured',
    disableSortBy: true,
    cellProps: { className: 'text-center' },
    Cell: FeaturedCell,
  },
];

const cb = ({ totalCount, pageSize, webinars }) => ({
  data: webinars,
  pageSize,
  totalCount,
});

const leadWebinar = (featuredWebinarId, webinars) => {
  let featuredWebinar = null;
  if (featuredWebinarId) {
    featuredWebinar = webinars.find(v => v.id === featuredWebinarId);
  }
  return featuredWebinar;
};

const Show = ({
  name,
  description,
  createdAt,
  active,
  featuredWebinarId: initialFeaturedWebinarId,
  webinars,
  path,
  adminHref,
  totalCount,
  pageSize,
  pageIndex,
  sortBy,
}) => {
  const fetchData = useFetchData(path, cb);
  const [featuredWebinarId, setFeaturedWebinarId] = useState(
    initialFeaturedWebinarId,
  );

  return (
    <Container>
      <h1>{name}</h1>

      <p>
        <strong>Created at: </strong> {createdAt}
      </p>

      <p>
        <strong>Active: </strong> {active ? 'Yes' : 'No'}
      </p>

      <h4>Description:</h4>
      <p>{description}</p>

      <h4>Featured Webinar:</h4>
      <FeaturedWebinarPanel
        webinar={leadWebinar(featuredWebinarId, webinars)}
      />

      <Table
        initialData={webinars}
        columns={columns}
        initialPageSize={pageSize}
        initialPageIndex={pageIndex}
        initialSortBy={sortBy}
        initialTotalCount={totalCount}
        fetchData={fetchData}
        featuredWebinarId={featuredWebinarId}
        setFeaturedWebinarId={setFeaturedWebinarId}
        webinarSeriesUpdatePath={adminHref}
      />
    </Container>
  );
};

Show.propTypes = ShowProps;

export default Show;
