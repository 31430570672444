import {
  Button,
  FormField,
  GlobalStyles,
  ThemeProvider,
  Typography,
} from '@bbnpm/bb-ui-framework';
import { number, shape, string } from 'prop-types';
import { Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';

import { toCurrency } from '../../../utils';
import Container from '../../Container';
import Hero from '../../Hero';
import { BatClassProps, ProductProps } from '../../types';

const StyledLink = styled.a`
  color: ${({ theme }) => theme.colors.blue[200]};
`;

const FormWidget = styled(Form)`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 300px;
  margin-top: 20px;
  padding: 10px;
`;

const ButtonGroup = styled.div`
  display: flex;
`;

const QuantityInput = styled(Form.Control)`
  width: 6.25rem;
`;

const Sponsor = ({
  batClass,
  product,
  paymentOrdersPath,
  marketingSiteUrl,
  usedLicenses,
  unusedLicenses,
}) => {
  const { register, watch } = useForm();
  const quantity = watch('quantity', 1);
  return (
    <ThemeProvider>
      <GlobalStyles />
      <Hero
        title={'Sponsor Web Access'}
        description={
          <Typography.Text>
            For learners who do not have access to the Bloomberg Terminal or
            would like to take BMC at home, you may purchase web licenses for
            them to access BMC online at{' '}
            <StyledLink href={marketingSiteUrl}>{marketingSiteUrl}</StyledLink>
            <br />
            <br />
            <strong>
              Web licenses will be granted on a first-come, first-served basis
              to learners who sign up to BMC with your class code.
            </strong>
          </Typography.Text>
        }
        link={{
          path: `/professors/classes/${batClass.id}`,
          name: 'Back to Class',
          icon: 'arrow-left',
        }}
      />
      <Container>
        <FormWidget action={paymentOrdersPath} method="GET">
          <Typography.Text>
            Unused Licenses: {unusedLicenses} &nbsp; Used Licenses:{' '}
            {usedLicenses}
          </Typography.Text>
          <strong>Buy Web Licenses For {batClass.nameAlias}</strong>
          <input name="batClassId" type="hidden" defaultValue={batClass.id} />
          <input name="productSku" type="hidden" defaultValue={product.sku} />
          <Form.Label>{product.name}</Form.Label>
          <FormField label="Number of Students" labelPosition="left">
            <QuantityInput
              name="quantity"
              type="number"
              ref={register}
              min={1}
              defaultValue={quantity}
              className="text-center"
            />
          </FormField>
          <Form.Label>
            Subtotal: {toCurrency(quantity * product.price)}
          </Form.Label>
          <ButtonGroup>
            <Button kind="primary" type="submit" className="mr-1">
              Checkout
            </Button>
            <Button
              kind="secondary"
              onClick={() => {
                window.history.back();
              }}>
              Cancel
            </Button>
          </ButtonGroup>
        </FormWidget>
      </Container>
    </ThemeProvider>
  );
};

Sponsor.propTypes = {
  batClass: shape(BatClassProps).isRequired,
  product: shape(ProductProps).isRequired,
  usedLicenses: number.isRequired,
  unusedLicenses: number.isRequired,
  paymentOrdersPath: string.isRequired,
  marketingSiteUrl: string.isRequired,
};

export default Sponsor;
