import { arrayOf, bool, number, shape, string } from 'prop-types';

import {
  BatClassProps,
  CourseVersionProps,
  CourseModuleProps as OrigCourseModuleProps,
  UserProps,
} from '../types';

export const ShowProps = CourseVersionProps;

export const CourseModuleProps = {
  ...OrigCourseModuleProps,
  needsReset: bool.isRequired,
  position: number.isRequired,
};

export const IndexProps = {
  certificateAvailable: bool.isRequired,
  downloadCertificatePath: string.isRequired,
  linkedinImagePath: string.isRequired,
  courseVersions: arrayOf(shape(CourseVersionProps)).isRequired,
  isProfessorWithUnlimitedBmcAccess: bool,
  createWithClassCodeClassEnrollmentsPath: string,
  batClassesPath: string,
  batClasses: arrayOf(shape(BatClassProps)),
  terminalPlanNeedsUpgrade: bool,
  newBmcSubscriptionPath: string.isRequired,
  currentUser: shape(UserProps),
  cpdImagePath: string.isRequired,
};

export const CourseSummaryProps = {
  courseVersions: arrayOf(shape(CourseVersionProps)).isRequired,
};
