import cn from 'classnames';
import { Draggable, Droppable } from 'react-beautiful-dnd';

import { StyledCategoryName } from './styles';
import { CourseModuleDroppableProps } from './types';

const style = {
  maxHeight: 400,
  overflow: 'auto',
};

const CourseModuleDroppable = ({
  droppableId,
  courseModules,
  selected,
  disabled,
  filterCategory,
}) => (
  <Droppable droppableId={droppableId} isDropDisabled={disabled}>
    {provided => (
      <div ref={provided.innerRef} className="h-100" style={style}>
        {courseModules.map(
          ({ id, internalName, createdAt, courseCategory }, index) =>
            !filterCategory || filterCategory === courseCategory.name ? (
              <Draggable
                key={id}
                draggableId={id.toString()}
                index={index}
                isDragDisabled={disabled}>
                {provided => {
                  const name = (
                    <>
                      <StyledCategoryName>
                        {courseCategory.name.toUpperCase()}
                      </StyledCategoryName>{' '}
                      {selected && `${index + 1}.`} {internalName}{' '}
                      <span className="text-muted font-weight-normal font-size-sm">
                        (created {createdAt})
                      </span>
                    </>
                  );
                  return (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className={cn('bg-white mt-2 p-2', {
                        'font-weight-bold': selected,
                      })}>
                      {name}
                      {selected && (
                        <input
                          type="hidden"
                          name={`courseModuleIds[]`}
                          value={id}
                        />
                      )}
                    </div>
                  );
                }}
              </Draggable>
            ) : (
              <>
                {selected && (
                  <input type="hidden" name={`courseModuleIds[]`} value={id} />
                )}
              </>
            ),
        )}
        {provided.placeholder}
      </div>
    )}
  </Droppable>
);

CourseModuleDroppable.propTypes = CourseModuleDroppableProps;
export default CourseModuleDroppable;
