import { arrayOf, shape, string } from 'prop-types';

import { ProfessorPackProps } from '../../types';

export const IndexProps = {
  professorPacks: arrayOf(shape(ProfessorPackProps)).isRequired,
  newAdminProfessorPackPath: string.isRequired,
};

export const ShowProps = ProfessorPackProps;

export const ProfessorPackFormProps = ProfessorPackProps;
