import {
  GlobalStyles,
  Pagination,
  ThemeProvider,
} from '@bbnpm/bb-ui-framework';
import { createSlice } from '@reduxjs/toolkit';
import dequal from 'dequal';
import {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import { useFirstMountState, usePrevious } from 'react-use';
import styled from 'styled-components';

import { LoadMoreProps } from './types';

const initialState = { isLoading: false };

const PaginationContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const { reducer, actions } = createSlice({
  name: 'data',
  initialState,
  reducers: {
    dataFetched(state) {
      state.isLoading = true;
    },
    dataReceived(state, { payload }) {
      const { data = [], pageSize, totalCount, filterIds } = payload;
      Object.assign(state, {
        data,
        totalCount,
        filterIds,
        isLoading: false,
        initialPageCount: Math.ceil(totalCount / pageSize),
      });
    },
  },
});

const toParams = ({ scopes, pageIndex, pageSize, sortBy }) => {
  const params = {
    ...scopes,
    pageIndex: pageIndex,
    pageSize,
  };
  if (sortBy.length) {
    params.sortBy = `${sortBy[0].id} ${sortBy[0].desc ? 'DESC' : 'ASC'}`;
  }
  return params;
};

const shouldResetPageIndex = (params, prevParams) => {
  return !dequal(
    { ...params, pageIndex: null },
    { ...prevParams, pageIndex: null },
  );
};

const LoadMore = memo(
  ({
    initialData,
    initialPageSize = 10,
    initialPageIndex = 0,
    initialSortBy = '',
    initialTotalCount = initialData ? initialData.length : 0,
    initialToolkitTags,
    fetchData,
    initialScopes = { filters: { active: true } },
    setDocumentsFn,
    setCountFn,
    setIsLoadingFn,
    setCurTagsFn,
    activeFilterIds,
    activeFilterTitles,
    query,
  }) => {
    const [{ isLoading, totalCount }, dispatch] = useReducer(
      reducer,
      reducer(
        initialState,
        actions.dataReceived({
          data: initialData,
          pageSize: initialPageSize,
          totalCount: initialTotalCount,
          filterIds: initialToolkitTags,
        }),
      ),
    );

    const [id, dir] = initialSortBy.split(' ');
    const sortByObj = { id, desc: (dir || '').toUpperCase() === 'DESC' };

    const pageOptions = [12, 24, 36, 48].filter(num => num <= totalCount);

    const setPageSize = pageSize => setState(state => ({ ...state, pageSize }));

    const setPageIndex = pageIndex =>
      setState(state => ({ ...state, pageIndex }));

    const initialState = {
      pageSize: initialPageSize,
      pageIndex: initialPageIndex,
      sortBy: sortByObj.id ? [sortByObj] : [],
      scopes: initialScopes,
    };

    const [state, setState] = useState(initialState);

    const { scopes, pageIndex, pageSize, sortBy } = state;

    const params = useMemo(
      () => toParams({ scopes, pageIndex, pageSize, sortBy }),
      [scopes, pageIndex, pageSize, sortBy],
    );
    const prevParams = usePrevious(params);

    const refreshData = useCallback(
      async (updateTags = false) => {
        setIsLoadingFn(true);
        dispatch(actions.dataFetched());
        const { pageSize, pageIndex, query, filters, filterIds } = params;
        const data = await fetchData({
          pageSize,
          pageIndex,
          filters: {
            ...filters,
            active: true,
          },
          query,
          filterIds,
        });
        setDocumentsFn(documents => data?.data ?? documents);
        setCountFn(count => data?.totalCount ?? count);
        if (updateTags)
          setCurTagsFn(tags => JSON.parse(data?.docCounts) ?? tags);
        setIsLoadingFn(false);
        dispatch(actions.dataReceived(data));
      },
      [
        setDocumentsFn,
        setCountFn,
        setIsLoadingFn,
        setCurTagsFn,
        dispatch,
        fetchData,
        params,
      ],
    );

    const isFirstRun = useFirstMountState();
    const hasInitialData = Array.isArray(initialData);
    const needsInitialData = isFirstRun && !hasInitialData;

    useEffect(() => {
      const paramsChanged = prevParams && !dequal(params, prevParams);
      if (fetchData && (paramsChanged || needsInitialData)) {
        if (shouldResetPageIndex(params, prevParams)) {
          setDocumentsFn([]);
          setCountFn(0);
          setPageIndex(0);
        }
        refreshData(
          !dequal(prevParams?.query, params?.query) ||
            !dequal(prevParams?.filters, params?.filters),
        );
      }
    }, [
      setDocumentsFn,
      setCountFn,
      params,
      prevParams,
      needsInitialData,
      fetchData,
      refreshData,
    ]);

    useEffect(() => {
      setState(state => {
        return {
          ...state,
          scopes: {
            filterIds: activeFilterIds,
            filters: activeFilterTitles,
            query,
          },
        };
      });
    }, [activeFilterIds, activeFilterTitles, query]);

    return (
      <ThemeProvider>
        <GlobalStyles />
        {isLoading ? (
          <></>
        ) : totalCount === 0 ? (
          <span>No results found</span>
        ) : (
          totalCount > initialPageSize && (
            <PaginationContainer>
              <div>
                <Pagination.PageSize
                  options={[
                    ...pageOptions,
                    { label: 'All', value: totalCount },
                  ]}
                  pageSize={pageSize}
                  onPageSizeChange={selectedPageSize =>
                    setPageSize(selectedPageSize)
                  }
                  label="Show documents:"
                  onPageChange={page => setPageIndex(page - 1)}
                />
                <Pagination.PageInfo
                  totalItems={totalCount}
                  currentPage={pageIndex + 1}
                  pageSize={pageSize}
                  itemLabel="documents"
                />
              </div>
              <Pagination.Paginator
                totalItems={totalCount}
                pageSize={pageSize}
                currentPage={pageIndex + 1}
                onPageChange={page => {
                  window.scrollTo(0, 0);
                  setPageIndex(page - 1);
                }}
              />
            </PaginationContainer>
          )
        )}
      </ThemeProvider>
    );
  },
);

LoadMore.propTypes = LoadMoreProps;

LoadMore.displayName = 'LoadMore';

export default LoadMore;
