import { arrayOf, number, objectOf, shape, string } from 'prop-types';

import { AttachmentProps } from '../../types';
import { PackSectionProps } from '../pack_sections/types';

export const PackResourceProps = {
  id: number,
  title: string,
  description: string,
  listOrder: number,
  href: string,
  errors: objectOf(arrayOf(string)),
  packSection: shape(PackSectionProps),
  cloudStorageAttachment: AttachmentProps,
  createdAt: string,
  editAdminPackResourcePath: string,
};

export const ShowProps = PackResourceProps;
export const PackResourceFormProps = {
  ...PackResourceProps,
  adminPackSectionPackResourcesPath: string,
  professorPacksBucketName: string,
};
