import { Footer, GlobalStyles, ThemeProvider } from '@bbnpm/bb-ui-framework';
import { string } from 'prop-types';

const MainFooter = ({ aboutUrl, privacyPolicyUrl, tosUrl }) => (
  <ThemeProvider activeTheme="light">
    <GlobalStyles />
    <Footer
      copyright={new Date().getFullYear()}
      companyName={'Bloomberg Finance L.P.'}
      width="1440px"
      style={{ position: 'fixed', bottom: '0', width: '100vw', zIndex: 5 }}>
      <Footer.Links>
        <Footer.Link>
          <a href={aboutUrl}>About Us</a>
        </Footer.Link>
        <Footer.Link>
          <a href={privacyPolicyUrl}>Privacy Policy</a>
        </Footer.Link>
        <Footer.Link>
          <a href={tosUrl}>Terms of Service</a>
        </Footer.Link>
        <Footer.Link>
          <a href={'mailto:bbg.edu@bloomberg.net'}>Contact Us</a>
        </Footer.Link>
      </Footer.Links>
    </Footer>
  </ThemeProvider>
);

MainFooter.propTypes = {
  aboutUrl: string,
  privacyPolicyUrl: string,
  tosUrl: string,
};

export default MainFooter;
