import { Button } from 'react-bootstrap';

import Container from '../../Container';
import Input from '../../Input';
import PatchForm from '../../PatchForm';
import PostForm from '../../PostForm';
import { PackSectionFormProps } from './types';

const PackSectionForm = ({
  id,
  title,
  listOrder,
  sectionType,
  href,
  adminProfessorPackPackSectionsPath,
  sectionTypes,
  errors = {},
  professorPack,
}) => {
  const [Form, action, submitText] = id
    ? [PatchForm, href, 'Update']
    : [PostForm, adminProfessorPackPackSectionsPath, 'Submit'];

  return (
    <Container>
      <div className="d-flex align-items-center">
        <h1>{id ? 'Edit' : 'Add'} Case Study Section</h1>
        <Button
          className="ml-4"
          variant="light"
          href={id ? href : professorPack.adminHref}
          size="sm">
          Cancel
        </Button>
      </div>

      <Form action={action} submitText={submitText}>
        <Input
          required
          name="title"
          defaultValue={title || ''}
          errors={errors.title}
        />
        <Input
          type="number"
          required
          name="listOrder"
          defaultValue={listOrder || 0}
          errors={errors.listOrder}
        />
        <Input
          required
          as="select"
          name="sectionType"
          options={sectionTypes}
          defaultValue={sectionType || ''}
          errors={errors.sectionType}
        />
      </Form>
    </Container>
  );
};

PackSectionForm.propTypes = PackSectionFormProps;

export default PackSectionForm;
