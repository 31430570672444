import {
  Button,
  GlobalStyles,
  ProgressSpinner,
  ThemeProvider,
  Typography,
  useNotification,
} from '@bbnpm/bb-ui-framework';
import { Col, Row } from '@bloomberg/styled-flexboxgrid';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useToggle } from 'react-use';
import styled from 'styled-components';

import { sendAsForm } from '../../../api';
import { BfeGrid } from '../../../styles/grid';
import { noop } from '../../../utils';
import { ButtonContainer, StatusIcon } from '../../CommonComponents';
import PrimaryAdminKey from '../../PrimaryAdminKey';
import Table from '../../Table';
import { useFetchData } from '../../utils';
import UploadModal from './UploadModal';
import { CurrencyProps, ShowProps } from './types';

const StyledCurrency = styled(Typography.Text)`
  color: ${({ value, theme }) =>
    value > 0
      ? theme.colors.green[600]
      : value < 0
        ? theme.colors.red[500]
        : theme.colors.black};
`;

const Currency = ({ value }) => (
  <StyledCurrency value={value}>
    {value < 0 && <>-</>}${Math.abs(value)}
  </StyledCurrency>
);

Currency.propTypes = CurrencyProps;

const columns = [
  {
    Header: 'Registration ID',
    accessor: 'tradingChallengeRegistrationId',
    Cell: PrimaryAdminKey,
  },
  {
    Header: 'Team Name',
    accessor: 'tradingChallengeRegistration.teamName',
    disableSortBy: true,
  },
  { Header: 'No. of Long Ideas', accessor: 'numOfLongIdeas' },
  { Header: 'No. of Short Ideas', accessor: 'numOfWinningIdeas' },
  { Header: 'Gross Exposure', accessor: 'grossExposure' },
  { Header: 'Relative P&L - OTD', accessor: 'relativePl', Cell: Currency },
  { Header: 'P&L - OTD', accessor: 'absolutePl', Cell: Currency },
  { Header: 'Portfolio Return', accessor: 'portfolioReturn' },
  { Header: 'Global Rank', accessor: 'globalRank' },
];

const cb = ({ totalCount, pageSize, leaderboardItems }) => ({
  data: leaderboardItems,
  pageSize,
  totalCount,
});

const scopes = [
  { id: 'isNorthAmerica', title: 'North America' },
  { id: 'isLatinAmerica', title: 'Latin America' },
  { id: 'isEurope', title: 'Europe' },
  { id: 'isMiddleEastAndAfrica', title: 'Middle East & Africa' },
  { id: 'isAsiaAndOceania', title: 'Asia & Oceania' },
];

const Show = ({
  name,
  active,
  leaderboardItems,
  path,
  pageSize,
  pageIndex,
  sortBy,
  totalCount,
  editAdminTradingChallengeLeaderboardPath,
  adminInstitutionsPath,
  adminTradingChallengePath,
  uploadPath,
  jobStatusPath,
  isFileIngestionInProgress,
}) => {
  const notif = useNotification();
  const fetchData = useFetchData(path, cb);
  const [{ refreshData }, setRefreshDataFn] = useState({ refreshData: noop });
  const timerIdRef = useRef(null);

  const [showUploadModal, toggleShowUploadModal] = useToggle(false);
  const [isPollingEnabled, setIsPollingEnabled] = useState(
    isFileIngestionInProgress,
  );
  useEffect(() => {
    const getJobStatus = async () => {
      try {
        const res = await fetch(jobStatusPath);
        const data = await res?.json();

        if (data?.uploadStatus?.status === 'FAILED') {
          notif.addError({
            message: data?.uploadStatus?.message,
            timeout: 5000,
          });
          setIsPollingEnabled(false);
        } else if (data?.uploadStatus?.status === 'COMPLETED') {
          notif.addSuccess({
            message: data?.uploadStatus?.message,
            timeout: 5000,
          });
          setIsPollingEnabled(false);
          refreshData();
        } else if (data?.uploadStatus?.status === 'UPLOADING') {
          notif.addInfo({
            message: data?.uploadStatus?.message,
            timeout: 5000,
          });
        }
      } catch (error) {
        notif.addError({
          message: 'Something went wrong',
          timeout: 5000,
        });
        setIsPollingEnabled(false);
      }
    };

    const startPolling = () => {
      timerIdRef.current = setInterval(getJobStatus, 10000);
    };

    const stopPolling = () => {
      clearInterval(timerIdRef.current);
    };

    if (isPollingEnabled) {
      startPolling();
    } else {
      stopPolling();
    }

    return () => {
      stopPolling();
    };
  }, [isPollingEnabled, jobStatusPath, notif, refreshData]);

  const additionalFilters = useMemo(
    () => [
      {
        Header: 'Institution',
        accessor: 'institutionId',
        filteredAs: 'autosuggest',
        path: adminInstitutionsPath,
      },
    ],
    [adminInstitutionsPath],
  );

  const handleUploadCsv = async values => {
    try {
      setIsPollingEnabled(true);
      await sendAsForm(uploadPath, values, 'post');
      notif.addInfo({
        message: 'Upload initiated',
        timeout: 5000,
      });
    } catch (error) {
      alert(error.response?.data?.errors);
      setIsPollingEnabled(false);
    }
  };

  return (
    <ThemeProvider>
      <GlobalStyles />
      <BfeGrid>
        <Row>
          <Button
            as="a"
            kind="tertiary"
            icon="arrow-left"
            href={adminTradingChallengePath}>
            Back to Trading Challenge
          </Button>
        </Row>
        <Row className="d-flex align-items-center justify-content-between">
          <Col>
            <Typography.PageTitle>{name}</Typography.PageTitle>
          </Col>
          <Col>
            <ButtonContainer>
              <Button
                kind="primary"
                onClick={toggleShowUploadModal}
                className="mr-1"
                disabled={isPollingEnabled}>
                {isPollingEnabled ? (
                  <ProgressSpinner
                    size="small"
                    label="Submitting..."
                    labelPosition="right"
                  />
                ) : (
                  'Upload CSV'
                )}
              </Button>
              <Button
                kind="secondary"
                as="a"
                href={editAdminTradingChallengeLeaderboardPath}
                className="mr-1">
                Edit
              </Button>
            </ButtonContainer>
          </Col>
        </Row>
        <Row>
          <Col>
            <Typography.Text>
              Active: &nbsp; <StatusIcon value={active} />
            </Typography.Text>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Table
              columns={columns}
              initialData={leaderboardItems}
              fetchData={fetchData}
              initialPageSize={pageSize}
              initialPageIndex={pageIndex}
              initialSortBy={sortBy}
              initialTotalCount={totalCount}
              additionalFilters={additionalFilters}
              availableScopes={scopes}
              onRefreshDataFn={setRefreshDataFn}
              refreshData={refreshData}
            />
          </Col>
        </Row>
        <UploadModal
          isOpen={showUploadModal}
          toggleIsOpen={toggleShowUploadModal}
          uploadPath={uploadPath}
          onSubmit={handleUploadCsv}
        />
      </BfeGrid>
    </ThemeProvider>
  );
};

Show.propTypes = ShowProps;

export default Show;
