import { arrayOf, shape, string } from 'prop-types';

import { FirmProps, TableProps } from '../../types';

export const FirmFormProps = {
  ...FirmProps,
  adminFirmsPath: string,
};

export const IndexProps = {
  ...TableProps,
  adminFirmsPath: string.isRequired,
  firms: arrayOf(shape(FirmProps)).isRequired,
  newAdminFirmPath: string.isRequired,
};

export const ShowProps = FirmProps;
