import {
  GlobalStyles,
  Icon,
  ThemeProvider,
  Typography,
} from '@bbnpm/bb-ui-framework';
import { node, oneOfType, shape, string } from 'prop-types';
import { isValidElement } from 'react';
import styled from 'styled-components';

import { LinkProps } from './types';

const StyledHero = styled.div`
  background-color: ${({ theme }) => theme.colors.grey[700]};
  color: ${({ theme }) => theme.colors.white};
`;

const HeroContainer = styled.div`
  padding: 2rem 0;
  margin: 0 auto;
  max-width: 1440px;
`;

const StyledLink = styled.a`
  width: 12.5rem;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.amber[500]};

  &:hover {
    background-color: none;
    text-decoration: none;
  }
`;

const Hero = ({
  title,
  description,
  primaryAction,
  link,
  logo,
  containerClassName,
}) => {
  return (
    <ThemeProvider activeTheme="dark">
      <GlobalStyles />
      <StyledHero>
        <HeroContainer className={containerClassName}>
          {link && (
            <StyledLink href={link.path}>
              {link.icon ? <Icon name={link.icon} /> : null}
              {link.name}
            </StyledLink>
          )}

          {title && <Typography.LargeTitle>{title}</Typography.LargeTitle>}

          {isValidElement(description) ? (
            description
          ) : (
            <Typography.Text>{description}</Typography.Text>
          )}
          {logo ? (
            <img src={logo} alt="logo" style={{ width: '4rem' }} />
          ) : null}
          {primaryAction}
        </HeroContainer>
      </StyledHero>
    </ThemeProvider>
  );
};

const HeroProps = {
  title: string,
  description: oneOfType([string, node]),
  primaryAction: node,
  link: shape(LinkProps),
  logo: string,
  containerClassName: string,
};

Hero.propTypes = HeroProps;
export default Hero;
