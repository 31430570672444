import cn from 'classnames';
import { Card } from 'react-bootstrap';

import ClientDate from '../ClientDate';
import CoverWithDuration from '../CoverWithDuration';
import { WebinarCardProps } from './types';

const WebinarCard = ({ isCurrent = false, webinar, className }) => (
  <Card
    as="a"
    href={webinar.href}
    className={cn(className, 'link-unstyled h-100', {
      'card--current': isCurrent,
    })}>
    <CoverWithDuration
      daperKey={webinar.thumbnail?.daperKey}
      width={630}
      duration={webinar.durationMins}
    />
    <Card.Body>
      <Card.Title>{webinar.name}</Card.Title>
      <Card.Text className="text-muted">
        <ClientDate
          date={webinar.occurredAt}
          dateFormat={{ month: 'long', year: 'numeric' }}
        />
      </Card.Text>
    </Card.Body>
  </Card>
);

WebinarCard.propTypes = WebinarCardProps;

export default WebinarCard;
