import { node, object } from 'prop-types';
import { Tooltip as BsTooltip, OverlayTrigger } from 'react-bootstrap';

const Tooltip = ({ tooltip, tooltipProps = {}, ...rest }) => (
  <OverlayTrigger
    overlay={<BsTooltip {...tooltipProps}>{tooltip}</BsTooltip>}
    {...rest}
  />
);

Tooltip.propTypes = {
  tooltip: node.isRequired,
  tooltipProps: object,
};

export default Tooltip;
