import { Button, Modal, Typography } from '@bbnpm/bb-ui-framework';

import { destroy } from '../../../api';
import { DeleteLeaderboardModalProps } from './types';

const DeleteLeaderboardModal = ({
  row,
  showDeleteModal,
  toggleShowDeleteModal,
  refreshData,
}) => {
  const handleDelete = async () => {
    try {
      await destroy(row.original.adminHref);
      refreshData();
    } catch (error) {
      alert('Something went wrong.');
    }
    toggleShowDeleteModal();
  };

  return (
    <Modal
      portalContainerId="bfe-root"
      title={`Delete Leaderboard: ${row.original.name}`}
      isOpen={showDeleteModal}
      primaryActions={
        <>
          <Button kind="secondary" onClick={toggleShowDeleteModal}>
            Cancel
          </Button>
          <Button kind="destruction" onClick={handleDelete}>
            Delete
          </Button>
        </>
      }>
      <Typography.Text>
        Deleting this leaderboard will delete all records associated with{' '}
        <strong>{row.original.name}</strong>. Please click delete to confirm.
      </Typography.Text>
    </Modal>
  );
};

DeleteLeaderboardModal.propTypes = DeleteLeaderboardModalProps;

export default DeleteLeaderboardModal;
