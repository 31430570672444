import { arrayOf, func, number, object, shape, string } from 'prop-types';

import { TableProps, ToolkitDocumentProps, ToolkitTagProps } from '../../types';

export const IndexProps = {
  ...TableProps,
  adminToolkitDocumentsPath: string.isRequired,
  newAdminToolkitDocumentPath: string.isRequired,
  adminToolkitTagsPath: string.isRequired,
  toolkitDocuments: arrayOf(shape(ToolkitDocumentProps)).isRequired,
};
export const ShowProps = ToolkitDocumentProps;

export const ToolkitDocumentFormProps = ToolkitDocumentProps;

export const StatusProps = {
  cell: shape({ value: ToolkitDocumentProps.active }),
};

export const TopicsProps = {
  row: shape({
    original: shape(ToolkitDocumentProps).isRequired,
  }).isRequired,
};

export const DocumentsActionsProps = {
  row: shape({
    original: shape(ToolkitDocumentProps),
  }),
  refreshData: func,
};

export const HiddenInputFieldsProps = {
  allToolkitTopics: arrayOf(shape(ToolkitTagProps)).isRequired,
  docType: number,
  initialDocType: number,
  existingTopicsMap: object.isRequired,
  selectedMap: object.isRequired,
};
