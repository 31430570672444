import TextareaAutosize from 'react-textarea-autosize';

import Button from '../../Button';
import Container from '../../Container';
import ImageFileInput from '../../ImageFileInput';
import Input from '../../Input';
import PatchForm from '../../PatchForm';
import PostForm from '../../PostForm';
import { PromoItemFormProps } from './types';

const PromoItemForm = ({
  adminPromoItemsPath,
  adminHref,
  id,
  title,
  description,
  actionText,
  url,
  active,
  position,
  enableForHss,
  enableForCandidates,
  enableForProfs,
  thumbnail,
  errors,
}) => {
  const [Form, action, submitText] = id
    ? [PatchForm, adminHref, 'Update']
    : [PostForm, adminPromoItemsPath, 'Submit'];

  return (
    <Container>
      <div className="d-flex align-items-center mb-3">
        <h1> Edit Promo Item</h1>
        <Button className="ml-2" variant="light" href={adminPromoItemsPath}>
          All Promo Items
        </Button>
      </div>

      <Form
        action={action}
        submitText={submitText}
        encType="multipart/form-data">
        <div className="d-flex align-items-center mb-3">
          <Input
            type="switch"
            name="active"
            defaultChecked={active}
            formGroupProps={{ className: 'mb-0' }}
          />
        </div>
        <Input
          name="title"
          required
          defaultValue={title ?? ''}
          errors={errors?.title}
        />
        <Input
          name="description"
          required
          defaultValue={description ?? ''}
          as={TextareaAutosize}
          errors={errors?.description}
        />
        <Input name="actionText" defaultValue={actionText ?? ''} />
        <Input name="url" defaultValue={url ?? ''} />
        <Input defaultValue={position} required type="number" name="position" />

        <Input
          type="switch"
          name="enableForHss"
          label="Show for High School Students"
          defaultChecked={enableForHss}
          formGroupProps={{ className: 'mb-0' }}
        />

        <Input
          type="switch"
          name="enableForCandidates"
          label="Show for Candidates"
          defaultChecked={enableForCandidates}
          formGroupProps={{ className: 'mb-0' }}
        />

        <Input
          type="switch"
          name="enableForProfs"
          label="Show for Professors"
          defaultChecked={enableForProfs}
          formGroupProps={{ className: 'mb-0' }}
        />

        <br />

        <ImageFileInput
          name="thumbnail"
          accept=".jpg, .png"
          helpText="upload the largest available version"
          value={thumbnail}
        />
      </Form>
    </Container>
  );
};

PromoItemForm.propTypes = PromoItemFormProps;

export default PromoItemForm;
