import { GlobalStyles, ThemeProvider } from '@bbnpm/bb-ui-framework';
import { useState } from 'react';
import { useToggle } from 'react-use';

import { destroy } from '../../../api';
import Button from '../../Button';
import CheckOrMinusIcon from '../../CheckOrMinusIcon';
import Container from '../../Container';
import DaperImgCell from '../../DaperImgCell';
import PrimaryKey from '../../PrimaryKey';
import Table from '../../Table';
import { useFetchData } from '../../utils';
import CourseFormModal from './CourseFormModal';
import { CourseActionsProps, IndexProps, StatusProps } from './types';

const Status = ({ cell }) => <CheckOrMinusIcon isTrue={!!cell.value} />;

Status.propTypes = StatusProps;

const CourseActions = ({ row, refreshData, courseCategories }) => {
  const [showEditForm, toggleShowEditForm] = useToggle(false);
  const handleDelete = async () => {
    try {
      await destroy(row.original.href);
      refreshData();
    } catch (error) {
      alert('Something went wrong.');
    }
  };

  return (
    <span className="text-nowrap">
      <Button
        title="Edit"
        icon="edit"
        variant="light"
        size="sm"
        onClick={toggleShowEditForm}
      />
      <Button
        title="Delete"
        disabled={!!row.original.activeVersionName}
        icon="trash-alt"
        variant="danger"
        size="sm"
        className="ml-2"
        onClick={handleDelete}
      />
      <CourseFormModal
        show={showEditForm}
        onHide={toggleShowEditForm}
        course={row.original}
        courseCategories={courseCategories}
      />
    </span>
  );
};

CourseActions.propTypes = CourseActionsProps;

const columns = [
  { Header: 'ID', accessor: 'id', filteredAs: 'number', Cell: PrimaryKey },
  {
    Header: 'Thumbnail',
    accessor: 'thumbnail',
    disableSortBy: true,
    cellProps: { className: 'text-center' },
    Cell: DaperImgCell,
  },
  { Header: 'Pos', accessor: 'position' },
  { Header: 'Created', accessor: 'createdAt' },
  { Header: 'Name', accessor: 'name', filteredAs: 'string' },
  {
    Header: 'Category',
    accessor: 'courseCategory.name',
  },
  { Header: '# Versions', accessor: 'courseVersionsCount' },
  {
    Header: 'Active',
    id: 'active',
    accessor: 'activeVersionName',
    disableSortBy: true,
    Cell: Status,
  },
  {
    Header: 'Active Version',
    accessor: 'activeVersionName',
    disableSortBy: true,
  },
  {
    accessor: 'n/a',
    id: 'actions',
    disableSortBy: true,
    Cell: CourseActions,
  },
];

const scopes = [
  { id: 'bmc', title: 'BMC' },
  { id: 'esg', title: 'ESG' },
  { id: 'bff', title: 'BFF' },
];

const initialScopes = {
  bmc: false,
  esg: false,
  bff: false,
};

const cb = ({ totalCount, pageSize, courses }) => ({
  data: courses,
  pageSize,
  totalCount,
});

const Index = ({
  adminCoursesPath,
  path,
  courses,
  totalCount,
  pageSize,
  pageIndex,
  sortBy,
  courseCategories,
}) => {
  const [showAddForm, toggleShowAddForm] = useToggle(false);
  const [{ refreshData }, setRefreshDataFn] = useState({
    refreshData: () => {},
  });
  const fetchData = useFetchData(path, cb);

  return (
    <ThemeProvider>
      <GlobalStyles />
      <Container>
        <CourseFormModal
          show={showAddForm}
          courseCategories={courseCategories}
          onHide={toggleShowAddForm}
          adminCoursesPath={adminCoursesPath}
        />
        <div className="d-flex align-items-center mb-3">
          <h1>Courses</h1>
          <Button
            className="ml-5"
            variant="primary"
            onClick={toggleShowAddForm}>
            Add Course
          </Button>
        </div>
        <Table
          initialData={courses}
          initialPageSize={pageSize}
          initialPageIndex={pageIndex}
          initialSortBy={sortBy}
          initialTotalCount={totalCount}
          columns={columns}
          fetchData={fetchData}
          onRefreshDataFn={setRefreshDataFn}
          refreshData={refreshData}
          initialScopes={initialScopes}
          availableScopes={scopes}
          courseCategories={courseCategories}
        />
      </Container>
    </ThemeProvider>
  );
};

Index.propTypes = IndexProps;

export default Index;
