import { array, arrayOf, number, shape, string } from 'prop-types';
import { useCallback, useState } from 'react';
import { Button } from 'react-bootstrap';

import { create } from '../api';
import { noop } from '../utils';
import AsyncModal, { useAsyncModal } from './AsyncModal';
import Input from './Input';
import PrimaryKey from './PrimaryKey';
import Table from './Table';
import { BatClassProps } from './types';
import { useFetchData } from './utils';

const defaultColumns = [
  {
    Header: 'ID',
    accessor: 'classCode',
    disableSortBy: true,
    Cell: PrimaryKey,
  },
  {
    Header: 'Name',
    accessor: 'nameAlias',
    disableSortBy: true,
  },
  {
    Header: 'Professor',
    accessor: 'professor.fullName',
    disableSortBy: true,
  },
  {
    Header: 'Deadline',
    accessor: 'expiredAt',
    disableSortBy: true,
  },
];

const cb = ({ totalCount, pageSize, batClasses: data }) => ({
  data,
  pageSize,
  totalCount,
});

const ClassEnrollments = ({
  createWithClassCodeClassEnrollmentsPath,
  batClassesPath,
  batClasses,
  columns = defaultColumns,
  currentId,
  ...rest
}) => {
  const [{ refreshData }, setRefreshDataFn] = useState({ refreshData: noop });
  const { register, errors, open, modalProps } = useAsyncModal();
  const fetchData = useFetchData(batClassesPath, cb);

  const getRowProps = useCallback(
    ({ id }) => (id === currentId ? { className: 'table-active' } : {}),
    [currentId],
  );

  const handleAddClass = async () => {
    await open(async ({ resolve, reject, values: { classCode } }) => {
      try {
        resolve(
          await create(createWithClassCodeClassEnrollmentsPath, { classCode }),
        );
      } catch (error) {
        reject({
          classCode:
            error?.response?.data?.errors?.batClass?.[0] ??
            'Something went wrong. Try again or use different code',
        });
      }
    });
    refreshData();
  };

  return (
    <div {...rest}>
      <div className="d-flex align-items-center justify-content-between">
        <div className="font-size-lg">
          Class Enrollments
          <i className="fas fa-chalkboard-teacher ml-3" />
        </div>
        <Button variant="outline-dark" size="sm" onClick={handleAddClass}>
          Add Class
        </Button>
      </div>

      <Table
        size="sm"
        columns={columns}
        initialData={batClasses}
        fetchData={fetchData}
        onRefreshDataFn={setRefreshDataFn}
        getRowProps={getRowProps}
      />

      <AsyncModal {...modalProps} title="Add Class">
        <Input
          ref={register}
          required
          name="classCode"
          errors={errors?.classCode ? [errors.classCode.message] : []}
        />
      </AsyncModal>
    </div>
  );
};

ClassEnrollments.propTypes = {
  createWithClassCodeClassEnrollmentsPath: string.isRequired,
  batClassesPath: string.isRequired,
  batClasses: arrayOf(shape(BatClassProps)).isRequired,
  columns: array,
  currentId: number,
};

export default ClassEnrollments;
