import {
  Button,
  GlobalStyles,
  Icon,
  Modal,
  ThemeProvider,
  Typography,
} from '@bbnpm/bb-ui-framework';
import { bool, string } from 'prop-types';
import { useToggle } from 'react-use';

import DeleteButton from '../../DeleteButton';

const DeleteClassButton = ({ disabled, nameAlias, classCode, action }) => {
  const [isOpen, toggleIsOpen] = useToggle(false);

  return (
    <ThemeProvider>
      <GlobalStyles />
      <Button kind="destruction" disabled={disabled} onClick={toggleIsOpen}>
        <Icon name="trash" /> Delete Class
      </Button>

      <Modal
        isOpen={isOpen}
        title={`Delete Class: ${nameAlias}`}
        contentLabel="Delete Class"
        // When you paste this code, you MUST change this "rsg-root" to a real id that's used in
        // your app's root element, usually named "root" or something similar
        portalContainerId="bfe-root"
        primaryActions={
          <>
            <DeleteButton action={action} kind="destruction">
              Delete Class &quot;{nameAlias}&quot; Permanently
            </DeleteButton>
            <Button kind="secondary" onClick={toggleIsOpen}>
              Cancel
            </Button>
          </>
        }>
        <Typography.Text>
          Are you sure you want to delete class{' '}
          <strong>
            {nameAlias} - {classCode}
          </strong>
          ? This action cannot be undone.
        </Typography.Text>
      </Modal>
    </ThemeProvider>
  );
};

DeleteClassButton.propTypes = {
  disabled: bool.isRequired,
  nameAlias: string.isRequired,
  classCode: string.isRequired,
  action: string.isRequired,
};

export default DeleteClassButton;
