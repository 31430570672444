import cn from 'classnames';
import { node, number, shape, string } from 'prop-types';
import { Children, useMemo } from 'react';
import { Carousel, Col, Row } from 'react-bootstrap';

import { useBreakpoint } from './utils';

const toGroupsOf = (items, groupSize) =>
  items.reduce((accumulator, item, idx) => {
    if (idx % groupSize === 0) {
      accumulator.push([item]);
    } else {
      accumulator[accumulator.length - 1].push(item);
    }
    return accumulator;
  }, []);

const CardCarousel = ({ spans = {}, className, children }) => {
  const breakpoint = useBreakpoint();

  const spanPerBreakpoint = ['xs', 'sm', 'md', 'lg', 'xl'].reduce(
    ([acc, prevBp], bp) => [
      { ...acc, [bp]: spans[bp] ?? prevBp },
      spans[bp] ?? prevBp,
    ],
    [{}, 1],
  )[0];

  const itemsPerGroup = 12 / spanPerBreakpoint[breakpoint];

  const groups = useMemo(
    () => toGroupsOf(Children.toArray(children), itemsPerGroup),
    [children, itemsPerGroup],
  );

  return (
    <Carousel
      className={cn('card-carousel', className)}
      indicators={false}
      interval={null}
      controls={groups.length > 1}
      prevIcon={<i className="fas fa-angle-left fa-3x" />}
      nextIcon={<i className="fas fa-angle-right fa-3x" />}>
      {groups.map((group, i) => (
        <Carousel.Item key={i}>
          <Row>
            {group.map((child, i) => (
              <Col {...spans} key={i}>
                {child}
              </Col>
            ))}
          </Row>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

CardCarousel.propTypes = {
  className: string,
  children: node.isRequired,
  spans: shape({
    xl: number,
    lg: number,
    md: number,
    sm: number,
  }),
};

export default CardCarousel;
