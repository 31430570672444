import { Button } from 'react-bootstrap';
import TextareaAutosize from 'react-textarea-autosize';

import AttachmentInputs from '../../AttachmentInputs';
import Container from '../../Container';
import Input from '../../Input';
import PatchForm from '../../PatchForm';
import PostForm from '../../PostForm';
import { PackResourceFormProps } from './types';

const PackResourceForm = ({
  id,
  title,
  description,
  listOrder,
  href,
  adminPackSectionPackResourcesPath,
  errors = {},
  packSection,
  cloudStorageAttachment,
  professorPacksBucketName,
}) => {
  const [Form, action, submitText] = id
    ? [PatchForm, href, 'Update']
    : [PostForm, adminPackSectionPackResourcesPath, 'Submit'];

  return (
    <Container>
      <div className="d-flex align-items-center">
        <h1>{id ? 'Edit' : 'Add'} Case Study Resource</h1>
        <Button
          className="ml-4"
          variant="light"
          href={id ? href : packSection.href}
          size="sm">
          Cancel
        </Button>
      </div>

      <Form
        encType="multipart/form-data"
        action={action}
        submitText={submitText}>
        <Input
          required
          name="title"
          defaultValue={title || ''}
          errors={errors.title}
        />
        <Input
          required
          name="description"
          as={TextareaAutosize}
          defaultValue={description || ''}
        />
        <Input
          type="number"
          required
          name="listOrder"
          defaultValue={listOrder || 0}
          errors={errors.listOrder}
        />
        <AttachmentInputs
          attachment={cloudStorageAttachment}
          professorPacksBucketName={professorPacksBucketName}
          attributesKey="cloudStorageAttachmentAttributes"
          label="Case Study Resource"
          referenceType="PackResource"
        />
      </Form>
    </Container>
  );
};

PackResourceForm.propTypes = PackResourceFormProps;

export default PackResourceForm;
