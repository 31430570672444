import {
  GlobalStyles,
  ThemeProvider,
  Typography,
} from '@bbnpm/bb-ui-framework';

import Container from '../../Container';
import CaseStudiesList from './CaseStudiesList';
import Feedback from './Feedback';
import Materials from './Materials';
import NotEnabledNotice from './NotEnabledNotice';
import PreviewCaseStudiesList from './PreviewCaseStudiesList';
import { IndexProps } from './types';

const Index = ({
  canAccessProfessorPacks,
  professorPacks,
  professorsCaseStudiesPath,
  caseStudiesSurvey,
  batContactLink,
}) => (
  <ThemeProvider>
    <GlobalStyles />
    <Container>
      <Typography.PageTitle>
        <strong>Case Studies</strong>
      </Typography.PageTitle>
      {canAccessProfessorPacks ? (
        <>
          <Materials />
          <CaseStudiesList
            professorPacks={professorPacks}
            professorsCaseStudiesPath={professorsCaseStudiesPath}
          />
          <Feedback caseStudiesSurvey={caseStudiesSurvey} />
        </>
      ) : (
        <>
          <NotEnabledNotice batContactLink={batContactLink} />
          <Materials />
          <PreviewCaseStudiesList professorPacks={professorPacks} />
        </>
      )}
    </Container>
  </ThemeProvider>
);

Index.propTypes = IndexProps;

export default Index;
