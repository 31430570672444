import { arrayOf, bool, exact, number, shape, string } from 'prop-types';

import {
  BatClassProps,
  CourseVersionProps,
  RusticiRegistrationsProps,
  TableProps,
  UserProps,
} from '../../types';

export const IndexProps = {
  ...TableProps,
  adminUsersPath: string.isRequired,
  newAdminUserPath: string.isRequired,
  adminInstitutionsPath: string.isRequired,
  canAddUser: bool.isRequired,
  users: arrayOf(shape(UserProps)).isRequired,
};

export const UserDropdownProps = {
  row: shape({ original: shape(UserProps) }).isRequired,
};

export const ProfessorInfoProps = exact({
  id: number,
  accountVerified: bool,
  notifyLearnersDrEnablement: bool,
});

export const CourseNameProps = {
  value: shape(CourseVersionProps),
};

export const ShowProps = UserProps;

export const ProfessorLinkProps = { value: shape(UserProps) };

export const RusticiRegistrationsMarkCompletedProps = {
  row: shape({ original: shape(RusticiRegistrationsProps) }).isRequired,
};

export const RusticiRegistrationsRefreshScoreProps = {
  row: shape({ original: shape(RusticiRegistrationsProps) }).isRequired,
};

export const RusticiRegistrationsResetScoreProps = {
  row: shape({ original: shape(RusticiRegistrationsProps) }).isRequired,
};

export const BatClassPrimaryAdminKeyProps = {
  value: shape(BatClassProps),
};

export const UserFormProps = {
  ...UserProps,
  adminInstitutionsPath: string.isRequired,
};

const CertificateInfoProps = {
  id: number.isRequired,
  name: string.isRequired,
  title: string.isRequired,
  status: string.isRequired,
  certifiedAt: string.isRequired,
};

export const CertificationTableProps = {
  certificationsInfo: arrayOf(shape(CertificateInfoProps).isRequired),
  addCertificationEnabled: bool,
};

export const AddCertificateButtonProps = {
  courseCategoryId: number.isRequired,
  courseCategoryName: string.isRequired,
  courseCategoryTitle: string.isRequired,
  action: string.isRequired,
  disabled: bool.isRequired,
};
