import {
  Button,
  GlobalStyles,
  Modal,
  ThemeProvider,
  Typography,
} from '@bbnpm/bb-ui-framework';
import { useToggle } from 'react-use';

import PostForm from '../../PostForm';
import { FlexBox } from './styles';
import { AddCertificateButtonProps } from './types';

const AddCertificationButton = ({
  courseCategoryId,
  courseCategoryName,
  courseCategoryTitle,
  action,
  disabled,
}) => {
  const [isOpen, toggleIsOpen] = useToggle(false);
  return (
    <ThemeProvider>
      <GlobalStyles />
      <Button kind="primary" disabled={disabled} onClick={toggleIsOpen}>
        Add Certification
      </Button>
      <Modal
        isOpen={isOpen}
        title={
          <Typography.PageTitle>
            Add <strong>{courseCategoryName}</strong> Certification
          </Typography.PageTitle>
        }
        portalContainerId="bfe-root"
        primaryActions={
          <FlexBox>
            <PostForm action={action} submitText="Confirm">
              <input
                type="hidden"
                value={courseCategoryId}
                name="courseCategoryId"
              />
            </PostForm>
            &nbsp; &nbsp;
            <Button kind="secondary" onClick={toggleIsOpen}>
              Cancel
            </Button>
          </FlexBox>
        }>
        <Typography.Text>
          Are you sure you want to add <strong>{courseCategoryTitle}</strong>{' '}
          Certification to this user?
        </Typography.Text>
      </Modal>
    </ThemeProvider>
  );
};

AddCertificationButton.propTypes = AddCertificateButtonProps;
export default AddCertificationButton;
