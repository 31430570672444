import { arrayOf, shape } from 'prop-types';

import { SeriesProps, VideoProps } from '../types';

export const ShowProps = {
  ...SeriesProps,
  videos: arrayOf(shape(VideoProps)).isRequired,
  allSeries: arrayOf(shape(SeriesProps)).isRequired,
};

export const LeadVideoPanelProps = {
  video: shape(VideoProps).isRequired,
};
