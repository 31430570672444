import {
  Button,
  GlobalStyles,
  List,
  ListItem,
  Modal,
  ThemeProvider,
  Typography,
} from '@bbnpm/bb-ui-framework';
import { bool, number, string } from 'prop-types';
import { useToggle } from 'react-use';

import AlertWithIcon from '../AlertWithIcon';
import Container from '../Container';
import Hero from '../Hero';
import Input from '../Input';
import PostForm from '../PostForm';
import Spinner from '../Spinner';
import {
  StatusDiv,
  StyledCompleted,
  StyledInProgress,
  StyledLink,
  StyledNotStarted,
  StyledShowItem,
  StyledStack,
} from './courseVersions.styles';
import { ShowProps } from './types';

const statusElement = status => {
  switch (status) {
    case 'COMPLETED':
      return <StyledCompleted>Complete</StyledCompleted>;
    case 'IN_PROGRESS':
      return <StyledInProgress>In Progress</StyledInProgress>;
    case 'NOT_STARTED':
      return <StyledNotStarted>Not Started</StyledNotStarted>;
  }
};

const CourseItem = ({ number, title, status }) => (
  <StyledStack>
    <Typography.SectionTitle>
      {number}. {title}
    </Typography.SectionTitle>
    {statusElement(status)}
  </StyledStack>
);

const Show = ({
  name,
  description,
  courseModules,
  eligibleForUpgrade,
  upgradeCourseVersionPath,
  needsReset,
  resetCourseVersionPath,
  courseCategoryId,
  courseVersionsPath,
  courseCategoryTitle,
  overviewItems,
}) => {
  const [showResetForm, toggleShowResetForm] = useToggle(false);
  const [showUpgradeForm, toggleShowUpgradeForm] = useToggle(false);
  const [isUpgradeAccepted, toggleIsUpgradeAccepted] = useToggle(false);

  const courseStatusList = courseModules.map(cm => cm.status);
  const overallStatus = statusList => {
    if (statusList.every(status => status === 'NOT_STARTED' || !status)) {
      return <Typography.Caption>Not Started</Typography.Caption>;
    } else if (statusList.every(status => status === 'COMPLETED')) {
      return <StyledCompleted>Complete</StyledCompleted>;
    } else {
      return <StyledInProgress>In Progress</StyledInProgress>;
    }
  };

  return (
    <ThemeProvider>
      <GlobalStyles />
      <Hero
        title={name}
        description={
          <div>
            <Typography.Text>
              in{' '}
              <StyledLink
                href={`${courseVersionsPath}?course_category_id=${courseCategoryId}`}>
                {courseCategoryTitle} Certificate
              </StyledLink>
            </Typography.Text>
            <Typography.Text>{description}</Typography.Text>
            <StatusDiv>
              {overallStatus(courseStatusList)} &nbsp; | &nbsp;{' '}
              <Typography.Caption>
                {courseModules.length} videos
              </Typography.Caption>
            </StatusDiv>
          </div>
        }
        link={{
          path: '/certifications',
          name: 'Back to Certificates',
          icon: 'arrow-left',
        }}
      />
      <Container>
        {overviewItems && (
          <div>
            <Typography.SectionTitle>
              What you will learn
            </Typography.SectionTitle>
            <ul>
              {overviewItems.map((item, i) => (
                <li key={i}>{item}</li>
              ))}
            </ul>
          </div>
        )}

        <StyledShowItem>
          <Typography.SectionTitle>Videos</Typography.SectionTitle>
          <div>
            {needsReset && (
              <>
                <Button onClick={toggleShowResetForm} kind="destruction">
                  Reset Course
                </Button>
                <Modal
                  portalContainerId="bfe-root"
                  title="Reset Course"
                  isOpen={showResetForm}
                  onClose={toggleShowResetForm}>
                  <AlertWithIcon variant="danger">
                    <strong>Danger!</strong> This action can not be reversed.
                  </AlertWithIcon>
                  <p>
                    Due to a change in our website, you must reset your progress
                    to watch the modules in the &quot;{name}
                    &quot; course. If you have already completed your Bloomberg
                    Certification, your certification will NOT be affected.
                  </p>
                  <PostForm
                    action={resetCourseVersionPath}
                    className="float-right"
                    submitText="Reset my course"
                    submitProps={{
                      variant: 'danger',
                      className: 'mr-2',
                    }}
                    onCancel={toggleShowResetForm}
                  />
                </Modal>
              </>
            )}
            {eligibleForUpgrade && (
              <>
                <Button onClick={toggleShowUpgradeForm} kind="secondary">
                  Upgrade Course
                </Button>
                <Modal
                  portalContainerId="bfe-root"
                  isOpen={showUpgradeForm}
                  onClose={toggleShowUpgradeForm}
                  title="Upgrade Course">
                  <AlertWithIcon variant="danger">
                    <strong>Danger!</strong> This action can not be reversed.
                  </AlertWithIcon>
                  <strong>
                    Please check box to acknowledge before upgrading
                  </strong>
                  <PostForm
                    action={upgradeCourseVersionPath}
                    submitRender={submitProps => (
                      <div className="float-right mt-2">
                        <Button
                          kind="primary"
                          type="submit"
                          disabled={
                            !isUpgradeAccepted || submitProps.isSubmitting
                          }>
                          {submitProps.isSubmitting && (
                            <Spinner className="mr-1" />
                          )}
                          {submitProps.isSubmitting
                            ? 'Upgrading...'
                            : 'Upgrade'}
                        </Button>
                        <Button
                          onClick={toggleShowUpgradeForm}
                          kind="secondary">
                          Cancel
                        </Button>
                      </div>
                    )}>
                    <Input
                      type="checkbox"
                      name="isUpgradeAccepted"
                      checked={isUpgradeAccepted}
                      onChange={toggleIsUpgradeAccepted}
                      label={`I understand that upgrading to the new version of the ${name}
                                   course will remove scores and progress for all modules
                                   in this specific course. If you have already completed
                                   your Bloomberg Certification, your certification will
                                   NOT be affected.`}
                    />
                  </PostForm>
                </Modal>
              </>
            )}
          </div>
        </StyledShowItem>
        <List>
          {courseModules.map((courseModule, index) => (
            <ListItem
              key={index}
              label={
                <CourseItem
                  number={index + 1}
                  title={courseModule.name}
                  status={courseModule.status}
                />
              }
              actions={
                needsReset ? (
                  <Button disabled kind="secondary">
                    Reset Course to Resume
                  </Button>
                ) : (
                  <PostForm
                    action={courseModule.registerCourseModuleCourseVersionPath}
                    submitText={
                      courseModule.status === 'NOT_STARTED' ||
                      !courseModule.status
                        ? 'Start'
                        : 'Resume'
                    }
                  />
                )
              }
            />
          ))}
        </List>
      </Container>
    </ThemeProvider>
  );
};

Show.propTypes = ShowProps;

CourseItem.propTypes = {
  number: number.isRequired,
  title: string.isRequired,
  status: string.isRequired,
  action: string.isRequired,
  needsReset: bool.isRequired,
};

export default Show;
