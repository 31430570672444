import { Button, Typography } from '@bbnpm/bb-ui-framework';
import styled from 'styled-components';

export const PageTitle = styled.div`
  text-align: center;
`;

export const HighSchoolCheckboxContainer = styled.div`
  margin-top: 0.5em;
  margin-bottom: 0.5em;
`;

export const KeepMeUpdatedCheckboxContainer = styled.div`
  margin-top: 1em;
  margin-bottom: 1em;
`;

export const DisabledText = styled(Typography.Hint)`
  color: ${({ theme }) => theme.colors.disabledText};
`;

export const ProfessorInfoContainer = styled.div`
  margin-top: 1em;
`;

export const StyledLink = styled(Typography.Link)`
  color: ${({ theme }) => theme.colors.blue[600]} !important;
`;

export const StyledButton = styled(Button)`
  padding: 0 3em 0 3em;
`;

export const PasswordShowHideText = styled(Typography.Text)`
  margin: auto 1em auto 0;
  font-weight: bold;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.grey[500]};
`;
