import {
  Button,
  Dropdown,
  FormField,
  GlobalStyles,
  Input,
  MultiSelect,
  Switch,
  TextArea,
  ThemeProvider,
} from '@bbnpm/bb-ui-framework';
import { Fragment, useState } from 'react';
import { useMap } from 'react-use';

import AttachmentInputs from '../../AttachmentInputs';
import { ButtonContainer, RequiredLabel } from '../../CommonComponents';
import Container from '../../Container';
import DaperImg, { daperUrl } from '../../DaperImg';
import ImageFileInput from '../../ImageFileInput';
import PatchForm from '../../PatchForm';
import PostForm from '../../PostForm';
import { HiddenInputFieldsProps, ToolkitDocumentFormProps } from './types';

const HiddenInputFields = ({
  allToolkitTopics,
  existingTopicsMap,
  selectedMap,
  docType,
  initialDocType,
}) => {
  return (
    <>
      {allToolkitTopics.map((topic, index) => {
        const documentTopicId = existingTopicsMap[topic.id];

        return (
          <Fragment key={index}>
            {documentTopicId && (
              <input
                type="hidden"
                name={`toolkitDocumentTagsAttributes[${index}][id]`}
                defaultValue={documentTopicId}
              />
            )}
            <input
              type="hidden"
              name={`toolkitDocumentTagsAttributes[${index}][toolkitTagId]`}
              defaultValue={topic.id || ''}
            />
            <input
              type="hidden"
              name={`toolkitDocumentTagsAttributes[${index}][_destroy]`}
              defaultValue={selectedMap[topic.id] ? '0' : '1'}
            />
          </Fragment>
        );
      })}
      {initialDocType && (
        <input
          type="hidden"
          name={`toolkitDocumentTagsAttributes[${
            allToolkitTopics.length + 1
          }][id]`}
          defaultValue={existingTopicsMap[initialDocType]}
        />
      )}
      {docType && (
        <input
          type="hidden"
          name={`toolkitDocumentTagsAttributes[${
            allToolkitTopics.length + 1
          }][toolkitTagId]`}
          defaultValue={docType || ''}
        />
      )}
    </>
  );
};
HiddenInputFields.propTypes = HiddenInputFieldsProps;

const ToolkitDocumentForm = ({
  id,
  title,
  description,
  thumbnail,
  active,
  allToolkitTags,
  allToolkitTypes,
  toolkitDocumentTags,
  toolkitType,
  cloudStorageAttachment,
  adminHref,
  errors = {},
  adminToolkitDocumentsPath,
  toolkitDocumentsBucketName,
}) => {
  const initialDocType =
    toolkitType?.id ??
    (allToolkitTypes?.length > 0 ? allToolkitTypes[0].id : undefined);

  const [docType, setDocType] = useState(initialDocType);

  const docTypeThumbnail =
    docType && allToolkitTypes.find(tag => tag.id === docType).thumbnail;

  let existingTopicsMap = {};
  toolkitDocumentTags.forEach(docTopic => {
    existingTopicsMap[docTopic.toolkit_tag_id] = docTopic.id;
  });

  let initialSelected = {};
  const defaultSelected = [];
  allToolkitTags.forEach(topic => {
    if (topic.id in existingTopicsMap) defaultSelected.push(topic.id);
    initialSelected[topic.id] = topic.id in existingTopicsMap;
  });

  defaultSelected.push(initialDocType);

  const [selectedMap, { set: setSelected }] = useMap(initialSelected);

  const [Form, action, submitText] = id
    ? [PatchForm, adminHref, 'Update']
    : [PostForm, adminToolkitDocumentsPath, 'Submit'];

  const [checked, setChecked] = useState(active);

  const allToolkitTopics = allToolkitTags.filter(
    ({ type }) => type === 'ToolkitTopic',
  );
  const options = allToolkitTopics.map(({ id, title }) => ({
    label: title,
    value: id,
  }));

  const defaultValues = toolkitDocumentTags.map(
    ({ toolkit_tag_id }) => toolkit_tag_id,
  );

  return (
    <ThemeProvider>
      <GlobalStyles />
      <Container>
        <div className="d-flex align-items-center">
          <h1>{id ? 'Edit' : 'Add'} Toolkit Document</h1>
        </div>

        <Form encType="multipart/form-data" action={action}>
          <FormField
            htmlFor="toolkit-doc-title"
            label={<RequiredLabel>Title</RequiredLabel>}
            validationType={errors.title && 'error'}
            validationContent={errors.title}>
            <Input
              id="toolkit-doc-title"
              type="text"
              defaultValue={title || ''}
              name="title"
            />
          </FormField>

          <FormField
            htmlFor="toolkit-doc-description"
            label={<RequiredLabel>Description (max length: 280)</RequiredLabel>}
            validationType={errors.description && 'error'}
            validationContent={errors.description}>
            <TextArea
              id="toolkit-doc-description"
              defaultValue={description || ''}
              autosize={{ minRows: 2 }}
              maxLength="280"
              name="description"
            />
          </FormField>

          {allToolkitTypes?.length > 0 && (
            <FormField label="Document Type">
              <Dropdown
                options={allToolkitTypes.map(({ id, title }) => ({
                  label: title,
                  value: id,
                }))}
                className="mb-2"
                onItemSelect={selected => setDocType(selected?.value)}
                defaultValue={initialDocType}
              />
            </FormField>
          )}

          <FormField label="Topics">
            <MultiSelect
              defaultValues={defaultValues}
              onItemSelect={({ value }) => {
                setSelected(value, true);
              }}
              onItemDeselect={({ value }) => {
                setSelected(value, false);
              }}
              options={options}
              searchable={true}
            />
          </FormField>

          <FormField label="Active (show for Professors)">
            <Switch
              checked={checked}
              onChange={(_, isChecked) => {
                setChecked(isChecked);
              }}
              label="Active"
              labelPosition="right"
              value={checked}
              name="active"
            />
            <input type="hidden" name="active" value={checked} />
          </FormField>

          {docTypeThumbnail && !thumbnail && (
            <FormField label="Default Document Type Thumbnail">
              <a
                href={daperUrl(docTypeThumbnail)}
                target="_blank"
                rel="noreferrer">
                <DaperImg
                  fluid
                  metadata={docTypeThumbnail}
                  height={75}
                  className="mr-2"
                />
              </a>
            </FormField>
          )}

          <FormField label="Thumbnail">
            <ImageFileInput
              name="thumbnail"
              accept=".jpg, .png"
              label={false}
              helpText="Upload the largest available version to override default document type thumbnail."
              value={thumbnail}
            />
          </FormField>

          <FormField label="Document">
            <AttachmentInputs
              attachment={cloudStorageAttachment}
              professorPacksBucketName={toolkitDocumentsBucketName}
              attributesKey="cloudStorageAttachmentAttributes"
              label={false}
              referenceType="ToolkitDocument"
            />
          </FormField>

          <HiddenInputFields
            allToolkitTopics={allToolkitTopics}
            docType={docType}
            initialDocType={initialDocType}
            selectedMap={selectedMap}
            existingTopicsMap={existingTopicsMap}
          />
          <ButtonContainer>
            <Button type="submit" className="ml-4" kind="primary" size="sm">
              {submitText}
            </Button>
            <Button
              as="a"
              className="ml-4"
              kind="secondary"
              href={id ? adminHref : adminToolkitDocumentsPath}
              size="sm">
              Cancel
            </Button>
          </ButtonContainer>
        </Form>
      </Container>
    </ThemeProvider>
  );
};

ToolkitDocumentForm.propTypes = ToolkitDocumentFormProps;

export default ToolkitDocumentForm;
