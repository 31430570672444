import AlertWithIcon from '../AlertWithIcon';

const ErrorAlert = () => (
  <AlertWithIcon className="my-2" variant="danger">
    An error occurred. If this continues, please contact bbg.edu@bloomberg.net
    to have your terminal sign-up session reset.
  </AlertWithIcon>
);

export default ErrorAlert;
