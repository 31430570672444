import { arrayOf, shape } from 'prop-types';

import { CourseCategoryProps } from '../types';

export const IndexProps = {
  courseCategories: arrayOf(shape(CourseCategoryProps)).isRequired,
};

export const CourseCardProps = {
  courseCategory: shape(CourseCategoryProps).isRequired,
};
