import { Button, Typography } from '@bbnpm/bb-ui-framework';
import { Col, Grid, Row } from '@bloomberg/styled-flexboxgrid';
import camelCase from 'lodash.camelcase';
import { shape } from 'prop-types';
import styled from 'styled-components';

import DaperImg from '../DaperImg';
import { PromoItemProps } from '../types';
import PromotionalCarousel from './PromotionalCarousel';
import { NoMarginTitle, StyledRow } from './styles';

const StyledCard = styled.div`
  display: flex;
  background: black;
  color: white;
  margin: 8px 4px;
  height: ${({ primary }) => (primary ? '380px' : '182px')};
`;

const CarouselView = styled.span`
  @media (width >= 80em) {
    display: none;
  }
`;

const SplitView = styled(Grid)`
  width: 100%;
  padding: 0;

  @media (width < 80em) {
    display: none;
  }
`;

export const CardImage = styled.div`
  & > img {
    height: 100%;
    max-width: ${({ primary }) => (primary ? '330' : '182')}px;
    max-height: ${({ primary }) => (primary ? '380' : '182')}px;
    object-fit: cover;
  }
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  justify-content: space-between;
`;

const PromoCard = ({
  title,
  description,
  actionText,
  url,
  thumbnail,
  primary,
}) => {
  const Title = primary ? Typography.SectionTitle : Typography.SubSectionTitle;
  return (
    <StyledCard primary={primary}>
      <CardContent>
        <span>
          <Title style={{ marginBottom: primary ? '2rem' : '0.5rem' }}>
            {title}
          </Title>
          <Typography.Text style={{ whiteSpace: 'pre-line' }}>
            {description}
          </Typography.Text>
        </span>
        {actionText && (
          <span style={{ marginBottom: primary ? '1rem' : '' }}>
            <Button
              kind={primary ? 'primary' : 'tertiary'}
              as="a"
              target="_blank"
              href={url}
              style={primary ? {} : { color: '#66abff' }}
              onClick={() =>
                window.bfeAnalytics.trackEvent({
                  category: 'homepageAction',
                  action: `promoItemClicked`,
                  label: camelCase(title),
                })
              }>
              {actionText}
            </Button>
          </span>
        )}
      </CardContent>

      {thumbnail && (
        <CardImage primary={primary}>
          <DaperImg metadata={thumbnail} width={500} />
        </CardImage>
      )}
    </StyledCard>
  );
};

PromoCard.propTypes = shape(PromoItemProps);

const CarouselSection = ({ promotionalItems }) => {
  return (
    <StyledRow>
      <Col xs={12} md={3}>
        <NoMarginTitle>What&apos;s New</NoMarginTitle>
      </Col>
      <Col xs={12} md={9}>
        <PromotionalCarousel promotionalItems={promotionalItems} />
      </Col>
    </StyledRow>
  );
};

const PromoSection = ({ promotionalItems }) => {
  if (promotionalItems.length !== 3) {
    return <CarouselSection promotionalItems={promotionalItems} />;
  }

  return (
    <>
      <CarouselView>
        <CarouselSection promotionalItems={promotionalItems} />
      </CarouselView>
      <SplitView>
        <Row xs={12} style={{ paddingLeft: '8px' }}>
          <NoMarginTitle>What&apos;s New</NoMarginTitle>
        </Row>
        <Row xs={12}>
          <Col xs={7}>
            <PromoCard
              title={promotionalItems[0].title}
              description={promotionalItems[0].description}
              actionText={promotionalItems[0].actionText}
              url={promotionalItems[0].url}
              thumbnail={promotionalItems[0].thumbnail}
              primary
            />
          </Col>
          <Col xs={5}>
            <Row>
              <PromoCard
                title={promotionalItems[1].title}
                description={promotionalItems[1].description}
                actionText={promotionalItems[1].actionText}
                url={promotionalItems[1].url}
                thumbnail={promotionalItems[1].thumbnail}
              />
            </Row>
            <Row>
              <PromoCard
                title={promotionalItems[2].title}
                description={promotionalItems[2].description}
                actionText={promotionalItems[2].actionText}
                url={promotionalItems[2].url}
                thumbnail={promotionalItems[2].thumbnail}
              />
            </Row>
          </Col>
        </Row>
      </SplitView>
    </>
  );
};

export default PromoSection;
