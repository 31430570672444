import { AttachmentProps } from './types';

const AttachmentLink = ({ attachment }) => (
  <a
    href={attachment.downloadAdminCloudStorageAttachmentPath}
    target="_blank"
    rel="noreferrer">
    {attachment.fileName}
  </a>
);

AttachmentLink.propTypes = { attachment: AttachmentProps };

export default AttachmentLink;
