import { Spinner as BsSpinner } from 'react-bootstrap';

const Spinner = props => (
  <BsSpinner
    as="span"
    animation="border"
    size="sm"
    role="status"
    aria-hidden="true"
    {...props}
  />
);

export default Spinner;
