import { Typography } from '@bbnpm/bb-ui-framework';
import { Col, Row } from '@bloomberg/styled-flexboxgrid';
import { arrayOf, shape } from 'prop-types';

import { CourseVersionProps, VideoProps } from '../types';
import VideoCard from './VideoCard';

const NewContent = ({ latestCourseVersions, latestVideos }) => {
  const findLatestThree = () => {
    const content = [];
    let cvIdx = 0;
    let videoIdx = 0;
    while (cvIdx < 3 && videoIdx < 3) {
      if (content.length === 3) {
        break;
      }
      if (
        new Date(latestCourseVersions[cvIdx].createdAt) >
        new Date(latestVideos[videoIdx].createdAt)
      ) {
        content.push({
          type: latestCourseVersions[cvIdx].course.courseCategory.title,
          content: latestCourseVersions[cvIdx],
        });
        cvIdx += 1;
      } else {
        content.push({
          type: 'Videos',
          content: latestVideos[videoIdx],
        });
        videoIdx += 1;
      }
    }
    return content;
  };
  const content = findLatestThree();

  return (
    <>
      <Typography.PageTitle>New Content</Typography.PageTitle>
      <Row>
        {content.map((c, idx) => (
          <Col xs={4} key={idx}>
            <VideoCard
              content={c.content}
              type={c.type}
              date={c.content.createdAt}
              className="col-sm-4 link-unstyled pl-0"
            />
          </Col>
        ))}
      </Row>
    </>
  );
};

NewContent.propTypes = {
  latestVideos: arrayOf(shape(VideoProps)),
  latestCourseVersions: arrayOf(shape(CourseVersionProps)),
};
export default NewContent;
