import {
  Button,
  GlobalStyles,
  Icon,
  Modal,
  ThemeProvider,
  Typography,
} from '@bbnpm/bb-ui-framework';
import { string } from 'prop-types';
import { useToggle } from 'react-use';

import DeleteButton from '../../DeleteButton';

const DeleteUserButton = ({ name, action }) => {
  const [isOpen, toggleIsOpen] = useToggle(false);

  return (
    <ThemeProvider>
      <GlobalStyles />
      <Button kind="destruction" onClick={toggleIsOpen}>
        <Icon name="trash" /> Delete User
      </Button>

      <Modal
        isOpen={isOpen}
        title={`Delete User: ${name}`}
        contentLabel="Delete User"
        portalContainerId="bfe-root"
        primaryActions={
          <>
            <DeleteButton action={action} kind="destruction">
              Delete User Permanently
            </DeleteButton>
            <Button kind="secondary" onClick={toggleIsOpen}>
              Cancel
            </Button>
          </>
        }>
        <Typography.Text>
          Are you sure you want to delete user <strong>{name}</strong> ? This
          action cannot be undone.
        </Typography.Text>
      </Modal>
    </ThemeProvider>
  );
};

DeleteUserButton.propTypes = {
  name: string.isRequired,
  action: string.isRequired,
};

export default DeleteUserButton;
