import {
  Button,
  GlobalStyles,
  ThemeProvider,
  Typography,
} from '@bbnpm/bb-ui-framework';
import startcase from 'lodash.startcase';
import { useForm } from 'react-hook-form';

import { get } from '../../../api';
import AutosuggestInput from '../../AutosuggestInput';
import Container from '../../Container';
import Input from '../../Input';
import PatchForm from '../../PatchForm';
import PostForm from '../../PostForm';
import { FlexBox } from './styles';
import { UserFormProps } from './types';

const UserForm = ({
  userid,
  firstName,
  lastName,
  email,
  isConfirmed,
  isDeleted,
  types: initialTypes,
  type: initialType,
  errors = {},
  professorInfo,
  institution,
  href,
  adminUsersPath,
  adminInstitutionsPath,
  bssoEnabled,
  uuid,
  hasLockedPassword,
}) => {
  const { register, watch } = useForm();

  const type = watch('type', initialType);

  const types = initialTypes.map(array => [array[0], startcase(array[1])]);

  const [Form, action, submitText] = userid
    ? [PatchForm, href, 'Update']
    : [PostForm, adminUsersPath, 'Submit'];

  const isAdminType = ['Specialist', 'Administrator', 'Manager'].includes(type);

  return (
    <ThemeProvider>
      <GlobalStyles />
      <Container>
        <FlexBox>
          <Typography.PageTitle>
            {userid ? 'Edit' : 'Add'} User
          </Typography.PageTitle>
          {href && (
            <Button kind="secondary" as="a" href={href}>
              Cancel
            </Button>
          )}
        </FlexBox>

        <Form action={action} submitText={submitText}>
          <Input
            ref={register}
            defaultValue={type}
            as="select"
            required
            name="type"
            options={types}
            readOnly={types.length < 2}
          />
          <Input
            errors={errors.firstName}
            required
            name="firstName"
            defaultValue={firstName || ''}
          />
          <Input
            errors={errors.lastName}
            required
            name="lastName"
            defaultValue={lastName || ''}
          />
          <Input
            errors={errors.email}
            required={!hasLockedPassword}
            disabled={hasLockedPassword}
            type="email"
            name="email"
            defaultValue={email || ''}
          />
          <Input
            errors={errors.password}
            name="password"
            type="password"
            disabled={hasLockedPassword}
          />
          <Input
            errors={errors.passwordConfirmation}
            name="passwordConfirmation"
            type="password"
            disabled={hasLockedPassword}
          />

          <Input errors={errors.uuid} name="uuid" defaultValue={uuid || ''} />

          {!isAdminType && (
            <AutosuggestInput
              entity={institution}
              onSuggestionsFetchRequested={async value => {
                const { institutions } = await get(adminInstitutionsPath, {
                  nameCont: value,
                });
                return institutions;
              }}
              label="Institution"
              name="institutionId"
              placeholder="type to look for institutions"
              errors={[
                ...(errors.institution || []),
                ...(errors.customer || []),
              ]}
            />
          )}

          {type === 'Professor' && (
            <>
              <Input
                defaultChecked={
                  professorInfo
                    ? professorInfo.accountVerified
                    : initialType !== 'Professor'
                }
                type="switch"
                label="Professor Status Confirmed"
                name="professorInfoAttributes[accountVerified]"
                helpText="Professor account status is confirmed."
              />
              {!!professorInfo && professorInfo.id && (
                <input
                  type="hidden"
                  name="professorInfoAttributes[id]"
                  defaultValue={professorInfo.id}
                />
              )}
            </>
          )}
          <Input
            errors={errors.isConfirmed}
            defaultChecked={isConfirmed}
            type="switch"
            name="isConfirmed"
            label="Email Activated"
            helpText="This refers to the activation step during sign up. Cannot be switched off."
            disabled={userid && isConfirmed}
          />
          <Input
            errors={errors.isDeleted}
            defaultChecked={isDeleted}
            type="switch"
            name="isDeleted"
            label="Is Inactive"
            helpText="This refers to the overall status of the user's account."
          />
          <Input
            errors={errors.bssoEnabled}
            defaultChecked={bssoEnabled}
            type="switch"
            name="bssoEnabled"
            label="BSSO Enabled"
            disabled={!uuid || hasLockedPassword}
            helpText="This refers to whether user can use BSSO. Cannot be switched off if user has no BCONNECT account."
          />
        </Form>
      </Container>
    </ThemeProvider>
  );
};

UserForm.propTypes = UserFormProps;

export default UserForm;
