import {
  Button,
  GlobalStyles,
  ThemeProvider,
  Typography,
} from '@bbnpm/bb-ui-framework';
import styled from 'styled-components';

import Container from '../../Container';
import { PurchaseEntitlementProps } from '../../types';

const StyledDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50%;
`;

const Show = ({
  id,
  user,
  productSku,
  createdAt,
  updatedAt,
  editAdminPurchaseEntitlementPath,
}) => {
  return (
    <ThemeProvider>
      <GlobalStyles />
      <Container>
        <StyledDiv>
          <Typography.PageTitle>BMC Type: {productSku}</Typography.PageTitle>
          <a href={editAdminPurchaseEntitlementPath}>
            <Button kind="primary">Edit</Button>
          </a>
        </StyledDiv>
        <Typography.Text>
          <strong>ID: </strong> {id}
        </Typography.Text>
        <Typography.Text>
          <strong>Created at: </strong>
          {createdAt}
        </Typography.Text>
        <Typography.Text>
          <strong>Updated at: </strong>
          {updatedAt}
        </Typography.Text>
        <Typography.Text>
          <strong>User Type: </strong> {user.type}
        </Typography.Text>
        <Typography.Text>
          <strong>First Name: </strong> {user.firstName}
        </Typography.Text>
        <Typography.Text>
          <strong>Last Name: </strong> {user.lastName}
        </Typography.Text>
        <Typography.Text>
          <strong>Email: </strong> {user.email}
        </Typography.Text>
      </Container>
    </ThemeProvider>
  );
};

Show.propTypes = PurchaseEntitlementProps;

export default Show;
