import { arrayOf, bool, string } from 'prop-types';

import { CourseModuleProps, CourseVersionProps } from '../../types';

export const CourseVersionFormProps = CourseVersionProps;

export const ShowProps = {
  ...CourseVersionProps,
  availableCourseModules: arrayOf(CourseModuleProps),
};

export const CourseModuleDroppableProps = {
  droppableId: string.isRequired,
  courseModules: ShowProps.availableCourseModules,
  selected: bool,
  disabled: bool.isRequired,
};
