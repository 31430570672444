import {
  Card,
  Experimental__SkeletonPulse,
  GlobalStyles,
  Input,
  ProgressSpinner,
  ThemeProvider,
  Token,
  Tooltip,
  Typography,
} from '@bbnpm/bb-ui-framework';
import { Col, Grid, Row } from '@bloomberg/styled-flexboxgrid';
import camelCase from 'lodash.camelcase';
import debounce from 'lodash.debounce';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { get } from '../../../api';
import { noop } from '../../../utils';
import Container from '../../Container';
import VimeoPlayer from '../../VimeoPlayer';
import { TOOLKIT_FILTER_MAPPINGS } from '../../admin/toolkit_tags/constants';
import TitleCard from '../../home_page/TitleCard';
import { usePostData } from '../../utils';
import {
  ClearAll,
  DocumentCardThumbnail,
  FilterWrapper,
  FilteredTokens,
  Left,
  PDFWrapper,
  Right,
  RightCount,
  StyledCard,
  StyledFormField,
  StyledHr,
  StyledModal,
  StyledToken,
} from './Index.styles';
import LoadMore from './LoadMore';
import { DocumentCardProps, IndexProps } from './types';

const theme = {
  flexboxgrid: {
    gutterWidth: 1, // rem
    outerMargin: 0, // rem
    mediaQuery: 'only screen',
  },
};

const cb = ({ totalCount, toolkitDocuments, activeTags, docCounts }) => ({
  data: toolkitDocuments,
  totalCount,
  activeTags,
  docCounts,
});

const Skeleton = () => {
  return (
    <Col xs={12} sm={6} md={6} lg={4} theme={theme}>
      <StyledCard onClick={() => {}}>
        <Card.Header>
          <Card.SuperTitle>
            <Experimental__SkeletonPulse
              $width={`${Math.random() * 10 + 20}%`}
              $height="1.25rem"
            />
          </Card.SuperTitle>
          <Card.Title>
            <Experimental__SkeletonPulse
              $width={`${Math.random() * 30 + 60}%`}
              $height="1.75rem"
            />
          </Card.Title>
        </Card.Header>
        <Card.Content>
          <Experimental__SkeletonPulse $width="150px" $height="168px" />
          <Experimental__SkeletonPulse
            $width={`${Math.random() * 40 + 60}%`}
            $height="1em"
          />
          <Experimental__SkeletonPulse
            $width={`${Math.random() * 40 + 60}%`}
            $height="1em"
          />
        </Card.Content>
      </StyledCard>
    </Col>
  );
};
const SkeletonMemo = memo(Skeleton);

const DocumentCard = ({ doc, idx, pdfLoading, cardFooterAction }) => (
  <>
    <StyledCard onClick={() => {}}>
      <Card.Header>
        {doc.toolkitType?.title && (
          <Card.SuperTitle>{doc.toolkitType?.title}</Card.SuperTitle>
        )}
        <Card.Title>{doc.title}</Card.Title>
      </Card.Header>
      <Card.Content>
        {doc.thumbnail && doc.thumbnail.daperKey ? (
          <DocumentCardThumbnail metadata={doc.thumbnail} fluid />
        ) : (
          doc.toolkitType?.thumbnail?.daperKey && (
            <DocumentCardThumbnail metadata={doc.toolkitType.thumbnail} fluid />
          )
        )}
        <Typography.Text className="text-break">
          {doc.description}
        </Typography.Text>
        {(doc?.tableTopics ?? []).slice(0, 2).map(({ title }, index) => (
          <StyledToken key={`token-${index}`}>{title}</StyledToken>
        ))}
        {(doc?.tableTopics ?? []).length > 2 && (
          <Tooltip
            content={doc?.tableTopics.map(({ title }) => title).join(', ')}>
            <StyledToken>+{(doc?.tableTopics ?? []).length - 2}</StyledToken>
          </Tooltip>
        )}
      </Card.Content>
      {doc.cloudStorageAttachment && (
        <Card.Footer
          actions={[
            pdfLoading?.action === 'download' && pdfLoading?.doc === idx
              ? { name: <ProgressSpinner label={null} /> }
              : {
                  name: 'Download',
                  icon: 'download',
                  onClick: () => cardFooterAction('download', idx, doc),
                },
            pdfLoading?.action === 'preview' && pdfLoading?.doc === idx
              ? { name: <ProgressSpinner label={null} /> }
              : {
                  name: 'Preview',
                  icon: 'eye',
                  onClick: () => cardFooterAction('preview', idx, doc),
                },
          ]}
        />
      )}
    </StyledCard>
  </>
);
DocumentCard.propTypes = DocumentCardProps;

const DocumentCardMemo = memo(DocumentCard);

const toOptions = ({ tags, curTags, checkedStates, updateChecked }) => {
  return tags.map(({ type, value }) => {
    return {
      type,
      value: value.map(({ title, id }, index) => {
        const size = curTags[title] ?? 0;
        return {
          name: `${id}`,
          label: (
            <>
              <div>{title}</div>
              <RightCount>{size}</RightCount>
            </>
          ),
          title,
          checked: checkedStates[type][index],
          size,
          onClick: e => updateChecked(type, title, e.target.checked),
          type,
        };
      }),
    };
  });
};

const Index = ({
  toolkitDocuments,
  activeTags,
  docCounts,
  totalCount,
  fullName,
  searchHref,
  backgroundImg,
}) => {
  const [{ refreshData }] = useState({ refreshData: noop });
  const [documents, setDocuments] = useState(toolkitDocuments ?? []);
  const [curTags, setCurTags] = useState(JSON.parse(docCounts) ?? {});
  const [count, setCount] = useState(totalCount ?? []);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState('');

  const setDocumentsFn = useCallback(docs => setDocuments(docs), []);
  const setCountFn = useCallback(count => setCount(count), []);
  const setIsLoadingFn = useCallback(loading => setLoading(loading), []);
  const setCurTagsFn = useCallback(tags => setCurTags(tags), []);

  const inputRef = useRef(null);

  const fetchData = usePostData(searchHref, cb);

  const tags = Object.entries(activeTags)
    .map(([key, val]) => {
      return {
        type: key,
        value: val,
      };
    })
    .sort((a, b) => {
      return a.value.length - b.value.length;
    });

  const initialChecked = {};
  Object.entries(activeTags).forEach(([key, val]) => {
    initialChecked[key] = val.map(() => false);
  });

  const [checkedStates, setCheckedStates] = useState(initialChecked);

  const updateChecked = useCallback(
    (type, token, status) => {
      const newStates = { ...checkedStates };
      const index = indexMap[type][token];
      newStates[type][index] = status;
      setCheckedStates(newStates);

      if (status) {
        window.bfeAnalytics.trackEvent({
          category: 'professorAction',
          action: `filterToolkitDocument`,
          label: camelCase(token),
        });
      }
    },
    [checkedStates, indexMap],
  );

  const clearAll = () => {
    setQuery('');
    inputRef.current.value = '';
    setCheckedStates(initialChecked);
  };

  const [pdfLoading, setPdfLoading] = useState(null);

  const handleSearch = e => {
    if (e.key !== 'Enter') return;
    setQuery(e.target?.value);
  };

  const onChange = e => {
    setQuery(e.target?.value);
  };
  const _onChange = debounce(onChange, 200);

  const options = useMemo(
    () => toOptions({ tags, curTags, checkedStates, updateChecked }),
    [tags, curTags, checkedStates, updateChecked],
  );

  const indexMap = Object.fromEntries(
    options.map(({ type, value }) => {
      const titleToIndex = {};
      value.forEach(({ title }, index) => (titleToIndex[title] = index));
      return [type, titleToIndex];
    }),
  );

  const checkedTokens = options
    .map(({ value }) => value.filter(({ checked }) => checked))
    .flat();

  const [file, setFile] = useState(undefined);

  const getPDF = async (path, title, isDownload = false) => {
    try {
      const res = await get(
        path,
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/pdf',
          },
        },
        { responseType: 'blob' },
      );
      const blob = new Blob([res], { type: 'application/pdf' });
      if (isDownload) {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `${title}.pdf`;
        link.click();
        link.remove();
      } else {
        setFile({ path: window.URL.createObjectURL(blob), title: title });
      }
      if (res) {
        setPdfLoading(null);
      }
    } catch (error) {
      if (isDownload) {
        alert('Failed to download file ' + title);
      } else {
        setFile({ title, error });
      }
    }
    setPdfLoading(null);
  };

  const cardFooterAction = (action, idx, doc) => {
    setPdfLoading({
      action,
      doc: idx,
    });
    getPDF(
      doc.cloudStorageAttachment.professorsAttachmentPreviewPath,
      doc.title,
      action === 'download',
    );
    window.bfeAnalytics.trackEvent({
      category: 'professorAction',
      action: `${action}ToolkitDocument`,
      label: camelCase(`${doc.title}${doc.id}`),
    });
  };

  const activeFilterIds = Object.fromEntries(
    options.map(({ type, value }) => {
      return [
        type,
        value.filter(({ checked }) => checked).map(({ name }) => name),
      ];
    }),
  );

  const activeFilterTitles = Object.fromEntries(
    options
      .map(({ type, value }) => {
        const checkedValues = value.filter(({ checked }) => checked);
        return checkedValues.length > 0
          ? [
              TOOLKIT_FILTER_MAPPINGS[type]?.key,
              checkedValues.map(({ title }) => title),
            ]
          : null;
      })
      .filter(val => val != null),
  );

  // make sure we're only basing number of skeletons loaded on one filter type
  // base on toolkitType if all filters are toolkitType, otherwise base on topic
  const skeletonCountType = checkedTokens.every(
    ({ type }) => type === 'toolkitType',
  )
    ? 'toolkitType'
    : 'toolkitTopic';
  const checkedCount =
    checkedTokens.length === 0
      ? 6
      : checkedTokens
          .filter(({ type }) => type === skeletonCountType)
          .map(({ size }) => size)
          .reduce((num, acc) => num + acc, 0);

  useEffect(() => {
    window.bfeAnalytics.trackEvent({
      category: 'professorAction',
      action: `searchToolkitDocument`,
      label: camelCase(query),
    });
  }, [query]);

  return (
    <ThemeProvider>
      <GlobalStyles />
      <TitleCard
        containerClassName="pb-3 pt-3 d-flex justify-content-between"
        backgroundImg={backgroundImg}
        title={`Hello ${fullName},`}
        description={
          <div>
            <Typography.Text>
              Welcome to the Bloomberg Toolkit for Professors
            </Typography.Text>
            <Typography.Text>
              Here you will find everything you need to get started and enrich
              your teaching.
            </Typography.Text>
            <Typography.Text>
              Explore and download Bloomberg activities to bring the real world
              of finance into your classroom.
            </Typography.Text>
          </div>
        }
        sideContent={
          <VimeoPlayer
            vimeoId={850459369}
            style={{ height: '293px', width: '520px' }}
          />
        }
      />
      <Container className="pl-0 pr-0">
        <StyledModal
          isOpen={file != null}
          title={
            file?.title ? `Previewing: ${file?.title}` : 'Document Preview'
          }
          contentLabel="A modal displaying a preview of a document PDF"
          portalContainerId="bfe-root"
          onClose={() => setFile(undefined)}>
          {file && file.path && (
            <PDFWrapper
              title="document preview"
              src={`${file?.path}#view=FitH`}
              type="application/pdf"
              loading="lazy"></PDFWrapper>
          )}
          {file?.error && (
            <Typography.Text>Error loading document preview</Typography.Text>
          )}
        </StyledModal>
        <div className="d-flex flex-nowrap">
          <Left>
            <Typography.SectionTitle>Filters</Typography.SectionTitle>
            <StyledFormField label="Search Documents">
              <Input
                onKeyDown={handleSearch}
                onChange={_onChange}
                name="search"
                placeholder="Type in a keyword"
                ref={inputRef}
              />
            </StyledFormField>
            {options.map(({ type, value }) => (
              <FilterWrapper
                key={`filters-${type}`}
                label={TOOLKIT_FILTER_MAPPINGS[type]?.label}
                options={value}
                className="bbui-disable-vertical-margin"
              />
            ))}
            <ClearAll kind="secondary" onClick={clearAll}>
              Clear All
            </ClearAll>
          </Left>
          <Right>
            <Typography.Hint>
              Showing {count} Result{count === 1 ? '' : 's'}
            </Typography.Hint>
            <FilteredTokens>
              {checkedTokens.map(({ type, title }, index) => (
                <Token
                  key={`token-${type}-${index}`}
                  onClose={() => {
                    updateChecked(type, title, false);
                  }}>
                  {title}
                </Token>
              ))}
              {checkedTokens.length > 0 && (
                <Typography.Link onClick={clearAll}>Clear All</Typography.Link>
              )}
            </FilteredTokens>
            <StyledHr />
            <Grid className="pl-0 pr-0" fluid>
              <Row className="ml-0 mr-0">
                {loading
                  ? Array.from(Array(checkedCount)).map((_, idx) => {
                      return <SkeletonMemo key={`skeleton-${idx}`} />;
                    })
                  : documents.map((doc, idx) => (
                      <Col
                        xs={12}
                        sm={6}
                        md={6}
                        lg={4}
                        key={`toolkit-doc-${idx}`}
                        theme={theme}>
                        <DocumentCardMemo
                          doc={doc}
                          idx={idx}
                          pdfLoading={pdfLoading}
                          cardFooterAction={cardFooterAction}
                        />
                      </Col>
                    ))}
              </Row>
            </Grid>
            <LoadMore
              fetchData={fetchData}
              refreshData={refreshData}
              initialData={toolkitDocuments}
              setDocumentsFn={setDocumentsFn}
              setCountFn={setCountFn}
              setIsLoadingFn={setIsLoadingFn}
              setCurTagsFn={setCurTagsFn}
              documents={documents}
              initialTotalCount={totalCount}
              initialToolkitTags={activeTags}
              activeFilterIds={activeFilterIds}
              activeFilterTitles={activeFilterTitles}
              query={query}
              initialPageSize={12}
            />
          </Right>
        </div>
      </Container>
    </ThemeProvider>
  );
};

Index.propTypes = IndexProps;
export default Index;
