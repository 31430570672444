import { Button } from '@bbnpm/bb-ui-framework';
import { useToggle } from 'react-use';

import DeleteLeaderboardModal from './DeleteLeaderboardModal';
import { LeaderboardActionsCellProps } from './types';

const LeaderboardActionsCell = ({ row, refreshData }) => {
  const [showDeleteModal, toggleShowDeleteModal] = useToggle(false);

  return (
    <div className="d-flex justify-content-end">
      <Button
        kind="secondary"
        icon="pencil"
        as="a"
        href={row.original.editAdminTradingChallengeLeaderboardPath}
        className="mr-3"
      />

      <Button kind="destruction" icon="trash" onClick={toggleShowDeleteModal} />

      <DeleteLeaderboardModal
        row={row}
        showDeleteModal={showDeleteModal}
        toggleShowDeleteModal={toggleShowDeleteModal}
        refreshData={refreshData}
      />
    </div>
  );
};

LeaderboardActionsCell.propTypes = LeaderboardActionsCellProps;

export default LeaderboardActionsCell;
