import {
  GlobalStyles,
  ThemeProvider,
  Typography,
} from '@bbnpm/bb-ui-framework';
import { string } from 'prop-types';

import Container from '../../Container';
import PostButton from '../../PostButton';

const Index = ({ infoMailLink, switchToCandidateProfessorsDashboardsPath }) => (
  <ThemeProvider>
    <GlobalStyles />
    <Container>
      <Typography.PageTitle>
        <strong>Professor Verification</strong>
      </Typography.PageTitle>
      <Typography.Text>
        Thank you for your interest in Bloomberg Market Concepts (BMC).
      </Typography.Text>
      <Typography.Text>
        In order to access BMC with a Professor Account, we must first verify
        your identity. A Bloomberg for Education representative will be in
        contact with you shortly.
      </Typography.Text>
      <Typography.Text>
        For further information or assistance, please email us at{' '}
        <Typography.Link href={`mailto:${infoMailLink}`}>
          bbg.edu@bloomberg.net
        </Typography.Link>
      </Typography.Text>
      <Typography.Text>
        <PostButton action={switchToCandidateProfessorsDashboardsPath}>
          Click Here
        </PostButton>{' '}
        if you made this selection in error and would like to proceed to BMC
        with a Learner account.
      </Typography.Text>
    </Container>
  </ThemeProvider>
);

Index.propTypes = {
  infoMailLink: string.isRequired,
  switchToCandidateProfessorsDashboardsPath: string.isRequired,
};

export default Index;
