import { Button, GlobalStyles, ThemeProvider } from '@bbnpm/bb-ui-framework';
import { arrayOf, shape, string } from 'prop-types';
import { Container } from 'react-bootstrap';

import Hero from '../../Hero';
import Table from '../../Table';
import { BatClassProps, TableProps } from '../../types';
import { useFetchData } from '../../utils';

const columns = [
  { Header: 'Class', accessor: 'nameAlias' },
  { Header: 'Learners', accessor: 'classEnrollmentsCount' },
  { Header: 'Deadline', accessor: 'expiredAt' },
  {
    Header: 'Status',
    accessor: 'status',
    disableSortBy: true,
  },
];

const scopes = [
  { id: 'open', title: 'Open' },
  { id: 'closed', title: 'Closed' },
];

const cb = ({ totalCount, pageSize, classes }) => ({
  data: classes,
  pageSize,
  totalCount,
});

const onRowClick = ({ professorHref }) =>
  window.Turbolinks.visit(professorHref);

const Index = ({
  newProfessorsClassPath,
  classes,
  pageSize,
  pageIndex,
  totalCount,
  sortBy,
  professorsClassesPath,
}) => (
  <ThemeProvider>
    <GlobalStyles />

    <Hero
      title={'Manage Classes'}
      description={`
        Use classes to assign courses, videos, case studies, and other Bloomberg for Education materials to
        your students. When you create a class, you will be given a 10 digit code
        that you can share with your students. Once your students add the class
        code to their profile, you can track their progress and scores.
      `}
      primaryAction={
        <Button as="a" kind="primary" href={newProfessorsClassPath}>
          Create Class
        </Button>
      }
    />

    <Container fluid="xl" className="py-3">
      <Table
        initialData={classes}
        initialPageSize={pageSize}
        initialPageIndex={pageIndex}
        initialTotalCount={totalCount}
        initialSortBy={sortBy}
        initialScopes={{ open: false, closed: false }}
        columns={columns}
        availableScopes={scopes}
        fetchData={useFetchData(professorsClassesPath, cb)}
        onRowClick={onRowClick}
      />
    </Container>
  </ThemeProvider>
);

Index.propTypes = {
  ...TableProps,
  newProfessorsClassPath: string.isRequired,
  classes: arrayOf(shape(BatClassProps)).isRequired,
  professorsClassesPath: string.isRequired,
};

export default Index;
