import { Button, Typography } from '@bbnpm/bb-ui-framework';
import styled from 'styled-components';

export const StyledButton = styled(Button)`
  border: none;
`;

export const FlexBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const BoldSectionTitle = styled(Typography.SectionTitle)`
  font-weight: bold;
  margin-top: 20px;
`;
