import { Button, FormField, Input, Modal } from '@bbnpm/bb-ui-framework';
import { useState } from 'react';

import { EditModalProps } from './types';

const EditModal = ({ isOpen, toggleIsOpen, teamName, handleEdit }) => {
  const [name, setName] = useState(teamName);
  const [errors, setErrors] = useState({});
  const submitForm = async () => {
    setErrors({});
    const data = new FormData();
    data.append('teamName', name);

    try {
      await handleEdit(data);
      toggleIsOpen();
    } catch (e) {
      setErrors({
        teamName: 'Try a different name',
      });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      title="Edit Team Registration"
      portalContainerId="bfe-root"
      onClose={toggleIsOpen}
      primaryActions={
        <>
          <Button kind="primary" onClick={submitForm}>
            Confirm
          </Button>
          <Button kind="secondary" onClick={toggleIsOpen}>
            Cancel
          </Button>
        </>
      }>
      <FormField
        label="Team Name"
        validationType={errors?.teamName && 'error'}
        validationContent={errors?.teamName && `${errors?.teamName}`}>
        <Input
          defaultValue={name}
          onChange={e => setName(e.target.value)}
          required
        />
      </FormField>
    </Modal>
  );
};

EditModal.propTypes = EditModalProps;

export default EditModal;
