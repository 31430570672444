import { useCallback } from 'react';
import { actions } from 'react-table';

actions.setScopes = 'setScopes';

const reducer = (state, action) => {
  if (action.type === actions.setScopes) {
    return { ...state, scopes: action.payload };
  }

  return state;
};

const useInstance = instance => {
  const { dispatch } = instance;
  instance.setScopes = useCallback(
    scopes => {
      dispatch({ type: actions.setScopes, payload: scopes });
    },
    [dispatch],
  );
};

const useTableScopePlugin = hooks => {
  hooks.stateReducers.push(reducer);
  hooks.useInstance.push(useInstance);
};

useTableScopePlugin.pluginName = 'useTableScopePlugin';

export default useTableScopePlugin;
