import { Card } from '@bbnpm/bb-ui-framework';
import { shape, string } from 'prop-types';

import DaperImg from '../DaperImg';
import { VideoProps } from '../types';
import { LargeIcon, StyledCard } from './styles';

const VideoCard = ({ type, content, date }) => {
  const displayCard = (type, content) => {
    switch (type) {
      case 'Webinars':
      case 'Videos':
        if (content.thumbnail) {
          return (
            <DaperImg height={100} width={165} metadata={content.thumbnail} />
          );
        } else {
          return <LargeIcon name="exclamation-circle-alt" />;
        }
      default:
        if (content.course.thumbnail) {
          return (
            <DaperImg
              height={100}
              width={165}
              metadata={content.course.thumbnail}
            />
          );
        } else {
          return (
            <DaperImg
              height={100}
              width={165}
              metadata={content.course.courseCategory.thumbnail}
            />
          );
        }
    }
  };

  return (
    <StyledCard onClick={() => window.location.assign(content.href)}>
      <Card.Content>{displayCard(type, content)}</Card.Content>
      <Card.Header>
        <Card.Title>{content.name}</Card.Title>
        <Card.SubTitle>{type}</Card.SubTitle>
        <Card.SubTitle>{date}</Card.SubTitle>
      </Card.Header>
    </StyledCard>
  );
};

VideoCard.propTypes = {
  content: shape(VideoProps),
  type: string,
  date: string,
  className: string,
};
export default VideoCard;
