import {
  Button,
  GlobalStyles,
  ThemeProvider,
  Typography,
} from '@bbnpm/bb-ui-framework';

import CheckOrMinusCell from '../../CheckOrMinusCell';
import Container from '../../Container';
import DaperImgCell from '../../DaperImgCell';
import PrimaryAdminKey from '../../PrimaryAdminKey';
import Table from '../../Table';
import { IconCellProps, ShowProps } from './types';

const IconCell = ({ cell }) => <i className={`fas fa-${cell.value}`} />;

IconCell.propTypes = IconCellProps;

const columns = [
  { Header: 'ID', accessor: 'id', Cell: PrimaryAdminKey },
  { Header: 'Name', accessor: 'internalName' },
  { Header: 'Icon', accessor: 'icon', Cell: IconCell },
  { Header: 'Created', accessor: 'formattedCreatedAt' },
  { Header: '# Users', accessor: 'courseRegistrationsCount' },
  { Header: '# Modules', accessor: 'courseModuleCourseVersionsCount' },
  {
    Header: 'Active',
    accessor: 'active',
    Cell: CheckOrMinusCell,
    disableSortBy: true,
  },
  {
    Header: 'Req Cert',
    accessor: 'requiredForCertification',
    Cell: CheckOrMinusCell,
    disableSortBy: true,
  },
  {
    Header: 'Upgradable',
    accessor: 'upgradable',
    Cell: CheckOrMinusCell,
    disableSortBy: true,
  },
];

const Show = ({
  name,
  createdAt,
  description,
  overviewItems,
  courseVersions,
  thumbnail,
  newAdminCourseCourseVersionPath,
}) => {
  return (
    <ThemeProvider>
      <GlobalStyles />
      <Container>
        <Typography.LargeTitle>Course: {name}</Typography.LargeTitle>
        <Typography.Text>Created at {createdAt}</Typography.Text>
        <Typography.Text>Description: {description}</Typography.Text>
        {overviewItems && (
          <Typography.Text>Overview: {overviewItems.join(',')}</Typography.Text>
        )}
        <Typography.Text>
          Thumbnail:
          <DaperImgCell value={thumbnail} />
        </Typography.Text>

        <Typography.SectionTitle>Versions</Typography.SectionTitle>
        <a href={newAdminCourseCourseVersionPath}>
          <Button kind="primary">Add Version</Button>
        </a>
        <Table size="sm" columns={columns} initialData={courseVersions} />
      </Container>
    </ThemeProvider>
  );
};

Show.propTypes = ShowProps;

export default Show;
