import { object, string } from 'prop-types';

import ClientRender from './ClientRender';

export const ClientDate = ({ date, dateFormat = { dateStyle: 'long' } }) => (
  <ClientRender>
    {new Date(date).toLocaleDateString('en-US', dateFormat)}
  </ClientRender>
);

export const ClientDateTime = ({
  date,
  dateFormat = { dateStyle: 'long', timeZone: 'America/New_York' },
}) => (
  <ClientRender>
    {new Date(date).toLocaleString('en-US', dateFormat)},{' '}
    {new Date(date).toLocaleTimeString('en-Us', {
      hour: '2-digit',
      minute: '2-digit',
      timeZone: 'America/New_York',
    })}
  </ClientRender>
);

const dateProps = { date: string.isRequired, dateFormat: object };
ClientDate.propTypes = dateProps;
ClientDateTime.propTypes = dateProps;

export default ClientDate;
