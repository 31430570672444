import { useNotification } from '@bbnpm/bb-ui-framework';
import { node, string } from 'prop-types';
import { useState } from 'react';
import { useEffectOnce, useToggle } from 'react-use';

import { cookiesSupported } from '../utils';

const DISMISSAL_TIME = 10000;

const Alert = ({ children, variant }) => {
  const notifAccessor = useNotification();

  switch (variant) {
    case 'success':
      notifAccessor.addSuccess({
        message: children,
        timeout: DISMISSAL_TIME,
      });
      break;

    case 'danger':
      notifAccessor.addError({
        message: children,
        timeout: DISMISSAL_TIME,
      });
      break;

    default:
      break;
  }

  return null;
};

Alert.propTypes = { children: node, variant: string };

const Flash = props => {
  const [isCookiesSupported, toggleIsCookiesSupported] = useToggle(true);

  const [{ alert, notice }, setState] = useState(props);

  useEffectOnce(() => {
    const sessionFlash = window.sessionStorage.getItem('flash');
    if (sessionFlash) {
      window.sessionStorage.removeItem('flash');
      setState(state => ({ ...JSON.parse(sessionFlash), ...state }));
    }

    toggleIsCookiesSupported(cookiesSupported());
  });

  return (
    <>
      {notice && <Alert variant="success">{notice}</Alert>}
      {alert && <Alert variant="danger">{alert}</Alert>}
      {!isCookiesSupported && (
        <Alert variant="danger">
          <h2>
            This site requires browser cookies to be enabled. Refresh this page
            after cookies are enabled to begin
          </h2>
        </Alert>
      )}
    </>
  );
};

Flash.propTypes = {
  notice: string,
  alert: string,
};

export default Flash;
