import { Typography } from '@bbnpm/bb-ui-framework';
import styled from 'styled-components';

export const FormFieldContainer = styled.div`
  margin-top: 1em;
`;

export const StyledLink = styled(Typography.Link)`
  &&& {
    color: ${({ theme }) => theme.colors.blue[600]};
  }
`;

export const ButtonLinkStyle = {
  textDecoration: 'none',
  margin: '0 0.5em 0 2em',
};

export const StyledCategoryName = styled.strong`
  color: ${({ theme }) => theme.colors.blue[600]};
`;
