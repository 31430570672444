import {
  GlobalStyles,
  Icon,
  ThemeProvider,
  Typography,
} from '@bbnpm/bb-ui-framework';
import { array, string } from 'prop-types';

import Container from '../Container';
import { StyledLink, StyledListItem } from '../professor/case_studies/styles';

const Show = ({ title, overview, learningObjective, sections }) => {
  return (
    <ThemeProvider>
      <GlobalStyles />
      <Container>
        <Typography.PageTitle>
          <strong>{title}</strong>
        </Typography.PageTitle>

        <Typography.SectionTitle>
          <strong>Overview</strong>
        </Typography.SectionTitle>
        <Typography.Text>{overview}</Typography.Text>

        <Typography.SectionTitle>
          <strong>Learning Objectives</strong>
        </Typography.SectionTitle>
        <Typography.Text>{learningObjective}</Typography.Text>

        {sections.map(section => (
          <div key={section.id}>
            <Typography.SectionTitle>
              <strong>{section.title}</strong>{' '}
            </Typography.SectionTitle>
            {section.packResources
              .filter(resource => resource.cloudStorageAttachment)
              .map((resource, i) => (
                <StyledListItem key={resource.id}>
                  <Typography.Text>
                    {i + 1 + '. '} {resource.title} &nbsp; | &nbsp;
                    <StyledLink
                      href={
                        resource.cloudStorageAttachment.caseStudyResourcePath
                      }>
                      Download <Icon name="download" />
                    </StyledLink>
                  </Typography.Text>
                  <Typography.Text>{resource.description}</Typography.Text>
                </StyledListItem>
              ))}
          </div>
        ))}
      </Container>
    </ThemeProvider>
  );
};

Show.propTypes = {
  title: string.isRequired,
  overview: string.isRequired,
  learningObjective: string.isRequired,
  sections: array.isRequired,
};

export default Show;
