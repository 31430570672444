import { Typography } from '@bbnpm/bb-ui-framework';

const Materials = () => (
  <>
    <Typography.Text>
      Bloomberg Businessweek Case Studies are the result of a collaboration
      between the Bloomberg for Education and Bloomberg Businessweek teams. Case
      studies in finance are normally reserved for graduate level coursework. We
      designed these case studies to align with the most commonly used undergrad
      Intro to Finance textbooks and the Bloomberg Terminal. As employers and
      students are clamoring for more practical experience, these case studies
      focus on experiential learning. At the end of the course, we want students
      to be able to DO as well as KNOW.
    </Typography.Text>
    <Typography.PageTitle>
      <strong>Case Study Materials</strong>
    </Typography.PageTitle>
    <Typography.Text>
      <strong>Bloomberg Businessweek Pre-Work Article -</strong> This article
      should be assigned to students to read before class to give them a recent
      real-world example of the case study topic. This can be used as a
      discussion point in class and to provide context for the lecture.
    </Typography.Text>
    <Typography.Text>
      <strong>PowerPoint Slides -</strong> This presentation can be appended to
      an existing class lecture to give a more in-depth perspective on how the
      topic plays out practically in the finance world.
    </Typography.Text>
    <Typography.Text>
      <strong> Student Version of the Case Study -</strong>This case study is
      intended as homework after the class lecture. Each case study includes
      questions the students should answer using the Bloomberg Terminal.
    </Typography.Text>
    <Typography.Text>
      <strong>Professor Version of the Case Study -</strong>This version of the
      case study includes teaching notes to inform class discussion.
    </Typography.Text>
    <Typography.Text>
      <strong>Bloomberg Tutorial Video and Script -</strong>Students can use
      this video link to learn how to navigate the Bloomberg Terminal to respond
      to the case study questions. Within the case study is a script of the
      video and a link students can use to access the video on the terminal.
    </Typography.Text>
    <Typography.Text>
      <strong>Additional Materials -</strong>These include any non-standard
      materials, like an Excel file, that are required to execute the lesson.
    </Typography.Text>
  </>
);

export default Materials;
