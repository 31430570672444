import { GlobalStyles, ThemeProvider } from '@bbnpm/bb-ui-framework';

import { FaqRenderer } from '../EtkbRenderer';

const FaqPage = ({ doc }) => {
  return (
    <ThemeProvider>
      <GlobalStyles />
      <FaqRenderer {...doc} />
    </ThemeProvider>
  );
};

export default FaqPage;
