import {
  BloombergAnalyticsProvider,
  Environment,
  createBrowserHistoryPageTracker,
  createEventTracker,
  initialize,
  setDimension,
  setUserId,
} from '@bbnpm/bb-analytics';

const BFE_ANALYTICS_SITE_ID = {
  alpha: 12,
  stg: 82,
  prod: 13,
};

const BFE_ANALYTICS_ENV = {
  alpha: Environment.ALPHA,
  stg: Environment.PROD,
  prod: Environment.PROD,
};

const analytics = () => {
  let bbAnalyticsSiteId = BFE_ANALYTICS_SITE_ID.alpha;
  let bbAnalyticsEnv = BFE_ANALYTICS_ENV.alpha;

  if (window.IS_STAGING) {
    bbAnalyticsSiteId = BFE_ANALYTICS_SITE_ID.stg;
    bbAnalyticsEnv = BFE_ANALYTICS_ENV.stg;
  } else if (window.IS_PROD) {
    bbAnalyticsSiteId = BFE_ANALYTICS_SITE_ID.prod;
    bbAnalyticsEnv = BFE_ANALYTICS_ENV.prod;
  }

  const provider = new BloombergAnalyticsProvider(
    bbAnalyticsSiteId,
    bbAnalyticsEnv,
  );

  const trackPage = createBrowserHistoryPageTracker({
    provider,
    includeQuery: true,
  });
  const trackEvent = createEventTracker();

  const withEventTracker = (callBackToWrap, eventToTrack) => {
    return (...args) => {
      trackEvent(eventToTrack);
      return callBackToWrap(...args);
    };
  };

  window.bfeAnalytics = { trackEvent, trackPage, withEventTracker };

  initialize({
    provider: provider,
    options: {
      debug: !window.IS_PROD,
    },
  });

  const userData = document.querySelector('#currentUserData');
  const { userid, uuid, institutionId, type } =
    (userData && userData.dataset) || {};

  // don't set id and dimensions if user is not logged in
  if (userid) {
    setUserId(provider, userid);

    setDimension(provider, {
      dimension1: userid,
      dimension2: uuid,
      dimension3: institutionId,
      dimension4: type,
    });
  }

  return { trackPage, trackEvent };
};

export default analytics;
