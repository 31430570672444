import {
  Button,
  GlobalStyles,
  ThemeProvider,
  Typography,
} from '@bbnpm/bb-ui-framework';
import { useState } from 'react';

import { BfeGrid } from '../../../styles/grid';
import { ClientDateTime } from '../../ClientDate';
import { StatusIcon } from '../../CommonComponents';
import PrimaryAdminKey from '../../PrimaryAdminKey';
import Table from '../../Table';
import { useFetchData } from '../../utils';
import { FormattedDateProps, IndexProps, TableActionsProps } from './types';

const FormattedDate = ({ cell }) => <ClientDateTime date={cell.value} />;
FormattedDate.propTypes = FormattedDateProps;

const TableActions = ({ row }) => {
  return (
    <span className="text-nowrap">
      <Button
        as="a"
        href={row.original.editAdminTradingChallengePath}
        title="Edit"
        icon="pencil"
        kind="secondary"
      />
    </span>
  );
};

TableActions.propTypes = TableActionsProps;

const columns = [
  { Header: 'ID', accessor: 'id', filteredAs: 'number', Cell: PrimaryAdminKey },
  { Header: 'Name', accessor: 'name', filteredAs: 'string' },
  { Header: 'Start Date', accessor: 'challengeStart', Cell: FormattedDate },
  { Header: 'End Date', accessor: 'challengeEnd', Cell: FormattedDate },
  {
    Header: 'Active',
    accessor: 'active',
    disableSortBy: true,
    Cell: StatusIcon,
  },
  {
    accessor: 'n/a',
    id: 'actions',
    disableSortBy: true,
    Cell: TableActions,
  },
];

const scopes = [
  { id: 'isActive', title: 'Active' },
  { id: 'isInactive', title: 'Inactive' },
];

const cb = ({ totalCount, pageSize, tradingChallenges }) => ({
  data: tradingChallenges,
  pageSize,
  totalCount,
});

const Index = ({
  tradingChallenges,
  adminTradingChallengesPath,
  newAdminTradingChallengePath,
  totalCount,
  pageSize,
  pageIndex,
  sortBy,
  adminTradingChallengeRegionsPath,
}) => {
  const fetchData = useFetchData(adminTradingChallengesPath, cb);
  const [{ refreshData }, setRefreshDataFn] = useState({
    refreshData: () => {},
  });

  return (
    <ThemeProvider>
      <GlobalStyles />
      <BfeGrid>
        <div className="d-flex align-items-center mb-3">
          <Typography.PageTitle>Trading Challenges</Typography.PageTitle>
          <Button
            as="a"
            href={newAdminTradingChallengePath}
            className="ml-4"
            kind="primary">
            Add Trading Challenge
          </Button>
          <Button
            kind="secondary"
            as="a"
            href={adminTradingChallengeRegionsPath}>
            Trading Challenge Regions
          </Button>
        </div>

        <Table
          initialData={tradingChallenges}
          columns={columns}
          initialPageSize={pageSize}
          initialPageIndex={pageIndex}
          initialSortBy={sortBy}
          initialTotalCount={totalCount}
          initialScopes={{ isActive: false, isInactive: false }}
          availableScopes={scopes}
          fetchData={fetchData}
          onRefreshDataFn={setRefreshDataFn}
          refreshData={refreshData}
        />
      </BfeGrid>
    </ThemeProvider>
  );
};

Index.propTypes = IndexProps;

export default Index;
