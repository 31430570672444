import Button from '../../Button';
import Container from '../../Container';
import Input from '../../Input';
import PatchForm from '../../PatchForm';
import PostForm from '../../PostForm';
import { FirmFormProps } from './types';

const FirmForm = ({
  id,
  href,
  name,
  bbgFirmId,
  errors = {},
  adminFirmsPath,
}) => {
  const [Form, action, submitText] = id
    ? [PatchForm, href, 'Update']
    : [PostForm, adminFirmsPath, 'Submit'];

  return (
    <Container>
      <div className="d-flex align-items-center">
        <h1>{id ? 'Edit' : 'Add'} Firm</h1>
        {href && (
          <Button className="ml-4" variant="light" href={href} size="sm">
            Cancel
          </Button>
        )}
      </div>
      <Form action={action} submitText={submitText}>
        <Input required name="name" defaultValue={name} errors={errors.name} />
        <Input
          errors={errors.bbgFirmId}
          required
          name="bbgFirmId"
          type="number"
          defaultValue={bbgFirmId}
        />
      </Form>
    </Container>
  );
};

FirmForm.propTypes = FirmFormProps;

export default FirmForm;
