import { Fragment, useMemo } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';

import { dateSorter } from '../../utils';
import CardCarousel from '../CardCarousel';
import DaperImg, { daperUrl } from '../DaperImg';
import TabGrid from '../TabGrid';
import WebinarCard from './WebinarCard';
import { IndexProps } from './types';

const Index = ({ webinars }) => {
  const { latestWebinars, featuredWebinar, activeSeries } = useMemo(() => {
    const { activeWebinars, featuredWebinar, activeSeries } = webinars.reduce(
      (acc, webinar) => {
        if (webinar.active) {
          acc.activeWebinars.push(webinar);
          if (webinar.webinarSeries?.active) {
            if (!acc.activeSeries[webinar.webinarSeries.id]) {
              acc.activeSeries[webinar.webinarSeries.id] = {
                ...webinar.webinarSeries,
                webinars: [webinar],
              };
            } else {
              acc.activeSeries[webinar.webinarSeries.id].webinars.push(webinar);
            }
          }
          if (!acc.featuredWebinar && webinar.featured)
            acc.featuredWebinar = webinar;
        }
        return acc;
      },
      { activeWebinars: [], activeSeries: {} },
    );
    const sortedWebinars = [...activeWebinars].sort(
      dateSorter({ prop: 'createdAt' }),
    );
    return {
      activeWebinars,
      activeSeries: Object.values(activeSeries),
      featuredWebinar,
      latestWebinars: sortedWebinars.slice(0, 3),
    };
  }, [webinars]);

  return (
    <>
      <div className="py-5 text-white bg-dark position-relative overflow-hidden">
        {featuredWebinar && (
          <div
            style={{
              position: 'absolute',
              top: -5,
              right: -5,
              bottom: -5,
              left: -5,
              opacity: 0.3,
              backgroundImage: featuredWebinar.thumbnail?.daperKey
                ? `url(${daperUrl({
                    daperKey: featuredWebinar.thumbnail.daperKey,
                    width: 2220,
                    height: -1,
                  })})`
                : '',
              filter: 'blur(5px)',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          />
        )}
        <Container fluid="xl" className="position-relative">
          <div className="d-flex align-items-center">
            <h1>Webinars</h1>
            <Button
              variant="primary"
              className="ml-auto"
              href="https://www.bloomberg.com/professional/blog/webinars/education/"
              target="_blank"
              rel="noreferrer">
              Upcoming Webinars
            </Button>
          </div>
          <p className="font-size-lg">
            Experience how market professionals use the Terminal to find
            information and to analyze current events.
          </p>
          {featuredWebinar && (
            <div
              className="p-4"
              style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }}>
              <Row>
                <Col>
                  <DaperImg
                    fluid
                    metadata={featuredWebinar.thumbnail}
                    width={1064}
                  />
                </Col>
                <Col>
                  <p className="text-muted">Featured</p>
                  <h4>{featuredWebinar.name}</h4>
                  <p className="text-pre-wrap">{featuredWebinar.description}</p>
                  <Button
                    href={featuredWebinar.href}
                    variant="primary"
                    className="mt-5 px-5">
                    Watch
                  </Button>
                </Col>
              </Row>
            </div>
          )}
        </Container>
      </div>
      <Container fluid="xl" className="py-3">
        <h2 className="font-weight-normal with-angle-right">
          Available Series
        </h2>
        <TabGrid className="mb-3">
          {activeSeries.map(({ name, id, href }) => (
            <TabGrid.Tab key={id} href={href}>
              {name}
            </TabGrid.Tab>
          ))}
        </TabGrid>

        <h2 className="font-weight-normal with-angle-right">Latest Releases</h2>
        <Row>
          {latestWebinars.map(webinar => (
            <Col lg={4} sm={6} key={webinar.id} className="mb-3">
              <WebinarCard webinar={webinar} />
            </Col>
          ))}
        </Row>

        {activeSeries.map(({ id, name, webinars }) => (
          <Fragment key={id}>
            <h2 className="font-weight-normal with-angle-right">{name}</h2>
            <CardCarousel spans={{ lg: 4, sm: 6 }} className="mb-3">
              {webinars.map(webinar => (
                <WebinarCard key={webinar.id} webinar={webinar} />
              ))}
            </CardCarousel>
          </Fragment>
        ))}
      </Container>
    </>
  );
};

Index.propTypes = IndexProps;

export default Index;
