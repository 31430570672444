import {
  Table as BBUITable,
  GlobalStyles,
  ThemeProvider,
} from '@bbnpm/bb-ui-framework';
import { createSlice } from '@reduxjs/toolkit';
import cn from 'classnames';
import dequal from 'dequal';
import { memo, useCallback, useEffect, useMemo, useReducer } from 'react';
import { usePagination, useSortBy, useTable } from 'react-table';
import { useFirstMountState, usePrevious } from 'react-use';

import { StyledTableItem } from '../home_page/styles';
import TableControls from './TableControls';
import TableDropdown from './TableDropdown';
import { TableProps } from './types';
import useFilterPlugin from './useFilterPlugin';
import useTableScopePlugin from './useTableScopePlugin';

const initialState = { isLoading: false };

const { reducer, actions } = createSlice({
  name: 'data',
  initialState,
  reducers: {
    dataFetched(state) {
      state.isLoading = true;
    },
    dataReceived(state, { payload }) {
      const { data = [], pageSize, totalCount } = payload;
      Object.assign(state, {
        data,
        totalCount,
        isLoading: false,
        initialPageCount: Math.ceil(totalCount / pageSize),
      });
    },
  },
});

const validFiltersFor = filters =>
  filters.reduce(
    (acc, { id, predicate, value }) =>
      id && predicate && value
        ? {
            ...acc,
            [`${id}_${predicate}`]: value,
          }
        : acc,
    {},
  );

const toParams = ({ scopes, filters, pageIndex, pageSize, sortBy }) => {
  const params = {
    ...scopes,
    ...validFiltersFor(filters),
    pageIndex: pageIndex + 1,
    pageSize,
  };
  if (sortBy.length) {
    params.sortBy = `${sortBy[0].id} ${sortBy[0].desc ? 'DESC' : 'ASC'}`;
  }
  return params;
};

const shouldResetPageIndex = (state, prevState) => {
  return !dequal(
    { ...toParams(state), pageIndex: null },
    { ...toParams(prevState), pageIndex: null },
  );
};

const Table = memo(
  ({
    columns,
    initialData,
    initialPageSize = 10,
    initialPageIndex = 1,
    initialSortBy = '',
    initialTotalCount = initialData ? initialData.length : 0,
    fetchData,
    initialScopes = {},
    availableScopes = [],
    additionalFilters = [],
    onRowClick,
    size,
    onRefreshDataFn,
    getRowProps,
    variant = '',
    ...cellProps
  }) => {
    const [{ data, initialPageCount, totalCount, isLoading }, dispatch] =
      useReducer(
        reducer,
        reducer(
          initialState,
          actions.dataReceived({
            data: initialData,
            pageSize: initialPageSize,
            totalCount: initialTotalCount,
          }),
        ),
      );

    const [id, dir] = initialSortBy.split(' ');
    const sortByObj = { id, desc: (dir || '').toUpperCase() === 'DESC' };

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      page,
      pageCount,
      canPreviousPage,
      canNextPage,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      state: tableState,
      setFilters,
      setScopes,
    } = useTable(
      {
        initialState: {
          pageSize: initialPageSize,
          pageIndex: initialPageIndex - 1,
          sortBy: sortByObj.id ? [sortByObj] : [],
          filters: [],
          scopes: initialScopes,
        },

        stateReducer: (state, action, prevState) => {
          if (shouldResetPageIndex(state, prevState)) {
            return { ...state, pageIndex: 0 };
          } else {
            return state;
          }
        },

        columns,
        data,

        // Sorting options
        manualSortBy: !!fetchData,
        autoResetSortBy: false,
        disableSortRemove: true,
        disableMultiSort: true,

        // Pagination options
        manualPagination: !!fetchData,
        autoResetPage: false,
        pageCount: initialPageCount,
      },
      useSortBy,
      usePagination,
      useFilterPlugin,
      useTableScopePlugin,
    );

    const { pageSize, pageIndex, sortBy, filters, scopes } = tableState;

    const params = useMemo(
      () => toParams({ scopes, filters, pageIndex, pageSize, sortBy }),
      [scopes, filters, pageIndex, pageSize, sortBy],
    );
    const prevParams = usePrevious(params);

    const refreshData = useCallback(async () => {
      dispatch(actions.dataFetched());
      const data = await fetchData(params);
      dispatch(actions.dataReceived(data));
    }, [dispatch, fetchData, params]);

    useEffect(() => {
      if (onRefreshDataFn) {
        onRefreshDataFn({ refreshData });
      }
    }, [onRefreshDataFn, refreshData]);

    const isFirstRun = useFirstMountState();
    const hasInitialData = Array.isArray(initialData);
    const needsInitialData = isFirstRun && !hasInitialData;

    useEffect(() => {
      const paramsChanged = prevParams && !dequal(params, prevParams);
      if (fetchData && (paramsChanged || needsInitialData)) {
        refreshData();
      }
    }, [params, prevParams, needsInitialData, fetchData, refreshData]);

    // This is for when the component is given a new `fetchData` fn
    // This should cause `refreshData` to be called
    const prevFetchData = usePrevious(fetchData);
    useEffect(() => {
      if (!isFirstRun && fetchData && fetchData !== prevFetchData) {
        refreshData();
      }
    }, [prevFetchData, fetchData, refreshData, isFirstRun]);

    return (
      <ThemeProvider>
        <GlobalStyles />
        <div className="d-flex flex-column align-items-stretch">
          <TableControls
            {...{
              columns,
              pageCount,
              totalCount,
              page,
              canPreviousPage,
              canNextPage,
              gotoPage,
              nextPage,
              previousPage,
              setPageSize,
              pageSize,
              pageIndex,
              isLoading,
              filters,
              onFiltersSet: setFilters,
              availableScopes,
              scopes,
              onScopesSet: setScopes,
              additionalFilters,
              variant,
            }}
          />

          <div className="w-100 overflow-auto">
            <BBUITable
              className="w-100 overflow-hidden"
              size={size}
              hover={!variant}
              borders={variant === 'revamp' ? 'none' : ''}
              {...getTableProps()}>
              {(variant !== 'revamp' || totalCount > 0) && (
                <thead
                  className={variant && totalCount !== 0 ? 'border-bottom' : ''}
                  style={
                    variant === 'tradingChallenge'
                      ? { backgroundColor: 'black', color: 'white' }
                      : {}
                  }>
                  {headerGroups.map(headerGroup => (
                    // eslint-disable-next-line react/jsx-key
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map(column => (
                        // eslint-disable-next-line react/jsx-key
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps(),
                          )}>
                          {column.render('Header')}
                          {column.canSort ? (
                            column.isSorted ? (
                              column.isSortedDesc ? (
                                <i className="fas fa-fw fa-sm fa-sort-down" />
                              ) : (
                                <i className="fas fa-fw fa-sm fa-sort-up" />
                              )
                            ) : (
                              <i className="fas fa-fw fa-sm text-muted fa-sort" />
                            )
                          ) : null}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
              )}

              <tbody
                className={cn({ 'pointer-on-hover': onRowClick })}
                {...getTableBodyProps()}>
                {page.map(row => {
                  prepareRow(row);
                  return (
                    // eslint-disable-next-line react/jsx-key
                    <tr
                      onClick={() => onRowClick?.(row.original)}
                      {...row.getRowProps(getRowProps?.(row.original) ?? {})}>
                      {row.cells.map(cell => {
                        switch (variant) {
                          case 'revamp':
                            return (
                              // eslint-disable-next-line react/jsx-key
                              <StyledTableItem
                                {...cell.getCellProps(
                                  cell.column.cellProps || {},
                                )}>
                                {cell.render('Cell', cellProps)}
                              </StyledTableItem>
                            );
                          default:
                            return (
                              // eslint-disable-next-line react/jsx-key
                              <td
                                {...cell.getCellProps(
                                  cell.column.cellProps || {},
                                )}>
                                {cell.render('Cell', cellProps)}
                              </td>
                            );
                        }
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </BBUITable>
          </div>
        </div>
      </ThemeProvider>
    );
  },
);

Table.propTypes = TableProps;

Table.displayName = 'Table';

Table.Dropdown = TableDropdown;

export default Table;
