import {
  GlobalStyles,
  ThemeProvider,
  Typography,
} from '@bbnpm/bb-ui-framework';

import Container from '../Container';

const Error = () => (
  <ThemeProvider>
    <GlobalStyles />
    <Container>
      <Typography.PageTitle>
        Oops! You don&apos;t have permissions to do that
      </Typography.PageTitle>
    </Container>
  </ThemeProvider>
);

export default Error;
