import cn from 'classnames';
import { Card } from 'react-bootstrap';

import ClientDate from '../ClientDate';
import CoverWithDuration from '../CoverWithDuration';
import UserStatus from '../UserStatus';
import { VideoCardProps } from './types';

const VideoCard = ({ isCurrent = false, video, className }) => (
  <Card
    as="a"
    href={video.href}
    className={cn(className, 'link-unstyled h-100', {
      'card--current': isCurrent,
    })}>
    <CoverWithDuration
      daperKey={video.thumbnail?.daperKey}
      width={1000}
      duration={video.durationMins}
    />
    <Card.Body>
      <Card.Title>{video.name}</Card.Title>
      <Card.Text className="text-muted">
        <ClientDate
          date={video.createdAt}
          dateFormat={{ month: 'long', year: 'numeric' }}
        />
      </Card.Text>
    </Card.Body>
    <Card.Footer className="border-0 bg-white font-weight-bold">
      <UserStatus {...(video.userInfo ?? {})} />
    </Card.Footer>
  </Card>
);

VideoCard.propTypes = VideoCardProps;

export default VideoCard;
