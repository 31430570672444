import accepts from 'attr-accept';
import cn from 'classnames';
import { func, node, string } from 'prop-types';
import { useRef, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useDropArea } from 'react-use';

const Dropzone = ({ onFile, accept, children }) => {
  const [rejectedFor, setRejectedFor] = useState(false);
  const fileInputEl = useRef(null);
  const handleFiles = files => {
    const file = files[0];
    if (file) {
      if (accepts(file, accept)) {
        setRejectedFor(false);
        onFile(file);
      } else {
        setRejectedFor(`File not accepted. (Currently accepting: ${accept})`);
      }
    }
  };
  const handleClick = e => {
    e.stopPropagation();
    fileInputEl.current.click();
  };

  const [bond, { over }] = useDropArea({
    onFiles: handleFiles,
    onUri: uri => console.log('uri', uri),
    onText: text => console.log('text', text),
  });

  return (
    <div
      className={cn('dropzone h-100 pointer-on-hover', {
        dropzone__active: over,
        dropzone__reject: rejectedFor && !over,
      })}
      onClick={handleClick}
      {...bond}>
      <div className={cn({ 'opacity-50': over, 'pointer-events-none': over })}>
        <input
          type="file"
          name="file"
          accept={accept}
          className="d-none"
          ref={fileInputEl}
          onChange={e => handleFiles(e.target.files)}
        />
        {rejectedFor && <Alert variant="danger">{rejectedFor}</Alert>}
        {children}
      </div>
    </div>
  );
};

Dropzone.propTypes = {
  onFile: func.isRequired,
  accept: string,
  children: node,
};

export default Dropzone;
