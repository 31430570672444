import { FormField, Input, Typography } from '@bbnpm/bb-ui-framework';

import { RequiredLabel } from '../../CommonComponents';
import { FacultyInfoSectionPropTypes } from '../types';

export const FacultyInfo = ({
  disabled,
  user,
  facultyInfo,
  setFacultyInfo,
  formErrors,
}) => {
  return (
    <div id="faculty-info-section">
      <Typography.SectionTitle style={{ margin: '1rem 0' }}>
        Faculty Advisor relevant to the challenge
      </Typography.SectionTitle>
      <FormField
        label={<RequiredLabel>Faculty Advisor First Name</RequiredLabel>}
        validationType={formErrors.teamMembers?.['1']?.firstName && 'error'}
        validationContent={
          formErrors.teamMembers?.['1']?.firstName &&
          'First Name' + formErrors.teamMembers?.['1']?.firstName
        }>
        <Input
          type="text"
          value={user ? user.firstName : facultyInfo.firstName}
          disabled={user || disabled}
          onChange={evt =>
            setFacultyInfo({ ...facultyInfo, firstName: evt.target.value })
          }
        />
      </FormField>
      <FormField
        label={<RequiredLabel>Faculty Advisor Last Name</RequiredLabel>}
        validationType={formErrors.teamMembers?.['1']?.lastName && 'error'}
        validationContent={
          formErrors.teamMembers?.['1']?.lastName &&
          'Last Name' + formErrors.teamMembers?.['1']?.lastName
        }>
        <Input
          type="text"
          disabled={user || disabled}
          value={user ? user.lastName : facultyInfo.lastName}
          onChange={evt =>
            setFacultyInfo({ ...facultyInfo, lastName: evt.target.value })
          }
        />
      </FormField>
      <FormField
        label={<RequiredLabel>Faculty Advisor Email</RequiredLabel>}
        validationType={formErrors.teamMembers?.['1']?.email && 'error'}
        validationContent={
          formErrors.teamMembers?.['1']?.email &&
          'Email' + formErrors.teamMembers?.['1']?.email
        }>
        <Input
          type="text"
          disabled={user || disabled}
          value={user ? user.email || user.uuid : facultyInfo.email}
          onChange={evt =>
            setFacultyInfo({ ...facultyInfo, email: evt.target.value })
          }
        />
      </FormField>

      <FormField label="Faculty Advisor Phone">
        <Input
          disabled={disabled}
          type="text"
          value={facultyInfo.phone}
          onChange={evt =>
            setFacultyInfo({ ...facultyInfo, phone: evt.target.value })
          }
        />
      </FormField>
      <FormField label="Faculty Advisor Department">
        <Input
          disabled={disabled}
          type="text"
          value={facultyInfo.department}
          onChange={evt =>
            setFacultyInfo({ ...facultyInfo, department: evt.target.value })
          }
        />
      </FormField>
      <FormField label="Faculty Advisor Title">
        <Input
          disabled={disabled}
          type="text"
          value={facultyInfo.title}
          onChange={evt =>
            setFacultyInfo({ ...facultyInfo, title: evt.target.value })
          }
        />
      </FormField>
    </div>
  );
};

FacultyInfo.propTypes = FacultyInfoSectionPropTypes;
