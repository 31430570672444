import cn from 'classnames';
import debounce from 'lodash.debounce';
import { array, arrayOf, bool, func, object, string } from 'prop-types';
import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import Autosuggest from 'react-autosuggest';
import { useFirstMountState } from 'react-use';

import Input from './Input';

const defaultEntityKeyFn = entity => entity.id;
const defaultEntityValueFn = entity => entity.name;
const fetchSuggestionsSync = ({ entities, entityValueFn, value }) =>
  entities.filter(entity =>
    entityValueFn(entity).toLowerCase().includes(value.toLowerCase()),
  );

const shouldRenderSuggestionsFn = () => true;

const renderSectionTitle = ({ title }) => title;

// https://developer.mozilla.org/en/docs/Web/JavaScript/Guide/Regular_Expressions#Using_Special_Characters
const escapeRegexCharacters = str => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

const INITIAL_SUGGESTIONS = [];

const AutosuggestInput = forwardRef(
  (
    {
      name,
      size,
      entity,
      entityKeyFn = defaultEntityKeyFn,
      entityValueFn = defaultEntityValueFn,
      onSuggestionsFetchRequested: fetchSuggestions,
      isFetchAsync = true,
      shouldRenderSuggestions,
      initialSuggestions = INITIAL_SUGGESTIONS,
      errors = [],
      onChange,
      onEntityKeyChange,
      multiSection = false,
      getSectionSuggestions,
      ...inputProps
    },
    ref,
  ) => {
    const [entityValue, setEntityValue] = useState(
      entity ? entityValueFn(entity) : '',
    );

    const [entityKey, setEntityKey] = useState(
      entity ? entityKeyFn(entity) : '',
    );

    useEffect(() => {
      setEntityValue(entity ? entityValueFn(entity) : '');
      setEntityKey(entity ? entityKeyFn(entity) : '');
    }, [entity, entityValueFn, entityKeyFn]);

    const [suggestions, setSuggestions] = useState(initialSuggestions);

    const setSuggestionsFromValue = useMemo(
      () =>
        isFetchAsync
          ? debounce(
              async value =>
                setSuggestions(
                  value ? await fetchSuggestions(value) : initialSuggestions,
                ),
              200,
            )
          : value =>
              setSuggestions(
                value
                  ? (fetchSuggestions ?? fetchSuggestionsSync)({
                      entityValueFn,
                      entities: initialSuggestions,
                      value,
                    })
                  : initialSuggestions,
              ),

      [isFetchAsync, entityValueFn, fetchSuggestions, initialSuggestions],
    );

    const onSuggestionsFetchRequested = ({ value }) =>
      setSuggestionsFromValue(value);

    const isFirstMount = useFirstMountState();

    useEffect(() => {
      if (!isFirstMount && onEntityKeyChange) onEntityKeyChange(entityKey);
    }, [entityKey, onEntityKeyChange, isFirstMount]);

    const handleChange = suggestion => {
      setEntityKey(suggestion ? entityKeyFn(suggestion) : '');
      onChange && onChange(suggestion);
    };

    const renderSuggestion = useCallback(
      (suggestion, { query }) => (
        <span
          dangerouslySetInnerHTML={{
            __html: entityValueFn(suggestion).replace(
              new RegExp(escapeRegexCharacters(query), 'gi'),
              match => `<mark>${match}</mark>`,
            ),
          }}
        />
      ),
      [entityValueFn],
    );

    const isInvalid = !!errors.length;

    const theme = {
      container: 'react-autosuggest__container',
      suggestionsContainer: 'dropdown-menu',
      suggestionsContainerOpen: 'show',
      suggestionsList: 'list-unstyled m-0',
      suggestion: 'dropdown-item',
      suggestionHighlighted: 'active',
      input: cn('form-control', {
        'is-invalid': isInvalid,
        [`form-control-${size}`]: size,
      }),
      sectionTitle: 'dropdown-header',
    };

    const autosuggest = (
      <>
        <Autosuggest
          multiSection={multiSection}
          renderSectionTitle={renderSectionTitle}
          getSectionSuggestions={getSectionSuggestions}
          suggestions={suggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={() => setSuggestions(initialSuggestions)}
          getSuggestionValue={entityValueFn}
          renderSuggestion={renderSuggestion}
          shouldRenderSuggestions={
            shouldRenderSuggestions && shouldRenderSuggestionsFn
          }
          inputProps={{
            disabled: inputProps?.disabled ?? false,
            required: inputProps?.required ?? false,
            placeholder: inputProps?.placeholder ?? '',
            value: entityValue,
            onChange: (event, { newValue }) => setEntityValue(newValue),
            onBlur: (event, { highlightedSuggestion }) => {
              // NOTE: a non-existent entityValue will silently fail

              // In the case of TABbing a suggestion
              if (highlightedSuggestion)
                return handleChange(highlightedSuggestion);

              if (!entityValue) {
                // In the case of clearing the value
                handleChange(null);
              } else if (
                suggestions.length === 1 &&
                entityValueFn(suggestions[0]) === entityValue
              ) {
                // In the case where the entityValue matches exactly one suggestion
                handleChange(suggestions[0]);
              }
            },
          }}
          theme={theme}
          onSuggestionSelected={(event, { suggestion, method }) => {
            // By default, pressing `enter` will submit the form.
            if (method === 'enter') event.preventDefault();
            handleChange(suggestion);
          }}
        />
        <input
          ref={ref}
          type="hidden"
          name={name}
          disabled={inputProps?.disabled ?? false}
          defaultValue={entityKey}
        />
      </>
    );

    return (
      <Input
        name={name}
        {...inputProps}
        inputRender={() => <>{autosuggest}</>}
      />
    );
  },
);

AutosuggestInput.displayName = 'AutosuggestInput';

AutosuggestInput.propTypes = {
  name: string.isRequired,
  size: string,
  entity: object,
  entityKeyFn: func,
  entityValueFn: func,
  onSuggestionsFetchRequested: func,
  onEntityKeyChange: func,
  errors: arrayOf(string),
  isFetchAsync: bool,
  shouldRenderSuggestions: bool,
  initialSuggestions: array,
  onChange: func,
  multiSection: bool,
  getSectionSuggestions: func,
};

export default AutosuggestInput;
