import { GlobalStyles, Icon, ThemeProvider } from '@bbnpm/bb-ui-framework';
import { arrayOf, elementType, oneOfType, string } from 'prop-types';
import styled from 'styled-components';

import { StatusIconProps } from './types';

export const DEFAULT_PAGE_WIDTH = 1440;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-top: 1rem;
`;

export const RequiredLabel = styled.span`
  &:after {
    content: '*';
    color: red;
  }
`;

export const PageContainer = styled.div`
  margin: 0 auto;
  width: ${({ width }) => width || DEFAULT_PAGE_WIDTH + 'px'};
`;

export const FlexRow = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  div {
    flex-basis: ${({ basis }) => basis || '49%'};
  }
`;

export const InactiveIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.red[500]};
`;

export const ActiveIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.green[600]};
`;

export const PageWrapper = ({ width, children }) => {
  return (
    <ThemeProvider>
      <GlobalStyles />
      <PageContainer width={width}>{children}</PageContainer>
    </ThemeProvider>
  );
};
PageWrapper.propTypes = {
  children: oneOfType([elementType, arrayOf(elementType)]),
  width: string,
};

export const StatusIcon = ({ value }) =>
  value ? <ActiveIcon name="radio-on" /> : <InactiveIcon name="restricted" />;
StatusIcon.propTypes = StatusIconProps;

export const HorizontalLineBreak = styled.hr`
  margin: 1.5rem 0;
  border-top: solid 1px ${({ theme }) => theme.colors.grey[300]};
`;
