import {
  GlobalStyles,
  ThemeProvider,
  Typography,
} from '@bbnpm/bb-ui-framework';

import Container from '../Container';
import Table from '../Table';
import { useFetchData } from '../utils';
import { IndexProps, ReceiptLinkProps } from './types';

const ReceiptLink = ({ row }) => (
  <Typography.Link href={row.original.href}>View</Typography.Link>
);

ReceiptLink.propTypes = ReceiptLinkProps;

const columns = [
  { Header: 'Date', accessor: 'createdAt' },
  {
    Header: 'Description',
    accessor: 'name',
    disableSortBy: true,
  },
  {
    Header: 'Total',
    accessor: 'total',
    disableSortBy: true,
  },
  {
    Header: 'Receipt',
    accessor: 'href',
    disableSortBy: true,
    Cell: ReceiptLink,
  },
];

const cb = ({ totalCount, pageSize, orders }) => ({
  data: orders,
  pageSize,
  totalCount,
});

const Index = ({
  totalCount,
  pageSize,
  pageIndex,
  sortBy,
  ordersPath,
  orders,
}) => {
  const fetchData = useFetchData(ordersPath, cb);

  return (
    <ThemeProvider>
      <GlobalStyles />
      <Container>
        <Typography.PageTitle>Orders</Typography.PageTitle>
        <Table
          initialData={orders}
          initialPageSize={pageSize}
          initialPageIndex={pageIndex}
          initialSortBy={sortBy}
          initialTotalCount={totalCount}
          columns={columns}
          fetchData={fetchData}
        />
      </Container>
    </ThemeProvider>
  );
};

Index.propTypes = IndexProps;

export default Index;
