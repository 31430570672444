import { Icon } from '@bbnpm/bb-ui-framework';
import { bool } from 'prop-types';
import styled from 'styled-components';

export const Expander = ({ expanded, ...rest }) => {
  return (
    <ExpandButton {...rest}>
      <Icon name={expanded ? 'chevron-down' : 'chevron-right'} />
    </ExpandButton>
  );
};

Expander.propTypes = {
  expanded: bool,
};

export const ExpandButton = styled.button`
  background-color: transparent;
  border: 0;
  color: ${({ theme }) => theme.colors.text};
  padding: 0;
`;
