import { Typography } from '@bbnpm/bb-ui-framework';
import { arrayOf, func, shape } from 'prop-types';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import { WebinarProps } from '../types';
import { useBreakpoint } from '../utils';
import VideoCard from './VideoCard';
import { StyledArrow, StyledButtonGroup } from './styles';

const breakpointCardCount = {
  xs: 1,
  sm: 3,
  md: 3,
  lg: 6,
  xl: 6,
};

const responsive = {
  superLargeDesktop: {
    // xl
    breakpoint: { max: 4000, min: 1200 },
    items: 6,
  },
  desktop: {
    // lg
    breakpoint: { max: 1200, min: 992 },
    items: 6,
  },
  tablet: {
    // sm, md
    breakpoint: { max: 992, min: 576 },
    items: 3,
  },
  mobile: {
    // xs
    breakpoint: { max: 576, min: 0 },
    items: 1,
  },
};

const ButtonGroup = ({ next, previous, ...rest }) => {
  const {
    carouselState: { currentSlide },
  } = rest;
  return (
    <StyledButtonGroup className="carousel-button-group position-absolute">
      <StyledArrow
        className={currentSlide === 0 ? 'disable' : ''}
        name="chevron-left"
        onClick={() => previous()}
      />
      <StyledArrow
        className="ml-2"
        name="chevron-right"
        onClick={() => next()}
      />
    </StyledButtonGroup>
  );
};

ButtonGroup.propTypes = {
  next: func,
  previous: func,
};

const Webinars = ({ webinars }) => {
  const formattedDate = date => {
    return new Date(date).toLocaleDateString('en-US', {
      month: 'long',
      year: 'numeric',
    });
  };

  const breakpoint = useBreakpoint();

  return (
    <div className="position-relative">
      <Typography.PageTitle>Webinars</Typography.PageTitle>
      <Carousel
        responsive={responsive}
        infinite={true}
        arrows={false}
        containerClass="carousel-container"
        customButtonGroup={
          webinars.length > breakpointCardCount[breakpoint] && <ButtonGroup />
        }
        className="mt-3"
        renderButtonGroupOutside={true}>
        {webinars.map((c, idx) => (
          <VideoCard
            content={c}
            date={formattedDate(c.occurredAt)}
            type="Webinars"
            key={idx}
            className="link-unstyled pl-0"
          />
        ))}
      </Carousel>
    </div>
  );
};

Webinars.propTypes = {
  webinars: arrayOf(shape(WebinarProps)),
};
export default Webinars;
