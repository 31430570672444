import { Button, GlobalStyles, ThemeProvider } from '@bbnpm/bb-ui-framework';
import { func, node, shape, string } from 'prop-types';
import { forwardRef } from 'react';
import { useToggle } from 'react-use';

import Csrf from './Csrf';

const PostForm = forwardRef(
  (
    {
      children,
      submitText,
      submitProps = {},
      submitRender,
      onSubmit,
      onCancel,
      ...rest
    },
    ref,
  ) => {
    const [isSubmitting, toggleIsSubmitting] = useToggle(false);
    const handleSubmit = (...args) => {
      toggleIsSubmitting();
      onSubmit && onSubmit(...args);
    };

    const { disabled = false, ...restSubmitProps } = submitProps;

    return (
      <ThemeProvider>
        <GlobalStyles />
        <form {...rest} method="POST" ref={ref} onSubmit={handleSubmit}>
          <Csrf />
          {children}
          {submitRender ? (
            submitRender({ isSubmitting })
          ) : submitText ? (
            <Button
              disabled={isSubmitting || disabled}
              type="submit"
              kind="primary"
              {...restSubmitProps}>
              {submitText}
            </Button>
          ) : null}
          {onCancel && (
            <Button onClick={onCancel} kind="secondary">
              Cancel
            </Button>
          )}
        </form>
      </ThemeProvider>
    );
  },
);

PostForm.propTypes = {
  children: node,
  submitText: node,
  onSubmit: func,
  onCancel: func,
  submitProps: shape({
    className: string,
    variant: string,
  }),
  submitRender: func,
};

PostForm.displayName = 'PostForm';

export default PostForm;
