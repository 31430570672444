import { Button, Typography } from '@bbnpm/bb-ui-framework';
import styled from 'styled-components';

export const StyledLink = styled(Typography.Link)`
  width: 'auto';
`;

export const StyledLinksContainer = styled.div`
  margin-top: 1rem;
`;

export const StyledActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0;
  margin-bottom: 1rem;
`;

export const StyledTitleContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: ${props => props.margin || '0'};
`;

export const StyledButton = styled(Button)`
  width: 100%;
`;

export const StyledBssoButton = styled(Button)`
  width: 100%;
  background-color: black;
  color: white;
  margin-bottom: 1rem;

  :hover {
    background-color: ${({ theme }) => theme.colors.grey[650]};
    color: white;
  }
`;

export const StyledBssoText = styled(Typography.Text)`
  margin-bottom: 0.3rem;
`;

export const StyledCaption = styled(Typography.Caption)`
  margin-bottom: 0;
`;

export const StyledBorderContainer = styled.div`
  border: solid;
  border-width: thin;
  padding: 2rem;
  width: 95%;
  max-width: 460px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
`;
