import cn from 'classnames';
import { node, string } from 'prop-types';
import { Button as BsButton } from 'react-bootstrap';

const Button = ({ children, icon, ...rest }) => (
  <BsButton {...rest}>
    {icon && (
      <i className={cn({ fas: icon, 'mr-2': children }, `fa-${icon}`)} />
    )}
    {children}
  </BsButton>
);

Button.propTypes = {
  children: node,
  icon: string,
};

export default Button;
