import { number, oneOfType, shape, string } from 'prop-types';

import { StyledLink } from './home_page/styles';

const PrimaryProfessorKey = ({ row, value }) => (
  <StyledLink title="See details" href={row.original.professorHref}>
    {value}
  </StyledLink>
);

PrimaryProfessorKey.propTypes = {
  row: shape({
    original: shape({
      professorHref: string.isRequired,
    }).isRequired,
  }).isRequired,
  value: oneOfType([string, number]).isRequired,
};

export default PrimaryProfessorKey;
