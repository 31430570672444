import {
  Button,
  FormField,
  GlobalStyles,
  Input,
  Switch,
  TextArea,
  ThemeProvider,
  Typography,
} from '@bbnpm/bb-ui-framework';
import { useState } from 'react';

import {
  ButtonContainer,
  FlexRow,
  RequiredLabel,
} from '../../CommonComponents';
import Container from '../../Container';
import PatchForm from '../../PatchForm';
import PostForm from '../../PostForm';
import { TradingChallengeProps } from '../../types';

const Show = ({
  id,
  name,
  overview,
  description,
  active,
  registrationStart,
  registrationEnd,
  challengeStart,
  challengeEnd,
  startingPositionsDeadline,
  winnersAnnouncedDate,
  adminHref,
  adminTradingChallengesPath,
  errors = {},
}) => {
  const [Form, action, submitText] = id
    ? [PatchForm, adminHref, 'Update']
    : [PostForm, adminTradingChallengesPath, 'Submit'];

  const [formActive, setFormActive] = useState(active);

  return (
    <ThemeProvider>
      <GlobalStyles />
      <Container>
        <Typography.PageTitle>
          {id ? 'Edit' : 'Create'} Trading Challenge
        </Typography.PageTitle>

        <Form action={action}>
          <FormField>
            <Switch
              checked={formActive}
              onChange={(_, isChecked) => {
                setFormActive(isChecked);
              }}
              label="Active"
              labelPosition="right"
              value={formActive}
              name="active"
            />
            <input type="hidden" name="active" value={formActive} />
          </FormField>

          <FormField
            label={<RequiredLabel>Name</RequiredLabel>}
            validationType={errors.name?.length && 'error'}
            validationContent={errors.name?.join(', ')}>
            <Input type="text" name="name" defaultValue={name} required />
          </FormField>

          <FormField label="Overview">
            <TextArea
              autosize={{ minRows: 2 }}
              name="overview"
              defaultValue={overview}
            />
          </FormField>

          <FormField label="Description">
            <TextArea
              autosize={{ minRows: 2 }}
              name="description"
              defaultValue={description}
            />
          </FormField>

          <FlexRow basis="49%">
            <FormField
              label={<RequiredLabel>Registration Start</RequiredLabel>}
              validationType={errors.registrationStart?.length && 'error'}
              validationContent={errors.registrationStart?.join(', ')}>
              <Input
                type="datetime-local"
                name="registrationStart"
                defaultValue={registrationStart?.slice(0, 16)}
                required
              />
            </FormField>
            <FormField
              label={<RequiredLabel>Registration End</RequiredLabel>}
              validationType={errors.registrationEnd?.length && 'error'}
              validationContent={errors.registrationEnd?.join(', ')}>
              <Input
                type="datetime-local"
                name="registrationEnd"
                defaultValue={registrationEnd?.slice(0, 16)}
                required
              />
            </FormField>
          </FlexRow>

          <FlexRow basis="49%">
            <FormField
              label={<RequiredLabel>Challenge Start</RequiredLabel>}
              validationType={errors.challengeStart?.length && 'error'}
              validationContent={errors.challengeStart?.join(', ')}>
              <Input
                type="datetime-local"
                name="challengeStart"
                defaultValue={challengeStart?.slice(0, 16)}
                required
              />
            </FormField>
            <FormField
              label={<RequiredLabel>Challenge End</RequiredLabel>}
              validationType={errors.challengeEnd?.length && 'error'}
              validationContent={errors.challengeEnd?.join(', ')}>
              <Input
                type="datetime-local"
                name="challengeEnd"
                defaultValue={challengeEnd?.slice(0, 16)}
                required
              />
            </FormField>
          </FlexRow>

          <FlexRow basis="49%">
            <FormField
              label={<RequiredLabel>Starting Positions Deadline</RequiredLabel>}
              validationType={
                errors.startingPositionsDeadline?.length && 'error'
              }
              validationContent={errors.startingPositionsDeadline?.join(', ')}>
              <Input
                type="datetime-local"
                name="startingPositionsDeadline"
                defaultValue={startingPositionsDeadline?.slice(0, 16)}
                required
              />
            </FormField>

            <FormField
              label={<RequiredLabel>Winners Announced Date</RequiredLabel>}
              validationType={errors.winnersAnnouncedDate?.length && 'error'}
              validationContent={errors.winnersAnnouncedDate?.join(', ')}>
              <Input
                type="datetime-local"
                name="winnersAnnouncedDate"
                defaultValue={winnersAnnouncedDate?.slice(0, 16)}
                required
              />
            </FormField>
          </FlexRow>

          <ButtonContainer>
            <Button type="submit" className="ml-4" kind="primary" size="sm">
              {submitText}
            </Button>
            <Button
              as="a"
              className="ml-4"
              kind="secondary"
              href={adminHref}
              size="sm">
              Cancel
            </Button>
          </ButtonContainer>
        </Form>
      </Container>
    </ThemeProvider>
  );
};

export default Show;

Show.propTypes = TradingChallengeProps;
