import { arrayOf, bool, func, shape, string } from 'prop-types';

import { CourseModuleProps, TableProps } from '../../types';

export const IndexProps = {
  ...TableProps,
  adminCourseModulesPath: string.isRequired,
  path: string.isRequired,
  courseModules: arrayOf(shape(CourseModuleProps)).isRequired,
};

export const CourseModuleActionsProps = {
  row: shape({
    original: shape(CourseModuleProps).isRequired,
  }).isRequired,
  refreshData: func.isRequired,
};

export const CourseModuleFormModalProps = {
  show: bool.isRequired,
  onHide: func.isRequired,
  onSubmit: func.isRequired,
  courseModule: shape(CourseModuleProps),
};

export const PreviewModalProps = {
  show: bool.isRequired,
  onHide: func.isRequired,
  rusticiId: string.isRequired,
};

export const UploadModalProps = {
  show: bool.isRequired,
  onHide: func.isRequired,
  courseModule: shape(CourseModuleProps),
  refreshData: func.isRequired,
};
