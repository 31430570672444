import { Button } from 'react-bootstrap';

import AttachmentLink from '../../AttachmentLink';
import Container from '../../Container';
import DeleteButton from '../../DeleteButton';
import PrimaryKey from '../../PrimaryKey';
import Table from '../../Table';
import { ShowProps } from './types';

const columns = [
  { Header: 'ID', accessor: 'id', Cell: PrimaryKey },
  { Header: 'Order', accessor: 'listOrder' },
  { Header: 'Title', accessor: 'title' },
  { Header: 'Type', accessor: 'sectionType' },
  { Header: 'Created At', accessor: 'createdAt' },
];

const Show = ({
  title,
  description,
  shortDescription,
  overview,
  learningObjective,
  packPreviewAttachment,
  professorPackLogo,
  createdAt,
  editAdminProfessorPackPath,
  adminHref,
  packSections,
  newAdminProfessorPackPackSectionPath,
}) => (
  <Container>
    <div className="d-flex align-items-center">
      <h1>{title}</h1>
      <Button
        className="ml-4"
        variant="outline-dark"
        href={editAdminProfessorPackPath}
        size="sm">
        Edit Case Study
      </Button>

      <DeleteButton
        size="sm"
        className="ml-2"
        variant="danger"
        action={adminHref}>
        <i className="fas fa-eraser mr-2" />
        Delete
      </DeleteButton>
    </div>

    <p>Created at {createdAt}.</p>

    <h4>Description:</h4>
    <p className="text-pre-wrap">{description}</p>

    <h4>Short Description:</h4>
    <p className="text-pre-wrap">{shortDescription}</p>

    <h4>Overview:</h4>
    <p className="text-pre-wrap">{overview}</p>

    <h4>Learning Objective:</h4>
    <p className="text-pre-wrap">{learningObjective}</p>

    {packPreviewAttachment && (
      <p>
        <strong>Case Study Preview:</strong>{' '}
        <AttachmentLink attachment={packPreviewAttachment} />
      </p>
    )}
    {professorPackLogo && (
      <p>
        <strong>Case Study Logo:</strong>{' '}
        <AttachmentLink attachment={professorPackLogo} />
      </p>
    )}

    <div className="d-flex align-items-center mb-3">
      <h4 className="m-0">Case Study Sections</h4>
      <Button
        className="ml-4"
        variant="outline-dark"
        href={newAdminProfessorPackPackSectionPath}
        size="sm">
        New Case Study Section
      </Button>
    </div>

    <Table
      columns={columns}
      initialData={packSections}
      initialSortBy="listOrder ASC"
    />
  </Container>
);

Show.propTypes = ShowProps;

export default Show;
