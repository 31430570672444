import { DataTable, Switch, Typography } from '@bbnpm/bb-ui-framework';
import PropTypes from 'prop-types';
import { useState } from 'react';

import { Expander } from '../../CollapsibleTable';
import RequiredForCertificationIcon from '../../RequiredForCertificationIcon';

const CourseRequiredCell = ({
  courseVersionId,
  deletionStatusCourseMap,
  setDeletionStatusCourse,
}) => (
  <Switch
    id={courseVersionId}
    key={courseVersionId}
    onChange={() =>
      setDeletionStatusCourse(
        courseVersionId,
        !deletionStatusCourseMap[courseVersionId],
      )
    }
    checked={!deletionStatusCourseMap[courseVersionId]}
  />
);

const CourseCategoryRequiredCell = ({
  deletionStatusCourseMap,
  setDeletionStatusCourse,
  courseName,
  children,
}) => {
  return (
    <Switch
      id={courseName}
      key={courseName}
      onChange={event => {
        children.forEach(child => {
          setDeletionStatusCourse(child.courseVersionId, !event.target.checked);
        });
      }}
      checked={children.every(
        child => !deletionStatusCourseMap[child.courseVersionId],
      )}
    />
  );
};

CourseRequiredCell.propTypes = {
  courseVersionId: PropTypes.number.isRequired,
  setDeletionStatusCourse: PropTypes.func.isRequired,
  deletionStatusCourseMap: PropTypes.object.isRequired,
};

CourseCategoryRequiredCell.propTypes = {
  deletionStatusCourseMap: PropTypes.object.isRequired,
  setDeletionStatusCourse: PropTypes.func.isRequired,
  courseName: PropTypes.string.isRequired,
  children: PropTypes.array.isRequired,
};

const CourseNameCell = ({ courseName, requiredForCertification }) => (
  <>
    {courseName}
    {requiredForCertification && <RequiredForCertificationIcon />}
  </>
);

CourseNameCell.propTypes = {
  courseName: PropTypes.string.isRequired,
  requiredForCertification: PropTypes.bool.isRequired,
};

const AssignedCourses = ({
  classCourses,
  deletionStatusCourseMap,
  setDeletionStatusCourse,
}) => {
  const [openRows, setOpenRows] = useState([]);
  const toggleOpenRow = rowId =>
    setOpenRows(rows =>
      rows.includes(rowId) ? rows.filter(id => id !== rowId) : [...rows, rowId],
    );

  const rows = classCourses
    .map(
      ({
        courseCategoryName,
        courseCategoryTitle,
        courseContent,
        totalDuration,
        totalQuestions,
      }) => ({
        courseName: `${courseCategoryTitle} (${courseCategoryName})`,
        numberOfQuestions: totalQuestions,
        duration: totalDuration,
        setForDeletion: true,
        children: courseContent,
      }),
    )
    .flatMap(
      (
        { courseName, numberOfQuestions, duration, setForDeletion, children },
        index,
      ) => {
        const rowId = String(index);

        return [
          {
            rowId,
            data: {
              courseName,
              numberOfQuestions,
              duration,
              setForDeletion,
              children,
            },
          },
          ...(openRows.includes(rowId)
            ? children.map((childData, childIndex) => ({
                rowId: `child-${index}-${childIndex}`,
                data: childData,
              }))
            : []),
        ];
      },
    );
  return (
    <>
      <DataTable
        columns={[
          {
            columnId: 'expander',
            headerRenderer: () => '',
            dataRenderer: ({ rowId }) => {
              const isChild = rowId.includes('child');
              return !isChild ? (
                <Expander
                  expanded={openRows.includes(rowId)}
                  onClick={() => toggleOpenRow(rowId)}
                />
              ) : (
                ''
              );
            },
            width: '32px',
          },
          {
            columnId: 'courseName',
            headerRenderer: () => 'Course Name',
            dataRenderer: ({ rowId, data }) =>
              rowId.includes('child') ? (
                <CourseNameCell {...data} />
              ) : (
                data.courseName
              ),
          },
          { columnId: 'numberOfQuestions', headerRenderer: () => 'Questions' },
          { columnId: 'duration', headerRenderer: () => 'Duration' },
          {
            columnId: 'setForDeletion',
            headerRenderer: () => 'Required',
            dataRenderer: ({ rowId, data }) =>
              rowId.includes('child') ? (
                <CourseRequiredCell
                  deletionStatusCourseMap={deletionStatusCourseMap}
                  setDeletionStatusCourse={setDeletionStatusCourse}
                  {...data}
                />
              ) : (
                <CourseCategoryRequiredCell
                  deletionStatusCourseMap={deletionStatusCourseMap}
                  setDeletionStatusCourse={setDeletionStatusCourse}
                  {...data}
                />
              ),
          },
        ]}
        rows={rows}
      />
      <Typography.SubSectionTitle>
        <i className="fas fa-award mr-2 text-primary" />
        Requirement for Certification
      </Typography.SubSectionTitle>
    </>
  );
};

AssignedCourses.propTypes = {
  classCourses: PropTypes.array.isRequired,
  deletionStatusCourseMap: PropTypes.object.isRequired,
  setDeletionStatusCourse: PropTypes.func.isRequired,
};

export default AssignedCourses;
