import { Button, Dropdown } from 'react-bootstrap';

import Container from '../../Container';
import PrimaryAdminKey from '../../PrimaryAdminKey';
import Table from '../../Table';
import { useFetchData } from '../../utils';
import { ClassDropdownProps, IndexProps } from './types';

const ClassDropdown = ({ row }) => (
  <Dropdown drop="left">
    <Dropdown.Toggle variant="outline-dark" className="py-0 border-0" size="sm">
      <i className="fas fa-lg fa-fw fa-ellipsis-h" />
    </Dropdown.Toggle>

    <Dropdown.Menu>
      <Dropdown.Item href={row.original.adminHref}>
        <i className="fas fa-user fa-fw mr-2" />
        View Class
      </Dropdown.Item>
      <Dropdown.Item href={row.original.editAdminBatClassPath}>
        <i className="fas fa-edit fa-fw mr-2" />
        Edit Class
      </Dropdown.Item>
    </Dropdown.Menu>
  </Dropdown>
);

ClassDropdown.propTypes = ClassDropdownProps;

const columns = [
  { Header: 'ID', accessor: 'id', filteredAs: 'number', Cell: PrimaryAdminKey },
  { Header: 'Name', accessor: 'nameAlias', filteredAs: 'string' },
  { Header: 'Created', accessor: 'createdAt' },
  { Header: 'Learners', accessor: 'classEnrollmentsCount' },
  { Header: 'Professor', accessor: 'professor.fullName', disableSortBy: true },
  { Header: 'Class Code', accessor: 'classCode', filteredAs: 'string' },
  {
    Header: 'Avail Uses',
    accessor: 'remainingUses',
  },
  { Header: 'Status', accessor: 'status', disableSortBy: true },
  {
    accessor: 'id',
    id: 'actions',
    disableSortBy: true,
    Cell: ClassDropdown,
  },
];

const scopes = [
  { id: 'open', title: 'Open' },
  { id: 'closed', title: 'Closed' },
];

const initialScopes = { open: false, closed: false };

const cb = ({ totalCount, pageSize, classes }) => ({
  data: classes,
  pageSize,
  totalCount,
});

const Index = ({
  adminClassesPath,
  newAdminClassPath,
  classes,
  totalCount,
  pageSize,
  pageIndex,
  sortBy,
}) => {
  const fetchData = useFetchData(adminClassesPath, cb);
  return (
    <Container>
      <div className="d-flex align-items-center mb-3">
        <h1>Classes</h1>
        <Button href={newAdminClassPath} className="ml-5" variant="primary">
          Add Class
        </Button>
      </div>
      <Table
        initialData={classes}
        initialPageSize={pageSize}
        initialPageIndex={pageIndex}
        initialSortBy={sortBy}
        initialTotalCount={totalCount}
        initialScopes={initialScopes}
        availableScopes={scopes}
        columns={columns}
        fetchData={fetchData}
      />
    </Container>
  );
};

Index.propTypes = IndexProps;

export default Index;
