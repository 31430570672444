import { arrayOf, shape } from 'prop-types';

import { WebinarProps, WebinarSeriesProps } from '../types';

export const ShowProps = {
  ...WebinarSeriesProps,
  allWebinarSeries: arrayOf(shape(WebinarSeriesProps)).isRequired,
};

export const LeadWebinarPanelProps = {
  webinar: shape(WebinarProps).isRequired,
};
