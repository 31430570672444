import { array, boolean, object, string } from 'yup';

const teamMemberSchema = emailDomains =>
  object({
    firstName: string().required(),
    lastName: string().required(),
    email: string()
      .test(
        'validate-domain',
        "${path} domain does not match your school's email",
        value =>
          !emailDomains?.length ||
          emailDomains.some(domain =>
            value.toLowerCase().endsWith(domain.toLowerCase()),
          ), // skip validation if there are no email domains
      )
      .email()
      .required(),
    phone: string(),
    department: string(),
    title: string(),
    memberType: string().required().oneOf(['professor', 'member', 'captain']),
  });

const schema = emailDomains =>
  object({
    termsAccepted: boolean().required().oneOf([true]),
    teamName: string().required(),
    teamMembers: array(teamMemberSchema(emailDomains)).required().min(4).max(6),
  });

export default schema;
