import {
  DataTable,
  NavigationList,
  Tree,
  Typography,
} from '@bbnpm/bb-ui-framework';
import { Col, Grid, Row } from '@bloomberg/styled-flexboxgrid';
import camelCase from 'lodash.camelcase';
import snakeCase from 'lodash.snakecase';
import { useEffect, useState } from 'react';
import { Element, scroller } from 'react-scroll';
import styled from 'styled-components';

const SectionHeader = styled(Typography.SectionTitle)`
  background: ${({ theme }) => theme.colors.grey[100]};
  padding: 8px;
  margin-bottom: 16px;
`;

const StickySideBar = styled.div`
  max-width: 100%;
  position: sticky;
  top: 0;
`;

const getSectionId = element => {
  const { uid, title, type } = element;
  return uid || `nodeId-${title}-${type}`;
};

const sectionsToTreeChildren = sections => {
  if (!Array.isArray(sections)) return [];
  let res = [];
  for (let i = 0; i < sections.length; i++) {
    const element = sections[i];
    let sect = {
      title: element.title,
      nodeId: getSectionId(element),
      treeChildren:
        Array.isArray(element.content) && element.content.length > 1
          ? sectionsToTreeChildren(element.content)
          : null,
    };
    res.push(sect);
  }
  return res;
};

// for multi level content
export const TreeSideBar = ({ title, sections }) => {
  return (
    <StickySideBar>
      <Tree
        title={title}
        treeChildren={sectionsToTreeChildren(sections)}
        defaultExpanded={sections[0]?.uid}
        onSelectChange={(evt, node) => {
          scroller.scrollTo(`step-${node.nodeId}`, {
            duration: 500,
            smooth: true,
          });
        }}
      />
    </StickySideBar>
  );
};

const renderContent = contentArray => {
  const nodes = [];
  for (const content of contentArray) {
    const {
      content: faqContent,
      type,
      url,
      caption,
      header,
      rows,
      title,
      uid,
    } = content;

    if (title) {
      nodes.push(
        <Typography.SubSectionTitle key={'content-key-header'}>
          {title}
        </Typography.SubSectionTitle>,
      );
    }

    if (type === 'rich-text') {
      nodes.push(
        <div
          key={`content-key-${uid}`}
          dangerouslySetInnerHTML={{
            __html: faqContent,
          }}
        />,
      );
    } else if (type === 'image') {
      nodes.push(
        <img
          src={url}
          style={{ maxWidth: '100%' }}
          key={`content-key-${uid}`}
        />,
      );
      if (caption)
        nodes.push(
          <div
            key={`content-key-${uid}2`}
            dangerouslySetInnerHTML={{
              __html: caption,
            }}
          />,
        );
    } else if (type === 'table') {
      const colIds = header.cells.map(cell => camelCase(cell.content));
      nodes.push(
        <DataTable
          key={`content-key-${uid}`}
          columns={header.cells.map(cell => ({
            columnId: camelCase(cell.content),
            headerRenderer: () => cell.content,
          }))}
          rows={rows.map(row => ({
            data: row.cells.reduce((accum, cell, idx) => {
              accum[colIds[idx]] = cell.content;
              return accum;
            }, {}),
          }))}
        />,
      );
    }
  }
  return nodes;
};

const renderFaq = faqs => {
  const components = [];

  for (const idx in faqs) {
    const { title, content } = faqs[idx];
    const Node = (
      <div key={`section-key-${idx}`}>
        <Element name={'step-' + idx} />
        {title && <SectionHeader>{title}</SectionHeader>}
        {renderContent(content)}
      </div>
    );
    components.push(Node);
  }

  return components;
};

export const FaqRenderer = ({ sections, title }) => {
  const [step, setStep] = useState(0);
  const { content } = sections[0];
  const navTitles = content.map(({ title }) => snakeCase(title));

  const handleIdxChange = link => {
    scroller.scrollTo(`step-${link}`, {
      duration: 500,
      smooth: true,
    });
    setStep(link);
    const newPath = window.location.pathname + '#' + navTitles[link];
    history.replaceState({ turbolinks: {} }, '', newPath);
  };

  useEffect(() => {
    const hash = window.location.hash.slice(1);
    const hashIdx = navTitles.findIndex(el => el === hash);

    if (hashIdx > -1) {
      handleIdxChange(hashIdx);
    }
  }, []);

  return (
    <Grid>
      <Row>
        <Typography.PageTitle key="section-key-title">
          {title}
        </Typography.PageTitle>
      </Row>
      <Row>
        <Col xs={3}>
          <StickySideBar>
            <NavigationList activeIndex={step} onLinkChange={handleIdxChange}>
              {content.map(({ title }, idx) => {
                return (
                  <NavigationList.Link key={`navlink-${idx}`}>
                    {title}
                  </NavigationList.Link>
                );
              })}
            </NavigationList>
          </StickySideBar>
        </Col>
        <Col xs={8}>{renderFaq(content)}</Col>
      </Row>
    </Grid>
  );
};
