import {
  Table as BBUITable,
  Button,
  GlobalStyles,
  ThemeProvider,
} from '@bbnpm/bb-ui-framework';
import { useState } from 'react';

import { noop } from '../../../utils';
import AlertWithIcon from '../../AlertWithIcon';
import Container from '../../Container';
import PostForm from '../../PostForm';
import PrimaryKey from '../../PrimaryKey';
import Table from '../../Table';
import { useFetchData } from '../../utils';
import ActionsCell from './ActionsCell';
import { ShowProps } from './types';

const AdminLearnerKey = ({ row, value }) => (
  <a title="See details" href={row.original.user.href}>
    {value}
  </a>
);
const enrolledLearnersColumns = [
  {
    Header: 'ID',
    accessor: 'user.userid',
    Cell: AdminLearnerKey,
    disableSortBy: true,
  },
  { Header: 'Name', accessor: 'user.fullName', disableSortBy: true },
  {
    accessor: 'id',
    id: 'actions',
    disableSortBy: true,
    Cell: ActionsCell,
  },
];

const cb = ({ totalCount, pageSize, classEnrollments }) => ({
  data: classEnrollments,
  pageSize,
  totalCount,
});

const courseVersionsColumns = [
  { Header: 'ID', accessor: 'id', Cell: PrimaryKey },
  { Header: 'Name', accessor: 'internalName' },
];

const Show = ({
  id,
  nameAlias,
  createdAt,
  status,
  classCode,
  remainingUses,
  professor,
  impersonateAdminClassPath,
  editAdminBatClassPath,
  classEnrollments,
  courseVersions,
  locked,
  nonMatchEmailDomainLimit,
  path,
  pageSize,
  totalCount,
  pageIndex,
  sortBy,
}) => {
  const [{ refreshData }, setRefreshDataFn] = useState({ refreshData: noop });
  const fetchData = useFetchData(path, cb);
  return (
    <ThemeProvider>
      <GlobalStyles />
      <Container>
        {/* {professor.institution?.isNonUniversity &&
        professor.institution?.nonMatchEmailDomainLimit === 0 && (
          <AlertWithIcon variant="warning">
            This class is linked to a non-university institution:{' '}
            <a href={professor.institution?.href}>
              {professor.institution?.name}
            </a>
            <br />
            The institution&apos;s non-matching email domain limit setting is
            currently:{' '}
            <strong>{professor.institution?.nonMatchEmailDomainLimit}</strong>
            <br />
            If this setting is 0 and a user is having issues attempting to use
            this class code, you may need to increase this number by clicking on
            the institution link provided above and editing the institution.
          </AlertWithIcon>
        )} */}
        {professor.institution?.isNonUniversity &&
          nonMatchEmailDomainLimit === 0 && (
            <AlertWithIcon variant="warning">
              This class is linked to a non-university institution:{' '}
              <a href={professor.institution?.href}>
                {professor.institution?.name}
              </a>
              <br />
              The class&apos;s non-matching email domain limit setting is
              currently: <strong>{nonMatchEmailDomainLimit}</strong>
              <br />
              If this setting is 0 and a user is having issues attempting to use
              this class code, you may need to increase this number by clicking
              on the edit clas button below.
            </AlertWithIcon>
          )}
        <div className="d-flex align-items-center">
          <h1>Class: {nameAlias}</h1>
          <PostForm
            action={impersonateAdminClassPath}
            className="d-inline-block ml-4"
            submitText={
              <>
                <i className="fas fa-user-secret mr-2" />
                Masquerade
              </>
            }
            submitProps={{ size: 'sm', variant: 'outline-dark' }}
          />
          <Button
            className="ml-2"
            kind="secondary"
            as="a"
            href={editAdminBatClassPath}
            size="sm">
            Edit Class
          </Button>
        </div>

        <p>Class created at {createdAt}.</p>
        <div className="d-flex justify-content-between">
          {[
            [
              ['ID', id],
              ['Status', status],
              ['Class Code', classCode],
              ['Remaining Free BMC Credits', remainingUses],
              ['Class Code Revoked?', locked ? 'True' : 'False'],
              professor.institution?.isNonUniversity
                ? ['Remaining Non-match Domain', nonMatchEmailDomainLimit]
                : '',
            ],
            [
              [
                'Professor',
                <>
                  <a href={professor.href}>{professor.fullName}</a>
                </>,
              ],
              [
                'Institution',
                <>
                  <a href={professor.institution?.href}>
                    {professor.institution?.name}
                  </a>
                </>,
              ],
            ],
          ].map((table, i) => (
            <div key={i} className="mr-5">
              <BBUITable>
                <tbody>
                  {table.map(([k, v], j) => (
                    <tr key={j}>
                      <td className="text-muted">{k}</td>
                      <td>{v}</td>
                    </tr>
                  ))}
                </tbody>
              </BBUITable>
            </div>
          ))}
        </div>

        <h4 className="border-top pt-3 mt-3">Learners</h4>
        <Table
          initialData={classEnrollments}
          columns={enrolledLearnersColumns}
          refreshData={refreshData}
          onRefreshDataFn={setRefreshDataFn}
          fetchData={fetchData}
          initialPageSize={pageSize}
          initialTotalCount={totalCount}
          initialPageIndex={pageIndex}
          initialSortBy={sortBy}
        />

        <h4 className="border-top pt-3 mt-3">Course Versions</h4>
        <Table initialData={courseVersions} columns={courseVersionsColumns} />
      </Container>
    </ThemeProvider>
  );
};

Show.propTypes = ShowProps;

export default Show;
