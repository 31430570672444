import { bool, string } from 'prop-types';

import VideoPlayer from './VideoPlayer';

const VimeoPlayer = ({ vimeoId, autoplay = false, ...rest }) => (
  <VideoPlayer
    {...rest}
    src={`https://player.vimeo.com/video/${vimeoId}?autoplay=${
      autoplay ? '1' : '0'
    }&title=0&byline=0&portrait=0`}
  />
);

VimeoPlayer.propTypes = {
  vimeoId: string.isRequired,
  autoplay: bool,
};

export default VimeoPlayer;
