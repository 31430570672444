import {
  Button,
  FormField,
  GlobalStyles,
  Input,
  ThemeProvider,
  Typography,
} from '@bbnpm/bb-ui-framework';
import { object, string } from 'prop-types';
import styled from 'styled-components';

import Container from '../Container';
import PostForm from '../PostForm';

const StyledForm = styled(PostForm)`
  width: 95%;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
`;

const New = ({ errors = {}, url }) => (
  <ThemeProvider>
    <GlobalStyles />
    <Container>
      <StyledForm action={url}>
        <Typography.PageTitle>
          <strong>Reset Password</strong>
        </Typography.PageTitle>
        <Typography.Text>
          <strong>Request a link to reset your password</strong>
          <br />
          The link is only active for 24 hours.
        </Typography.Text>
        <FormField
          validationType={errors.email?.length && 'error'}
          validationContent={errors.email
            ?.map(error => 'Email ' + error)
            .join(', ')}>
          <Input name="user[email]" placeholder="Email (Username)" required />
        </FormField>
        <br />
        <Button kind="primary" type="submit">
          Request Link
        </Button>
      </StyledForm>
    </Container>
  </ThemeProvider>
);

New.propTypes = { url: string.isRequired, errors: object };

export default New;
