import { Typography } from '@bbnpm/bb-ui-framework';

import { StyledListItem } from './styles';
import { PreviewCaseStudiesListProps } from './types';

const PreviewCaseStudiesList = ({ professorPacks }) => (
  <>
    <Typography.PageTitle>
      <strong>Select a case study below to download a preview!</strong>
    </Typography.PageTitle>
    {professorPacks.map((pack, i) => (
      <StyledListItem key={pack.id}>
        {pack.packPreviewAttachment ? (
          <Typography.Text>
            {i + 1}.{' '}
            <Typography.Link
              href={pack.packPreviewAttachment.professorsAttachmentPreviewPath}>
              {pack.title}
            </Typography.Link>
          </Typography.Text>
        ) : (
          <Typography.Text>
            {i + 1}. {pack.title}
          </Typography.Text>
        )}

        <Typography.Text>{pack.description}</Typography.Text>
      </StyledListItem>
    ))}
  </>
);

PreviewCaseStudiesList.propTypes = PreviewCaseStudiesListProps;

export default PreviewCaseStudiesList;
