import { useState } from 'react';
import { Alert } from 'react-bootstrap';

import { post } from '../../../api';
import Button from '../../Button';
import Dropzone from '../../Dropzone';
import { UPLOAD_FILE_STATUSES, useUploadFile } from '../../utils';
import AcceptedFile from './AcceptedFile';
import { UploadVideoProps } from './types';

const nameWithoutExt = name => name.split('.').slice(0, -1).join('.');

const UploadVideo = ({
  rusticiToken,
  rusticiBaseUrl,
  adminVideosPath,
  mayCreateNewVersion = false,
  ...videoProps
}) => {
  const [file, setFile] = useState(null);

  const { uploadFile, uploadFileProgress, status, error } = useUploadFile({
    rusticiToken,
    rusticiBaseUrl,
  });

  const now =
    uploadFileProgress.total > 0
      ? (uploadFileProgress.loaded / uploadFileProgress.total) * 100
      : 0;

  const handleRequestUpload = async e => {
    e.stopPropagation();

    const onEditPage = !!videoProps.rusticiId;
    if (!onEditPage) {
      videoProps = await post(adminVideosPath, {
        name: nameWithoutExt(file.name),
      });
    }
    await uploadFile({
      file,
      id: videoProps.rusticiId,
      markUploadedPath: videoProps.markUploadedAdminVideoPath,
      mayCreateNewVersion,
      onComplete: () => {
        !onEditPage
          ? window.Turbolinks.visit(videoProps.editAdminVideoPath)
          : window.Turbolinks.reload();
      },
    });
  };

  return (
    <>
      {!file ? (
        <Dropzone onFile={setFile} accept=".zip">
          <div className="mb-3 font-size-lg text-muted">
            Drag and drop anywhere to upload
          </div>
          <Button
            variant="primary"
            className="btn-block"
            icon="cloud-upload-alt">
            or choose file
          </Button>
        </Dropzone>
      ) : status === UPLOAD_FILE_STATUSES.INIT ? (
        <Dropzone onFile={setFile}>
          <AcceptedFile
            status={status}
            file={file}
            now={now}
            handleRequestUpload={handleRequestUpload}
          />
        </Dropzone>
      ) : (
        <div className="align-items-center d-flex flex-fill justify-content-center">
          <div>
            {status === UPLOAD_FILE_STATUSES.FAILED && (
              <Alert variant="danger">
                <strong>Error:</strong> {error.message}
              </Alert>
            )}
            {status === UPLOAD_FILE_STATUSES.IMPORT_STARTED && (
              <Alert variant="secondary">
                The file is being processed. Please be patient...
              </Alert>
            )}
            <AcceptedFile
              status={status}
              file={file}
              now={now}
              handleRequestUpload={handleRequestUpload}
            />
          </div>
        </div>
      )}
    </>
  );
};
UploadVideo.propTypes = UploadVideoProps;

export default UploadVideo;
