import { array, bool, string } from 'prop-types';

export const IndexProps = {
  canAccessProfessorPacks: bool.isRequired,
  professorPacks: array.isRequired,
  professorsCaseStudiesPath: string.isRequired,
  caseStudiesSurvey: string.isRequired,
  batContactLink: string.isRequired,
};

export const ShowProps = {
  title: string.isRequired,
  overview: string.isRequired,
  packSections: array,
};

export const CaseStudiesListProps = {
  professorPacks: array.isRequired,
  professorsCaseStudiesPath: string.isRequired,
};

export const FeedbackProps = { caseStudiesSurvey: string.isRequired };

export const NotEnabledNoticeProps = { batContactLink: string.isRequired };

export const PreviewCaseStudiesListProps = { professorPacks: array.isRequired };
