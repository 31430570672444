import {
  Banner,
  Button,
  Checkbox,
  FormField,
  Input,
  NavigationList,
  Typography,
  useNotification,
} from '@bbnpm/bb-ui-framework';
import { Col, Grid, Row } from '@bloomberg/styled-flexboxgrid';
import { useState } from 'react';
import { Element, scroller } from 'react-scroll';
import styled from 'styled-components';

import { post } from '../../api';
import {
  HorizontalLineBreak,
  PageWrapper,
  RequiredLabel,
} from '../CommonComponents';
import RegistrationBssoOptInModal from './RegistrationBssoOptInModal';
import { CaptainInfo } from './formSections/CaptainInfo';
import { FacultyInfo } from './formSections/FacultyInfo';
import TeamMemberForm, {
  newTeamMember,
} from './formSections/TeamMemberFormSection';
import validationSchema from './registrationFormValidation';
import { RegistrationFormProps } from './types';

const formatFormErrors = errors => {
  const errorsMap = {};
  errors.forEach(errorMsg => {
    if (errorMsg.includes('teamName')) {
      errorsMap.teamName = errorMsg.slice(8);
    } else if (errorMsg.includes('termsAccepted')) {
      errorsMap.termsAccepted =
        'You must accept the privacy policy and terms and conditions to register.';
    } else if (errorMsg.includes('teamMembers')) {
      const idx = parseInt(errorMsg[12], 10);
      const field = errorMsg.match(/\.[A-Za-z0-9]+/)[0].slice(1);
      const error = errorMsg.match(/(\s([A-Za-z]+\s)+)[A-Za-z]+/)[0];
      errorsMap.teamMembers ??= {};
      errorsMap.teamMembers[idx] ??= {};
      errorsMap.teamMembers[idx][field] = error;
    }
  });
  return errorsMap;
};

const SubmitRow = styled.div`
  text-align: center;

  button {
    margin-top: 1rem;
    min-width: 150px;
  }
`;

const RegistrationForm = ({
  tradingChallenge,
  user,
  institution,
  registrationError,
  registrationWarning,
  isHighSchool,
  createRegistrationPath,
  emailDomains,
  bssoRedirectUrl,
}) => {
  const [step, setStep] = useState(0);
  const [terms1, setTerms1] = useState(false);
  const [terms2, setTerms2] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submittedSuccess, setSubmittedSuccess] = useState(false);
  const [teamName, setTeamName] = useState('');
  const [formErrors, setFormErrors] = useState({});
  const [showBssoModal, setShowBssoModal] = useState(!!bssoRedirectUrl);
  const notif = useNotification();

  const [facultyInfo, setFacultyInfo] = useState(
    newTeamMember(
      !isHighSchool
        ? { memberType: 'professor' }
        : {
            memberType: 'professor',
            email: user.email,
            firstName: user.firstName,
            lastName: user.lastName,
          },
    ),
  );

  const [captainInfo, setCaptainInfo] = useState(
    newTeamMember(
      isHighSchool
        ? { memberType: 'captain' }
        : {
            memberType: 'captain',
            email: user.email,
            firstName: user.firstName,
            lastName: user.lastName,
          },
    ),
  );

  const [teamMembers, setTeamMembers] = useState([
    newTeamMember(),
    newTeamMember(),
  ]);

  const disabled = isSubmitting || registrationError || submittedSuccess;

  const handleSubmit = async () => {
    setIsSubmitting(true);
    setFormErrors({});
    const formData = {
      termsAccepted: terms1 && terms2,
      teamName,
      teamMembers: [captainInfo, facultyInfo, ...teamMembers],
    };
    try {
      await validationSchema(emailDomains).validate(formData, {
        abortEarly: false,
      });
      await post(createRegistrationPath, formData);
      setSubmittedSuccess(true);
      notif.addSuccess({ message: 'Registration successfully submitted!' });
    } catch (e) {
      if (e.name === 'ValidationError') {
        const messages = e.errors;
        const errors = formatFormErrors(messages);
        setFormErrors(errors);
        notif.addError({
          message: 'Please fix form errors before resubmitting',
        });
      } else {
        notif.addError({
          message:
            e?.response?.data?.error || 'Something went wrong with submission.',
        });
      }
      setIsSubmitting(false);
    }
  };

  const navigationListHandler = link => {
    scroller.scrollTo(`step-${link}`, { duration: 500, smooth: true });
    setStep(link);
  };
  // NOTE - either a professor or a student captain can fill out the form based on institution
  // The registrator's info should be immutable on the form, control the forms by passing
  // current user into either the captain or faculty form section
  return (
    <PageWrapper width="1140px">
      <Typography.PageTitle>
        Register for the {tradingChallenge.name}
      </Typography.PageTitle>

      <RegistrationBssoOptInModal
        show={showBssoModal}
        onHide={() => setShowBssoModal(false)}
        bssoUrl={bssoRedirectUrl}
      />

      {registrationError && (
        <Banner kind="error" message={registrationError} onClose={() => {}} />
      )}

      {!registrationError && registrationWarning && (
        <Banner
          kind="warning"
          message={registrationWarning}
          onClose={() => {}}
        />
      )}

      <Grid style={{ width: '100%', padding: 0 }}>
        <Row>
          <Col xs={3}>
            <NavigationList
              style={{ maxWidth: '100%', position: 'sticky', top: 0 }}
              onLinkChange={navigationListHandler}
              activeIndex={step}>
              <NavigationList.Link>Team Information</NavigationList.Link>
              <NavigationList.Link>
                {isHighSchool ? 'Faculty Information' : 'Captain Information'}
              </NavigationList.Link>
              <NavigationList.Link>
                {isHighSchool ? 'Captain Information' : 'Faculty Information'}
              </NavigationList.Link>
              <NavigationList.Link>Team Members</NavigationList.Link>
            </NavigationList>
          </Col>
          <Col xs={8}>
            <form>
              <Element name="step-0" />
              <Typography.SectionTitle style={{ marginBottom: '1rem' }}>
                Team Information
              </Typography.SectionTitle>
              <FormField label={<RequiredLabel>Institution</RequiredLabel>}>
                <Input
                  name="institution"
                  type="text"
                  defaultValue={institution}
                  disabled
                />
              </FormField>
              <FormField
                label={<RequiredLabel>Team Name</RequiredLabel>}
                validationType={formErrors.teamName && 'error'}
                validationContent={
                  formErrors.teamName && 'Team Name' + formErrors.teamName
                }>
                <Input
                  type="text"
                  value={teamName}
                  onChange={evt => setTeamName(evt.target.value)}
                  disabled={disabled}
                />
              </FormField>

              <HorizontalLineBreak />
              <Element name="step-1" />
              {isHighSchool ? (
                <FacultyInfo
                  user={user}
                  facultyInfo={facultyInfo}
                  setFacultyInfo={setFacultyInfo}
                  formErrors={formErrors}
                  disabled={disabled}
                />
              ) : (
                <CaptainInfo
                  formErrors={formErrors}
                  user={user}
                  disabled={disabled}
                />
              )}

              <HorizontalLineBreak />
              <Element name="step-2" />
              {isHighSchool ? (
                <CaptainInfo
                  disabled={disabled}
                  formErrors={formErrors}
                  captainInfo={captainInfo}
                  setCaptainInfo={setCaptainInfo}
                />
              ) : (
                <FacultyInfo
                  disabled={disabled}
                  facultyInfo={facultyInfo}
                  setFacultyInfo={setFacultyInfo}
                  formErrors={formErrors}
                />
              )}

              <HorizontalLineBreak />
              <Element name="step-3" />
              <TeamMemberForm
                disabled={disabled}
                setTeamMembers={setTeamMembers}
                teamMembers={teamMembers}
                formErrors={formErrors}
              />

              <HorizontalLineBreak />

              <FormField
                validationType={!terms1 && formErrors.termsAccepted && 'error'}
                validationContent={
                  !terms1 &&
                  formErrors.termsAccepted &&
                  formErrors.termsAccepted
                }>
                <Checkbox
                  disabled={disabled}
                  label={
                    <>
                      By submitting this information I agree to{' '}
                      <Typography.Link
                        target="_blank"
                        href="https://www.bloomberg.com/notices/privacy-policy-education/">
                        Bloomberg for Education&#39;s privacy policy
                      </Typography.Link>
                      {!isHighSchool &&
                        " and to learn more about Bloomberg's products and services."}
                    </>
                  }
                  value={terms1}
                  onClick={() => setTerms1(!terms1)}
                />
              </FormField>
              <FormField
                validationType={!terms2 && formErrors.termsAccepted && 'error'}
                validationContent={
                  !terms2 &&
                  formErrors.termsAccepted &&
                  formErrors.termsAccepted
                }>
                <Checkbox
                  disabled={disabled}
                  label={
                    <>
                      By submitting this information I agree to the{' '}
                      <Typography.Link
                        target="_blank"
                        href="/trading_challenges/terms">
                        Bloomberg Trading Challenge Terms and Conditions
                      </Typography.Link>
                    </>
                  }
                  value={terms2}
                  onClick={() => setTerms2(!terms2)}
                />
              </FormField>

              <SubmitRow>
                <Button
                  kind="primary"
                  onClick={handleSubmit}
                  disabled={disabled}>
                  Submit
                </Button>
              </SubmitRow>
            </form>
          </Col>
        </Row>
      </Grid>
    </PageWrapper>
  );
};

RegistrationForm.propTypes = RegistrationFormProps;
export default RegistrationForm;
