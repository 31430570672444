import { Col, Row } from '@bloomberg/styled-flexboxgrid';
import styled from 'styled-components';

export const StyledRow = styled(Row)`
  margin: 20px 0;
`;

export const FlexGrowCol = styled(Col)`
  flex-grow: 1;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;
