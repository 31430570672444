import { arrayOf, bool, func, shape, string } from 'prop-types';

import { TableProps, ToolkitTagProps } from '../../types';

export const IndexProps = {
  ...TableProps,
  toolkitTags: arrayOf(shape(ToolkitTagProps)).isRequired,
  adminToolkitTagsPath: string.isRequired,
  adminToolkitDocumentsPath: string.isRequired,
};

export const ShowProps = {
  ...TableProps,
  toolkitTag: shape(ToolkitTagProps).isRequired,
};

export const ToolkitTagFormProps = {
  isOpen: bool.isRequired,
  onClose: func.isRequired,
  onSubmit: func.isRequired,
  toolkitTag: shape(ToolkitTagProps),
};

export const TopicsActionsProps = {
  row: shape({
    original: shape(ToolkitTagProps),
  }),
  refreshData: func,
};
