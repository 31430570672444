import { node } from 'prop-types';

import { useIsMounted } from './utils';

const ClientRender = ({ children }) => {
  const isMounted = useIsMounted();
  return isMounted ? children : null;
};

ClientRender.propTypes = { children: node };

export default ClientRender;
