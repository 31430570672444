import { Button } from 'react-bootstrap';

import AttachmentLink from '../../AttachmentLink';
import Container from '../../Container';
import DeleteButton from '../../DeleteButton';
import { ShowProps } from './types';

const Show = ({
  title,
  description,
  createdAt,
  cloudStorageAttachment,
  packSection,
  href,
  editAdminPackResourcePath,
}) => (
  <Container>
    <div className="d-flex align-items-center">
      <h1>{title}</h1>
      <Button
        className="ml-4"
        variant="outline-dark"
        href={editAdminPackResourcePath}
        size="sm">
        Edit Case Study Resource
      </Button>

      <DeleteButton size="sm" className="ml-2" variant="danger" action={href}>
        <i className="fas fa-eraser mr-2" />
        Delete
      </DeleteButton>
    </div>

    <p>
      Created at {createdAt}. Part of{' '}
      <a href={packSection.professorPack.adminHref}>
        {packSection.professorPack.title}
      </a>
      {' > '}
      <a href={packSection.href}>{packSection.title}</a>
    </p>

    <h4>Description:</h4>
    <p className="text-pre-wrap">{description}</p>

    {cloudStorageAttachment && (
      <p>
        <strong>Case Study Resource:</strong>{' '}
        <AttachmentLink attachment={cloudStorageAttachment} />
      </p>
    )}
  </Container>
);

Show.propTypes = ShowProps;

export default Show;
