import {
  Button,
  Card,
  CheckboxGroup,
  FormField,
  Modal,
  Token,
} from '@bbnpm/bb-ui-framework';
import styled from 'styled-components';

import DaperImg from '../../DaperImg';

export const DocumentCardThumbnail = styled(DaperImg)`
  margin-bottom: 1rem;
  background-color: ${({ theme }) => theme.colors.white};
  width: 150px;
  -webkit-box-shadow: 2px -1px 15px -3px rgba(0, 0, 0, 0.45);
  box-shadow: 2px -1px 15px -3px rgba(0, 0, 0, 0.45);
`;

export const Left = styled.div`
  width: 25%;
  padding-top: 1rem;
  padding-right: 2rem;
  display: flex;
  flex-direction: column;

  legend {
    border: none;
    text-transform: none;
  }
`;

export const ClearAll = styled(Button)`
  align-self: flex-end;
  margin-top: 10px;
`;

export const Right = styled.div`
  width: 75%;
  padding-top: 1rem;
`;

export const FilteredTokens = styled.div`
  > * {
    margin-right: 0.5rem;
    margin-bottom: 0rem;
  }
`;

export const StyledToken = styled(Token)`
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  line-height: 1rem;
  min-width: 24px;
  max-width: 100%;

  > div {
    overflow-x: hidden;
  }
`;

export const StyledCard = styled(Card)`
  width: auto;
  min-width: auto;
  padding-bottom: 24px;

  button[aria-label='Exit Content'] {
    width: 1px;
    height: 1px;
  }

  &:hover {
    cursor: default;
  }

  .bbui-card-title {
    min-height: 48px;
  }

  .bbui-card-content > p {
    min-height: 168px;
  }
`;

export const FilterWrapper = styled(CheckboxGroup)`
  padding-top: 10px;

  .bbui-checkbox-label {
    display: flex;
  }

  .bbui-checkbox-label-text {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &:last-of-type {
    border-bottom: ${({ theme }) => `1px solid ${theme.colors.grey[500]}`};
    padding-bottom: 10px;
  }
`;

export const StyledFormField = styled(FormField)`
  border-top: ${({ theme }) => `1px solid ${theme.colors.grey[500]}`};
  padding-top: 10px;
`;

export const StyledHr = styled.hr`
  border-left: 0px;
  border-right: 0px;
  border-top: 1px solid ${({ theme }) => theme.colors.grey[400]};
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[400]};
  margin-top: 7px;
  margin-bottom: 10px;
`;

export const StyledModal = styled(Modal)`
  max-width: 65vw;
  max-height: 90vh;
`;

export const PDFWrapper = styled.iframe`
  height: 75vh;
  width: 100%;
  overflow: auto;

  @media (max-width: 767px) {
    width: 100%;
  }
`;

export const RightCount = styled.div`
  text-align: right;
  font-size: 14px;
  margin-top: 6px;
  color: ${({ theme }) => theme.colors.disabledText};
`;
