import { Switch, Typography } from '@bbnpm/bb-ui-framework';
import PropTypes from 'prop-types';

import DaperImgCell from '../../DaperImgCell';
import Table from '../../Table';

const VideoRequiredCell = ({
  row,
  deletionStatusVideoMap,
  setDeletionStatusVideo,
}) => {
  const { videoId } = row.original;

  return (
    <Switch
      key={videoId}
      id={videoId}
      label=""
      onChange={() =>
        setDeletionStatusVideo(videoId, !deletionStatusVideoMap[videoId])
      }
      checked={!deletionStatusVideoMap[videoId]}
    />
  );
};

VideoRequiredCell.propTypes = {
  row: PropTypes.object.isRequired,
  setDeletionStatusVideo: PropTypes.func.isRequired,
  deletionStatusVideoMap: PropTypes.object.isRequired,
};

const videosColumns = [
  {
    Header: '',
    accessor: 'thumbnail',
    disableSortBy: true,
    cellProps: { className: 'text-center' },
    Cell: DaperImgCell,
  },
  { Header: 'Name', accessor: 'name' },
  { Header: '#Qs', accessor: 'numberOfQuestions' },
  { Header: 'Series', accessor: 'seriesName' },
  { Header: 'Duration', accessor: 'duration' },
  {
    Header: 'Required',
    accessor: 'actions',
    Cell: VideoRequiredCell,
    disableSortBy: true,
  },
];

export const AssignedVideos = ({
  videoAssignments,
  deletionStatusVideoMap,
  setDeletionStatusVideo,
}) => {
  return (
    <>
      <Typography.SectionTitle>Select Required Videos</Typography.SectionTitle>
      <Table
        initialPageSize={50}
        size="sm"
        columns={videosColumns}
        initialData={videoAssignments}
        deletionStatusVideoMap={deletionStatusVideoMap}
        setDeletionStatusVideo={setDeletionStatusVideo}
      />
    </>
  );
};

AssignedVideos.propTypes = {
  videoAssignments: PropTypes.array.isRequired,
  deletionStatusVideoMap: PropTypes.object.isRequired,
  setDeletionStatusVideo: PropTypes.func.isRequired,
};

export default AssignedVideos;
