import { useState } from 'react';

import Button from '../../Button';
import CheckOrMinusIcon from '../../CheckOrMinusIcon';
import Container from '../../Container';
import DaperImgCell from '../../DaperImgCell';
import Table from '../../Table';
import { useFetchData } from '../../utils';
import { ActionsProps, IndexProps, StatusProps } from './types';

const Actions = ({ row }) => {
  return (
    <Button
      title="Edit"
      icon="edit"
      variant="light"
      size="sm"
      href={row.original.editAdminCourseCategoryPath}
    />
  );
};
Actions.propTypes = ActionsProps;

const Status = ({ cell }) => <CheckOrMinusIcon isTrue={!!cell.value} />;
Status.propTypes = StatusProps;

const columns = [
  { Header: 'ID', accessor: 'id', filteredAs: 'number' },
  {
    Header: 'Thumbnail',
    accessor: 'thumbnail',
    disableSortBy: true,
    cellProps: { className: 'text-center' },
    Cell: DaperImgCell,
  },
  { Header: 'Pos', accessor: 'position' },
  { Header: 'Created', accessor: 'createdAt' },
  { Header: 'Name', accessor: 'name', filteredAs: 'string' },
  { Header: 'Title', accessor: 'title', filteredAs: 'string' },
  {
    Header: 'Active',
    accessor: 'active',
    disableSortBy: true,
    Cell: Status,
  },
  {
    accessor: 'n/a',
    id: 'actions',
    disableSortBy: true,
    Cell: Actions,
  },
];
const scopes = [
  { id: 'isActive', title: 'Active' },
  { id: 'isInactive', title: 'Inactive' },
];

const cb = ({ totalCount, pageSize, courseCategories }) => ({
  data: courseCategories,
  pageSize,
  totalCount,
});

const Index = ({
  courseCategories,
  adminCourseCategoriesPath,
  totalCount,
  pageSize,
  pageIndex,
  sortBy,
}) => {
  const fetchData = useFetchData(adminCourseCategoriesPath, cb);
  const [{ refreshData }, setRefreshDataFn] = useState({
    refreshData: () => {},
  });

  return (
    <Container>
      <h1>Course Categories</h1>
      <Table
        initialData={courseCategories}
        columns={columns}
        initialPageSize={pageSize}
        initialPageIndex={pageIndex}
        initialSortBy={sortBy}
        initialTotalCount={totalCount}
        initialScopes={{ isActive: false, isInactive: false }}
        availableScopes={scopes}
        fetchData={fetchData}
        onRefreshDataFn={setRefreshDataFn}
        refreshData={refreshData}
      />
    </Container>
  );
};

Index.propTypes = IndexProps;

export default Index;
