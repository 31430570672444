import { bool } from 'prop-types';

import CheckOrMinusIcon from './CheckOrMinusIcon';

const ReversedCheckOrMinusCell = ({ value }) => (
  <CheckOrMinusIcon isTrue={!value} />
);

ReversedCheckOrMinusCell.propTypes = { value: bool.isRequired };

export default ReversedCheckOrMinusCell;
