import { arrayOf, bool, func, shape, string } from 'prop-types';

import { CourseProps, CourseVersionProps, TableProps } from '../../types';

export const StatusProps = {
  cell: shape({ value: CourseProps.activeVersionName }),
};

export const CourseActionsProps = {
  row: shape({
    original: shape(CourseProps),
  }),
  refreshData: func,
};

export const CourseFormModalProps = {
  show: bool,
  onHide: func,
  onSubmit: func,
  course: shape(CourseProps),
};

export const IndexProps = {
  ...TableProps,
  adminCoursesPath: string,
  courses: arrayOf(shape(CourseProps)),
  path: string,
};

export const IconCellProps = {
  cell: shape({ value: CourseVersionProps.icon }),
};

export const ShowProps = CourseProps;
