import {
  Button,
  ContextualMessage,
  FormField,
  GlobalStyles,
  Input,
  Switch,
  ThemeProvider,
  Typography,
} from '@bbnpm/bb-ui-framework';
import { Col, Row } from '@bloomberg/styled-flexboxgrid';
import { useForm } from 'react-hook-form';

import { BfeGrid } from '../../../styles/grid';
import PatchForm from '../../PatchForm';
import PostForm from '../../PostForm';
import { LeaderboardFormProps } from './types';

const LeaderboardForm = ({
  id,
  name,
  position,
  active: initialActive,
  adminTradingChallengeLeaderboardsPath,
  errors = {},
  adminHref,
  adminTradingChallengePath,
}) => {
  const [Form, action, submitText] = id
    ? [PatchForm, adminHref, 'Update']
    : [PostForm, adminTradingChallengeLeaderboardsPath, 'Submit'];

  const { register, watch } = useForm();
  const active = watch('active', initialActive);

  return (
    <ThemeProvider>
      <GlobalStyles />
      <BfeGrid>
        <Row>
          <Button
            as="a"
            kind="tertiary"
            icon="arrow-left"
            href={adminTradingChallengePath}>
            Back to Trading Challenge
          </Button>
        </Row>
        <Row>
          <Col>
            <Typography.PageTitle>
              {id ? 'Edit' : 'Add'} Leaderboard
            </Typography.PageTitle>
          </Col>
        </Row>
        <Form
          action={action}
          submitText={submitText}
          encType="multipart/form-data">
          <FormField
            label="Name"
            validationType={errors.name?.length && 'error'}
            validationContent={errors.name?.join(', ')}>
            <Input name="name" defaultValue={name || ''} required />
          </FormField>
          <FormField
            label="Position"
            labelDescription="Please use as week numbers"
            validationType={errors.position?.length && 'error'}
            validationContent={errors.position?.join(', ')}
            className="mb-3">
            <Input
              name="position"
              type="number"
              defaultValue={position || 1}
              min="0"
              required
            />
          </FormField>
          {id && (
            <FormField
              label="Active"
              validationType={errors.active?.length && 'error'}
              validationContent={errors.active?.join(', ')}>
              <Switch
                defaultChecked={initialActive}
                name="active"
                ref={register}
              />
              <input type="hidden" value={active} name="active" />
              {active && !initialActive && (
                <ContextualMessage
                  kind="warning"
                  message="Making this leaderboard active will set it as current leaderboard for this trading challenge. Only one leaderboard can be active at a time for this trading challenge."
                />
              )}
            </FormField>
          )}
        </Form>
      </BfeGrid>
    </ThemeProvider>
  );
};

LeaderboardForm.propTypes = LeaderboardFormProps;

export default LeaderboardForm;
