import DaperImg from './DaperImg';
import { VideoProps } from './types';

const DaperImgCell = ({ value }) =>
  value ? (
    <DaperImg metadata={value} height={50} />
  ) : (
    <i className="fa-file-image fas text-gray-300 fa-2x" />
  );

DaperImgCell.propTypes = { value: VideoProps.thumbnail };

export default DaperImgCell;
