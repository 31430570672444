import { arrayOf, bool, func, number, shape, string } from 'prop-types';

import { SeriesProps, TableProps, VideoProps } from '../../types';

export const SeriesActionsProps = {
  row: shape({
    original: shape(SeriesProps),
  }),
  refreshData: func,
};

export const SeriesFormModalProps = {
  show: bool,
  onHide: func,
  onSubmit: func,
  series: shape(SeriesProps),
};

export const IndexProps = {
  ...TableProps,
  adminSeriesPath: string,
  path: string,
  series: arrayOf(shape(SeriesProps)),
};

export const ActionsProps = {
  row: shape({
    original: shape(VideoProps).isRequired,
  }).isRequired,
};

export const ShowProps = {
  ...TableProps,
  ...SeriesProps,
  videos: arrayOf(shape(VideoProps)).isRequired,
  path: string.isRequired,
};

export const FeaturedVideoPanelProps = {
  video: shape(VideoProps),
};

export const FeaturedCellProps = {
  row: shape({
    original: shape(VideoProps).isRequired,
  }).isRequired,
  seriesUpdatePath: string,
  featuredVideoId: number,
  setFeaturedVideoId: func,
};
