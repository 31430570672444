import { Typography } from '@bbnpm/bb-ui-framework';
import { Badge } from 'react-bootstrap';

import { isNullish } from '../../utils';
import RequiredForCertificationIcon from '../RequiredForCertificationIcon';
import RequiredForClassIcon from '../RequiredForClassIcon';
import UserStatus from '../UserStatus';
import { CourseVersionProps } from '../types';

const CourseVersion = ({
  name,
  description,
  requiredForCertification,
  requiredForClass = false,
  eligibleForUpgrade = false,
  href,
  userInfo,
}) => (
  <Typography.Link
    href={href}
    className="text-reset d-block mb-4 p-4 bg-white border course-version text-decoration-none">
    <div>
      <Typography.SectionTitle style={{ fontWeight: 'bold' }}>
        {name}
        {requiredForCertification && <RequiredForCertificationIcon />}
        {requiredForClass && <RequiredForClassIcon />}
      </Typography.SectionTitle>
      <div className="ml-auto">
        {!isNullish(userInfo.score) && (
          <span>
            <strong>Score:</strong> {userInfo.score}%
          </span>
        )}
      </div>
    </div>
    <div
      style={{
        width: '70%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}>
      {description && <Typography.Text>{description}</Typography.Text>}
      <strong>
        <UserStatus status={userInfo.status} />
      </strong>
    </div>

    {eligibleForUpgrade && (
      <div className="h5 text-center mt-2">
        <Badge variant="primary" className="text-uppercase">
          New Version Available
        </Badge>
      </div>
    )}
  </Typography.Link>
);

CourseVersion.propTypes = CourseVersionProps;

export default CourseVersion;
