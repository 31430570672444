import { Col, Grid, Row } from '@bloomberg/styled-flexboxgrid';
import styled from 'styled-components';

const BfeGrid = styled(Grid)`
  padding: 0;

  @media only screen and (min-width: 35em) {
    width: 36rem;
  }

  @media only screen and (min-width: 47em) {
    width: 48rem;
  }

  @media only screen and (min-width: 79em) {
    width: 80rem;
  }

  @media only screen and (min-width: 89em) {
    width: 90rem;
  }
`;

export { BfeGrid, Row, Col };
