import { arrayOf, bool, shape, string } from 'prop-types';
import { Card, ListGroup } from 'react-bootstrap';

import Container from '../../Container';
import Input from '../../Input';
import PatchForm from '../../PatchForm';

const SettingsForm = ({ settings, adminSettingsPath }) => {
  return (
    <Container>
      <h1>Settings</h1>
      <PatchForm action={adminSettingsPath} submitText="Update Settings">
        <Card className="mb-4 w-50">
          <ListGroup variant="flush">
            {settings.map(({ key, type, value }) => (
              <ListGroup.Item key={key}>
                {type === 'boolean' && (
                  <Input
                    name={key}
                    type="switch"
                    formGroupProps={{ className: 'mb-0' }}
                    defaultChecked={value}
                  />
                )}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Card>
      </PatchForm>
    </Container>
  );
};

SettingsForm.propTypes = {
  adminSettingsPath: string.isRequired,
  settings: arrayOf(
    shape({
      key: string.isRequired,
      type: string.isRequired,
      value: bool,
    }),
  ),
};

export default SettingsForm;
