import { FileInput, Typography } from '@bbnpm/bb-ui-framework';
import { string } from 'prop-types';
import { useRef } from 'react';
import { useForm } from 'react-hook-form';

import AttachmentLink from './AttachmentLink';
import { AttachmentProps } from './types';

const AttachmentInputs = ({
  attachment,
  attributesKey,
  fileDirectory,
  professorPacksBucketName,
  label,
  referenceType,
}) => {
  const inputRef = useRef(null);

  const { register, watch } = useForm();
  const files = watch(`${attributesKey}[file]`);

  const fileChosen = files && files[0];

  return (
    <>
      <input
        type="hidden"
        name={`${attributesKey}[referenceType]`}
        defaultValue={referenceType}
      />
      <input
        type="hidden"
        name={`${attributesKey}[bucketName]`}
        defaultValue={professorPacksBucketName}
      />
      <input
        type="hidden"
        name={`${attributesKey}[fileDirectory]`}
        defaultValue={fileDirectory}
      />

      <label className="form-label" htmlFor={`${attributesKey}[file]`}>
        {label}
      </label>
      <div className="mb-4">
        <FileInput
          ref={ref => {
            inputRef.current = ref;
            register(ref);
          }}
          name={`${attributesKey}[file]`}
          id={`${attributesKey}[file]`}
          multiple={false}
          label={label}
          className="mr-2"
        />
        <Typography.Text className="d-inline">
          {fileChosen ? files[0].name : 'No file chosen'}
        </Typography.Text>
      </div>
      {attachment && (
        <Typography.Text>
          Current File: <AttachmentLink attachment={attachment} />
          <br />
          <Typography.FormLabel>
            <input
              type="hidden"
              name={`${attributesKey}[id]`}
              defaultValue={attachment.id}
            />
            <input type="checkbox" name={`${attributesKey}[_destroy]`} /> Remove
            current file?
          </Typography.FormLabel>
        </Typography.Text>
      )}

      {!attachment && !fileChosen && (
        <input type="hidden" name={`${attributesKey}[_destroy]`} value="true" />
      )}
    </>
  );
};

AttachmentInputs.propTypes = {
  attachment: AttachmentProps,
  attributesKey: string.isRequired,
  fileDirectory: string.isRequired,
  professorPacksBucketName: string.isRequired,
  label: string.isRequired,
  referenceType: string.isRequired,
};

export default AttachmentInputs;
