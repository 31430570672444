import {
  GlobalStyles,
  ThemeProvider,
  Typography,
} from '@bbnpm/bb-ui-framework';
import { Col } from '@bloomberg/styled-flexboxgrid';
import { arrayOf, bool, number, shape, string } from 'prop-types';
import { useEffect, useState } from 'react';
import { useLocalStorage } from 'react-use';

import { BfeGrid } from '../../styles/grid';
import AlertTerminalPlanNeedsUpgrade from '../AlertTerminalPlanNeedsUpgrade';
import VimeoPlayer from '../VimeoPlayer';
import BssoOptInModal, { BssoUpgradeModal } from '../bsso/BssoOptInModal';
import {
  BatClassProps,
  CourseVersionProps,
  PromoItemProps,
  UserProps,
  VideoProps,
  WebinarProps,
} from '../types';
import KeepWatching from './KeepWatching';
import LatestEnrollments from './LatestEnrollments';
import NewContent from './NewContent';
import PromoSection from './PromoSection.jsx';
import TitleCard from './TitleCard';
import Webinars from './Webinars';
import {
  StyledHr,
  StyledRow,
  TitleFooterContainer,
  TitleFooterHero,
} from './styles';

const Index = ({
  currentUser,
  latestVideos,
  startedCourseVersions,
  createWithClassCodeClassEnrollmentsPath,
  professorNewClassPath,
  batClassesPath,
  terminalPlanNeedsUpgrade,
  newBmcSubscriptionPath,
  batClasses,
  webinars,
  showBssoOptInModal,
  forceBssoLoginUpgrade,
  bssoUrl,
  backgroundImg,
  promotionalItems,
  latestCourseVersions,
}) => {
  const BssoModal = forceBssoLoginUpgrade ? BssoUpgradeModal : BssoOptInModal;
  const [isOpen, setIsOpen] = useState(showBssoOptInModal);
  const [hideBssoModalLS, updateHideBssoModalLS] =
    useLocalStorage('hideBssoModal');
  const [hideBssoModal, updateHideBssoModal] = useState(true);

  const handleBssoSkip = () => {
    updateHideBssoModalLS(true);
    setIsOpen(false);
  };

  useEffect(() => {
    updateHideBssoModal(hideBssoModalLS);
  }, [hideBssoModalLS, updateHideBssoModal]);

  const showBssoModal = hideBssoModal ? false : isOpen;

  return (
    <ThemeProvider>
      <GlobalStyles />
      <TitleCard
        title={`Welcome ${currentUser.firstName},`}
        description="Discover how to use Bloomberg in new, creative and exciting
                ways."
        backgroundImg={backgroundImg}
      />
      <TitleFooterHero>
        <TitleFooterContainer>
          {startedCourseVersions && (
            <KeepWatching
              href={startedCourseVersions.href}
              thumbnail={
                startedCourseVersions.course.thumbnail
                  ? startedCourseVersions.course.thumbnail
                  : startedCourseVersions.course.courseCategory.thumbnail
              }
              courseVersionName={startedCourseVersions.name}
              courseCategoryTitle={
                startedCourseVersions.course.courseCategory.title
              }
            />
          )}
          {batClassesPath && (
            <LatestEnrollments
              type={currentUser.type}
              batClasses={batClasses}
              batClassesPath={batClassesPath}
              createWithClassCodeClassEnrollmentsPath={
                createWithClassCodeClassEnrollmentsPath
              }
              professorNewClassPath={professorNewClassPath}
            />
          )}
        </TitleFooterContainer>
      </TitleFooterHero>
      <BfeGrid>
        <BssoModal
          bssoUrl={bssoUrl}
          show={showBssoModal}
          onHide={handleBssoSkip}
        />
        {terminalPlanNeedsUpgrade && (
          <AlertTerminalPlanNeedsUpgrade
            className="mt-3"
            url={newBmcSubscriptionPath}
          />
        )}

        {promotionalItems.length > 0 && (
          <PromoSection promotionalItems={promotionalItems} />
        )}

        <StyledHr />

        <StyledRow>
          <Col xs={12} md={6}>
            <NewContent
              latestVideos={latestVideos}
              latestCourseVersions={latestCourseVersions}
            />
          </Col>
          <Col xs={12} md={6}>
            {currentUser.type === 'Professor' ? (
              <>
                <Typography.PageTitle>
                  Bloomberg for Education - Professor Guide
                </Typography.PageTitle>
                <VimeoPlayer vimeoId="751652248" />
              </>
            ) : (
              <>
                <Typography.PageTitle>
                  Getting Started on the Terminal
                </Typography.PageTitle>
                <VimeoPlayer vimeoId="539093714" />
              </>
            )}
          </Col>
        </StyledRow>

        <StyledHr />

        <StyledRow>
          <Col xs={12}>
            <Webinars webinars={webinars} />
          </Col>
        </StyledRow>
      </BfeGrid>
    </ThemeProvider>
  );
};

Index.propTypes = {
  currentUser: shape(UserProps),
  createWithClassCodeClassEnrollmentsPath: string,
  batClassesPath: string,
  batClasses: arrayOf(shape(BatClassProps)),
  courseRegistrationsCount: number,
  videoRegistrationsCount: number,
  latestVideos: arrayOf(shape(VideoProps)),
  startedCourseVersions: shape(CourseVersionProps),
  professorNewClassPath: string,
  terminalPlanNeedsUpgrade: bool,
  newBmcSubscriptionPath: string,
  webinarsPath: string.isRequired,
  bssoUrl: string,
  showBssoOptInModal: bool,
  backgroundImg: string,
  promotionalItems: arrayOf(shape(PromoItemProps)),
  latestCourseVersions: arrayOf(shape(CourseVersionProps)),
  webinars: arrayOf(shape(WebinarProps)),
};

export default Index;
