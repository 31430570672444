import cn from 'classnames';
import { bool, string } from 'prop-types';
import { useEffect } from 'react';
import { Button, ButtonGroup, Image } from 'react-bootstrap';
import { useCopyToClipboard, useTimeoutFn, useToggle } from 'react-use';

import Tooltip from './Tooltip';

export const CertificateAsideProps = {
  certificateAvailable: bool.isRequired,
  downloadCertificatePath: string.isRequired,
  linkedinImagePath: string.isRequired,
  certificateUrl: string,
};

const CertificateAside = ({
  certificateAvailable,
  downloadCertificatePath,
  linkedinImagePath,
  certificateUrl,
}) => {
  const [isCopied, toggleIsCopied] = useToggle(false);
  const [, cancel, reset] = useTimeoutFn(toggleIsCopied, 2000);
  const [, copyToClipboard] = useCopyToClipboard();

  useEffect(cancel, [cancel]);

  const handleCopyToClipboard = () => {
    copyToClipboard(certificateUrl);
    toggleIsCopied(true);
    reset();
  };

  return (
    <div className="aside-flourish mb-3">
      <div className="d-flex mb-3">
        <h4>Bloomberg Certificate</h4>
        <i className="fas fa-award ml-auto fa-2x text-primary" />
      </div>

      {certificateAvailable ? (
        <>
          <Button
            href={downloadCertificatePath}
            variant="outline-dark"
            target="_blank"
            rel="noreferrer">
            Download
          </Button>

          <ButtonGroup className="ml-3">
            <Button
              href={certificateUrl}
              variant="outline-dark"
              target="_blank"
              rel="noreferrer">
              Verification Link
            </Button>
            <Tooltip
              key={`is-copied-${isCopied}`}
              tooltip={isCopied ? 'Copied!' : 'Copy to clipboard'}
              tooltipProps={{
                className: cn({ 'tooltip-success': isCopied }),
              }}
              show={isCopied ? true : undefined}>
              <Button
                disabled={isCopied}
                onClick={handleCopyToClipboard}
                variant={isCopied ? 'outline-success' : 'outline-dark'}>
                <i
                  className={cn('fas fa-fw fa-sm', {
                    'fa-clipboard': !isCopied,
                    'fa-check': isCopied,
                  })}></i>
              </Button>
            </Tooltip>
          </ButtonGroup>
          <div className="mt-3 text-right">
            <a
              href="https://www.linkedin.com/profile/add"
              target="_blank"
              rel="nofollow noopener noreferrer">
              <Image
                fluid
                src={linkedinImagePath}
                alt="LinkedIn Add to Profile button"
              />
            </a>
          </div>
        </>
      ) : (
        <div>
          To earn the Bloomberg Certificate, you must complete all courses with
          the certificate icon.
        </div>
      )}
    </div>
  );
};

CertificateAside.propTypes = CertificateAsideProps;

export default CertificateAside;
