import cn from 'classnames';
import { bool } from 'prop-types';

const CheckOrMinusIcon = ({ isTrue }) => (
  <i
    className={cn('fas', {
      'fa-check-circle': isTrue,
      'fa-minus-circle': !isTrue,
      'text-danger': !isTrue,
    })}
  />
);

CheckOrMinusIcon.propTypes = { isTrue: bool.isRequired };

export default CheckOrMinusIcon;
