import { Table } from '@bbnpm/bb-ui-framework';

import AddCertificationButton from './AddCertificationButton';
import { CertificationTableProps } from './types';

const CertificationTable = ({
  certificationsInfo,
  addCertificationEnabled,
}) => (
  <Table fixedHeader={true} hover={true}>
    <thead>
      <tr>
        <th>ID</th>
        <th>Name</th>
        <th>Title</th>
        <th>Certified At</th>
        <th>Status</th>
      </tr>
    </thead>
    <tbody>
      {certificationsInfo.map(certificationInfo => (
        <tr key={certificationInfo.id}>
          <td>{certificationInfo.id}</td>
          <td>{certificationInfo.name.toUpperCase()}</td>
          <td>{certificationInfo.title}</td>
          <td>{certificationInfo.certifiedAt}</td>
          <td>{certificationInfo.status}</td>
          {addCertificationEnabled && (
            <td>
              <AddCertificationButton
                disabled={certificationInfo.status === 'Complete'}
                courseCategoryId={certificationInfo.id}
                courseCategoryName={certificationInfo.name.toUpperCase()}
                courseCategoryTitle={certificationInfo.title}
                action={certificationInfo.addCertificationPath}
              />
            </td>
          )}
        </tr>
      ))}
    </tbody>
  </Table>
);

CertificationTable.propTypes = CertificationTableProps;

export default CertificationTable;
