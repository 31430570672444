import { Button, Modal, Typography } from '@bbnpm/bb-ui-framework';
import styled from 'styled-components';

import PostForm from '../PostForm';
import { BssoOptInModalProps } from './types';

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  button:first-child {
    margin-right: 1em;
  }
`;

const BssoOptInModal = ({ show, onHide, bssoUrl }) => {
  return (
    <Modal
      portalContainerId="bfe-root"
      isOpen={show}
      onClose={onHide}
      title="Bloomberg Single-Sign-On">
      <>
        <Typography.Text>
          We&apos;ve detected that your email is associated to a known Bloomberg
          Professional account. You can opt-in to leverage Bloomberg
          Single-Sign-On to access your Bloomberg for Education certificates.
        </Typography.Text>
        <Typography.Text>
          Upon opt-in, email/password will no longer be available for this
          account, and future log in will be via the &quot;Continue with
          Bloomberg&quot; option.
        </Typography.Text>
        <Typography.Text>
          Bloomberg Single-Sign-On is a secure Bloomberg authentication service
          that allows you access to Bloomberg services from whenever you are.
          You can later opt-in to the service under the &quot;Profile&quot; link
          once logged in.
        </Typography.Text>
        <PostForm action={bssoUrl}>
          <ButtonGroup>
            <Button kind="primary" type="submit">
              Opt In
            </Button>
            <Button kind="secondary" onClick={onHide}>
              Skip
            </Button>
          </ButtonGroup>
        </PostForm>
      </>
    </Modal>
  );
};

export const BssoUpgradeModal = ({ show, bssoUrl }) => {
  return (
    <Modal
      portalContainerId="bfe-root"
      isOpen={show}
      title="Bloomberg Single-Sign-On">
      <>
        <Typography.Text>
          We&apos;ve detected that your email is associated to a known Bloomberg
          Professional account. You <strong>must</strong> upgrade your account
          to use Bloomberg Single-Sign-On to access your Bloomberg for Education
          account and certificates.
        </Typography.Text>
        <Typography.Text>
          Future logins will be via the &quot;Continue with Bloomberg&quot;
          option.
        </Typography.Text>
        <Typography.Text>
          Bloomberg Single-Sign-On is a secure Bloomberg authentication service
          that allows you access to Bloomberg services from wherever you are.
        </Typography.Text>
        <PostForm action={bssoUrl}>
          <ButtonGroup>
            <Button kind="primary" type="submit">
              Upgrade
            </Button>
          </ButtonGroup>
        </PostForm>
      </>
    </Modal>
  );
};

BssoOptInModal.propTypes = BssoOptInModalProps;

export default BssoOptInModal;
