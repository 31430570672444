import { Button, Modal, Typography } from '@bbnpm/bb-ui-framework';

import PostForm from '../PostForm';

const RegistrationBssoOptInModal = ({ show, onHide, bssoUrl }) => {
  return (
    <Modal
      portalContainerId="bfe-root"
      isOpen={show}
      onClose={onHide}
      title="Bloomberg Single-Sign-On Required for Trading Challenge"
      primaryActions={
        <PostForm action={bssoUrl}>
          <Button kind="primary" type="submit" style={{ marginRight: '1rem' }}>
            Opt In
          </Button>
          <Button kind="secondary" onClick={onHide}>
            Cancel
          </Button>
        </PostForm>
      }>
      <>
        <Typography.Text>
          To Participate in the challenge, your Bloomberg Terminal Login and
          your Education Portal Account must be linked. We call this Single
          Sign-On (SSO).
        </Typography.Text>
        <Typography.Text>
          We&apos;ve detected that you DO have a Terminal Login Name, but it
          isn&apos;t yet Linked to this Web Portal Account.
        </Typography.Text>
        <Typography.Text>
          <strong>
            Would you like us to link your Terminal Login and Portal Account?
          </strong>
        </Typography.Text>
        <Typography.Text>
          This will log you out briefly, and will direct you to the Single
          Sign-On login screen. Please sign-in again using your Bloomberg
          Terminal login name and password to link your accounts. After
          you&apos;ve opted-in, all your future logins will be via the
          &quot;Continue with Bloomberg&quot; option.
        </Typography.Text>
      </>
    </Modal>
  );
};

export default RegistrationBssoOptInModal;
